import React from 'react';
import { useParams } from 'react-router-dom';
import IpsInfo from '../IpsInfo';
import DevicesInfo from '../DevicesInfo';
import AccountsInfo from '../AccountsInfo';
import AccountsCountriesInfo from '../AccountsCountriesInfo';
import DevicesCountriesInfo from '../DevicesCountriesInfo';
import MostUsedRuleInfo from '../MostUsedRuleInfo';
import SystemKPI from '../SystemKPI';
import ServerStats from '../ServerStats';

const reportPages = {
	'ips-info': <IpsInfo />,
	'devices-info': <DevicesInfo />,
	'accounts-info': <AccountsInfo />,
	'accounts-countries-info': <AccountsCountriesInfo />,
	'devices-countries-info': <DevicesCountriesInfo />,
	'most-used-rule': <MostUsedRuleInfo />,
	'system-kpi': <SystemKPI />,
	'server-stats': <ServerStats />,
};

const ReportsInfo = (props) => {
	const params = useParams();

	return reportPages[params.type] ?? <IpsInfo />;
};

export default ReportsInfo;
