import React from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { createRule } from 'actions/rulesActions';
import { RuleForm, withAdmin } from 'components';
import destroyRulesIds from 'helpers/destroyRuleIds';
import clearArray from 'helpers/clearEmptyStrings';

const NewRule = (props) => {
	const navigate = useNavigate();

	const { createRule, createRuleStatus } = props;

	const handleSubmit = ({ rules, exRuleSet, defaultTimes, ...rest }) => {
		createRule(
			{
				...rest,
				validTo: defaultTimes ? new Date(2099, 11, 31) : rest.validTo,
				validFrom: defaultTimes ? new Date(2000, 0, 1) : rest.validFrom,
				exRuleSet: clearArray(exRuleSet),
				rules: destroyRulesIds(rules),
			},
			() => navigate('/rule-manager')
		);
	};

	return (
		<RuleForm
			handleSubmit={handleSubmit}
			buttonLabel='Create rule'
			status={createRuleStatus}
		/>
	);
};

const mapStateToProps = ({ rules }) => ({
	createRuleStatus: rules.createRuleStatus,
});

const mapDispatchToProps = (dispatch) => ({
	createRule: (body, onLoad) => dispatch(createRule(body, onLoad)),
});

export default withAdmin(connect(mapStateToProps, mapDispatchToProps)(NewRule));
