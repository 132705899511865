import React from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { Wrapper, Status } from '@googlemaps/react-wrapper';
import './styles.css';
import Map from './components/Map';
import Marker from './components/Marker';

const render = (status) => {
	if (status === Status.LOADING) return <CircularProgress />;
	if (status === Status.FAILURE) {
		return <Typography variant='h4'> Map render ERROR </Typography>;
	}
	return null;
};

const GoogleMap = ({ location }) => {
	const zoom = 6;
	const { lon, lat } = location ?? {};

	const center = React.useRef({ lat, lng: lon });

	if (!lon || !lat) {
		return null;
	}

	const MapStyles = { flexGrow: '1', height: '100%' };

	return (
		<div className='wrapperContainer'>
			<Wrapper
				apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
				render={render}
			>
				<Map center={center.current} zoom={zoom} style={MapStyles}>
					<Marker position={center.current} />
				</Map>
			</Wrapper>
		</div>
	);
};

export default GoogleMap;
