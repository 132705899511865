import React, { useEffect } from 'react';
import { Typography, Box, Card, CardHeader, CardContent } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import { LOADING } from 'constants/apiStatuses';
import './styles.css';
import { Pie } from 'react-chartjs-2';
import { rowsPerPageOptions } from 'constants/tablesVariables';
import { Chart, ArcElement, Tooltip, BarController } from 'chart.js';
import { getRowColorByMark } from 'helpers/getTableRowColor';
Chart.register(ArcElement, Tooltip, BarController);

const ReportsPage = ({
	getInfo,
	data,
	status,
	title,
	columns,
	DataGridProps = {},
	chartConfig,
	chartOptions = {},
	onChartPartClick,
}) => {
	const theme = useTheme();
	const [paginationModel, setPaginationModel] = React.useState({
		page: 0,
		pageSize: rowsPerPageOptions[0],
	});

	useEffect(() => {
		getInfo();
	}, []);

	const handlePaginationModelChange = (newPaginationModel) => {
		setPaginationModel(newPaginationModel);
	};

	const pieLegendColor = { labels: { color: theme.palette.text.secondary } };

	const pieChartOptions = {
		...chartOptions,
		plugins: {
			legend: {
				...(chartOptions.plugins?.legend ?? {}),
				...pieLegendColor,
			},
		},
		onClick(event, elements, chart) {
			if (elements[0]) {
				onChartPartClick(data[elements[0].index]);
			}
		},
	};

	const pageTitle = <Typography variant='h5'>{title}</Typography>;

	return (
		<Card>
			<CardHeader title={pageTitle} />
			<CardContent>{status === LOADING && <div>Loading...</div>}</CardContent>
			<CardContent>
				{data && (
					<Box className='pieChart'>
						<Pie data={chartConfig} options={pieChartOptions} />
					</Box>
				)}
			</CardContent>
			<CardContent>
				{data && (
					<DataGrid
						autoHeight
						disableColumnMenu={true}
						paginationMode='client'
						columns={columns}
						getRowClassName={getRowColorByMark}
						rows={data}
						paginationModel={paginationModel}
						pageSizeOptions={rowsPerPageOptions}
						onPaginationModelChange={handlePaginationModelChange}
						{...DataGridProps}
					/>
				)}
			</CardContent>
		</Card>
	);
};

export default ReportsPage;
