import { CustomTableCell, PureTableCell } from 'components';
import React from 'react';

const ipsWithMostAccounts = [
	{
		field: 'ip',
		editable: false,
		headerName: 'Ip',
		headerAlign: 'center',
		flex: 1,
		renderCell: (params) => (
			<CustomTableCell
				getNewTabLink={(id) =>
					`${window.location.origin}/?tab=ipBlocks&ip=${id}`
				}
				{...params}
			/>
		),
	},
	{
		field: 'accountsCount',
		editable: false,
		headerName: 'Accounts count',
		headerAlign: 'center',
		flex: 1,
		renderCell: (params) => <PureTableCell {...params} />,
	},
	{
		field: 'result',
		headerName: 'Result',
		width: 80,
		hideSortIcons: true,
		sortable: false,
		headerAlign: 'center',
		valueGetter: () => ' ',
		renderCell: () => <span className='scoreIndicator' />,
	},
];

export default ipsWithMostAccounts;
