import React from 'react';
import { BeatLoader } from 'react-spinners';
import './styles.css';

const LoadingSpinner = () => {
	return (
		<div className='loadingSpinner'>
			<BeatLoader color='#007bff' size={15} />
		</div>
	);
};

export default LoadingSpinner;
