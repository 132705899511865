import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import RefreshIcon from '@mui/icons-material/Refresh';
import { ReactComponent as ClearAllIcon } from 'icons/clearFilter.svg';
import {
	getDevices,
	changeFilterField,
	resetFilters,
} from 'actions/devicesActions';
import getQueryFromSearchParams from 'helpers/getQueryFromSearchParams';
import devicesColumns from 'constants/columns/devices';
import { Pagination, FilterProvider, CustomInput } from 'components';
import './styles.css';
import { LOADING } from 'constants/apiStatuses';
import defaultSortModels from 'constants/defaultHeaderSortModels';
import { VALIDATION_TYPES } from '../../helpers/validations/validateFilters';
import LoadingSpinner from '../../components/LoadingSpinner';

const DevicesPage = (props) => {
	const navigate = useNavigate();
	const location = useLocation();
	const filtersQuery = new URLSearchParams(location.search);

	const {
		filters,
		devices,
		devicesTotalAmount,
		getDevicesStatus,
		getDevices,
		changeFilterField,
		resetFilters,
	} = props;

	const handleRowClick = ({ id }) => {
		navigate(`/devices/${id}`);
	};

	useEffect(() => {
		filtersQuery.delete('tab');
		resetFilters(getQueryFromSearchParams(filtersQuery.entries()));
	}, []);

	const getTableClass = (state) => {
		return `${getDevicesStatus === LOADING ? 'disablePageButton' : ''} ${
			state ? 'removePageButton' : ''
		}`;
	};

	const getRowColorByMark = (params) => {
		return params.row.isMarked ? 'tableCellRed' : 'tableCellGreen';
	};

	return (
		<>
			<Typography variant='h4'>Devices</Typography>
			<Pagination
				getItems={getDevices}
				changeFilterField={changeFilterField}
				filters={filters}
				defaultSortModel={defaultSortModels.device}
				urlToReset='?tab=devices'
				resetFilters={resetFilters}
				initialFilters={getQueryFromSearchParams(filtersQuery.entries())}
				validationType={VALIDATION_TYPES.DEVICES}
			>
				{({
					handleChangeFilters,
					handleRefresh,
					page,
					handleAddQuery,
					handleResetFilters,
					pageSize,
					rowsPerPageOptions,
					isArrowBlocked,
					handleHeaderFilterChange,
					sortModel,
					handlePaginationModelChange,
				}) => (
					<>
						<Box className='deviceFilterBar'>
							<CustomInput
								value={filters.uuid}
								name='uuid'
								onBlur={handleAddQuery}
								onChange={handleChangeFilters}
								label='uuid'
							/>
							<CustomInput
								value={filters.id}
								name='id'
								onBlur={handleAddQuery}
								onChange={handleChangeFilters}
								label='Id'
							/>
							<CustomInput
								value={filters.app}
								name='app'
								onBlur={handleAddQuery}
								onChange={handleChangeFilters}
								label='Browser'
							/>
							<CustomInput
								value={filters.osType}
								name='osType'
								onBlur={handleAddQuery}
								onChange={handleChangeFilters}
								label='OS'
							/>
							<CustomInput
								value={filters.osVersion}
								name='osVersion'
								onBlur={handleAddQuery}
								onChange={handleChangeFilters}
								label='OS Version'
							/>
							<CustomInput
								value={filters.gpu}
								name='gpu'
								onBlur={handleAddQuery}
								onChange={handleChangeFilters}
								label='GPU'
							/>
							<IconButton
								className='deviceFilterInput'
								color='primary'
								onClick={() => handleRefresh(pageSize)}
							>
								<RefreshIcon fontSize='small' className='deviceClearAllIcon' />
							</IconButton>
							<Tooltip title='Clear filters'>
								<IconButton
									className='deviceFilterInput'
									color='primary'
									onClick={handleResetFilters}
								>
									<ClearAllIcon className='deviceClearAllIcon' />
								</IconButton>
							</Tooltip>
						</Box>
						{getDevicesStatus === LOADING ? (
							<LoadingSpinner />
						) : (
							devices && (
								<FilterProvider changeFilterField={changeFilterField}>
									<DataGrid
										autoHeight
										className={getTableClass(isArrowBlocked)}
										onRowClick={handleRowClick}
										getRowClassName={getRowColorByMark}
										disableColumnMenu={true}
										columns={devicesColumns}
										rows={devices}
										paginationMode='server'
										rowCount={devicesTotalAmount}
										paginationModel={{ page, pageSize }}
										pageSizeOptions={rowsPerPageOptions}
										onPaginationModelChange={handlePaginationModelChange}
										sortingMode='server'
										onSortModelChange={handleHeaderFilterChange}
										sortModel={sortModel}
									/>
								</FilterProvider>
							)
						)}
					</>
				)}
			</Pagination>
		</>
	);
};

const mapStateToProps = ({ devices }) => ({
	devicesCursor: devices.devicesCursor,
	pagesCursors: devices.pagesCursors,
	devicesTotalAmount: devices.total,
	filters: devices.filters,
	devices: devices.data,
	getDevicesStatus: devices.getDevicesStatus,
});

const mapDispatchToProps = (dispatch) => ({
	getDevices: (cursor, type, filters) =>
		dispatch(getDevices(cursor, type, filters)),
	changeFilterField: (value) => dispatch(changeFilterField(value)),
	resetFilters: (body) => dispatch(resetFilters(body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DevicesPage);
