import React, { Fragment } from 'react';
import {
	Box,
	FormHelperText,
	IconButton,
	TextField,
	Autocomplete,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import '../../styles.css';
import '../../styles.css';

const RuleSetManager = (props) => {
	const { fields, errors, touched, ruleSets, TextFieldLabel } = props;

	const handleChangeRuleSet = (value, index) => {
		const ruleSetArray =
			value?.split('\n').filter((rule) => rule.trim() !== '') ?? [];

		const updatedValue =
			ruleSetArray.length === 1 ? ruleSetArray[0] : ruleSetArray;

		fields.update(index, updatedValue);
	};

	const handleAddRuleSet = () => {
		fields.push('');
	};

	const handleDeleteRuleSet = (index) => {
		fields.remove(index);
	};

	const handleShowIcon = (condition, type, onClick) => {
		return (
			condition && (
				<IconButton disableTouchRipple onClick={onClick}>
					{type === 'add' ? <AddIcon /> : <CloseIcon />}
				</IconButton>
			)
		);
	};

	const checkVisibility = (index) => {
		return fields.value[index] !== '' || TextFieldLabel === 'Rule set';
	};

	const handleEnterKeyPress = (e, index) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			const value = e.target.value.trim();
			if (value.includes(',')) {
				const valuesArray = value.split(',').map((v) => v.trim());
				valuesArray.forEach((val, i) => {
					if (i === 0) {
						fields.update(index, val);
					} else {
						fields.push(val);
					}
				});
			} else {
				fields.update(index, value);
				handleAddRuleSet();
			}
		}
	};

	return fields.map((name, index) => {
		return (
			<Fragment key={name}>
				<Box className='input' display='flex' alignItems='center'>
					<Autocomplete
						fullWidth
						freeSolo
						options={ruleSets}
						value={fields.value[index]}
						getOptionLabel={(option) => option}
						onInputChange={(_, value) => {
							handleChangeRuleSet(value, index);
						}}
						onChange={(_, value) => handleChangeRuleSet(value, index)}
						renderInput={(params) => (
							<TextField
								{...params}
								multiline
								error={errors[index] && touched}
								autoComplete='off'
								label={TextFieldLabel}
								InputLabelProps={{ shrink: true }}
								onKeyDown={(e) => handleEnterKeyPress(e, index)}
							/>
						)}
					/>
					{checkVisibility(index) &&
						handleShowIcon(
							index === fields.value.length - 1,
							'add',
							handleAddRuleSet
						)}
					{handleShowIcon(fields.value.length !== 1, 'delete', () =>
						handleDeleteRuleSet(index)
					)}
				</Box>
				{errors[index] && touched && (
					<FormHelperText error={true} className='ruleHelperText'>
						{errors[index]}
					</FormHelperText>
				)}
			</Fragment>
		);
	});
};

export default RuleSetManager;
