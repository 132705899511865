import axiosInstance from '../index';
import { parseISO } from 'date-fns';

const getRules = async (filters) => {
	let ret = await axiosInstance.get(`/rules?ruleSet=${filters.ruleSet}`);
	ret = ret.data;
	for (let rule of ret.rules) {
		rule.validFrom = parseISO(rule.validFrom);
		rule.validTo = parseISO(rule.validTo);
	}

	return ret;
};

export { getRules };
