import { getStats as getStatsAPI } from '../../services/api/fpStats/getStats';
import { FPSTATS_GET_STATS_STATUS, FPSTATS_GET_STATS } from '../constants';
import { LOADING, ERROR, NONE } from 'constants/apiStatuses';

export const setGetStatsStatus = (status) => ({
	type: FPSTATS_GET_STATS_STATUS,
	payload: status,
});

export const setGetStats = (getStats) => ({
	type: FPSTATS_GET_STATS,
	payload: getStats,
});

export const getStats = () => (dispatch, getState) => {
	dispatch(setGetStatsStatus(LOADING));
	getStatsAPI()
		.then((data) => {
			dispatch(setGetStats(data));
			dispatch(setGetStatsStatus(NONE));
		})
		.catch(() => dispatch(setGetStatsStatus(ERROR)));
};
