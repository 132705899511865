export const getEvidenceHeight = (evidence) => {
	if (!evidence) {
		return 0;
	}
	const commentsItems = evidence.comments?.length ?? 0;
	const endDateItem = evidence.status === 'active' ? +!!evidence.endDate : 0;
	const items = commentsItems + endDateItem;

	// const totalHeight = 185 + items * 53;
	return 300 + items * 53;
};

export const getRowHeight = (params) => {
	const evidence = params.model;
	return getEvidenceHeight(evidence);
};

export const getTableHeight = (data) => {
	if (!data?.length) {
		return 215;
	}
	const contentHeight =
		data?.reduce((t, item) => t + getEvidenceHeight(item), 0) ?? 0;
	return 111 + contentHeight;
};
