import { LOADING, NONE, ERROR } from 'constants/apiStatuses';
import { getSingleRule as getSingleRuleAPI } from 'services/api/rules/getSingleRule';
import { deleteRule as deleteRuleAPI } from 'services/api/rules/deleteRule';
import { getRules as getRulesAPI } from 'services/api/rules/getRules';
import { createRule as createRuleAPI } from 'services/api/rules/createRule';
import { editRule as editRuleAPI } from 'services/api/rules/editRule';
import { getRuleKeys as getRuleKeysAPI } from 'services/api/rules/getRuleKeys';
import { getRulesSets as getRuleSetsAPI } from 'services/api/rules/getRuleSets';
import { createRuleField as createRuleFieldAPI } from 'services/api/rules/createRuleField';
import { deleteRuleField as deleteRuleFieldAPI } from 'services/api/rules/deleteRuleField';
import {
	RULES_GET_RULES_STATUS,
	RULES_SET_RULES,
	RULES_DELETE_RULE,
	RULES_SET_SINGLE_RULE,
	RULES_DELETE_RULE_STATUS,
	RULES_GET_SINGLE_RULE_STATUS,
	RULES_CREATE_RULE_STATUS,
	RULES_EDIT_RULE_STATUS,
	RULES_GET_KEYS_STATUS,
	RULES_SET_KEYS,
	RULES_CHANGE_FILTER_FIELD,
	RULES_SET_RULESETS,
	RULES_GET_RULESETS_STATUS,
	CREATE_RULE_FIELD_STATUS,
	DELETE_RULE_FIELD_STATUS,
	DELETE_RULE_FIELD,
} from '../constants';

export const setGetRulesStatus = (status) => ({
	type: RULES_GET_RULES_STATUS,
	payload: status,
});

export const setRules = (rules) => ({
	type: RULES_SET_RULES,
	payload: rules,
});

export const getRules = (filters, refresh) => (dispatch, getState) => {
	if (!refresh) {
		dispatch(setGetRulesStatus(LOADING));
	}
	getRulesAPI(filters)
		.then((rules) => {
			dispatch(setRules(rules));
			dispatch(setGetRulesStatus(NONE));
		})
		.catch(() => dispatch(setGetRulesStatus(ERROR)));
};

export const setGetSingleRuleStatus = (status) => ({
	type: RULES_GET_SINGLE_RULE_STATUS,
	payload: status,
});

export const setRule = (rule) => ({
	type: RULES_SET_SINGLE_RULE,
	payload: rule,
});

export const getSingleRule = (id) => (dispatch, getState) => {
	dispatch(setGetSingleRuleStatus(LOADING));
	getSingleRuleAPI(id)
		.then((rule) => {
			dispatch(setRule(rule));
			dispatch(setGetSingleRuleStatus(NONE));
		})
		.catch(() => dispatch(setGetSingleRuleStatus(ERROR)));
};

export const setCreateRuleStatus = (status) => ({
	type: RULES_CREATE_RULE_STATUS,
	payload: status,
});

export const createRule = (body, onLoad) => (dispatch, getState) => {
	dispatch(setCreateRuleStatus(LOADING));
	createRuleAPI(body)
		.then((rule) => {
			dispatch(setCreateRuleStatus(NONE));
			onLoad();
		})
		.catch(() => dispatch(setCreateRuleStatus(ERROR)));
};

export const setCreateRuleFieldStatus = (status) => ({
	type: CREATE_RULE_FIELD_STATUS,
	payload: status,
});

export const createRuleField = (body, onLoad) => (dispatch, getState) => {
	dispatch(setCreateRuleFieldStatus(LOADING));
	createRuleFieldAPI(body)
		.then((rule) => {
			dispatch(setCreateRuleFieldStatus(NONE));
			onLoad();
		})
		.catch(() => dispatch(setCreateRuleFieldStatus(ERROR)));
};

export const setDeleteRuleFieldStatus = (status) => ({
	type: DELETE_RULE_FIELD_STATUS,
	payload: status,
});

export const setDeleteRuleField = (ruleField) => ({
	type: DELETE_RULE_FIELD,
	payload: ruleField,
});

export const deleteRuleField = (id, onLoad) => (dispatch, getState) => {
	dispatch(setDeleteRuleFieldStatus(LOADING));
	deleteRuleFieldAPI(id)
		.then((reason) => {
			dispatch(setDeleteRuleFieldStatus(NONE));
			dispatch(setDeleteRuleField(reason));
			onLoad();
		})
		.catch(() => dispatch(setDeleteRuleFieldStatus(ERROR)));
};

export const setEditRuleStatus = (status) => ({
	type: RULES_EDIT_RULE_STATUS,
	payload: status,
});

export const editRule = (body, onLoad) => (dispatch, getState) => {
	dispatch(setEditRuleStatus(LOADING));
	editRuleAPI(body)
		.then((rule) => {
			dispatch(setEditRuleStatus(NONE));
			onLoad();
		})
		.catch(() => dispatch(setEditRuleStatus(ERROR)));
};

export const setDeleteRuleStatus = (status) => ({
	type: RULES_DELETE_RULE_STATUS,
	payload: status,
});

export const setDeleteRule = (rule) => ({
	type: RULES_DELETE_RULE,
	payload: rule,
});

export const deleteRule = (id, onLoad) => (dispatch, getState) => {
	dispatch(setDeleteRuleStatus(LOADING));
	deleteRuleAPI(id)
		.then((rule) => {
			dispatch(setDeleteRule(rule));
			onLoad();
			dispatch(setDeleteRuleStatus(NONE));
		})
		.catch(() => dispatch(setDeleteRuleStatus(ERROR)));
};

export const setGetRuleKeysStatus = (status) => ({
	type: RULES_GET_KEYS_STATUS,
	payload: status,
});

export const setRuleKeys = (ruleKeys) => ({
	type: RULES_SET_KEYS,
	payload: ruleKeys,
});

export const getRuleKeys = (dispatch, getState) => {
	dispatch(setGetRuleKeysStatus(LOADING));
	getRuleKeysAPI()
		.then((ruleKeys) => {
			dispatch(setRuleKeys(ruleKeys));
			dispatch(setGetRuleKeysStatus(NONE));
		})
		.catch(() => dispatch(setGetRulesStatus(ERROR)));
};

export const setRuleSets = (ruleSets) => ({
	type: RULES_SET_RULESETS,
	payload: ruleSets,
});

export const setGetRuleSetsStatus = (status) => ({
	type: RULES_GET_RULESETS_STATUS,
	payload: status,
});

export const getRuleSets = (dispatch, getState) => {
	dispatch(setGetRuleKeysStatus(LOADING));
	getRuleSetsAPI()
		.then((ruleSets) => {
			dispatch(setRuleSets(ruleSets));
			dispatch(setGetRuleSetsStatus(NONE));
		})
		.catch(() => dispatch(setGetRulesStatus(ERROR)));
};

export const changeFilterField = (value) => ({
	type: RULES_CHANGE_FILTER_FIELD,
	payload: value,
});
