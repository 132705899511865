import { getSingleTransaction as getSingleTransactionAPI } from 'services/api/transactions/getSingleTransaction';
import { exportTransactions as exportTransactionsAPI } from 'services/api/transactions/exportTransactions';
import { exportTransactionsToCSV as exportTransactionsToCSVAPI } from 'services/api/transactions/exportTransactionsToCSV';
import { getTransactions as getTransactionsAPI } from 'services/api/transactions/getTransactions';
import { ERROR, LOADING, NONE } from 'constants/apiStatuses';
import blobDownloader from 'helpers/blobDownloader';

import {
	TRANSACTIONS_SET_SINGLE_TRANSACTION,
	TRANSACTIONS_GET_SINGLE_TRANSACTION_STATUS,
	TRANSACTIONS_GET_TRANSACTIONS_STATUS,
	TRANSACTIONS_SET_TRANSACTIONS,
	TRANSACTIONS_CHANGE_FILTER_FIELD,
	TRANSACTIONS_RESET_FILTERS,
	TRANSACTIONS_EXPORT_TO_JSON_STATUS,
	TRANSACTIONS_EXPORT_TO_CSV_STATUS,
} from '../constants';

export const setGetSingleTransactionStatus = (status) => ({
	type: TRANSACTIONS_GET_SINGLE_TRANSACTION_STATUS,
	payload: status,
});

export const setSingleTransaction = (transaction) => ({
	type: TRANSACTIONS_SET_SINGLE_TRANSACTION,
	payload: transaction,
});

export const getSingleTransaction = (id) => (dispatch, getState) => {
	dispatch(setGetSingleTransactionStatus(LOADING));
	getSingleTransactionAPI(id)
		.then((transaction) => {
			dispatch(setSingleTransaction(transaction));
			dispatch(setGetSingleTransactionStatus(NONE));
		})
		.catch(() => dispatch(setGetSingleTransactionStatus(ERROR)));
};

export const setGetTransactionsStatus = (status) => ({
	type: TRANSACTIONS_GET_TRANSACTIONS_STATUS,
	payload: status,
});

export const setTransactions = (transactions) => ({
	type: TRANSACTIONS_SET_TRANSACTIONS,
	payload: transactions,
});

export const getTransactions =
	({
		cursor,
		page,
		pageSize,
		changeCursors,
		filters,
		refresh,
		isSetData,
		sorting,
	}) =>
	(dispatch, getState) => {
		dispatch(setGetTransactionsStatus(LOADING));
		getTransactionsAPI({ cursor, page, pageSize, filters, sorting })
			.then((data) => {
				if (isSetData) {
					dispatch(setTransactions(data));
				}
				changeCursors({ ...data, refresh });
				dispatch(setGetTransactionsStatus(NONE));
			})
			.catch(() => dispatch(setGetTransactionsStatus(ERROR)));
	};

export const changeFilterField = (value) => ({
	type: TRANSACTIONS_CHANGE_FILTER_FIELD,
	payload: value,
});

export const resetFilters = (filters) => ({
	type: TRANSACTIONS_RESET_FILTERS,
	payload: filters,
});

export const setExportToJSONStatus = (status) => ({
	type: TRANSACTIONS_EXPORT_TO_JSON_STATUS,
	payload: status,
});

export const exportToJSON = (filters, sorting, onLoad) => (dispatch) => {
	const body = {
		filters,
		sorting,
	};
	dispatch(setExportToJSONStatus(LOADING));
	exportTransactionsAPI(body)
		.then((res) => {
			blobDownloader({ data: res.data, options: { type: 'Application/json' } });

			dispatch(setExportToJSONStatus(NONE));
			onLoad();
		})
		.catch((_) => {
			dispatch(setExportToJSONStatus(ERROR));
		});
};

export const setExportToCSVStatus = (status) => ({
	type: TRANSACTIONS_EXPORT_TO_CSV_STATUS,
	payload: status,
});

export const exportToCSV = (filters, sorting, onLoad) => (dispatch) => {
	const body = {
		filters,
		sorting,
	};
	dispatch(setExportToCSVStatus(LOADING));
	exportTransactionsToCSVAPI(body)
		.then((res) => {
			blobDownloader({ data: res.data, options: { type: 'text/csv' } });

			dispatch(setExportToCSVStatus(NONE));
			onLoad();
		})
		.catch((_) => {
			dispatch(setExportToCSVStatus(ERROR));
		});
};
