import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import notification from 'helpers/notification';

const NotificationService = (props) => {
	const snackbarManager = useSnackbar();

	useEffect(() => {
		notification.invoke = (message, options) => {
			return snackbarManager.enqueueSnackbar(message, options);
		};

		notification.close = (key) => {
			snackbarManager.closeSnackbar(key);
		};
	}, []);

	return null;
};

export default NotificationService;
