import {
	ACTIONS_SET_ACTIONS,
	ACTIONS_GET_ACTIONS_STATUS,
	ACTIONS_SET_SINGLE_ACTION,
	ACTIONS_GET_SINGLE_ACTION_STATUS,
	ACTIONS_CHANGE_FILTER_FIELD,
	ACTIONS_RESET_FILTERS,
} from '../constants';
import { NONE, LOADING } from 'constants/apiStatuses';

const initialState = {
	getActionsStatus: NONE,
	data: null,
	total: null,
	getSingleActionStatus: LOADING,
	singleAction: null,
	filters: {
		actionType: '',
		userName: '',
	},
};

const actionsReducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTIONS_SET_ACTIONS: {
			return {
				...state,
				data: action.payload.actions,
				total: action.payload.total,
			};
		}

		case ACTIONS_GET_ACTIONS_STATUS: {
			return {
				...state,
				getActionsStatus: action.payload,
			};
		}

		case ACTIONS_SET_SINGLE_ACTION: {
			return {
				...state,
				singleAction: action.payload,
			};
		}

		case ACTIONS_GET_SINGLE_ACTION_STATUS: {
			return {
				...state,
				getSingleActionStatus: action.payload,
			};
		}

		case ACTIONS_CHANGE_FILTER_FIELD: {
			return {
				...state,
				filters: {
					...state.filters,
					[action.payload.key]: action.payload.value,
				},
			};
		}

		case ACTIONS_RESET_FILTERS: {
			return {
				...state,
				filters:
					action.payload === null
						? initialState.filters
						: {
								...initialState.filters,
								...action.payload,
						  },
			};
		}

		default: {
			return state;
		}
	}
};

export default actionsReducer;
