const pinkColors = {
	transactionsExportButtons: '#ff1e3b',
	backgroundPrimary: '#fb9bff',
	backgroundSecondary: '#ED85D7',
	textPrimary: '#000',
	textSecondary: '#774545',
	divider: 'rgba(81, 81, 81, 1)',
	oddRowsBackground: '#fb9bf0',
	dialogBackground: 'rgb(18, 18, 18)',
};

const darkColors = {
	transactionsExportButtons: '#001e3b',
	backgroundPrimary: '#032f5c',
	backgroundSecondary: '#042f59',
	textPrimary: '#fff',
	textSecondary: '#fff',
	divider: 'rgba(81, 81, 81, 1)',
	oddRowsBackground: '#194164',
	dialogBackground: 'rgb(18, 18, 18)',
	pink: pinkColors,
};

export default darkColors;
