import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAccountsInfo } from 'actions/serverStatsActions';
import ReportsPage from 'components/ReportPage';
import accountsWithMostDevices from 'constants/columns/accountsWithMostDevices';
import generateDatasetConfigForPieChart from 'helpers/generateDatasetConfigForPieChart';

const AccountsInfo = (props) => {
	const { accountsInfo, getAccountsInfoStatus, getAccountsInfo } = props;

	const navigate = useNavigate();

	const handleClick = ({ id }) => {
		navigate(`/accounts/${id}`);
	};

	return (
		<ReportsPage
			getInfo={getAccountsInfo}
			data={accountsInfo}
			status={getAccountsInfoStatus}
			DataGridProps={{
				onRowClick: handleClick,
			}}
			chartConfig={generateDatasetConfigForPieChart({
				labels: accountsInfo?.map(({ id }) => id),
				data: accountsInfo?.map(({ devicesCount }) => devicesCount),
			})}
			chartOptions={{
				plugins: {
					legend: {
						display: false,
					},
				},
			}}
			onChartPartClick={handleClick}
			title='Accounts with most devices over last 96 hours'
			columns={accountsWithMostDevices}
		/>
	);
};

const mapStateToProps = ({ serverStats }) => ({
	accountsInfo: serverStats.accountsInfo,
	getAccountsInfoStatus: serverStats.getAccountsInfoStatus,
});

const mapDispatchToProps = (dispatch) => ({
	getAccountsInfo: () => dispatch(getAccountsInfo),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountsInfo);
