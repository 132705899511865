import CustomAccordion from '../Accordion';
import {
	IconButton,
	Link,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Tooltip,
} from '@mui/material';
import React from 'react';
import '../styles.css';
import { StripedTableRow } from 'components';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useNavigate } from 'react-router-dom';
import { dateFormatter } from 'helpers/formatters';

const EvidenceToCreate = ({ evidenceInfo }) => {
	const navigate = useNavigate();

	const handleGoToRadiateFrom = (id, type) => {
		navigate(`/${type}s/${id}`);
	};

	const tooltipClasses = { tooltip: 'tooltip' };

	const radiatedByContent = (evidence) => {
		if (!evidence?.radiatedBy?.id) {
			return '-';
		}

		const tooltipTitle = (
			<Link
				component={IconButton}
				className='tooltipButton'
				target='_blank'
				href={`/${evidence.radiatedBy.type}s/${evidence.radiatedBy.id}`}
			>
				<ExitToAppIcon fontSize='small' className='tooltipIcon' />
			</Link>
		);

		const handleLinkClick = () =>
			handleGoToRadiateFrom(evidence.radiatedBy.id, evidence.radiatedBy.type);

		return (
			<>
				Radiated by {evidence.radiatedBy.type}{' '}
				<Tooltip interactive title={tooltipTitle}>
					<Link component='button' onClick={handleLinkClick}>
						{evidence.radiatedby.name ?? evidence.radiatedBy.id}
					</Link>
				</Tooltip>
			</>
		);
	};

	return (
		<CustomAccordion title='Evidence'>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Reason</TableCell>
						<TableCell>Status</TableCell>
						<TableCell>Consumer</TableCell>
						<TableCell>History</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{evidenceInfo.map((evidence, index) => {
						return (
							<StripedTableRow key={evidence.reason + index}>
								<TableCell>{evidence.reason}</TableCell>
								<TableCell>{evidence.status}</TableCell>
								<TableCell>{evidence.consumerType}</TableCell>
								<TableCell>
									<Table>
										<TableHead>
											<TableRow>
												<TableCell>Action</TableCell>
												<TableCell>Radiated by</TableCell>
												<TableCell>Date</TableCell>
												<TableCell>Author</TableCell>
												<TableCell>Comment </TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											<TableRow>
												<TableCell>Created</TableCell>
												<TableCell>{radiatedByContent(evidence)}</TableCell>
												<TableCell>
													{dateFormatter(evidence?.createdTime)}
												</TableCell>
												<TableCell>
													{evidence?.authorCreatorId || 'System'}
												</TableCell>
												<TableCell>{evidence.createComment}</TableCell>
											</TableRow>
											{evidence?.status === 'active' && evidence.endDate && (
												<TableRow>
													<TableCell>Expires (+- 10min)</TableCell>
													<TableCell />
													<TableCell>
														{dateFormatter(evidence?.endDate)}
													</TableCell>
													<TableCell />
													<TableCell />
												</TableRow>
											)}
											{evidence.comments?.map((comment, index) => (
												<TableRow key={`${comment.comment}_${index}`}>
													<TableCell>Commented</TableCell>
													<TableCell />
													<TableCell>{dateFormatter(comment.date)}</TableCell>
													<TableCell>{comment.id}</TableCell>
													<TableCell>
														<Tooltip
															title={comment.comment}
															classes={tooltipClasses}
														>
															<span>
																{`${comment.comment.slice(0, 20)}`}
																{comment.comment.length > 20 ? '...' : ''}
															</span>
														</Tooltip>
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableCell>
							</StripedTableRow>
						);
					})}
				</TableBody>
			</Table>
		</CustomAccordion>
	);
};

export default EvidenceToCreate;
