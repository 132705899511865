import React from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import {
	Box,
	Link,
	TextField,
	Autocomplete,
	IconButton,
	Tooltip,
} from '@mui/material';
import { logOut, setMenuState } from 'actions/authActions';
import './styles.css';
import { getAccountsByName } from 'actions/accountsActions';
import { debounce } from 'lodash';
import notification from 'helpers/notification';
import { ColorModeContext } from 'components/ThemeContext';
import { useTheme } from '@mui/material/styles';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { MAX_STRING_LENGTH } from '../../constants/validations';

const Header = (props) => {
	const navigate = useNavigate();

	const theme = useTheme();
	const colorMode = React.useContext(ColorModeContext);

	const themeIcon =
		theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />;

	const { logOut, menuState, setMenuState, usersByName, handleNameChange } =
		props;

	const headerStyles = {
		...(theme.palette.mode === 'dark'
			? { backgroundColor: theme.palette.default.main }
			: {}),
	};

	const drawerStyles = {
		...(theme.palette.mode === 'dark'
			? { backgroundColor: theme.palette.default.main }
			: {}),
		border: `1px solid ${theme.palette.divider}`,
	};

	const data = usersByName || [{ id: '', name: '' }];
	const ruleOptions = data.map(({ ...content }) => ({ ...content })); // clone
	const handleUserSelect = (id) => {
		navigate(`/accounts/${id}`);
		setInputValue('');
	};

	const [inputValue, setInputValue] = React.useState('');
	const [input, setInput] = React.useState('');

	const debouncedHandleNameChange = React.useCallback(
		debounce((value) => handleNameChange(value), 1000),
		[]
	);
	const checkInputLength = (value) => {
		if (value.trim().length > MAX_STRING_LENGTH) {
			notification.invoke(`Input max length is ${MAX_STRING_LENGTH} symbols`, {
				variant: 'error',
			});
			return false;
		}
		return true;
	};

	const handleInputChange = (e, value) => {
		if (
			value &&
			value.trim() &&
			value[0] !== '\\' &&
			value[0] !== '/' &&
			checkInputLength(value)
		) {
			debouncedHandleNameChange(value.trim());
		}
		setInputValue(value?.trim?.() ?? value);
		setInput(input);
	};

	const onValueChange = (_, value) => {
		if (value?.id.trim()) {
			handleUserSelect(value.id.trim());
		}
		setInputValue('');
		setInput('');
	};

	const handleLogout = () => {
		const currentUrl = new URL(window.location.href);

		// Remove the existing lastVisitedPage parameter if it exists
		currentUrl.searchParams.delete('lastVisitedPage');

		const currentTab = currentUrl.searchParams.get('tab');
		currentUrl.searchParams.set(
			'lastVisitedPage',
			`${currentUrl.pathname}?tab=${currentTab}`
		);

		window.location.href = currentUrl.toString();
		logOut();
	};
	const autocompleteClasses = { option: 'option' };
	const autocompleteRenderInput = (params) => (
		<TextField
			fullWidth
			InputLabelProps={{ shrink: true }}
			autoComplete='off'
			{...params}
			label='Account Name or EMail'
		/>
	);
	const getOptionLabel = (option) => (option.name ? option.name : '');
	const isOptionEqualToValue = (option) => option.id;
	const handleInputBlur = () => setInputValue('');

	const handleDrawerOpen = () => {
		setMenuState(true);
	};

	const handleDrawerClose = () => {
		setMenuState(false);
	};

	const DrawerIcon = ({ children, ...props }) => {
		return (
			<IconButton
				size='large'
				color='inherit'
				aria-label='menu'
				className='headerMenuButton'
				{...props}
			>
				{children}
			</IconButton>
		);
	};

	const drawerClass = `drawerHeader ${menuState ? 'headerDrawerOpen' : ''}`;

	return (
		<header className='root' style={headerStyles}>
			<Box className={drawerClass} style={drawerStyles}>
				{menuState ? (
					<DrawerIcon onClick={handleDrawerClose}>
						<CloseIcon />
					</DrawerIcon>
				) : (
					<DrawerIcon onClick={handleDrawerOpen}>
						<MenuIcon />
					</DrawerIcon>
				)}
			</Box>
			<Box className='headerContent'>
				<Tooltip title='Change Theme'>
					<IconButton onClick={colorMode.toggleColorMode} color='inherit'>
						{themeIcon}
					</IconButton>
				</Tooltip>
				<Autocomplete
					disableClearable
					inputValue={inputValue}
					className='headerInput'
					isOptionEqualToValue={isOptionEqualToValue}
					getOptionLabel={getOptionLabel}
					options={ruleOptions}
					selectOnFocus
					limitTags={20}
					value={input}
					onBlur={handleInputBlur}
					noOptionsText='No available users'
					classes={autocompleteClasses}
					onInputChange={handleInputChange}
					onChange={onValueChange}
					renderInput={autocompleteRenderInput}
					filterOptions={(x) => x}
				/>
				<Link
					id='logout'
					className='navLink'
					underline='none'
					onClick={handleLogout}
				>
					Log out
				</Link>
			</Box>
		</header>
	);
};

const mapStateToProps = ({ auth, accounts }) => ({
	menuState: auth.menuState,
	usersByName: accounts.accountsForAutocomplete,
	authorizationType: auth.authorizationType,
});

const mapDispatchToProps = (dispatch) => ({
	logOut: () => dispatch(logOut),
	setMenuState: (state) => dispatch(setMenuState(state)),
	handleNameChange: (name) => dispatch(getAccountsByName(name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
