import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getDevicesInfo } from 'actions/serverStatsActions';
import ReportsPage from 'components/ReportPage';
import devicesWithMostAccounts from 'constants/columns/devicesWithMostAccounts';
import generateDatasetConfigForPieChart from 'helpers/generateDatasetConfigForPieChart';

const DevicesInfo = (props) => {
	const { devicesInfo, getDevicesInfoStatus, getDevicesInfo } = props;

	const navigate = useNavigate();

	const handleClick = ({ id }) => {
		navigate(`/devices/${id}`);
	};

	return (
		<ReportsPage
			getInfo={getDevicesInfo}
			data={devicesInfo}
			status={getDevicesInfoStatus}
			DataGridProps={{
				onRowClick: handleClick,
			}}
			chartConfig={generateDatasetConfigForPieChart({
				labels: devicesInfo?.map(({ id }) => id),
				data: devicesInfo?.map(({ accountsCount }) => accountsCount),
			})}
			onChartPartClick={handleClick}
			title='Devices with most accounts over last 96 hours'
			columns={devicesWithMostAccounts}
		/>
	);
};

const mapStateToProps = ({ serverStats }) => ({
	devicesInfo: serverStats.devicesInfo,
	getDevicesInfoStatus: serverStats.getDevicesInfoStatus,
});

const mapDispatchToProps = (dispatch) => ({
	getDevicesInfo: () => dispatch(getDevicesInfo),
});

export default connect(mapStateToProps, mapDispatchToProps)(DevicesInfo);
