import React from 'react';
import KeyValueTable, { RowValue } from 'components/KeyValueTable';

const IpEnrichmentBlock = ({ ipEnrichment }) => {
	return (
		<KeyValueTable width={80}>
			<RowValue oKey='Accuracy radius:' oValue={ipEnrichment.accuracyRadius} />
			<RowValue oKey='Country ISO code:' oValue={ipEnrichment.countryISOCode} />
			<RowValue oKey='Country Name:' oValue={ipEnrichment.countryName} />
			<RowValue oKey='Ip:' oValue={ipEnrichment.ip} />
			<RowValue oKey='Within Range:' oValue={ipEnrichment.ips} />
			<RowValue oKey='Proxy:' oValue={ipEnrichment.isProxy} />
			<RowValue oKey='Satellite:' oValue={ipEnrichment.isSatellite} />
			<RowValue oKey='Isp:' oValue={ipEnrichment.isp} />
			<RowValue oKey='Location' oValue={ipEnrichment.location} />
			<RowValue oKey='Name:' oValue={ipEnrichment.name} />
			<RowValue
				oKey='First Sub division ISO Code:'
				oValue={ipEnrichment.subDivision1ISOCode}
			/>
			<RowValue
				oKey='First Sub division Name:'
				oValue={ipEnrichment.subDivision1Name}
			/>
			<RowValue
				oKey='Second Sub division ISO Code:'
				oValue={ipEnrichment.subDivision2ISOCode}
			/>
			<RowValue
				oKey='Second Sub division Name:'
				oValue={ipEnrichment.subDivision2Name}
			/>
		</KeyValueTable>
	);
};

export default IpEnrichmentBlock;
