import React from 'react';
import { Box, Typography } from '@mui/material';

const PageNotFound = (props) => {
	return (
		<Box
			display='flex'
			alignItems='center'
			justifyContent='center'
			height={300}
		>
			<Typography variant='h3'>404 Page Not Found.</Typography>
		</Box>
	);
};

export default PageNotFound;
