import { getTransactions as getTransactionsAPI } from 'services/api/transactions/getTransactions';
import { getSingleDevice as getSingleDeviceAPI } from 'services/api/devices/getSingleDevice';
import { createEvidence as createEvidenceAPI } from 'services/api/evidence/createEvidence';
import { deleteEvidence as deleteEvidenceAPI } from 'services/api/evidence/deleteEvidence';
import { getDevices as getDevicesAPI } from 'services/api/devices/getDevices';
import { getDeviceAccounts as getDevicesAccountsAPI } from 'services/api/devices/getDeviceAccounts';
import { getAccounts as getAccountsAPI } from 'services/api/accounts/getAccounts';
import { exportTransactions as exportTransactionsAPI } from 'services/api/transactions/exportTransactions';
import { exportTransactionsToCSV as exportTransactionsToCSVAPI } from 'services/api/transactions/exportTransactionsToCSV';
import { resetDeviceVector as resetDeviceVectorAPI } from 'services/api/devices/resetDeviceVector';
import { getEvidenceHistory as getEvidenceHistoryAPI } from 'services/api/evidence/getEvidenceHistory';

import { ERROR, LOADING, NONE } from 'constants/apiStatuses';
import {
	DEVICES_SET_SINGLE_DEVICE,
	DEVICES_GET_SINGLE_DEVICE_STATUS,
	DEVICES_CREATE_EVIDENCE,
	DEVICES_CREATE_EVIDENCE_STATUS,
	DEVICES_DELETE_EVIDENCE,
	DEVICES_DELETE_EVIDENCE_STATUS,
	DEVICES_SET_DEVICES,
	DEVICES_GET_DEVICES_STATUS,
	DEVICES_CHANGE_FILTER_FIELD,
	DEVICES_SET_TRANSACTIONS,
	DEVICES_GET_TRANSACTIONS_STATUS,
	DEVICES_SET_ACCOUNTS,
	DEVICES_GET_ACCOUNTS_FOR_EVIDENCE_STATUS,
	DEVICES_SET_ACCOUNTS_FOR_EVIDENCE,
	DEVICES_RESET_FILTERS,
	DEVICES_CHANGE_ACCOUNTS_FOR_EVIDENCE_FILTER_FIELD,
	DEVICES_CHANGE_TRANSACTIONS_FILTER_FIELD,
	DEVICES_RESET_TRANSACTIONS_FILTERS,
	DEVICES_GET_ACCOUNTS_STATUS,
	DEVICES_EXPORT_TRANSACTIONS_TO_JSON_STATUS,
	DEVICES_EXPORT_TRANSACTIONS_TO_CSV_STATUS,
	DEVICES_ADD_EVIDENCE_COMMENT_STATUS,
	DEVICES_ADD_EVIDENCE_COMMENT,
	DEVICES_GET_RESET_VECTOR_STATUS,
	DEVICES_GET_EVIDENCE_HISTORY_STATUS,
	DEVICES_SET_EVIDENCE_HISTORY,
} from '../constants';
import { addComment as addCommentAPI } from 'services/api/evidence/addComment';
import notification from 'helpers/notification';
import blobDownloader from 'helpers/blobDownloader';

export const setGetDevicesStatus = (status) => ({
	type: DEVICES_GET_DEVICES_STATUS,
	payload: status,
});

export const setDevices = (ips) => ({
	type: DEVICES_SET_DEVICES,
	payload: ips,
});

export const getDevices =
	({
		cursor,
		page,
		pageSize,
		changeCursors,
		filters,
		refresh,
		isSetData,
		sorting,
	}) =>
	(dispatch, getState) => {
		dispatch(setGetDevicesStatus(LOADING));
		getDevicesAPI({ cursor, page, pageSize, filters, sorting })
			.then((data) => {
				if (isSetData) {
					dispatch(setDevices(data));
				}
				changeCursors({ ...data, refresh });
				dispatch(setGetDevicesStatus(NONE));
			})
			.catch(() => {
				dispatch(setGetDevicesStatus(ERROR));
			});
	};

export const setAccounts = (accounts) => ({
	type: DEVICES_SET_ACCOUNTS,
	payload: accounts,
});

export const getAccountsAttachedToDevice =
	({
		cursor,
		page,
		pageSize,
		accountsId,
		changeCursors,
		deviceId,
		isSetData,
		sorting,
	}) =>
	(dispatch, getState) => {
		dispatch(setGetAccountsStatus(LOADING));
		getAccountsAPI({
			cursor,
			page,
			pageSize,
			filters: { accountsId },
			deviceId,
			sorting,
		})
			.then((accounts) => {
				if (isSetData) {
					dispatch(setAccounts(accounts));
				}
				changeCursors({ ...accounts });
				dispatch(setGetAccountsStatus(NONE));
			})
			.catch(() => setGetAccountsStatus(ERROR));
	};

export const setGetSingleDeviceStatus = (status) => ({
	type: DEVICES_GET_SINGLE_DEVICE_STATUS,
	payload: status,
});

export const setSingleDevice = (device) => ({
	type: DEVICES_SET_SINGLE_DEVICE,
	payload: device,
});

export const getSingleDevice = (id) => (dispatch, getState) => {
	dispatch(setGetSingleDeviceStatus(LOADING));
	getSingleDeviceAPI(id)
		.then((device) => {
			dispatch(setSingleDevice(device));
			dispatch(setGetSingleDeviceStatus(NONE));
		})
		.catch(() => dispatch(setGetSingleDeviceStatus(ERROR)));
};

export const setCreateEvidenceStatus = (status) => ({
	type: DEVICES_CREATE_EVIDENCE_STATUS,
	payload: status,
});

export const setCreateEvidence = (device) => ({
	type: DEVICES_CREATE_EVIDENCE,
	payload: device,
});

export const createEvidence = (body, onLoad) => (dispatch, getState) => {
	dispatch(setCreateEvidenceStatus(LOADING));
	createEvidenceAPI(body)
		.then((device) => {
			dispatch(setCreateEvidence(device));
			dispatch(setCreateEvidenceStatus(NONE));
			onLoad();
		})
		.catch((e) => {
			dispatch(setCreateEvidenceStatus(ERROR));
		});
};

export const setDeleteEvidenceStatus = (status) => ({
	type: DEVICES_DELETE_EVIDENCE_STATUS,
	payload: status,
});

export const setDeleteEvidence = (evidence) => ({
	type: DEVICES_DELETE_EVIDENCE,
	payload: evidence,
});

export const deleteEvidence = (body, onClose) => (dispatch, getState) => {
	dispatch(setDeleteEvidenceStatus(LOADING));
	deleteEvidenceAPI(body)
		.then((history) => {
			dispatch(setDeleteEvidenceStatus(NONE));
			dispatch(setDeleteEvidence(history));
			onClose();
		})
		.catch(() => dispatch(setDeleteEvidenceStatus(ERROR)));
};

export const setAddEvidenceCommentStatus = (status) => ({
	type: DEVICES_ADD_EVIDENCE_COMMENT_STATUS,
	payload: status,
});

export const setAddEvidenceComment = (evidence) => ({
	type: DEVICES_ADD_EVIDENCE_COMMENT,
	payload: evidence,
});

export const addEvidenceComment = (body, onLoad) => (dispatch) => {
	dispatch(setAddEvidenceCommentStatus(LOADING));
	onLoad();
	addCommentAPI(body)
		.then((evidence) => {
			dispatch(setAddEvidenceComment(evidence));
			dispatch(setAddEvidenceCommentStatus(NONE));
		})
		.catch((err) => {
			dispatch(setAddEvidenceCommentStatus(ERROR));
		});
};

export const changeFilterField = (value) => ({
	type: DEVICES_CHANGE_FILTER_FIELD,
	payload: value,
});

export const resetFilters = (filters) => ({
	type: DEVICES_RESET_FILTERS,
	payload: filters,
});

export const setTransactions = (transactions) => ({
	type: DEVICES_SET_TRANSACTIONS,
	payload: transactions,
});

export const setGetTransactionsStatus = (status) => ({
	type: DEVICES_GET_TRANSACTIONS_STATUS,
	payload: status,
});

export const setGetAccountsStatus = (data) => ({
	type: DEVICES_GET_ACCOUNTS_STATUS,
	payload: data,
});

export const getDeviceTransactions =
	({
		cursor,
		page,
		pageSize,
		deviceId,
		changeCursors,
		filters,
		refresh,
		isSetData,
		sorting,
	}) =>
	(dispatch, getState) => {
		dispatch(setGetTransactionsStatus(LOADING));
		getTransactionsAPI({
			cursor,
			page,
			pageSize,
			filters: {
				...filters,
				'device.id': deviceId,
			},
			sorting,
		})
			.then((transactions) => {
				if (isSetData) {
					dispatch(setTransactions(transactions));
				}
				changeCursors({ ...transactions, refresh });
				dispatch(setGetTransactionsStatus(NONE));
			})
			.catch(() => dispatch(setGetTransactionsStatus(ERROR)));
	};

export const setGetAccountsForEvidenceStatus = (status) => ({
	type: DEVICES_GET_ACCOUNTS_FOR_EVIDENCE_STATUS,
	payload: status,
});

export const setAccountsForEvidence = (accounts) => ({
	type: DEVICES_SET_ACCOUNTS_FOR_EVIDENCE,
	payload: accounts,
});

export const getAccountsForEvidence =
	({ cursor, page, pageSize, filters, changeCursors, isSetData, sorting }) =>
	(dispatch, getState) => {
		dispatch(setGetAccountsForEvidenceStatus(LOADING));
		getDevicesAccountsAPI({ cursor, page, pageSize, filters, sorting })
			.then((data) => {
				if (isSetData) {
					dispatch(setAccountsForEvidence(data));
				}
				changeCursors({ ...data });
				dispatch(setGetAccountsForEvidenceStatus(NONE));
			})
			.catch(() => dispatch(setGetAccountsForEvidenceStatus(ERROR)));
	};

export const changeAccountsForEvidenceFilterField = (value) => ({
	type: DEVICES_CHANGE_ACCOUNTS_FOR_EVIDENCE_FILTER_FIELD,
	payload: value,
});

export const changeTransactionFilterField = (value) => ({
	type: DEVICES_CHANGE_TRANSACTIONS_FILTER_FIELD,
	payload: value,
});

export const resetTransactionFilters = (filters) => ({
	type: DEVICES_RESET_TRANSACTIONS_FILTERS,
	payload: filters,
});

export const setExportTransactionsToJSONStatus = (status) => ({
	type: DEVICES_EXPORT_TRANSACTIONS_TO_JSON_STATUS,
	payload: status,
});

export const setExportTransactionsToCSVStatus = (status) => ({
	type: DEVICES_EXPORT_TRANSACTIONS_TO_CSV_STATUS,
	payload: status,
});

export const setResetDeviceVectorStatus = (status) => ({
	type: DEVICES_GET_RESET_VECTOR_STATUS,
	payload: status,
});

export const resetDeviceVector = (id, onLoad) => (dispatch) => {
	dispatch(setResetDeviceVectorStatus(LOADING));
	resetDeviceVectorAPI(id)
		.then((res) => {
			dispatch(setSingleDevice(res));
			dispatch(setResetDeviceVectorStatus(NONE));
			notification.invoke('Vector count was reset successfully', {
				variant: 'success',
			});
			onLoad();
		})
		.catch(() => dispatch(setResetDeviceVectorStatus(ERROR)));
};

export const exportTransactionsToJSON =
	(filters, sorting, deviceId, onLoad) => (dispatch) => {
		const body = {
			filters: { ...filters, deviceId },
			sorting,
		};
		dispatch(setExportTransactionsToJSONStatus(LOADING));
		exportTransactionsAPI(body)
			.then((res) => {
				blobDownloader({
					data: res.data,
					options: { type: 'Application/json' },
				});

				dispatch(setExportTransactionsToJSONStatus(NONE));
				onLoad();
			})
			.catch((error) => {
				dispatch(setExportTransactionsToJSONStatus(ERROR));
			});
	};

export const exportTransactionsToCSV =
	(filters, sorting, deviceId, onLoad) => (dispatch) => {
		const body = {
			filters: { ...filters, deviceId },
			sorting,
		};
		dispatch(setExportTransactionsToCSVStatus(LOADING));
		exportTransactionsToCSVAPI(body)
			.then((res) => {
				blobDownloader({ data: res.data, options: { type: 'text/csv' } });

				dispatch(setExportTransactionsToCSVStatus(NONE));
				onLoad();
			})
			.catch((error) => {
				dispatch(setExportTransactionsToCSVStatus(ERROR));
			});
	};

export const setGetEvidenceHistoryStatus = (status) => ({
	type: DEVICES_GET_EVIDENCE_HISTORY_STATUS,
	payload: status,
});

export const setEvidenceHistory = (evidences) => ({
	type: DEVICES_SET_EVIDENCE_HISTORY,
	payload: evidences,
});

export const getDeviceEvidenceHistory =
	({
		cursor,
		page,
		pageSize,
		changeCursors,
		filters,
		refresh,
		isSetData,
		sorting,
	}) =>
	(dispatch) => {
		dispatch(setGetEvidenceHistoryStatus(LOADING));
		getEvidenceHistoryAPI({
			cursor,
			page,
			pageSize,
			filters,
			sorting,
		})
			.then((evidences) => {
				if (isSetData) {
					dispatch(setEvidenceHistory(evidences));
				}
				changeCursors({ ...evidences, refresh });
				dispatch(setGetEvidenceHistoryStatus(NONE));
			})
			.catch(() => dispatch(setGetEvidenceHistoryStatus(ERROR)));
	};
