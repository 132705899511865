import { dateFormatter } from 'helpers/formatters';
import { CustomTableCell } from 'components';

const actionsColumns = [
	{
		field: 'action',
		editable: false,
		headerName: 'Action Type',
		headerAlign: 'center',
		flex: 1,
		renderCell: (params) => (
			<CustomTableCell filterField='actionType' {...params} />
		),
	},
	{
		field: 'creationDate',
		editable: false,
		headerName: 'Date',
		headerAlign: 'center',
		flex: 1,
		renderCell: (params) => {
			return (
				<CustomTableCell {...params} value={dateFormatter(params.value)} />
			);
		},
	},
	{
		field: 'username',
		editable: false,
		headerName: 'User name',
		headerAlign: 'center',
		flex: 1,
		renderCell: (params) => (
			<CustomTableCell filterField='userName' {...params} />
		),
	},
];

export default actionsColumns;
