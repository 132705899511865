import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import {
	Typography,
	Box,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Button,
	InputLabel,
	Select,
	FormControl,
	MenuItem,
	IconButton,
	Tooltip,
} from '@mui/material';
import { getRules, deleteRule, changeFilterField } from 'actions/rulesActions';
import { LOADING } from 'constants/apiStatuses';
import { StripedTableRow, DeleteModal } from 'components';
import sortObjectArray from 'helpers/sortObjectArray';
import ruleToText from 'helpers/ruleToTextRepresentation';
import './styles.css';
import { ReactComponent as ClearAllIcon } from 'icons/clearFilter.svg';
import { getReasons } from 'actions/reasonsActions';
import { dateFormatter } from 'helpers/formatters';
import { sortRuleSets } from 'helpers/sortRuleSets';

const RuleManager = (props) => {
	const {
		rules,
		ruleSets,
		getRulesStatus,
		getRules,
		deleteRule,
		changeFilterField,
		filters,
		userInfo,
		evidenceTypes,
		getReasons,
	} = props;

	const [ruleIdToDelete, setRuleIdToDelete] = useState('');
	const [ruleSetValue, setRuleSetValue] = useState('');

	const navigate = useNavigate();

	const sortedRuleSets = sortRuleSets(ruleSets);

	const handleGoToNewRule = () => {
		navigate('/new-rule');
	};

	const handleChangeFilters = (e) => {
		const ruleName = e.target.name;
		const ruleValue = e.target.value;

		setRuleSetValue(ruleValue);
		changeFilterField({ key: ruleName, value: ruleValue });
		getRules(
			{
				...filters,
				[ruleName]: ruleValue,
			},
			true
		);
	};

	const handleDeleteRule = () => {
		deleteRule(ruleIdToDelete, () => setRuleIdToDelete(''));
	};

	const handleCloseModal = () => {
		setRuleIdToDelete('');
	};

	useEffect(() => {
		getRules(filters, false);
		getReasons();

		return () => {
			changeFilterField({ key: 'ruleSet', value: '' });
		};
	}, []);

	const handleResetFilters = () => {
		const initialFilters = {
			ruleSet: '',
		};
		changeFilterField({ key: 'ruleSet', value: '' });
		getRules(initialFilters, true);
		setRuleSetValue('');
	};

	const handleChangeRuleSet = (ruleSetValue, ruleSet) => {
		if (ruleSetValue === '') {
			return ruleSet && ruleSet.length > 1
				? `${ruleSet.join(', ').slice(0, 15)}...`
				: ruleSet;
		} else {
			return ruleSetValue;
		}
	};

	const getEvidenceNameById = (id) => {
		const reason = evidenceTypes.find((elem) => elem.id === id)?.name;
		return reason ?? id;
	};

	const ruleSetsSelectItems = sortedRuleSets.map((ruleSet) => (
		<MenuItem key={ruleSet} value={ruleSet}>
			{ruleSet}
		</MenuItem>
	));

	function getActiveRulesCnt() {
		const now = new Date().getTime();
		return (
			rules?.filter(
				(r) =>
					new Date(r.validFrom).getTime() <= now &&
					now <= new Date(r.validTo).getTime()
			).length ?? 0
		);
	}

	return (
		<Box>
			<Typography variant='h4'>Rule Management</Typography>
			<div>Active rules: {getActiveRulesCnt()}</div>
			<Box className='bar'>
				{userInfo?.role === 'admin' ? (
					<Button
						id='add-rule'
						variant='contained'
						color='primary'
						className='addRuleButton'
						onClick={handleGoToNewRule}
					>
						Add Rule
					</Button>
				) : null}
				{ruleSets && (
					<FormControl>
						<InputLabel id='rule-set'>Rule set</InputLabel>
						<Select
							id='rule-set'
							label='Rule set'
							name='ruleSet'
							value={ruleSetValue}
							onChange={handleChangeFilters}
							className='ruleFilterWidth'
						>
							{ruleSetsSelectItems}
						</Select>
					</FormControl>
				)}
				{ruleSets && (
					<Tooltip title='Clear filters'>
						<IconButton
							className='ruleFilterInput'
							color='primary'
							onClick={handleResetFilters}
						>
							<ClearAllIcon className='ruleClearAllIcon' />
						</IconButton>
					</Tooltip>
				)}
			</Box>
			{getRulesStatus === LOADING && <div>Loading...</div>}
			{rules && (
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Name</TableCell>
							<TableCell>Score</TableCell>
							<TableCell>Rule set</TableCell>
							<TableCell>Excluded rule set</TableCell>
							<TableCell>Rule</TableCell>
							<TableCell>Evidence</TableCell>
							{userInfo.role === 'admin' ? (
								<>
									<TableCell>Action</TableCell>
								</>
							) : null}
							<TableCell>Valid</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{sortObjectArray(rules, 'name').map((rule) => {
							const classes = [
								{
									condition:
										new Date(rule.validFrom).getTime() >= new Date().getTime(),
									className: 'tableRowLightBlue',
								},
								{
									condition:
										new Date().getTime() >= new Date(rule.validTo).getTime(),
									className: 'tableRowYellow',
								},
							];

							const className = classes
								.filter(({ condition }) => condition)
								.reduce(
									(prev, current) => `${prev.className} ${current.className}`,
									''
								);

							return (
								<StripedTableRow key={rule.id} className={className}>
									<TableCell>{rule.name}</TableCell>
									<TableCell>{rule.score}</TableCell>
									<TableCell>
										<Tooltip
											title={rule.ruleSet.join(', ')}
											classes={{ tooltip: 'ruleTooltip' }}
										>
											<span>
												{handleChangeRuleSet(ruleSetValue, rule.ruleSet)}
											</span>
										</Tooltip>
									</TableCell>
									<TableCell>
										<Tooltip
											title={rule.exRuleSet ? rule.exRuleSet.join(', ') : ''}
											classes={{ tooltip: 'ruleTooltip' }}
										>
											<span>{handleChangeRuleSet('', rule.exRuleSet)}</span>
										</Tooltip>
									</TableCell>
									<TableCell>
										{ruleToText(rule.rules, getEvidenceNameById)}
									</TableCell>
									<TableCell>
										{getEvidenceNameById(rule.evidence) || '-'}
										{!!rule.evidenceToEntity?.length && (
											<p className='reset'>
												{JSON.stringify(rule.evidenceToEntity)}
											</p>
										)}
										{rule.evidence && (
											<p className='reset'>
												Expires in: {rule.evidenceExpiresIn || '-'}{' '}
												{!!rule.evidenceExpiresIn && 'days'}
											</p>
										)}
									</TableCell>
									{userInfo.role === 'admin' ? (
										<>
											<TableCell>
												<div className='btnGroupWrap'>
													<Button
														className='editButton'
														onClick={() => navigate(`/edit-rule/${rule.id}`)}
													>
														Edit
													</Button>
													<Button
														variant='contained'
														color='secondary'
														onClick={() => setRuleIdToDelete(rule.id)}
													>
														Delete
													</Button>
													<Button
														className='copyEditBtn'
														variant='contained'
														color='secondary'
														onClick={() =>
															navigate(`/edit-rule/${rule.id}?action=copyRule`)
														}
													>
														Copy Rule
													</Button>
												</div>
											</TableCell>
										</>
									) : null}
									<TableCell>
										<Typography variant='body2'>
											<b>From</b> : {dateFormatter(rule.validFrom)}
										</Typography>
										<Typography variant='body2'>
											<b>To</b>: {dateFormatter(rule.validTo)}
										</Typography>
									</TableCell>
								</StripedTableRow>
							);
						})}
					</TableBody>
				</Table>
			)}
			{ruleIdToDelete && (
				<DeleteModal
					title='Deleting rule'
					onClose={handleCloseModal}
					onSubmit={handleDeleteRule}
					modalBody='Are you sure want to delete this rule?'
				/>
			)}
		</Box>
	);
};

const mapStateToProps = ({ rules, auth, reasons }) => ({
	rules: rules.data.rules ? rules.data.rules : null,
	ruleSets: rules.data.ruleSets ? rules.data.ruleSets : null,
	getRulesStatus: rules.getRulesStatus,
	filters: rules.filters,
	userInfo: auth.userInfo,
	evidenceTypes: reasons.reasons,
});

const mapDispatchToProps = (dispatch) => ({
	getRules: (filters, refresh) => dispatch(getRules(filters, refresh)),
	deleteRule: (id, onLoad) => dispatch(deleteRule(id, onLoad)),
	changeFilterField: (value) => dispatch(changeFilterField(value)),
	getReasons: () => dispatch(getReasons),
});

export default connect(mapStateToProps, mapDispatchToProps)(RuleManager);
