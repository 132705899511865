import {
	IPS_CHANGE_FILTER_FIELD,
	IPS_GET_IPS_STATUS,
	IPS_SET_IPS,
	IPS_GET_SINGLE_IP_STATUS,
	IPS_SET_SINGLE_IP,
	IPS_GET_IPS_FOR_EVIDENCE_STATUS,
	IPS_SET_IPS_FOR_EVIDENCE,
	IPS_CREATE_EVIDENCE_STATUS,
	IPS_DELETE_EVIDENCE,
	IPS_DELETE_EVIDENCE_STATUS,
	IPS_CREATE_IP_STATUS,
	IPS_RESET_FILTERS,
	IPS_ADD_EVIDENCE_COMMENT_STATUS,
	IPS_ADD_EVIDENCE_COMMENT,
	IPS_GET_EVIDENCE_HISTORY_STATUS,
	IPS_SET_EVIDENCE_HISTORY,
} from '../constants';
import { getIps as getIpsAPI } from 'services/api/ips/getIps';
import { getSingleIp as getSingleIpAPI } from 'services/api/ips/getSingleIp';
import { getIpsByIsp as getIpsByIspAPI } from 'services/api/ips/getIpsByIsp';
import { createEvidence as createEvidenceAPI } from 'services/api/evidence/createEvidence';
import { deleteEvidence as deleteEvidenceAPI } from 'services/api/evidence/deleteEvidence';
import { createIp as createIpAPI } from 'services/api/ips/createIp';
import { LOADING, NONE, ERROR } from 'constants/apiStatuses';
import { addComment as addCommentAPI } from 'services/api/evidence/addComment';
import { getEvidenceHistory as getEvidenceHistoryAPI } from 'services/api/evidence/getEvidenceHistory';

export const setGetIpsStatus = (status) => ({
	type: IPS_GET_IPS_STATUS,
	payload: status,
});

export const setIps = (ips) => ({
	type: IPS_SET_IPS,
	payload: ips,
});

export const getIps =
	({
		cursor,
		page,
		pageSize,
		changeCursors,
		filters,
		refresh,
		isSetData,
		sorting,
	}) =>
	(dispatch, getState) => {
		dispatch(setGetIpsStatus(LOADING));
		getIpsAPI({ cursor, page, pageSize, filters, sorting })
			.then((data) => {
				if (isSetData) {
					dispatch(setIps(data));
				}
				changeCursors({ ...data, refresh });
				dispatch(setGetIpsStatus(NONE));
			})
			.catch(() => dispatch(setGetIpsStatus(ERROR)));
	};

export const changeFilterField = (value) => ({
	type: IPS_CHANGE_FILTER_FIELD,
	payload: value,
});

export const resetFilters = (filters) => ({
	type: IPS_RESET_FILTERS,
	payload: filters,
});

export const setGetSingleIpStatus = (status) => ({
	type: IPS_GET_SINGLE_IP_STATUS,
	payload: status,
});

export const setSingleIp = (ip) => ({
	type: IPS_SET_SINGLE_IP,
	payload: ip,
});

export const getSingleIp = (id) => (dispatch, getState) => {
	dispatch(setGetSingleIpStatus(LOADING));
	getSingleIpAPI(id)
		.then((data) => {
			dispatch(setSingleIp(data));
			dispatch(setGetSingleIpStatus(NONE));
		})
		.catch(() => dispatch(setGetSingleIpStatus(ERROR)));
};

export const setGetIpsForEvidenceStatus = (status) => ({
	type: IPS_GET_IPS_FOR_EVIDENCE_STATUS,
	payload: status,
});

export const setIpsForEvidence = (ips) => ({
	type: IPS_SET_IPS_FOR_EVIDENCE,
	payload: ips,
});

export const getIpsForEvidence =
	({ cursor, page, pageSize, id, changeCursors, isSetData, sorting }) =>
	(dispatch, getState) => {
		dispatch(setGetIpsForEvidenceStatus(LOADING));
		getIpsByIspAPI({ cursor, page, pageSize, filters: { id }, sorting })
			.then((data) => {
				if (isSetData) {
					dispatch(setIpsForEvidence(data));
				}
				changeCursors({ ...data });
				dispatch(setGetIpsForEvidenceStatus(NONE));
			})
			.catch(() => dispatch(setGetIpsForEvidenceStatus(ERROR)));
	};

export const setCreateEvidenceStatus = (status) => ({
	type: IPS_CREATE_EVIDENCE_STATUS,
	payload: status,
});

export const createEvidence = (body, onLoad) => (dispatch, getState) => {
	dispatch(setCreateEvidenceStatus(LOADING));
	createEvidenceAPI(body)
		.then(() => {
			dispatch(setCreateEvidenceStatus(NONE));
			onLoad();
		})
		.catch(() => dispatch(setCreateEvidenceStatus(ERROR)));
};

export const setDeleteEvidenceStatus = (status) => ({
	type: IPS_DELETE_EVIDENCE_STATUS,
	payload: status,
});

export const setDeleteEvidence = (evidence) => ({
	type: IPS_DELETE_EVIDENCE,
	payload: evidence,
});

export const deleteEvidence = (body, onClose) => (dispatch, getState) => {
	dispatch(setDeleteEvidenceStatus(LOADING));
	deleteEvidenceAPI(body)
		.then((history) => {
			dispatch(setDeleteEvidenceStatus(NONE));
			dispatch(setDeleteEvidence(history));
			onClose();
		})
		.catch(() => dispatch(setDeleteEvidenceStatus(ERROR)));
};

export const setAddEvidenceCommentStatus = (status) => ({
	type: IPS_ADD_EVIDENCE_COMMENT_STATUS,
	payload: status,
});

export const setAddEvidenceComment = (evidence) => ({
	type: IPS_ADD_EVIDENCE_COMMENT,
	payload: evidence,
});

export const addEvidenceComment = (body, onLoad) => (dispatch) => {
	dispatch(setAddEvidenceCommentStatus(LOADING));
	onLoad();
	addCommentAPI(body)
		.then((evidence) => {
			dispatch(setAddEvidenceComment(evidence));
			dispatch(setAddEvidenceCommentStatus(NONE));
		})
		.catch((err) => {
			dispatch(setAddEvidenceCommentStatus(ERROR));
		});
};

export const setCreateIpStatus = (status) => ({
	type: IPS_CREATE_IP_STATUS,
	payload: status,
});

export const createIp = (body, onLoad) => (dispatch, getState) => {
	dispatch(setCreateIpStatus(LOADING));
	createIpAPI(body)
		.then(({ id }) => {
			dispatch(setCreateIpStatus(NONE));
			onLoad(id);
		})
		.catch(() => dispatch(setCreateIpStatus(ERROR)));
};

export const setGetEvidenceHistoryStatus = (status) => ({
	type: IPS_GET_EVIDENCE_HISTORY_STATUS,
	payload: status,
});

export const setEvidenceHistory = (evidences) => ({
	type: IPS_SET_EVIDENCE_HISTORY,
	payload: evidences,
});

export const getIpsEvidenceHistory =
	({
		cursor,
		page,
		pageSize,
		changeCursors,
		filters,
		refresh,
		isSetData,
		sorting,
	}) =>
	(dispatch) => {
		dispatch(setGetEvidenceHistoryStatus(LOADING));
		getEvidenceHistoryAPI({
			cursor,
			page,
			pageSize,
			filters,
			sorting,
		})
			.then((evidences) => {
				if (isSetData) {
					dispatch(setEvidenceHistory(evidences));
				}
				changeCursors({ ...evidences, refresh });
				dispatch(setGetEvidenceHistoryStatus(NONE));
			})
			.catch(() => dispatch(setGetEvidenceHistoryStatus(ERROR)));
	};
