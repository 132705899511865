import React from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';
import changeLogs from 'constants/changeLogs';
import './styles.css';

const ChangeLog = () => {
	return (
		<Box>
			<Typography variant='h4'>Change Log</Typography>
			<Box className='changeLogContainer'>
				{changeLogs.map((changeLog, index) => {
					return (
						<Grid
							key={index}
							container
							spacing={3}
							elevation={3}
							component={Paper}
							className='changeLog'
						>
							<Grid item xs={4}>
								<Typography variant='body1'>{changeLog.date}</Typography>
							</Grid>
							<Grid item xs={8}>
								<Typography variant='body1'>
									<b>{changeLog.title}</b>
								</Typography>
								<Typography variant='body2' className='changeLogBody'>
									{changeLog.description}
								</Typography>
							</Grid>
						</Grid>
					);
				})}
			</Box>
		</Box>
	);
};

export default ChangeLog;
