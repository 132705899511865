import axiosInstance from '../index';
import { parseISO } from 'date-fns';

const getSingleRule = async (id) => {
	let ret = await axiosInstance.get(`/rules/${id}`);
	ret = ret.data;
	console.log('single ret', ret);
	ret.validFrom = parseISO(ret.validFrom);
	ret.validTo = parseISO(ret.validTo);
	return ret;
};

export { getSingleRule };
