import './styles.css';
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Box,
	AccordionSummary,
	Accordion,
	AccordionDetails,
} from '@mui/material';
import React from 'react';
import { StripedTableRow } from 'components';
import { keys, fieldToLabel } from 'constants/transactionPage';
import { numberFormatter } from 'helpers/formatters';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const checkTableFields = (data) => {
	if (!data) {
		return false;
	}
	return Object.keys(data).reduce((total, current) => {
		return total && !!fieldToLabel[current];
	}, true);
};

const checkValue = (body) => {
	let data = body;
	if (Array.isArray(body)) {
		data = body.reduce((total, current) => {
			const [key, value] = Object.entries(current)[0];
			return { ...total, [key]: value };
		}, {});
	}
	return data;
};

const CustomTransactionTable = ({ data }) => {
	if (!checkTableFields(data)) {
		return null;
	}

	let timeKeys = [...keys];
	if (!data['countriesByAccountReport']?.['7D']) {
		timeKeys = timeKeys.slice(0, -1);
	}

	const tableTimeHeader = timeKeys.map((key, index) => (
		<TableCell key={index}>{key}</TableCell>
	));

	const tableBody = Object.entries(data).map(([field, timeValues]) => {
		const label = fieldToLabel[field];
		const values = checkValue(timeValues);
		return (
			<StripedTableRow key={field}>
				<TableCell className='tableName'>{label}</TableCell>
				{timeKeys.map((time, index) => {
					const currentValue = values?.[time] ?? '-';
					return (
						<TableCell key={time + index} className='reportsValueCell'>
							{numberFormatter(currentValue)}
						</TableCell>
					);
				})}
			</StripedTableRow>
		);
	});

	const accordionClasses = { root: 'infoCard' };
	const accordionSummaryClasses = { content: 'infoSummary' };
	const accordionSummaryClassName = `infoSummary infoSummaryHeader`;

	return (
		<Accordion elevation={0} classes={accordionClasses}>
			<AccordionSummary
				classes={accordionSummaryClasses}
				expandIcon={<ExpandMoreIcon />}
				className={accordionSummaryClassName}
			>
				Velocity Details
			</AccordionSummary>
			<AccordionDetails className='infoSummary'>
				<Box className='infoTable'>
					<TableContainer component={Paper} className='tableContainer'>
						<Table className='table'>
							<TableHead>
								<TableRow className='tableRow'>
									<TableCell>Transactions by</TableCell>
									{tableTimeHeader}
								</TableRow>
							</TableHead>
							<TableBody>{tableBody}</TableBody>
						</Table>
					</TableContainer>
				</Box>
			</AccordionDetails>
		</Accordion>
	);
};

export default CustomTransactionTable;
