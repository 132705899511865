import { NONE } from 'constants/apiStatuses';
import {
	REASONS_CREATE_REASON_STATUS,
	REASONS_DELETE_REASON_STATUS,
	REASONS_EDIT_REASON_STATUS,
	REASONS_GET_REASONS_STATUS,
	REASONS_CREATE_REASON,
	REASONS_EDIT_REASON,
	REASONS_SET_REASONS,
	REASONS_DELETE_REASON,
} from '../constants';

const initialState = {
	reasons: [],
	getReasonsStatus: NONE,
	createReasonStatus: NONE,
	editReasonStatus: NONE,
	deleteReasonStatus: NONE,
};

const reasonsReducer = (state = initialState, action) => {
	switch (action.type) {
		case REASONS_GET_REASONS_STATUS: {
			return {
				...state,
				getReasonsStatus: action.payload,
			};
		}
		case REASONS_SET_REASONS: {
			return {
				...state,
				reasons: action.payload,
			};
		}

		case REASONS_CREATE_REASON_STATUS: {
			return {
				...state,
				createReasonStatus: action.payload,
			};
		}
		case REASONS_DELETE_REASON_STATUS: {
			return {
				...state,
				deleteReasonStatus: action.payload,
			};
		}
		case REASONS_DELETE_REASON: {
			const reasonIdx = state.reasons.findIndex(
				(reason) => reason.id === action.payload.id
			);
			return {
				...state,
				reasons: [
					...state.reasons.slice(0, reasonIdx),
					...state.reasons.slice(reasonIdx + 1),
				],
			};
		}
		case REASONS_CREATE_REASON: {
			const newReasons = [...state.reasons, action.payload];
			return {
				...state,
				reasons: newReasons,
			};
		}
		case REASONS_EDIT_REASON_STATUS: {
			return {
				...state,
				editReasonStatus: action.payload,
			};
		}
		case REASONS_EDIT_REASON: {
			const reasonIdx = state.reasons.findIndex(
				(reason) => reason.id === action.payload.id
			);
			return {
				...state,
				reasons: [
					...state.reasons.slice(0, reasonIdx),
					{ ...action.payload },
					...state.reasons.slice(reasonIdx + 1),
				],
			};
		}
		default: {
			return state;
		}
	}
};

export default reasonsReducer;
