import { LOADING } from 'constants/apiStatuses';

const isSubmitButtonDisabled = (errors, touched, status) => {
	return (
		(Object.values(touched).every((touched) => touched) &&
			Object.keys(errors).length !== 0) ||
		status === LOADING
	);
};

export default isSubmitButtonDisabled;
