import React from 'react';
import './styles.css';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';

const Footer = (props) => {
	const { menuState } = props;
	const footerContentClass = menuState ? 'footerExpand' : 'footer';

	return (
		<footer className={footerContentClass}>
			<Link component={RouterLink} to='/change-log'>
				Agency 24.36.0
			</Link>
		</footer>
	);
};

export default Footer;
