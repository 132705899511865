const ipReputations = [
	'CHALLENGE-AnonymousVPN',
	'CHALLENGE-DatacenterIP',
	'CHALLENGE-GFNEG',
	'CHALLENGE-NOSCORE',
	'CHALLENGE-OpenProxy',
	'OK-GFNEG',
];

export default ipReputations;
