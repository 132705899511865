import React from 'react';
import { IconButton, Link, Tooltip } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useNavigate } from 'react-router-dom';

import { dateFormatter } from 'helpers/formatters';
import '../styles.css';

const LastAction = ({
	lastAction,
	userName,
	singleUser = false,
	placement = 'bottom',
}) => {
	const navigate = useNavigate();

	if (!lastAction?.action) {
		return <b>n/a</b>;
	}

	const handleLastActionClick = (link) => (e) => {
		e.stopPropagation();
		navigate(link);
	};

	const formActionsLink = (userName) => {
		return `/?tab=actions&userName=${userName}`;
	};

	const tooltipButton = (link) => (
		<Link
			component={IconButton}
			className='tooltipButton'
			target='_blank'
			href={link}
			onClick={(e) => e.stopPropagation()}
		>
			<ExitToAppIcon fontSize='small' className='tooltipIcon' />
		</Link>
	);

	const renderDate = (date) => {
		if (singleUser) {
			return <b className='reset'> ({date})</b>;
		}
		return <p className='reset'>{date}</p>;
	};

	return (
		<>
			<Tooltip
				title={tooltipButton(formActionsLink(userName))}
				className='actionLink'
				placement={placement}
			>
				<Link
					component='button'
					onClick={handleLastActionClick(formActionsLink(userName))}
				>
					<b>{lastAction?.action}</b>
				</Link>
			</Tooltip>
			{renderDate(dateFormatter(lastAction?.creationDate))}
		</>
	);
};

export default LastAction;
