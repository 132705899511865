import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import {
	createEvidence,
	getAccountsForEvidence,
	changeAccountsForEvidenceFilterField,
} from 'actions/devicesActions';
import { NewEvidence } from 'components';
import accountsColumns from 'constants/columns/accounts';
import defaultSortModels from 'constants/defaultHeaderSortModels';

const NewDeviceEvidence = (props) => {
	const {
		createEvidence,
		createEvidenceStatus,
		getAccountsForEvidence,
		singleDeviceAccountsForEvidence,
		filters,
		changeFilterField,
	} = props;

	const navigate = useNavigate();
	const { id } = useParams();
	const filteredAccountsColumns = accountsColumns.filter(
		(column) => column.field !== 'result'
	);

	const getItems = React.useCallback(
		(params) => {
			getAccountsForEvidence({
				...params,
				filters: {
					...params.filters,
					deviceId: id,
				},
			});
		},
		[id]
	);

	const handleSubmit = React.useCallback((values) => {
		createEvidence(
			{
				...values,
				radiateAs: 'accounts',
				radiateConsumerType: 'account',
				radiatedByType: 'device',
			},
			() => navigate(-1)
		);
	}, []);

	const getCheckboxLabel = React.useCallback(
		(total) => `Radiate evidence to these ${total} accounts`,
		[]
	);

	const getRadiatedLink = React.useCallback((id) => `/accounts/${id}`, []);

	const newEvidenceTableProps = {
		title: 'Accounts,attached to device',
		data: singleDeviceAccountsForEvidence.data,
		total: singleDeviceAccountsForEvidence.total,
		columns: filteredAccountsColumns,
		getItems,
		filters,
		changeFilterField,
		defaultSortModel: defaultSortModels.account,
	};

	return (
		<NewEvidence
			withFilters={true}
			tableProps={newEvidenceTableProps}
			getCheckboxLabel={getCheckboxLabel}
			getRadiateToLink={getRadiatedLink}
			handleSubmit={handleSubmit}
			status={createEvidenceStatus}
			type='device'
		/>
	);
};

const mapStateToProps = ({ devices }) => ({
	createEvidenceStatus: devices.createEvidenceStatus,
	singleDeviceAccountsForEvidence: devices.singleDeviceAccountsForEvidence,
	filters: devices.devicesFilterForEvidence,
});

const mapDispatchToProps = (dispatch) => ({
	createEvidence: (evidence, onLoad) =>
		dispatch(createEvidence(evidence, onLoad)),
	getAccountsForEvidence: (body) => dispatch(getAccountsForEvidence(body)),
	changeFilterField: (value) =>
		dispatch(changeAccountsForEvidenceFilterField(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewDeviceEvidence);
