import {
	SERVERSTATS_GET_INFO_STATUS,
	SERVERSTATS_SET_INFO,
	SERVERSTATS_SET_IPS_INFO,
	SERVERSTATS_GET_IPS_INFO_STATUS,
	SERVERSTATS_SET_DEVICES_INFO,
	SERVERSTATS_GET_DEVICES_INFO_STATUS,
	SERVERSTATS_SET_ACCOUNTS_INFO,
	SERVERSTATS_GET_ACCOUNTS_INFO_STATUS,
	SERVER_STATS_SET_ACCOUNTS_COUNTRIES_INFO,
	SERVER_STATS_GET_ACCOUNTS_COUNTRIES_INFO_STATUS,
	SERVER_STATS_SET_DEVICES_COUNTRIES_INFO,
	SERVER_STATS_GET_DEVICES_COUNTRIES_INFO_STATUS,
	SERVER_STATS_SET_MOST_USED_RULES,
	SERVER_STATS_GET_MOST_USED_RULES_STATUS,
	SERVER_STATS_CHANGE_EVIDENCE_ENTITIES_FILTER_FIELD,
	SERVER_STATS_SET_EVIDENCE_ENTITIES,
	SERVER_STATS_GET_EVIDENCE_ENTITIES_STATUS,
	SERVER_STATS_RESET_EVIDENCE_ENTITIES_FILTERS,
	SERVER_STATS_GET_SYSTEM_KPI_DATA_STATUS,
	SERVER_STATS_SET_SYSTEM_KPI_DATA,
} from '../constants';
import { LOADING } from 'constants/apiStatuses';
import { NONE } from 'constants/apiStatuses';
import mergeFilters from '../../helpers/mergeFilters';

const initialState = {
	getGeneralInfoStatus: LOADING,
	generalInfo: null,
	getIpsInfoStatus: LOADING,
	ipsInfo: null,
	getDevicesInfoStatus: LOADING,
	devicesInfo: null,
	getAccountsInfoStatus: LOADING,
	accountsInfo: null,
	getAccountsCountriesInfoStatus: LOADING,
	accountsCountriesInfo: null,
	getDevicesCountriesInfoStatus: LOADING,
	devicesCountriesInfo: null,
	mostUsedRulesInfo: null,
	getMostUsedRulesInfoStatus: LOADING,
	getSystemKPIDataStatus: LOADING,
	systemKPIData: null,
	evidenceEntities: {
		status: NONE,
		after: [],
		data: null,
		total: null,
	},
	evidenceEntitiesFilters: {
		entity: '',
		evidenceType: '',
	},
};

const serverStatsReducer = (state = initialState, action) => {
	switch (action.type) {
		case SERVERSTATS_SET_INFO: {
			return {
				...state,
				generalInfo: action.payload,
			};
		}

		case SERVERSTATS_GET_INFO_STATUS: {
			return {
				...state,
				getGeneralInfoStatus: action.payload,
			};
		}

		case SERVERSTATS_GET_IPS_INFO_STATUS: {
			return {
				...state,
				getIpsInfoStatus: action.payload,
			};
		}

		case SERVERSTATS_SET_IPS_INFO: {
			return {
				...state,
				ipsInfo: action.payload,
			};
		}

		case SERVERSTATS_GET_DEVICES_INFO_STATUS: {
			return {
				...state,
				getDevicesInfoStatus: action.payload,
			};
		}

		case SERVERSTATS_SET_DEVICES_INFO: {
			return {
				...state,
				devicesInfo: action.payload,
			};
		}

		case SERVERSTATS_GET_ACCOUNTS_INFO_STATUS: {
			return {
				...state,
				getAccountsInfoStatus: action.payload,
			};
		}

		case SERVERSTATS_SET_ACCOUNTS_INFO: {
			return {
				...state,
				accountsInfo: action.payload,
			};
		}

		case SERVER_STATS_GET_ACCOUNTS_COUNTRIES_INFO_STATUS: {
			return {
				...state,
				getAccountsCountriesInfoStatus: action.payload,
			};
		}

		case SERVER_STATS_SET_ACCOUNTS_COUNTRIES_INFO: {
			return {
				...state,
				accountsCountriesInfo: action.payload,
			};
		}

		case SERVER_STATS_GET_DEVICES_COUNTRIES_INFO_STATUS: {
			return {
				...state,
				getDevicesCountriesInfoStatus: action.payload,
			};
		}

		case SERVER_STATS_SET_DEVICES_COUNTRIES_INFO: {
			return {
				...state,
				devicesCountriesInfo: action.payload,
			};
		}

		case SERVER_STATS_GET_MOST_USED_RULES_STATUS: {
			return {
				...state,
				getMostUsedRulesInfoStatus: action.payload,
			};
		}

		case SERVER_STATS_SET_MOST_USED_RULES: {
			return {
				...state,
				mostUsedRulesInfo: action.payload,
			};
		}

		case SERVER_STATS_CHANGE_EVIDENCE_ENTITIES_FILTER_FIELD: {
			return {
				...state,
				evidenceEntitiesFilters: {
					...state.evidenceEntitiesFilters,
					[action.payload.key]: action.payload.value,
				},
				evidenceEntities: {
					...state.evidenceEntities,
					after: [],
					total: 0,
				},
			};
		}

		case SERVER_STATS_SET_EVIDENCE_ENTITIES: {
			return {
				...state,
				evidenceEntities: {
					...state.evidenceEntities,
					total: action.payload.total,
					after: action.payload.newEntity
						? [action.payload.after_key]
						: !state.evidenceEntities.after[action.payload.page]
						? [...state.evidenceEntities.after, action.payload.after_key]
						: [...state.evidenceEntities.after],
					data: action.payload.data,
				},
			};
		}

		case SERVER_STATS_GET_EVIDENCE_ENTITIES_STATUS: {
			return {
				...state,
				evidenceEntities: {
					...state.evidenceEntities,
					status: action.payload,
				},
			};
		}

		case SERVER_STATS_RESET_EVIDENCE_ENTITIES_FILTERS: {
			return {
				...state,
				evidenceEntitiesFilters: mergeFilters(
					action.payload,
					initialState.evidenceEntitiesFilters
				),
			};
		}

		case SERVER_STATS_GET_SYSTEM_KPI_DATA_STATUS: {
			return {
				...state,
				getSystemKPIDataStatus: action.payload,
			};
		}

		case SERVER_STATS_SET_SYSTEM_KPI_DATA: {
			return {
				...state,
				systemKPIData: action.payload,
			};
		}

		default: {
			return state;
		}
	}
};

export default serverStatsReducer;
