import axiosInstance from '../index';

const getEvidenceHistory = ({ cursor, page, pageSize, filters, sorting }) => {
	return axiosInstance
		.post(
			`/evidence/?sortId=${cursor.search_after[0]}&remain=${cursor.search_after[1]}&limit=${pageSize}`,
			{ filters, sorting, pit: cursor.pit }
		)
		.then((res) => {
			return {
				...res.data,
				page,
			};
		});
};

export { getEvidenceHistory };
