import { LOADING } from 'constants/apiStatuses';
import {
	IPS_CHANGE_FILTER_FIELD,
	IPS_CREATE_EVIDENCE_STATUS,
	IPS_DELETE_EVIDENCE_STATUS,
	IPS_DELETE_EVIDENCE,
	IPS_GET_IPS_FOR_EVIDENCE_STATUS,
	IPS_GET_IPS_STATUS,
	IPS_GET_SINGLE_IP_STATUS,
	IPS_SET_IPS,
	IPS_SET_IPS_FOR_EVIDENCE,
	IPS_SET_SINGLE_IP,
	IPS_CREATE_IP_STATUS,
	IPS_RESET_FILTERS,
	IPS_ADD_EVIDENCE_COMMENT_STATUS,
	IPS_ADD_EVIDENCE_COMMENT,
	IPS_SET_EVIDENCE_HISTORY,
	IPS_GET_EVIDENCE_HISTORY_STATUS,
} from '../constants';
import { NONE } from 'constants/apiStatuses';
import mergeFilters from '../../helpers/mergeFilters';

const initialState = {
	getIpsStatus: LOADING,
	data: null,
	total: null,
	createEvidenceStatus: NONE,
	deleteEvidenceStatus: NONE,
	ipBlocksForEvidence: {
		status: NONE,
		data: null,
		total: null,
	},
	evidenceHistory: {
		status: NONE,
		total: null,
		data: null,
	},
	getSingleIpStatus: LOADING,
	createIpStatus: NONE,
	addCommentStatus: NONE,
	singleIp: null,
	filters: {
		isp: '',
		ip: '',
	},
};

const ipsReducer = (state = initialState, action) => {
	switch (action.type) {
		case IPS_GET_IPS_STATUS: {
			return {
				...state,
				getIpsStatus: action.payload,
			};
		}

		case IPS_SET_IPS: {
			return {
				...state,
				data: action.payload.ips,
				total: action.payload.total,
			};
		}

		case IPS_CHANGE_FILTER_FIELD: {
			return {
				...state,
				filters: {
					...state.filters,
					[action.payload.key]: action.payload.value,
				},
			};
		}

		case IPS_GET_SINGLE_IP_STATUS: {
			return {
				...state,
				getSingleIpStatus: action.payload,
			};
		}

		case IPS_SET_SINGLE_IP: {
			return {
				...state,
				singleIp: action.payload,
			};
		}

		case IPS_DELETE_EVIDENCE_STATUS: {
			return {
				...state,
				deleteEvidenceStatus: action.payload,
			};
		}

		case IPS_DELETE_EVIDENCE: {
			const evidenceIndex = state.evidenceHistory.data.findIndex(
				(evidence) => evidence.id === action.payload.id
			);

			return {
				...state,
				evidenceHistory: {
					...state.evidenceHistory,
					data: [
						...state.evidenceHistory.data.slice(0, evidenceIndex),
						action.payload,
						...state.evidenceHistory.data.slice(evidenceIndex + 1),
					],
				},
			};
		}

		case IPS_GET_IPS_FOR_EVIDENCE_STATUS: {
			return {
				...state,
				ipBlocksForEvidence: {
					...state.ipBlocksForEvidence,
					status: action.payload,
				},
			};
		}

		case IPS_SET_IPS_FOR_EVIDENCE: {
			return {
				...state,
				ipBlocksForEvidence: {
					...state.ipBlocksForEvidence,
					total: action.payload.total,
					data: action.payload.ips,
				},
			};
		}

		case IPS_CREATE_EVIDENCE_STATUS: {
			return {
				...state,
				createEvidenceStatus: action.payload,
			};
		}

		case IPS_CREATE_IP_STATUS: {
			return {
				...state,
				createIpStatus: action.payload,
			};
		}

		case IPS_RESET_FILTERS: {
			return {
				...state,
				filters: mergeFilters(action.payload, initialState.filters),
			};
		}

		case IPS_ADD_EVIDENCE_COMMENT_STATUS: {
			return {
				...state,
				addCommentStatus: action.payload,
			};
		}

		case IPS_ADD_EVIDENCE_COMMENT: {
			const evidenceIndex = state.evidenceHistory.data.findIndex(
				(evidence) => evidence.id === action.payload.id
			);

			return {
				...state,
				evidenceHistory: {
					...state.evidenceHistory,
					data: [
						...state.evidenceHistory.data.slice(0, evidenceIndex),
						action.payload,
						...state.evidenceHistory.data.slice(evidenceIndex + 1),
					],
				},
			};
		}

		case IPS_SET_EVIDENCE_HISTORY: {
			return {
				...state,
				evidenceHistory: {
					...state.evidenceHistory,
					data: action.payload.evidences,
					total: action.payload.total,
				},
			};
		}

		case IPS_GET_EVIDENCE_HISTORY_STATUS: {
			return {
				...state,
				evidenceHistory: {
					...state.evidenceHistory,
					status: action.payload,
				},
			};
		}

		default: {
			return state;
		}
	}
};

export default ipsReducer;
