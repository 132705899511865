import axiosInstance from '../index';

const getAccountsByName = (name) => {
	return axiosInstance.get(`/accounts/byname/${name}`).then((res) => {
		return {
			...res.data,
		};
	});
};

export { getAccountsByName };
