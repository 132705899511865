import {
	ACCOUNTS_SET_SINGLE_ACCOUNT,
	ACCOUNTS_GET_SINGLE_ACCOUNT_STATUS,
	ACCOUNTS_CREATE_EVIDENCE_STATUS,
	ACCOUNTS_DELETE_EVIDENCE_STATUS,
	ACCOUNTS_DELETE_EVIDENCE,
	ACCOUNTS_GET_ACCOUNTS_STATUS,
	ACCOUNTS_SET_ACCOUNTS,
	ACCOUNTS_CHANGE_FILTER_FIELD,
	ACCOUNTS_SET_DEVICES,
	ACCOUNTS_GET_TRANSACTIONS_STATUS,
	ACCOUNTS_SET_TRANSACTIONS,
	ACCOUNTS_GET_ACCOUNTS_FOR_EVIDENCE_STATUS,
	ACCOUNTS_SET_ACCOUNTS_FOR_EVIDENCE,
	ACCOUNTS_SET_ATTACHED_ACCOUNTS,
	ACCOUNTS_SET_ATTACHED_DEVICES,
	ACCOUNTS_GET_ATTACHED_ACCOUNTS_STATUS,
	ACCOUNTS_CHANGE_ACCOUNTS_FOR_EVIDENCE_FILTER_FIELD,
	ACCOUNTS_RESET_FILTERS,
	ACCOUNTS_GET_ACCOUNTS_BY_NAME_STATUS,
	ACCOUNTS_SET_ACCOUNTS_BY_NAME,
	ACCOUNTS_DELETE_ACCOUNT_STATUS,
	ACCOUNTS_CHANGE_TRANSACTIONS_FILTER_FIELD,
	ACCOUNTS_RESET_TRANSACTIONS_FILTERS,
	ACCOUNTS_GET_ATTACHED_DEVICES_STATUS,
	ACCOUNTS_EXPORT_TRANSACTIONS_TO_JSON_STATUS,
	ACCOUNTS_ADD_EVIDENCE_COMMENT_STATUS,
	ACCOUNTS_ADD_EVIDENCE_COMMENT,
	ACCOUNTS_EXPORT_TRANSACTIONS_TO_CSV_STATUS,
	ACCOUNTS_SET_EVIDENCE_HISTORY,
	ACCOUNTS_GET_EVIDENCE_HISTORY_STATUS,
} from '../constants';
import { LOADING, NONE } from 'constants/apiStatuses';
import mergeFilters from 'helpers/mergeFilters';

const initialState = {
	data: null,
	total: null,
	attachedAccounts: null,
	attachedDevices: null,
	getAttachedAccountsStatus: LOADING,
	getAttachedDevicesStatus: NONE,
	exportTransactionsToJSONStatus: NONE,
	exportTransactionsToCSVStatus: NONE,
	accountsForEvidence: {
		status: NONE,
		total: null,
		data: null,
	},
	accountsFilterForEvidence: {
		lastSeen: new Date(new Date() - 3 * 30 * 24 * 60 * 60 * 1000),
	},
	getAccountsStatus: NONE,
	getSingleAccountStatus: LOADING,
	singleAccount: null,
	createEvidenceStatus: NONE,
	deleteEvidenceStatus: NONE,
	addCommentStatus: NONE,
	singleAccountDevices: {
		status: NONE,
		total: null,
		data: null,
	},
	singleAccountTransactions: {
		status: NONE,
		total: null,
		data: null,
	},
	evidenceHistory: {
		status: NONE,
		total: null,
		data: null,
	},
	filters: {
		name: '',
		id: '',
	},
	accountsForAutocomplete: null,
	accountsForAutocompleteStatus: NONE,
	deleteAccountStatus: NONE,
	transactionFilters: {
		accountName: '',
		deviceId: '',
		deviceUUID: '',
		deviceTimeZone: '',
		sessionIsp: '',
		sessionCountryName: '',
		ruleSet: '',
		ruleName: [''],
		score: '',
		maxScore: '',
		sessionIp: '',
		id: '',
		minCreationDate: new Date(2019, 11, 30),
		maxCreationDate: new Date(2030, 11, 31),
	},
};

const accountsReducer = (state = initialState, action) => {
	switch (action.type) {
		case ACCOUNTS_GET_ACCOUNTS_STATUS: {
			return {
				...state,
				getAccountsStatus: action.payload,
			};
		}

		case ACCOUNTS_SET_ACCOUNTS: {
			return {
				...state,
				data: action.payload.accounts,
				total: action.payload.total,
			};
		}

		case ACCOUNTS_SET_SINGLE_ACCOUNT: {
			return {
				...state,
				singleAccount: action.payload,
			};
		}
		case ACCOUNTS_GET_SINGLE_ACCOUNT_STATUS: {
			return {
				...state,
				getSingleAccountStatus: action.payload,
			};
		}

		case ACCOUNTS_CREATE_EVIDENCE_STATUS: {
			return {
				...state,
				createEvidenceStatus: action.payload,
			};
		}

		case ACCOUNTS_DELETE_EVIDENCE: {
			const evidenceIndex = state.evidenceHistory.data.findIndex(
				(evidence) => evidence.id === action.payload.id
			);

			return {
				...state,
				evidenceHistory: {
					...state.evidenceHistory,
					data: [
						...state.evidenceHistory.data.slice(0, evidenceIndex),
						action.payload,
						...state.evidenceHistory.data.slice(evidenceIndex + 1),
					],
				},
			};
		}

		case ACCOUNTS_SET_DEVICES: {
			return {
				...state,
				singleAccountDevices: {
					...state.singleAccountDevices,
					data: action.payload.devices,
					total: action.payload.total,
				},
			};
		}

		case ACCOUNTS_DELETE_EVIDENCE_STATUS: {
			return {
				...state,
				deleteEvidenceStatus: action.payload,
			};
		}

		case ACCOUNTS_CHANGE_FILTER_FIELD: {
			return {
				...state,
				filters: {
					...state.filters,
					[action.payload.key]: action.payload.value,
				},
			};
		}

		case ACCOUNTS_GET_TRANSACTIONS_STATUS: {
			return {
				...state,
				singleAccountTransactions: {
					...state.singleAccountTransactions,
					status: action.payload,
				},
			};
		}

		case ACCOUNTS_GET_ACCOUNTS_FOR_EVIDENCE_STATUS: {
			return {
				...state,
				accountsForEvidence: {
					...state.accountsForEvidence,
					status: action.payload,
				},
			};
		}

		case ACCOUNTS_SET_ACCOUNTS_FOR_EVIDENCE: {
			return {
				...state,
				accountsForEvidence: {
					...state.accountsForEvidence,
					total: action.payload.total,
					data: action.payload.accounts,
				},
			};
		}

		case ACCOUNTS_CHANGE_ACCOUNTS_FOR_EVIDENCE_FILTER_FIELD: {
			return {
				...state,
				accountsFilterForEvidence: {
					...state.accountsFilterForEvidence,
					[action.payload.key]: action.payload.value,
				},
			};
		}

		case ACCOUNTS_SET_TRANSACTIONS: {
			return {
				...state,
				singleAccountTransactions: {
					...state.singleAccountTransactions,
					data: action.payload.transactions.map(
						({ session, ...transaction }) => {
							return {
								...transaction,
								connectionIP: session.connectionIP,
								serverTime: session.serverTime,
								ip: session.ipEnrichment.ip,
								debug: JSON.stringify(session.debug),
								fpAgeInMs: session.fpAgeInMs,
								score: transaction.rating.score,
								ruleSet: transaction.rating.ruleSet,
								deviceId: transaction.device.id,
								accountName: transaction.account.name,
							};
						}
					),
					total: action.payload.total,
				},
			};
		}

		case ACCOUNTS_GET_ATTACHED_ACCOUNTS_STATUS: {
			return {
				...state,
				getAttachedAccountsStatus: action.payload,
			};
		}

		case ACCOUNTS_SET_ATTACHED_ACCOUNTS: {
			return {
				...state,
				attachedAccounts: action.payload,
			};
		}

		case ACCOUNTS_GET_ATTACHED_DEVICES_STATUS: {
			return {
				...state,
				getAttachedDevicesStatus: action.payload,
			};
		}

		case ACCOUNTS_SET_ATTACHED_DEVICES: {
			return {
				...state,
				attachedDevices: action.payload,
			};
		}

		case ACCOUNTS_RESET_FILTERS: {
			return {
				...state,
				filters: mergeFilters(action.payload, initialState.filters),
			};
		}

		case ACCOUNTS_SET_ACCOUNTS_BY_NAME: {
			return {
				...state,
				accountsForAutocomplete: action.payload,
			};
		}

		case ACCOUNTS_GET_ACCOUNTS_BY_NAME_STATUS: {
			return {
				...state,
				accountsForAutocompleteStatus: action.payload,
			};
		}

		case ACCOUNTS_DELETE_ACCOUNT_STATUS: {
			return {
				...state,
				deleteAccountStatus: action.payload,
			};
		}

		case ACCOUNTS_CHANGE_TRANSACTIONS_FILTER_FIELD: {
			let updatedFilters = {};

			if (Array.isArray(action.payload)) {
				action.payload.forEach((item) => {
					if (updatedFilters[item.key]) {
						updatedFilters[item.key].push(item.value);
					} else {
						updatedFilters[item.key] = [item.value];
					}
				});
			} else if (typeof action.payload === 'object') {
				updatedFilters = {
					...state.transactionFilters,
					[action.payload.key]: action.payload.value,
				};
			}

			return {
				...state,
				transactionFilters: {
					...state.transactionFilters,
					...updatedFilters,
				},
			};
		}

		case ACCOUNTS_RESET_TRANSACTIONS_FILTERS: {
			return {
				...state,
				transactionFilters: mergeFilters(
					action.payload,
					initialState.transactionFilters
				),
			};
		}

		case ACCOUNTS_EXPORT_TRANSACTIONS_TO_JSON_STATUS: {
			return {
				...state,
				exportTransactionsToJSONStatus: action.payload,
			};
		}

		case ACCOUNTS_EXPORT_TRANSACTIONS_TO_CSV_STATUS: {
			return {
				...state,
				exportTransactionsToCSVStatus: action.payload,
			};
		}

		case ACCOUNTS_ADD_EVIDENCE_COMMENT_STATUS: {
			return {
				...state,
				addCommentStatus: action.payload,
			};
		}

		case ACCOUNTS_ADD_EVIDENCE_COMMENT: {
			const evidenceIndex = state.evidenceHistory.data.findIndex(
				(evidence) => evidence.id === action.payload.id
			);

			return {
				...state,
				evidenceHistory: {
					...state.evidenceHistory,
					data: [
						...state.evidenceHistory.data.slice(0, evidenceIndex),
						action.payload,
						...state.evidenceHistory.data.slice(evidenceIndex + 1),
					],
				},
			};
		}

		case ACCOUNTS_SET_EVIDENCE_HISTORY: {
			return {
				...state,
				evidenceHistory: {
					...state.evidenceHistory,
					data: action.payload.evidences,
					total: action.payload.total,
				},
			};
		}

		case ACCOUNTS_GET_EVIDENCE_HISTORY_STATUS: {
			return {
				...state,
				evidenceHistory: {
					...state.evidenceHistory,
					status: action.payload,
				},
			};
		}

		default: {
			return state;
		}
	}
};

export default accountsReducer;
