import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { Link as RouterLink } from 'react-router-dom';
import { withAdmin } from 'components';
import {
	Box,
	Button,
	FormControl,
	Link,
	MenuItem,
	Select,
	TextField,
	Typography,
} from '@mui/material';
import JSONInput from 'react-json-editor-ajrm/index';
import locale from 'react-json-editor-ajrm/locale/en';
import { getRating } from 'actions/ratingActions';
import ipReputations from 'constants/ipReputations';
import { LOADING } from 'constants/apiStatuses';
import isSubmitButtonDisabled from 'helpers/isSubmitButtonDisabled';
import './styles.css';

const Rating = (props) => {
	const initialValues = {
		debug: '',
		ip: '',
		ruleSet: '',
		accountName: '',
	};

	const jsonInputRef = useRef();
	const [game1Result, setGame1Result] = useState(null);

	const { rating, getRating, getRatingStatus } = props;

	const handleGetRating = (form) => {
		getRating({
			...form,
			jsonInput: jsonInputRef.current.state,
		});
	};

	useEffect(async () => {
		// eslint-disable-next-line no-undef
		let data = await new Promise((res) => game1((e) => res(atob(e))));
		setGame1Result(JSON.parse(data));
	}, []);

	if (!game1Result) {
		return <div>Loading...</div>;
	}

	const jsonInputStyles = { container: { border: '1px solid lightgrey' } };
	const formInitialValues = {
		...initialValues,
		fp: game1Result,
	};

	return (
		<Form
			initialValues={formInitialValues}
			onSubmit={handleGetRating}
			render={({ handleSubmit, touched, errors }) => {
				return (
					<form onSubmit={handleSubmit}>
						<Typography variant='h4' margin='20px 0'>
							Rating page
						</Typography>
						<Field name='fp'>
							{(props) => (
								<JSONInput
									ref={jsonInputRef}
									placeholder={props.input.value}
									width='100%'
									confirmGood={false}
									style={jsonInputStyles}
									theme='light_mitsuketa_tribute'
									locale={locale}
								/>
							)}
						</Field>
						<Field name='ruleSet'>
							{(props) => (
								<Box display='flex' alignItems='center' className='ratingBlock'>
									<Typography className='ratingLabel'>Rule Set:</Typography>
									<TextField
										className='ratingInput'
										value={props.input.value}
										onChange={(e) => props.input.onChange(e.target.value)}
									/>
								</Box>
							)}
						</Field>
						<Field name='accountName'>
							{(props) => (
								<Box display='flex' alignItems='center' className='ratingBlock'>
									<Typography className='label'>Account Name:</Typography>
									<TextField
										className='input'
										value={props.input.value}
										onChange={(e) => props.input.onChange(e.target.value)}
									/>
								</Box>
							)}
						</Field>
						<Field name='ipReputation'>
							{(props) => (
								<Box display='flex' alignItems='center' className='ratingBlock'>
									<Typography className='ratingLabel'>
										Ip reputation:
									</Typography>
									<FormControl className='ratingInput'>
										<Select
											value={props.input.value}
											onChange={(e) => props.input.onChange(e.target.value)}
										>
											{ipReputations.map((ipReputation) => (
												<MenuItem value={ipReputation} key={ipReputation}>
													{ipReputation}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Box>
							)}
						</Field>
						<Field name='debug'>
							{(props) => (
								<Box
									display='flex'
									alignItems='flex-start'
									className='ratingBlock'
								>
									<Typography className='ratingLabel'>Debug:</Typography>
									<TextField
										multiline
										rows={7}
										variant='outlined'
										className='ratingInput'
										value={props.input.value}
										onChange={(e) => props.input.onChange(e.target.value)}
									/>
								</Box>
							)}
						</Field>
						<Field name='ip'>
							{(props) => (
								<Box display='flex' alignItems='center' className='ratingBlock'>
									<Typography className='ratingLabel'>IP:</Typography>
									<TextField
										value={props.input.value}
										onChange={(e) => props.input.onChange(e.target.value)}
										className='ratingInput'
									/>
								</Box>
							)}
						</Field>
						<Box
							width='200'
							className='ratingSubmitButtonContainer'
							display='flex'
							justifyContent='flex-end'
						>
							<Button
								variant='contained'
								color='primary'
								type='submit'
								disabled={isSubmitButtonDisabled(
									errors,
									touched,
									getRatingStatus
								)}
							>
								Rate
							</Button>
						</Box>
						{getRatingStatus === LOADING && <div>Loading...</div>}
						<Box>
							<Typography className='ratingResult' variant='h6'>
								Result:
							</Typography>
							{rating && (
								<Box>
									<Box className='ratingInfo'>Rating : {rating.score}</Box>
									<Box className='ratingInfo'>Outcome : {rating.outcome}</Box>
									<Link
										component={RouterLink}
										to={`/transactions/${rating.id}`}
									>
										Go to created transaction
									</Link>
								</Box>
							)}
						</Box>
					</form>
				);
			}}
		/>
	);
};

const mapStateToProps = ({ rating }) => ({
	getRatingStatus: rating.getRatingStatus,
	rating: rating.data,
});

const mapDispatchToProps = (dispatch) => ({
	getRating: (body) => dispatch(getRating(body)),
});

export default withAdmin(connect(mapStateToProps, mapDispatchToProps)(Rating));
