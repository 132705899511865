import { CustomTableCell } from 'components';
import transformAgeInMs from 'helpers/transformAgeInMs';
import { dateFormatter } from 'helpers/formatters';
import React from 'react';

const transactionsColumns = [
	{
		field: 'connectionIP',
		editable: false,
		headerName: 'Connection IP',
		headerAlign: 'center',
		flex: 1,
		renderCell: (params) => (
			<CustomTableCell filterField='sessionIp' {...params} />
		),
	},
	{
		field: 'serverTime',
		editable: false,
		headerName: 'Time',
		headerAlign: 'center',
		flex: 1,
		renderCell: (params) => (
			<CustomTableCell {...params} value={dateFormatter(params.value)} />
		),
	},
	{
		field: 'fpAgeInMs',
		editable: false,
		headerName: 'Age d:h:m:s.mmm',
		flex: 1,
		headerAlign: 'center',
		renderCell: (params) => (
			<CustomTableCell {...params} value={transformAgeInMs(params.value)} />
		),
	},
	{
		field: 'score',
		editable: false,
		headerName: 'Score',
		flex: 1,
		headerAlign: 'center',
		renderCell: (params) => <CustomTableCell filterField='score' {...params} />,
	},
	{
		field: 'ruleSet',
		editable: false,
		headerName: 'Rule set',
		flex: 1,
		headerAlign: 'center',
		renderCell: (params) => (
			<CustomTableCell filterField='ruleSet' {...params} />
		),
	},
	{
		field: 'accountName',
		editable: false,
		headerName: 'Account name',
		flex: 1,
		headerAlign: 'center',
		renderCell: (params) => {
			return (
				<CustomTableCell
					filterField='accountName'
					getNewTabLink={() =>
						`${window.location.origin}/accounts/${params.row.account.id}`
					}
					{...params}
				/>
			);
		},
	},
	{
		field: 'id',
		editable: false,
		headerName: 'Transaction ID',
		flex: 1,
		headerAlign: 'center',
		renderCell: (params) => (
			<CustomTableCell
				filterField='id'
				getNewTabLink={(id) => `${window.location.origin}/transactions/${id}`}
				{...params}
			/>
		),
	},
	{
		field: 'deviceId',
		editable: false,
		headerName: 'Device ID',
		flex: 1,
		headerAlign: 'center',
		renderCell: (params) => (
			<CustomTableCell
				filterField='deviceId'
				getNewTabLink={(deviceId) =>
					`${window.location.origin}/devices/${deviceId}`
				}
				{...params}
			/>
		),
	},
	{
		field: 'ruleMaxScore',
		editable: false,
		headerName: 'Rule',
		sortable: false,
		flex: 1,
		headerAlign: 'center',
		valueGetter: (params) => [params.value.name],
		renderCell: (params) => (
			<CustomTableCell filterField='ruleName' {...params} />
		),
	},
	{
		field: 'country', // countryISO field, if need country ISO name
		editable: false,
		headerName: 'Country',
		flex: 1,
		headerAlign: 'center',
		renderCell: (params) => (
			<CustomTableCell filterField='sessionCountryName' {...params} />
		),
	},
	{
		field: 'result',
		headerName: 'Result',
		width: 80,
		hideSortIcons: true,
		sortable: false,
		headerAlign: 'center',
		valueGetter: () => ' ',
		renderCell: () => <span className={'scoreIndicator'} />,
	},
];

export default transactionsColumns;
