import { getGeneralInfo as getGeneralInfoAPI } from 'services/api/serverstats/getGeneralInfo';
import { getIpsInfo as getIpsInfoAPI } from 'services/api/serverstats/getIpsInfo';
import { getDevicesInfo as getDevicesInfoAPI } from 'services/api/serverstats/getDevicesInfo';
import { getAccountsInfo as getAccountsInfoAPI } from 'services/api/serverstats/getAccountsInfo';
import { getAccountsCountriesInfo as getAccountsCountriesInfoAPI } from 'services/api/serverstats/getAccountsCountriesInfo';
import { getDevicesCountriesInfo as getDevicesCountriesInfoAPI } from 'services/api/serverstats/getDevicesCountriesInfo';
import { getSystemKPIData as getSystemKPIDataAPI } from 'services/api/serverstats/getSystemKPIData';
import { getMostUsedRule as getMostUsedRuleAPI } from 'services/api/serverstats/getMostUsedRule';
import { getEvidenceEntities as getEvidenceEntitiesAPI } from 'services/api/serverstats/getEvidenceEntities';
import { LOADING, NONE, ERROR } from 'constants/apiStatuses';
import {
	SERVERSTATS_SET_INFO,
	SERVERSTATS_GET_INFO_STATUS,
	SERVERSTATS_GET_IPS_INFO_STATUS,
	SERVERSTATS_SET_IPS_INFO,
	SERVERSTATS_GET_DEVICES_INFO_STATUS,
	SERVERSTATS_SET_DEVICES_INFO,
	SERVERSTATS_SET_ACCOUNTS_INFO,
	SERVERSTATS_GET_ACCOUNTS_INFO_STATUS,
	SERVER_STATS_GET_ACCOUNTS_COUNTRIES_INFO_STATUS,
	SERVER_STATS_SET_ACCOUNTS_COUNTRIES_INFO,
	SERVER_STATS_GET_DEVICES_COUNTRIES_INFO_STATUS,
	SERVER_STATS_SET_DEVICES_COUNTRIES_INFO,
	SERVER_STATS_GET_MOST_USED_RULES_STATUS,
	SERVER_STATS_SET_MOST_USED_RULES,
	SERVER_STATS_GET_EVIDENCE_ENTITIES_STATUS,
	SERVER_STATS_SET_EVIDENCE_ENTITIES,
	SERVER_STATS_CHANGE_EVIDENCE_ENTITIES_FILTER_FIELD,
	SERVER_STATS_RESET_EVIDENCE_ENTITIES_FILTERS,
	SERVER_STATS_GET_SYSTEM_KPI_DATA_STATUS,
	SERVER_STATS_SET_SYSTEM_KPI_DATA,
} from '../constants';

export const setGetInfoStatus = (status) => ({
	type: SERVERSTATS_GET_INFO_STATUS,
	payload: status,
});

export const setInfo = (info) => ({
	type: SERVERSTATS_SET_INFO,
	payload: info,
});

export const getGeneralInfo = (seconds) => (dispatch, getState) => {
	dispatch(setGetInfoStatus(LOADING));
	getGeneralInfoAPI(seconds)
		.then((info) => {
			dispatch(setInfo(info));
			dispatch(setGetInfoStatus(NONE));
		})
		.catch(() => dispatch(setGetInfoStatus(ERROR)));
};

export const setGetIpsInfoStatus = (status) => ({
	type: SERVERSTATS_GET_IPS_INFO_STATUS,
	payload: status,
});

export const setIpsInfo = (ipsInfo) => ({
	type: SERVERSTATS_SET_IPS_INFO,
	payload: ipsInfo,
});

export const getIpsInfo = (dispatch, getState) => {
	dispatch(setGetIpsInfoStatus(LOADING));
	getIpsInfoAPI()
		.then((info) => {
			dispatch(setIpsInfo(info));
			dispatch(setGetIpsInfoStatus(NONE));
		})
		.catch(() => dispatch(setGetIpsInfoStatus(ERROR)));
};

export const setGetDevicesInfoStatus = (status) => ({
	type: SERVERSTATS_GET_DEVICES_INFO_STATUS,
	payload: status,
});

export const setDevicesInfo = (devicesInfo) => ({
	type: SERVERSTATS_SET_DEVICES_INFO,
	payload: devicesInfo,
});

export const getDevicesInfo = (dispatch, getState) => {
	dispatch(setGetDevicesInfoStatus(LOADING));
	getDevicesInfoAPI()
		.then((info) => {
			dispatch(setDevicesInfo(info));
			dispatch(setGetDevicesInfoStatus(NONE));
		})
		.catch(() => dispatch(setGetDevicesInfoStatus(ERROR)));
};

export const setGetAccountsInfoStatus = (status) => ({
	type: SERVERSTATS_GET_ACCOUNTS_INFO_STATUS,
	payload: status,
});

export const setAccountsInfo = (accountsInfo) => ({
	type: SERVERSTATS_SET_ACCOUNTS_INFO,
	payload: accountsInfo,
});

export const getAccountsInfo = (dispatch, getState) => {
	dispatch(setGetAccountsInfoStatus(LOADING));
	getAccountsInfoAPI()
		.then((info) => {
			dispatch(setAccountsInfo(info));
			dispatch(setGetAccountsInfoStatus(NONE));
		})
		.catch(() => dispatch(setGetAccountsInfoStatus(ERROR)));
};

export const setGetAccountsCountriesInfoStatus = (status) => ({
	type: SERVER_STATS_GET_ACCOUNTS_COUNTRIES_INFO_STATUS,
	payload: status,
});

export const setAccountsCountriesInfo = (accountsCountriesInfo) => ({
	type: SERVER_STATS_SET_ACCOUNTS_COUNTRIES_INFO,
	payload: accountsCountriesInfo,
});

export const getAccountsCountriesInfo = (dispatch, getState) => {
	dispatch(setGetAccountsCountriesInfoStatus(LOADING));
	getAccountsCountriesInfoAPI()
		.then((info) => {
			dispatch(setAccountsCountriesInfo(info));
			dispatch(setGetAccountsCountriesInfoStatus(NONE));
		})
		.catch(() => dispatch(setGetAccountsCountriesInfoStatus(ERROR)));
};

export const setGetDevicesCountriesInfoStatus = (status) => ({
	type: SERVER_STATS_GET_DEVICES_COUNTRIES_INFO_STATUS,
	payload: status,
});

export const setDevicesCountriesInfo = (devicesCountriesInfo) => ({
	type: SERVER_STATS_SET_DEVICES_COUNTRIES_INFO,
	payload: devicesCountriesInfo,
});

export const getDevicesCountriesInfo = (dispatch, getState) => {
	dispatch(setGetDevicesCountriesInfoStatus(LOADING));
	getDevicesCountriesInfoAPI()
		.then((info) => {
			dispatch(setDevicesCountriesInfo(info));
			dispatch(setGetDevicesCountriesInfoStatus(NONE));
		})
		.catch(() => dispatch(setGetDevicesCountriesInfoStatus(ERROR)));
};

export const setGetMostUsedRulesStatus = (status) => ({
	type: SERVER_STATS_GET_MOST_USED_RULES_STATUS,
	payload: status,
});

export const setMostUsedRules = (rule) => ({
	type: SERVER_STATS_SET_MOST_USED_RULES,
	payload: rule,
});

export const getMostUsedRules = (dispatch, getState) => {
	dispatch(setGetMostUsedRulesStatus(LOADING));
	getMostUsedRuleAPI()
		.then((data) => {
			dispatch(setMostUsedRules(data));
			dispatch(setGetMostUsedRulesStatus(NONE));
		})
		.catch(() => dispatch(setGetMostUsedRulesStatus(ERROR)));
};

export const setGetEvidenceEntitiesStatus = (status) => ({
	type: SERVER_STATS_GET_EVIDENCE_ENTITIES_STATUS,
	payload: status,
});

export const setEvidenceEntities = (data) => ({
	type: SERVER_STATS_SET_EVIDENCE_ENTITIES,
	payload: data,
});

export const getEvidenceEntities = (body) => (dispatch, getState) => {
	dispatch(setGetEvidenceEntitiesStatus(LOADING));
	getEvidenceEntitiesAPI(body)
		.then((res) => {
			dispatch(
				setEvidenceEntities({
					...res,
					newEntity: body.newEntity,
					page: body.page,
				})
			);
			dispatch(setGetEvidenceEntitiesStatus(NONE));
		})
		.catch((e) => {
			dispatch(setGetEvidenceEntitiesStatus(ERROR));
		});
};

export const changeEvidenceEntitiesFilterField = (value) => ({
	type: SERVER_STATS_CHANGE_EVIDENCE_ENTITIES_FILTER_FIELD,
	payload: value,
});

export const resetEvidenceEntitiesFilters = (filters) => ({
	type: SERVER_STATS_RESET_EVIDENCE_ENTITIES_FILTERS,
	payload: filters,
});

export const setGetSystemKPIDataStatus = (status) => ({
	type: SERVER_STATS_GET_SYSTEM_KPI_DATA_STATUS,
	payload: status,
});

export const setSystemKPIData = (serverStatsKPIData) => ({
	type: SERVER_STATS_SET_SYSTEM_KPI_DATA,
	payload: serverStatsKPIData,
});

export const getSystemKPIData = (seconds) => (dispatch) => {
	dispatch(setGetSystemKPIDataStatus(LOADING));
	getSystemKPIDataAPI(seconds)
		.then((systemKPI) => {
			dispatch(setSystemKPIData(systemKPI));
			dispatch(setGetSystemKPIDataStatus(NONE));
		})
		.catch(() => dispatch(setGetSystemKPIDataStatus(ERROR)));
};
