import React from 'react';
import {
	IconButton,
	Link,
	Table,
	Button,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Tooltip,
} from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AddIcon from '@mui/icons-material/Add';

import { dateFormatter } from 'helpers/formatters';
import { EvidenceHistoryContext } from '../index';
import '../styles.css';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import notification from 'helpers/notification';

const EvidenceHistory = ({ row: evidence }) => {
	const {
		handleGoToRadiateFrom,
		handleOpenNewComment,
		handleDeleteEvidence,
		users,
	} = React.useContext(EvidenceHistoryContext);
	const evidenceItem = { id: evidence.id };

	const commentsContent = (evidence) => {
		return evidence.comments?.map((comment, index) => {
			const userName =
				users?.find(({ id }) => comment.id === id)?.username || comment.id;

			return (
				<TableRow key={`${comment.comment}_${index}`}>
					<TableCell>Commented</TableCell>
					<TableCell />
					<TableCell>{dateFormatter(comment.date)}</TableCell>
					<TableCell>{userName}</TableCell>
					<TableCell
						style={{
							width: 400,
							whiteSpace: 'normal',
							wordWrap: 'break-word',
						}}
					>
						{comment.comment}
					</TableCell>
				</TableRow>
			);
		});
	};

	const removeEvidenceContent = (evidence) => {
		const handleDelete = handleDeleteEvidence(evidenceItem);

		if (evidence?.status === 'active') {
			return (
				<TableCell colSpan={3} align='center'>
					<Button
						variant='contained'
						color='secondary'
						size='small'
						onClick={handleDelete}
					>
						Remove evidence
					</Button>
				</TableCell>
			);
		}
		const authorWhoDeleteId =
			users?.find(({ id }) => evidence.authorWhoDeleteId === id)?.username ||
			evidence.authorWhoDeleteId;

		return (
			<>
				<TableCell />
				<TableCell>{dateFormatter(evidence.deletedTime)}</TableCell>
				<TableCell>{authorWhoDeleteId}</TableCell>
				<TableCell>{evidence.deleteComment}</TableCell>
			</>
		);
	};

	const handleSuccessfulCopy = () => {
		notification.invoke('Value was successfully copied!', {
			variant: 'success',
		});
	};

	const tooltipLink = (evidence) => {
		return (
			<>
				<CopyToClipboard
					text={evidence.radiatedBy.name}
					onCopy={handleSuccessfulCopy}
				>
					<IconButton className='evidenceTooltipButton'>
						<FilterNoneIcon fontSize='small' className='evidenceTooltipIcon' />
					</IconButton>
				</CopyToClipboard>
				<Link
					component={IconButton}
					className='evidenceTooltipButton'
					target='_blank'
					href={`/${evidence.radiatedBy.type}s/${evidence.radiatedBy.id}`}
				>
					<ExitToAppIcon fontSize='small' className='evidenceTooltipIcon' />
				</Link>
			</>
		);
	};

	const radiatedByContent = (evidence) => {
		if (!evidence?.radiatedBy?.id) {
			return '-';
		}
		const handleLinkClick = () =>
			handleGoToRadiateFrom(evidence.radiatedBy.id, evidence.radiatedBy.type);

		return (
			<>
				Radiated by {evidence.radiatedBy.type}{' '}
				<Tooltip title={tooltipLink(evidence)}>
					<Link component='button' onClick={handleLinkClick}>
						{evidence.radiatedBy.name ?? evidence.radiatedBy.id}
					</Link>
				</Tooltip>
			</>
		);
	};

	return (
		<Table key={evidence.id}>
			<TableHead>
				<TableRow>
					<TableCell>Action</TableCell>
					<TableCell>Radiated by</TableCell>
					<TableCell>Date</TableCell>
					<TableCell>Author</TableCell>
					<TableCell>
						Comment{' '}
						<IconButton
							onClick={handleOpenNewComment(evidenceItem)}
							color='inherit'
						>
							<AddIcon />
						</IconButton>
					</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				<TableRow>
					<TableCell>Created</TableCell>
					<TableCell>{radiatedByContent(evidence)}</TableCell>
					<TableCell>{dateFormatter(evidence?.createdTime)}</TableCell>
					<TableCell>
						{users?.find(({ id }) => evidence?.authorCreatorId === id)
							?.username ||
							evidence?.authorCreatorId ||
							'System'}
					</TableCell>
					<TableCell
						style={{
							width: 400,
							whiteSpace: 'normal',
							wordWrap: 'break-word',
						}}
					>
						{evidence.createComment}
					</TableCell>
				</TableRow>
				{evidence?.status === 'active' && evidence.endDate && (
					<TableRow>
						<TableCell>Expires (+- 10min)</TableCell>
						<TableCell />
						<TableCell>{dateFormatter(evidence?.endDate)}</TableCell>
						<TableCell />
						<TableCell />
					</TableRow>
				)}
				<TableRow>
					<TableCell>Deleted</TableCell>
					{removeEvidenceContent(evidence)}
				</TableRow>
				{commentsContent(evidence)}
			</TableBody>
		</Table>
	);
};

export default EvidenceHistory;
