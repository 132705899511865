import React from 'react';

const FilterContext = React.createContext();

const FilterProvider = ({ changeFilterField, updateURL = true, children }) => (
	<FilterContext.Provider value={{ changeFilterField, updateURL }}>
		{children}
	</FilterContext.Provider>
);

export { FilterProvider };

export default FilterContext;
