import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import {
	Button,
	Typography,
	Card,
	CardHeader,
	CardContent,
} from '@mui/material';
import { LOADING } from 'constants/apiStatuses';
import './styles.css';
import { dateFormatter } from 'helpers/formatters';
import KeyValueTable, { RowValue } from 'components/KeyValueTable';
import { getSingleEvidence } from 'actions/evidencesActions';

const EvidenceLog = (props) => {
	const params = useParams();
	const navigate = useNavigate();

	const { singleEvidence, getSingleEvidenceStatus, getSingleEvidence } = props;

	useEffect(() => {
		getSingleEvidence(params.id);
	}, []);

	if (getSingleEvidenceStatus === LOADING && !singleEvidence) {
		return <div>Loading...</div>;
	}

	const handleGoBack = () => navigate(-1);
	const pageTitle = (
		<Typography variant='h3' className='logTitle'>
			Evidence Log
		</Typography>
	);

	const pageActions = (
		<Button color='primary' variant='contained' onClick={handleGoBack}>
			Go back
		</Button>
	);

	return (
		<Card>
			<CardHeader title={pageTitle} action={pageActions} />
			<CardContent>
				<KeyValueTable>
					<RowValue oKey='Evidence Id:' oValue={singleEvidence.id} />
					<RowValue oKey='UserName:' oValue={singleEvidence.userName} />
					<RowValue oKey='Consumer Id:' oValue={singleEvidence.consumerId} />
					<RowValue oKey='Evidence Code:' oValue={singleEvidence.status} />
					<RowValue oKey='Reason:' oValue={singleEvidence.reason} />
					<RowValue
						oKey='Evidence Type:'
						oValue={singleEvidence.consumerType}
					/>
					<RowValue
						oKey='Date:'
						oValue={dateFormatter(singleEvidence.actionTime)}
					/>
					<RowValue oKey='Action:' oValue={singleEvidence.action} />
				</KeyValueTable>
			</CardContent>
		</Card>
	);
};

const mapStateToProps = ({ evidences }) => ({
	singleEvidence: evidences.singleEvidence,
	getSingleEvidenceStatus: evidences.getSingleEvidenceStatus,
});

const mapDispatchToProps = (dispatch) => ({
	getSingleEvidence: (id) => dispatch(getSingleEvidence(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EvidenceLog);
