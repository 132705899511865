import {
	Box,
	Button,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Tooltip,
	FormControl,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import RefreshIcon from '@mui/icons-material/Refresh';
import { ReactComponent as ClearAllIcon } from 'icons/clearFilter.svg';
import React from 'react';
import '../styles.css';
import { CustomInput } from 'components';

const EvidenceLogFilters = ({
	filters,
	handleChangeFilters,
	handleRefresh,
	handleResetFilters,
	handleAddQuery = () => {},
	handleExportToJSONButtonClick = () => {},
	handleExportToCSVButtonClick = () => {},
	exclude = {},
}) => {
	const keyboardDateTimePickerClass = `logFilterInput pickerInput`;
	const dateTimePickerInputFormat = 'dd-MM-yyyy HH:mm';

	const handleDateTimePickerChange = (name) => (value) => {
		const currentDate = Date.parse(value);
		if (!isNaN(currentDate)) {
			handleAddQuery({
				target: {
					name,
					value: new Date(value).toISOString(),
				},
			});
		}
		handleChangeFilters({
			target: {
				name,
				value,
			},
		});
	};

	const dateTimePickerRenderInput = (params) => (
		<TextField {...params} className={keyboardDateTimePickerClass} />
	);

	return (
		<Box className='logFilterBar'>
			<CustomInput
				value={filters?.userName}
				name='userName'
				label='User Name'
				onChange={handleChangeFilters}
				onBlur={handleAddQuery}
			/>
			<CustomInput
				value={filters?.consumerId}
				name='consumerId'
				label='Device ID / Account Name'
				onChange={handleChangeFilters}
				onBlur={handleAddQuery}
			/>
			<CustomInput
				value={filters?.status}
				name='status'
				label='Code'
				onChange={handleChangeFilters}
				onBlur={handleAddQuery}
			/>
			<FormControl className='logFilterInput'>
				<InputLabel id='action'>Action</InputLabel>
				<Select
					labelId='action'
					onChange={handleChangeFilters}
					value={filters?.action}
					label='Action'
					name='action'
				>
					<MenuItem value='created'>Created</MenuItem>
					<MenuItem value='deleted'>Deleted</MenuItem>
				</Select>
			</FormControl>
			<FormControl className='logFilterInputWrapper'>
				<InputLabel id='action'>Evidence Type</InputLabel>
				<Select
					labelId='evidenceType'
					onChange={handleChangeFilters}
					value={filters?.evidenceType}
					label='Evidence Type'
					name='evidenceType'
				>
					<MenuItem value='ip'>ip</MenuItem>
					<MenuItem value='account'>account</MenuItem>
					<MenuItem value='device'>device</MenuItem>
				</Select>
			</FormControl>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<DateTimePicker
					sx={{ mr: 1, my: 2 }}
					format={dateTimePickerInputFormat}
					margin='normal'
					value={filters.fromDate}
					onChange={handleDateTimePickerChange('fromDate')}
					label='From Date'
					textField={dateTimePickerRenderInput}
				/>
				<DateTimePicker
					sx={{ mr: 1, my: 2 }}
					format={dateTimePickerInputFormat}
					margin='normal'
					value={filters.toDate}
					onChange={handleDateTimePickerChange('toDate')}
					label='To Date'
					textField={dateTimePickerRenderInput}
				/>
			</LocalizationProvider>
			<div className='logRightWrapper'>
				<Tooltip title='Refresh page'>
					<IconButton
						className='logFilterInput'
						color='primary'
						onClick={handleRefresh}
					>
						<RefreshIcon fontSize='small' className='logClearAllIcon' />
					</IconButton>
				</Tooltip>
				<Tooltip title='Clear filters'>
					<IconButton
						className='logFilterInput'
						color='primary'
						onClick={handleResetFilters}
					>
						<ClearAllIcon className='logClearAllIcon' />
					</IconButton>
				</Tooltip>
				{!exclude.exportButton && (
					<Box className='logExportButtonContainer'>
						<Button
							variant='contained'
							color='default'
							onClick={handleExportToCSVButtonClick}
							className='exportButton'
						>
							Export to CSV
						</Button>
						<Button
							color='default'
							variant='contained'
							className='exportButton'
							onClick={handleExportToJSONButtonClick}
						>
							Export to JSON
						</Button>
					</Box>
				)}
			</div>
		</Box>
	);
};

export default EvidenceLogFilters;
