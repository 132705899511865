import {
	authorize as authorizeAPI,
	gameforgeAuthorize as gameforgeAuthorizeAPI,
} from 'services/api/auth/authorize';
import { checkToken as checkTokenAPI } from 'services/api/auth/checkToken';
import {
	AUTH_CHECK_TOKEN_STATUS,
	AUTH_AUTHORIZE_STATUS,
	AUTH_AUTHORIZE,
	AUTH_SET_AUTHORIZATION_TYPE,
	AUTH_SET_FE_VERSION,
	APP_SET_OPEN_MENU,
} from '../constants';
import { LOADING, ERROR, NONE } from 'constants/apiStatuses';
import { DEFAULT } from 'constants/authorizations';

const setAuthorizationType = (type) => ({
	type: AUTH_SET_AUTHORIZATION_TYPE,
	payload: type,
});

export const setAuthorizeStatus = (status) => ({
	type: AUTH_AUTHORIZE_STATUS,
	payload: status,
});

export const setIsAuthorized = (user) => ({
	type: AUTH_AUTHORIZE,
	payload: user,
});

export const authorize = (body, onLoad) => (dispatch, getState) => {
	dispatch(setAuthorizeStatus(LOADING));
	authorizeAPI(body)
		.then((res) => {
			if (res.error) {
				dispatch(setAuthorizeStatus(ERROR));
			} else {
				localStorage.setItem('rating_app_token', res.token);
				dispatch(setAuthorizeStatus(NONE));
				dispatch(setIsAuthorized(res.user));
				dispatch(setAuthorizationType(DEFAULT));
				onLoad();
			}
		})
		.catch(({ response }) => {
			dispatch(setAuthorizeStatus(ERROR));
		});
};

export const gameforgeAuthorize =
	(body, onLoad = () => {}) =>
	(dispatch) => {
		console.log(`gameforge action`);
		gameforgeAuthorizeAPI(body);
	};

export const setTokenCheckStatus = (status) => ({
	type: AUTH_CHECK_TOKEN_STATUS,
	payload: status,
});

export const checkToken = (token) => (dispatch, getState) => {
	if (token) {
		dispatch(setTokenCheckStatus(LOADING));
		checkTokenAPI(token)
			.then((userInfo) => {
				dispatch(setIsAuthorized(userInfo));
				dispatch(setTokenCheckStatus(NONE));
				localStorage.setItem('rating_app_token', token);
			})
			.catch(() => {
				dispatch(setTokenCheckStatus(ERROR));
				localStorage.removeItem('rating_app_token');
			});
	} else {
		dispatch(setTokenCheckStatus(NONE));
	}
};

export const logOut = (dispatch, getState) => {
	localStorage.removeItem('rating_app_token');
	dispatch(setIsAuthorized(null));
};

export const setFEVersion = (version) => ({
	type: AUTH_SET_FE_VERSION,
	payload: version,
});

export const setMenuState = (state) => ({
	type: APP_SET_OPEN_MENU,
	payload: state,
});
