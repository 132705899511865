import React, { useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { connect } from 'react-redux';
import {
	Button,
	Box,
	TextField,
	FormControlLabel,
	Checkbox,
	FormHelperText,
	InputLabel,
	Select,
	MenuItem,
	FormControl,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import './styles.css';
import { getRuleSets } from 'actions/rulesActions';
import RuleFieldWrapperManager from './components/RuleSetWrapperManager';
import RuleSetManager from './components/RuleSetManager/index.jsx';

import validateRuleForm from 'helpers/validations/rule';
import generateId from 'helpers/generateId';
import isSubmitButtonDisabled from 'helpers/isSubmitButtonDisabled';
import checkIfRuleTimeIsDefault from 'helpers/checkIfRuleTimeIsDefault';
import checkIfPlaceEvidenceIsDefault from 'helpers/checkDefaultPlaceEvidence';
import WhenFieldChanges from './components/ConditionalField';
import sortObjectArray from 'helpers/sortObjectArray';
import CheckboxGroup from './components/CheckboxGroup';
import { sortReasons } from 'helpers/reasonsMethods';
import { sortRuleSets } from 'helpers/sortRuleSets';

const RuleForm = (props) => {
	const {
		handleSubmit,
		buttonLabel,
		ruleKeys,
		status,
		ruleSets,
		getRuleSets,
		evidenceTypes,
	} = props;

	const sortedEvidenceTypes = sortReasons(
		evidenceTypes.map((e) => ({ ...e, name: e.name.toLowerCase() })),
		'name'
	);
	const sortedRuleKeys = sortObjectArray(ruleKeys, 'label');
	const sortedRuleSets = sortRuleSets(ruleSets);

	const initialValues = {
		rules: [
			[
				{
					id: generateId(),
					operator: '=',
					value: [{ value: 'Europe/Berlin', id: generateId() }],
					field: 'device.tz',
					useField: false,
				},
			],
		],
		validFrom: new Date(2000, 0, 1),
		validTo: new Date(2099, 11, 31),
		placeEvidence: checkIfPlaceEvidenceIsDefault(props.initialValues?.evidence),
		evidence: '',
		ruleSet: [''],
		exRuleSet: [''],
		evidenceToEntity: [],
		evidenceExpiresIn: null,
		defaultTimes: props.initialValues
			? checkIfRuleTimeIsDefault(
					props.initialValues.validFrom,
					props.initialValues.validTo
			  )
			: true,
		...(props.initialValues
			? {
					...props.initialValues,
					rules: props.initialValues.rules.map((ruleBlock) => {
						return ruleBlock.map((rule) => ({
							...rule,
							value: Array.isArray(rule.value)
								? rule.value.map((value) => ({ value, id: generateId() }))
								: [{ value: rule.value, id: generateId() }],
							id: generateId(),
						}));
					}),
			  }
			: {}),
	};

	useEffect(() => {
		getRuleSets();
	}, []);

	const keyboardDateTimePickerClass = `ruleFormInput pickerInput`;
	const evidencesToPlace = ['account', 'device', 'ip'];

	return (
		<Form
			keepDirtyOnReinitialize={true}
			mutators={{ ...arrayMutators }}
			initialValues={initialValues}
			validate={validateRuleForm}
			onSubmit={handleSubmit}
			render={({ handleSubmit, values, touched, errors }) => {
				return (
					<form className='newRule' onSubmit={handleSubmit}>
						<Box className='widthFull'>
							<Field name='name'>
								{(props) => (
									<TextField
										error={Boolean(props.meta.touched && props.meta.error)}
										helperText={props.meta.touched && props.meta.error}
										autoComplete='off'
										value={props.input.value}
										name={props.input.name}
										onChange={props.input.onChange}
										className='ruleFormInput'
										label='Name'
										style={{ width: '50%' }}
										InputLabelProps={{ shrink: true }}
									/>
								)}
							</Field>
						</Box>
						<Box style={{ display: 'flex' }}>
							<Box className='widthHalf'>
								<FieldArray name='ruleSet'>
									{({ fields, meta }) => {
										return (
											<RuleSetManager
												ruleSets={sortedRuleSets}
												touched={meta.touched}
												fields={fields}
												TextFieldLabel='Rule set'
												errors={errors.ruleSet || []}
											/>
										);
									}}
								</FieldArray>
							</Box>
							<Box className='widthHalf'>
								<FieldArray name='exRuleSet'>
									{({ fields, meta }) => {
										return (
											<RuleSetManager
												ruleSets={sortedRuleSets}
												touched={meta.touched}
												fields={fields}
												TextFieldLabel='Rule set to exclude'
												errors={errors.exRuleSet || []}
											/>
										);
									}}
								</FieldArray>
							</Box>
						</Box>
						<Box className='widthHalf'>
							<FieldArray name='rules'>
								{({ fields, meta }) => (
									<RuleFieldWrapperManager
										fields={fields}
										meta={meta}
										ruleKeys={sortedRuleKeys}
										touched={meta.touched}
										errors={errors.rules || []}
										evidenceTypes={sortedEvidenceTypes}
									/>
								)}
							</FieldArray>
						</Box>
						<Field name='score'>
							{(props) => (
								<TextField
									error={Boolean(props.meta.touched && props.meta.error)}
									helperText={props.meta.touched && props.meta.error}
									autoComplete='off'
									value={props.input.value}
									name={props.input.name}
									onChange={props.input.onChange}
									className='ruleFormInput'
									label='Score'
									style={{ width: '50%' }}
									InputLabelProps={{ shrink: true }}
								/>
							)}
						</Field>
						<Field name='defaultTimes' type='checkbox'>
							{(props) => (
								<FormControlLabel
									control={
										<Checkbox
											checked={props.input.checked}
											onChange={props.input.onChange}
											color='primary'
										/>
									}
									className='widthHalf'
									label='Default time'
								/>
							)}
						</Field>
						<Box className={`widthHalf flex`}>
							{!values.defaultTimes && (
								<Field name='validFrom'>
									{(props) => {
										return (
											<LocalizationProvider dateAdapter={AdapterDateFns}>
												<Box className='pickerInput'>
													<DateTimePicker
														error={Boolean(
															props.meta.error && props.meta.touched
														)}
														format='dd-MM-yyyy HH:mm'
														margin='normal'
														value={props.input.value}
														onChange={props.input.onChange}
														className={keyboardDateTimePickerClass}
														label='Valid from'
														textField={(params) => <TextField {...params} />}
													/>
													{props.meta.error && props.meta.touched && (
														<FormHelperText
															error={true}
															className='ruleHelperText'
														>
															{props.meta.error}
														</FormHelperText>
													)}
												</Box>
											</LocalizationProvider>
										);
									}}
								</Field>
							)}
							{!values.defaultTimes && (
								<Field name='validTo'>
									{(props) => {
										return (
											<LocalizationProvider dateAdapter={AdapterDateFns}>
												<Box className='pickerInput'>
													<DateTimePicker
														error={Boolean(
															props.meta.error && props.meta.touched
														)}
														format='dd-MM-yyyy HH:mm'
														maxDate={new Date('2500-01-01')}
														margin='normal'
														value={props.input.value}
														onChange={props.input.onChange}
														className={keyboardDateTimePickerClass}
														label='Valid to'
														textField={(params) => <TextField {...params} />}
													/>
													{props.meta.error && props.meta.touched && (
														<FormHelperText
															error={true}
															className='ruleHelperText'
														>
															{props.meta.error}
														</FormHelperText>
													)}
												</Box>
											</LocalizationProvider>
										);
									}}
								</Field>
							)}
						</Box>
						<Box className='widthHalf'>
							<WhenFieldChanges
								field='placeEvidence'
								becomes={false}
								set='evidence'
								to=''
							/>

							<WhenFieldChanges
								field='placeEvidence'
								becomes={false}
								set='evidenceToEntity'
								to={[]}
							/>

							<Field name='placeEvidence' type='checkbox'>
								{(props) => (
									<FormControlLabel
										control={
											<Checkbox
												checked={props.input.checked}
												onChange={props.input.onChange}
												color='primary'
											/>
										}
										label='Place evidence'
									/>
								)}
							</Field>
							<Box display='flex' flexDirection='column'>
								{values.placeEvidence && (
									<>
										<Field name='evidence'>
											{(props) => (
												<FormControl fullWidth className='ruleFormInput'>
													<InputLabel
														id='evidence'
														error={Boolean(
															props.meta.touched && props.meta.error
														)}
													>
														Evidence
													</InputLabel>
													<Select
														error={Boolean(
															props.meta.touched && props.meta.error
														)}
														value={props.input.value}
														labelId='evidence'
														onChange={props.input.onChange}
														label='Evidence'
													>
														{sortedEvidenceTypes.map((evidence) => (
															<MenuItem value={evidence.id} key={evidence.id}>
																{evidence.name}
															</MenuItem>
														))}
													</Select>
													{props.meta.touched && props.meta.error && (
														<FormHelperText
															error={true}
															className={`ruleHelperText placeEvidence`}
														>
															{props.meta.error}
														</FormHelperText>
													)}
												</FormControl>
											)}
										</Field>
										<FieldArray name='evidenceToEntity'>
											{({ fields, meta }) => {
												const error = meta.touched && errors.evidenceToEntity;
												return (
													<>
														<CheckboxGroup
															options={evidencesToPlace}
															fields={fields}
															error={error}
														/>
														{error && (
															<FormHelperText
																error={true}
																className={`ruleHelperText placeEvidence`}
															>
																{error}
															</FormHelperText>
														)}
													</>
												);
											}}
										</FieldArray>
										<Field name='evidenceExpiresIn'>
											{(props) => (
												<TextField
													error={Boolean(
														props.meta.touched && props.meta.error
													)}
													helperText={props.meta.touched && props.meta.error}
													autoComplete='off'
													value={props.input.value}
													name={props.input.name}
													onChange={props.input.onChange}
													className={`ruleFormInput expirationInDays`}
													label='Expiration in days'
													InputLabelProps={{ shrink: true }}
												/>
											)}
										</Field>
									</>
								)}
							</Box>
							<Button
								disabled={isSubmitButtonDisabled(errors, touched, status)}
								type='submit'
								className='ruleFormInput'
								variant='contained'
								color='primary'
							>
								{buttonLabel}
							</Button>
						</Box>
					</form>
				);
			}}
		/>
	);
};

const mapStateToProps = ({ rules, reasons }) => ({
	ruleKeys: rules.ruleKeys || [],
	ruleSets: rules.ruleSets || [],
	evidenceTypes: reasons.reasons,
});

const mapDispatchToProps = (dispatch) => ({
	getRuleSets: () => dispatch(getRuleSets),
});

export default connect(mapStateToProps, mapDispatchToProps)(RuleForm);
