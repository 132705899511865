import { CustomTableCell } from 'components';
import React from 'react';

const devicesColumns = [
	{
		field: 'app',
		editable: false,
		headerName: 'Browser',
		headerAlign: 'center',
		flex: 1,
		renderCell: (params) => <CustomTableCell filterField='app' {...params} />,
	},
	{
		field: 'osType',
		editable: false,
		headerName: 'OS',
		headerAlign: 'center',
		flex: 1,
		renderCell: (params) => (
			<CustomTableCell filterField='osType' {...params} />
		),
	},
	{
		field: 'osVersion',
		editable: false,
		headerName: 'OS Version',
		headerAlign: 'center',
		flex: 1,
		renderCell: (params) => (
			<CustomTableCell filterField='osVersion' {...params} />
		),
	},
	{
		field: 'product',
		editable: false,
		headerName: 'Product',
		headerAlign: 'center',
		flex: 1,
		renderCell: (params) => <CustomTableCell {...params} />,
	},
	{
		field: 'gpu',
		editable: false,
		headerName: 'GPU',
		headerAlign: 'center',
		flex: 3,
		renderCell: (params) => <CustomTableCell filterField='gpu' {...params} />,
	},
	{
		field: 'id',
		editable: false,
		headerName: 'Device ID',
		headerAlign: 'center',
		flex: 1,
		renderCell: (params) => (
			<CustomTableCell
				filterField='id'
				getNewTabLink={(id) => `${window.location.origin}/devices/${id}`}
				{...params}
			/>
		),
	},
	{
		field: 'result',
		headerName: 'Result',
		width: 80,
		hideSortIcons: true,
		sortable: false,
		headerAlign: 'center',
		valueGetter: () => ' ',
		renderCell: () => <span className={'scoreIndicator'} />,
	},
];

export default devicesColumns;
