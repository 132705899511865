import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAccountsCountriesInfo } from 'actions/serverStatsActions';
import accountsWithMostCountries from 'constants/columns/accountsWithMostCountries';
import generateDatasetConfigForPieChart from 'helpers/generateDatasetConfigForPieChart';
import ReportsPage from 'components/ReportPage';

const AccountsInfo = (props) => {
	const {
		accountsCountriesInfo,
		getAccountsCountriesInfoStatus,
		getAccountsCountriesInfo,
	} = props;
	console.log(accountsCountriesInfo);
	const navigate = useNavigate();

	const handleClick = ({ id }) => {
		navigate(`/accounts/${id}`);
	};

	return (
		<ReportsPage
			getInfo={getAccountsCountriesInfo}
			data={accountsCountriesInfo}
			status={getAccountsCountriesInfoStatus}
			title='Accounts with most countries over last 96 hours'
			DataGridProps={{
				onRowClick: handleClick,
			}}
			chartConfig={generateDatasetConfigForPieChart({
				labels: accountsCountriesInfo?.map(({ id }) => id),
				data: accountsCountriesInfo?.map(
					({ countriesCount }) => countriesCount
				),
			})}
			chartOptions={{
				plugins: {
					legend: {
						display: false,
					},
				},
			}}
			onChartPartClick={handleClick}
			columns={accountsWithMostCountries}
		/>
	);
};

const mapStateToProps = ({ serverStats }) => ({
	accountsCountriesInfo: serverStats.accountsCountriesInfo,
	getAccountsCountriesInfoStatus: serverStats.getAccountsCountriesInfoStatus,
});

const mapDispatchToProps = (dispatch) => ({
	getAccountsCountriesInfo: () => dispatch(getAccountsCountriesInfo),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountsInfo);
