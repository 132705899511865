import axiosInstance from '../index';

const getDevices = ({
	cursor,
	page,
	pageSize,
	filters,
	accountId = null,
	sorting,
}) => {
	return axiosInstance
		.post(
			`/devices?sortId=${cursor.search_after[0]}&remain=${cursor.search_after[1]}&limit=${pageSize}&accountId=${accountId}`,
			{ filters, sorting, pit: cursor.pit }
		)
		.then((res) => {
			return {
				...res.data,
				page,
			};
		});
};

export { getDevices };
