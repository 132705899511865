import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
	Select,
	Typography,
	FormControl,
	InputLabel,
	MenuItem,
	Card,
	CardHeader,
	CardContent,
} from '@mui/material';
import { LOADING } from 'constants/apiStatuses';
import './styles.css';
import { getSystemKPIData } from 'actions/serverStatsActions';
import KeyValueTable, { RowValue } from 'components/KeyValueTable';

const SystemKPI = (props) => {
	const [time, setTime] = useState(1);
	const { systemKPIStatus, systemKPIData, getSystemKPI } = props;

	const handleChangeTime = (e) => {
		setTime(e.target.value);
		getSystemKPI(e.target.value);
	};

	useEffect(() => {
		getSystemKPI(1);
	}, []);

	const labels = {
		1: '1 hour',
		24: '24 hours',
	};

	const pageTitle = (
		<Typography variant='h5'>System KPI for the last {labels[time]}</Typography>
	);
	const pageActions = (
		<FormControl className='selectContainer'>
			<InputLabel id='time'>Time</InputLabel>
			<Select
				labelId='time'
				label='Info item'
				value={time}
				onChange={handleChangeTime}
			>
				{Object.entries(labels).map(([value, key]) => {
					return (
						<MenuItem key={key} value={value}>
							{key}
						</MenuItem>
					);
				})}
			</Select>
		</FormControl>
	);

	const renderContent = systemKPIData && systemKPIStatus !== LOADING;

	return (
		<Card>
			<CardHeader title={pageTitle} action={pageActions} />
			<CardContent>
				{systemKPIStatus === LOADING && <div>Loading...</div>}
			</CardContent>
			<CardContent>
				{renderContent && (
					<>
						<Typography variant='h5'>General info</Typography>
						<KeyValueTable classes='systemCustomTable'>
							<RowValue
								oKey='Avg of transactions by device:'
								oValue={systemKPIData.transactionsByDevice}
							/>
							<RowValue
								oKey='Avg of transactions by account:'
								oValue={systemKPIData.transactionsByAccount}
							/>
							<RowValue
								oKey='Avg of random number:'
								oValue={systemKPIData.randomAvg}
							/>
							<RowValue
								oKey='Transactions with defect vectors:'
								oValue={systemKPIData.vectorSum}
							/>
						</KeyValueTable>
					</>
				)}
			</CardContent>
			<CardContent>
				{renderContent && (
					<>
						<Typography variant='h5' className='counter'>
							Velocity info (Entities for the last {labels[time]} at the time of
							transaction creation)
						</Typography>
						<KeyValueTable classes='systemCustomTable'>
							<RowValue
								oKey='Avg of transactions by account:'
								oValue={systemKPIData.transactionsByAccountReport}
							/>
							<RowValue
								oKey='Avg of transactions by device:'
								oValue={systemKPIData.transactionsByDeviceReport}
							/>
							<RowValue
								oKey='Avg of transactions by ip:'
								oValue={systemKPIData.transactionsByIpReport}
							/>
							<RowValue
								oKey='Avg of transactions by account by RS:'
								oValue={systemKPIData.transactionsByAccountByRSReport}
							/>
							<RowValue
								oKey='Avg of transactions by device by RS:'
								oValue={systemKPIData.transactionsByDeviceByRSReport}
							/>
							<RowValue
								oKey='Avg of transactions by ip by RS:'
								oValue={systemKPIData.transactionsByIpByRSReport}
							/>
							<RowValue
								oKey='Avg of accounts by device:'
								oValue={systemKPIData.accountsByDevice}
							/>
							<RowValue
								oKey='Avg of devices by account:'
								oValue={systemKPIData.devicesByAccount}
							/>
							<RowValue
								oKey='Avg of countries by account:'
								oValue={systemKPIData.countriesByAccount}
							/>
							<RowValue
								oKey='Avg of countries by device:'
								oValue={systemKPIData.countriesByDevice}
							/>
						</KeyValueTable>
					</>
				)}
			</CardContent>
		</Card>
	);
};

const mapStateToProps = ({ serverStats }) => ({
	systemKPIStatus: serverStats.getSystemKPIDataStatus,
	systemKPIData: serverStats.systemKPIData,
});

const mapDispatchToProps = (dispatch) => ({
	getSystemKPI: (seconds) => dispatch(getSystemKPIData(seconds)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SystemKPI);
