import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	TransactionsPage,
	DevicesPage,
	AccountsPage,
	IpsPage,
	EvidenceFilter,
	ActionsPage,
	ReasonPage,
	EvidenceLogPage,
} from 'containers';

const tabConverter = {
	devices: () => <DevicesPage />,
	transactions: () => <TransactionsPage />,
	accounts: () => <AccountsPage />,
	ipBlocks: () => <IpsPage />,
	evidences: () => <EvidenceFilter />,
	actions: () => <ActionsPage />,
	evidenceLog: () => <EvidenceLogPage />,
	reasons: () => <ReasonPage />,
};

const Main = (props) => {
	const location = useLocation();
	const navigate = useNavigate();
	const query = new URLSearchParams(location.search);

	useEffect(() => {
		if (location.search === '') {
			navigate('/?tab=transactions');
		}
	}, []);

	let tabValue = query.get('tab');
	if (!Object.keys(tabConverter).includes(tabValue)) {
		tabValue = 'transactions';
	}

	return tabConverter[tabValue]();
};

export default Main;
