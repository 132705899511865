import React, { Fragment } from 'react';
import { Box, FormHelperText, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import generateId from 'helpers/generateId';
import RuleFieldManager from '../RuleFieldManager';
import './styles.css';

const RuleFieldWrapperManager = (props) => {
	const { fields, ruleKeys, errors, touched, meta, evidenceTypes } = props;

	const handleAddRuleBlock = () => {
		fields.push([
			{
				id: generateId(),
				operator: '=',
				value: [{ value: 'Europe/Berlin', id: generateId() }],
				field: 'device.tz',
				useField: false,
			},
		]);
	};

	const handleDeleteRuleBlock = (index) => {
		fields.remove(index);
	};

	const handleShowIcon = (condition, type, onClick) => {
		if (condition) {
			return (
				<IconButton disableTouchRipple onClick={onClick}>
					{type === 'add' ? <AddIcon /> : <CloseIcon />}
				</IconButton>
			);
		}
		return null;
	};

	return fields.value.map((value, index) => {
		const handleAddRuleValue = () => {
			fields.update(index, [
				...value,
				{
					id: generateId(),
					operator: '=',
					value: [{ value: 'Europe/Berlin', id: generateId() }],
					field: 'device.tz',
					useField: false,
				},
			]);
		};

		const handleDeleteRuleValue = (ruleIndex) => {
			const newRuleArray = [
				...value.slice(0, ruleIndex),
				...value.slice(ruleIndex + 1),
			];
			fields.update(index, newRuleArray);
		};

		return (
			<Fragment key={index}>
				<Box
					className={`ruleBlockContainer ${
						errors[index] && touched ? 'ruleBlockError' : ''
					}`}
				>
					<RuleFieldManager
						blockIndex={index}
						total={fields}
						fields={value}
						ruleKeys={ruleKeys}
						touched={meta.touched}
						errors={errors[index] || []}
						handleAddRuleValue={handleAddRuleValue}
						handleDeleteRuleValue={handleDeleteRuleValue}
						evidenceTypes={evidenceTypes}
					/>
					{handleShowIcon(
						index === fields.length - 1,
						'add',
						handleAddRuleBlock
					)}
					{handleShowIcon(fields.length !== 1, 'delete', () =>
						handleDeleteRuleBlock(index)
					)}
					{fields.length - 1 !== index && (
						<span className='addButton'>AND</span>
					)}
				</Box>
				{errors[index] && touched && (
					<FormHelperText error={true}>{errors[index]}</FormHelperText>
				)}
			</Fragment>
		);
	});
};

export default RuleFieldWrapperManager;
