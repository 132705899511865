import React, { useContext } from 'react';
import PureTableCell from '../PureTableCell';
import { getReasonNameById } from 'helpers/reasonsMethods';
import { EvidenceHistoryContext } from 'components/EvidenceManager';
import '../styles.css';

const EvidenceReasonCell = ({ value, ...props }) => {
	const { evidenceTypes } = useContext(EvidenceHistoryContext);
	const evidenceName = getReasonNameById(evidenceTypes, value);

	return (
		<PureTableCell
			align='center'
			variant='body2'
			className='tableCell'
			{...props}
			value={evidenceName}
		/>
	);
};

export default EvidenceReasonCell;
