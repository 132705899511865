import { CustomTableCell } from 'components';
import React from 'react';

const ipsColumns = [
	{
		field: 'ip',
		editable: false,
		sortable: false,
		headerName: 'IpBlock',
		headerAlign: 'center',
		flex: 1,
		renderCell: (params) => <CustomTableCell {...params} />,
	},
	{
		field: 'countryISOCode',
		editable: false,
		headerName: 'Country ISO Code',
		headerAlign: 'center',
		flex: 1,
		renderCell: (params) => <CustomTableCell {...params} />,
	},
	{
		field: 'countryName',
		editable: false,
		headerName: 'Country Name',
		headerAlign: 'center',
		flex: 1,
		renderCell: (params) => <CustomTableCell {...params} />,
	},
	{
		field: 'timeZone',
		editable: false,
		headerName: 'Time zone',
		headerAlign: 'center',
		flex: 1,
		renderCell: (params) => <CustomTableCell {...params} />,
	},
	{
		field: 'isp',
		editable: false,
		headerName: 'Isp',
		headerAlign: 'center',
		flex: 1,
		renderCell: (params) => <CustomTableCell filterField='isp' {...params} />,
	},
	{
		field: 'accuracyRadius',
		editable: false,
		headerName: 'Accuracy radius',
		headerAlign: 'center',
		flex: 1,
		renderCell: (params) => <CustomTableCell {...params} />,
	},
	{
		field: 'id',
		editable: false,
		headerName: 'IpBlock ID',
		headerAlign: 'center',
		flex: 1,
		renderCell: (params) => <CustomTableCell {...params} />,
	},
	{
		field: 'result',
		headerName: 'Result',
		width: 80,
		hideSortIcons: true,
		sortable: false,
		headerAlign: 'center',
		valueGetter: () => ' ',
		renderCell: () => <span className={'scoreIndicator'} />,
	},
];

export default ipsColumns;
