const blobDownloader = ({ data, options, fileName = 'transactions' }) => {
	const url = window.URL.createObjectURL(new Blob([data], options));
	const link = document.createElement('a');
	link.href = url;
	link.setAttribute('download', fileName);
	document.body.appendChild(link);
	link.click();
	window.URL.revokeObjectURL(url);
	document.body.removeChild(link);
};

export default blobDownloader;
