import React, { useEffect, useRef, useState } from 'react';

const Map = ({ center, zoom, style, children }) => {
	const ref = useRef(null);
	const [map, setMap] = useState();

	useEffect(() => {
		if (ref.current && !map) {
			setMap(() => new window.google.maps.Map(ref.current, { center, zoom }));
		}
	}, [map]);

	return (
		<>
			<div ref={ref} id='map' style={style} />
			{React.Children.map(children, (child) =>
				React.isValidElement(child) ? React.cloneElement(child, { map }) : null
			)}
		</>
	);
};

export default Map;
