import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { Box, Typography, Button } from '@mui/material';

import { getSingleUser } from 'actions/usersActions';
import LastAction from './LastAction';
import './styles.css';

const User = (props) => {
	const { user, getSingleUser } = props;
	const params = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		getSingleUser(params.id);
	}, []);

	const handleButtonClick = () => navigate(`/edit-user/${user.id}`);

	return (
		<Box>
			<Typography variant='h5'>User</Typography>
			{user && (
				<>
					<Box className='userInfoItem'>
						User email: <b>{user.email}</b>
					</Box>
					<Box className='userInfoItem'>
						Username: <b>{user.username}</b>
					</Box>
					<Box className='userInfoItem'>
						User role: <b>{user.role}</b>
					</Box>
					<Box className='userInfoItem'>
						User id: <b>{user.id}</b>
					</Box>
					<Box className='userInfoItem'>
						Last action:{' '}
						<LastAction
							lastAction={user.lastAction}
							userName={user.username}
							singleUser={true}
							placement='top'
						/>
					</Box>
				</>
			)}
			<Button
				color='primary'
				variant='contained'
				className='editUser'
				onClick={handleButtonClick}
			>
				Edit user
			</Button>
		</Box>
	);
};

const mapStateToProps = ({ users }) => ({
	getUserStatus: users.getSingleUserStatus,
	user: users.singleUser,
});

const mapDispatchToProps = (dispatch) => ({
	getSingleUser: (id) => dispatch(getSingleUser(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(User);
