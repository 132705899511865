import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import {
	Button,
	Typography,
	TableRow,
	TableCell,
	Card,
	CardHeader,
	CardContent,
} from '@mui/material';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import { getSingleAction } from 'actions/actionsActions';
import { LOADING } from 'constants/apiStatuses';
import './styles.css';
import { dateFormatter } from 'helpers/formatters';
import KeyValueTable, { RowValue } from 'components/KeyValueTable';

const Action = (props) => {
	const params = useParams();
	const navigate = useNavigate();

	const { singleAction, getSingleActionStatus, getSingleAction } = props;

	useEffect(() => {
		getSingleAction(params.id);
	}, []);

	if (getSingleActionStatus === LOADING && !singleAction) {
		return <div>Loading...</div>;
	}

	const JSONInputStyle = {
		container: {
			border: '1px solid lightgrey',
			width: '35%',
			marginTop: 10,
		},
	};

	const handleGoBack = () => navigate(-1);
	const pageTitle = (
		<Typography variant='h3' className='actionTitle'>
			Action
		</Typography>
	);

	const pageActions = (
		<Button color='primary' variant='contained' onClick={handleGoBack}>
			Go back
		</Button>
	);

	return (
		<Card>
			<CardHeader title={pageTitle} action={pageActions} />
			<CardContent>
				<KeyValueTable>
					<RowValue oKey='User name:' oValue={singleAction.user.username} />
					<RowValue
						oKey='Date:'
						oValue={dateFormatter(singleAction.creationDate)}
					/>
					<RowValue oKey='Action:' oValue={singleAction.action} />
					<TableRow>
						<TableCell>Content:</TableCell>
					</TableRow>
				</KeyValueTable>
				<CardContent>
					<JSONInput
						confirmGood={false}
						viewOnly
						placeholder={singleAction.content}
						width='100%'
						style={JSONInputStyle}
						theme='light_mitsuketa_tribute'
						locale={locale}
					/>
				</CardContent>
			</CardContent>
		</Card>
	);
};

const mapStateToProps = ({ actions }) => ({
	singleAction: actions.singleAction,
	getSingleActionStatus: actions.getSingleActionStatus,
});

const mapDispatchToProps = (dispatch) => ({
	getSingleAction: (id) => dispatch(getSingleAction(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Action);
