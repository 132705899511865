import {
	USERS_SET_USERS,
	USERS_DELETE_USER,
	USERS_GET_USERS_STATUS,
	USERS_EDIT_USER_STATUS,
	USERS_CREATE_USER_STATUS,
	USERS_GET_SINGLE_USER_STATUS,
	USERS_DELETE_USER_STATUS,
	USERS_SET_SINGLE_USER,
} from '../constants';
import { LOADING, NONE } from 'constants/apiStatuses';

const initialState = {
	getSingleUserStatus: LOADING,
	singleUser: null,
	editUserStatus: NONE,
	getUsersStatus: LOADING,
	data: null,
	createUserStatus: NONE,
	deleteUserStatus: NONE,
};

const usersReducer = (state = initialState, action) => {
	switch (action.type) {
		case USERS_SET_USERS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case USERS_GET_USERS_STATUS: {
			return {
				...state,
				getUsersStatus: action.payload,
			};
		}

		case USERS_CREATE_USER_STATUS: {
			return {
				...state,
				createUserStatus: action.payload,
			};
		}

		case USERS_DELETE_USER_STATUS: {
			return {
				...state,
				deleteUserStatus: action.payload,
			};
		}

		case USERS_DELETE_USER: {
			const userIdx = state.data.findIndex(
				(user) => user.id === action.payload.id
			);
			return {
				...state,
				data: [
					...state.data.slice(0, userIdx),
					...state.data.slice(userIdx + 1),
				],
			};
		}
		case USERS_SET_SINGLE_USER: {
			return {
				...state,
				singleUser: action.payload,
			};
		}

		case USERS_GET_SINGLE_USER_STATUS: {
			return {
				...state,
				getSingleUserStatus: action.payload,
			};
		}

		case USERS_EDIT_USER_STATUS: {
			return {
				...state,
				editSingleUserStatus: action.payload,
			};
		}

		default: {
			return state;
		}
	}
};

export default usersReducer;
