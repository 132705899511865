import Joi from 'joi';
import notification from 'helpers/notification';
import { MAX_STRING_LENGTH } from '../../constants/validations';

export const VALIDATION_TYPES = {
	TRANSACTIONS: 'TRANSACTIONS',
	DEVICES: 'DEVICES',
	ACCOUNTS: 'ACCOUNTS',
	ACTIONS: 'ACTIONS',
	IPS: 'IPS',
	DEFAULT: 'DEFAULT',
};

const JoiIp = (value) =>
	Joi.string()
		.ip({ version: ['ipv4', 'ipv6'] })
		.empty('')
		.messages({
			'string.ip': `'${value}' is not an IP`,
			'string.ipVersion': `'${value}' is not an IP`,
		});

const JoiString = ({ field, max = 150 }) =>
	Joi.string()
		.empty('')
		.max(max)
		.messages({
			'string.max': `'${field}' length must be less than or equal to ${max} characters long`,
		});

const JoiNumber = (field) =>
	Joi.number()
		.greater(-10_000_000)
		.less(10_000_000)
		.empty('')
		.messages({
			'number.base': `${field} must be a number`,
			'number.greater': `${field} must be greater than -10 000 000`,
			'number.less': `${field} must be less than 10 000 000`,
		});

const transactionsFilters = (values) => {
	return Joi.object({
		accountName: JoiString({ field: 'Account name', max: MAX_STRING_LENGTH }),
		deviceId: JoiString({ field: 'Device ID', max: MAX_STRING_LENGTH }),
		deviceTimeZone: JoiString({ field: 'Time zone', max: MAX_STRING_LENGTH }),
		deviceUUID: JoiString({ field: 'Device UUID', max: MAX_STRING_LENGTH }),
		id: JoiString({ field: 'Transaction ID', max: MAX_STRING_LENGTH }),
		ruleSet: JoiString({ field: 'Rule set', max: MAX_STRING_LENGTH }),
		sessionCountryName: JoiString({
			field: 'Country name',
			max: MAX_STRING_LENGTH,
		}),
		sessionIp: JoiIp(values.sessionIp),
		sessionIsp: JoiString({ field: 'Isp', max: MAX_STRING_LENGTH }),
		maxScore: JoiNumber('Max score'),
		score: JoiNumber('Min score'),
		minCreationDate: Joi.date(),
		maxCreationDate: Joi.date().min(Joi.ref('minCreationDate')).messages({
			'date.min': `'Max creation date' must be greater than 'Min creation date'`,
		}),
	});
};

const devicesFilters = (_) => {
	return Joi.object({
		id: JoiString({ field: 'Device ID', max: MAX_STRING_LENGTH }),
		uuid: JoiString({ field: 'Device UUID', max: MAX_STRING_LENGTH }),
		gpu: JoiString({ field: 'gpu', max: MAX_STRING_LENGTH }),
		app: JoiString({ field: 'app', max: MAX_STRING_LENGTH }),
		os: JoiString({ field: 'os', max: MAX_STRING_LENGTH }),
		osVersion: JoiString({ field: 'osVersion', max: MAX_STRING_LENGTH }),
	});
};

const accountsFilters = (_) => {
	return Joi.object({
		id: JoiString({ field: 'Account ID', max: MAX_STRING_LENGTH }),
		name: JoiString({ field: 'Account Name', max: MAX_STRING_LENGTH }),
	});
};

const actionsFilters = (_) => {
	return Joi.object({
		actionType: JoiString({ field: 'Action Type', max: MAX_STRING_LENGTH }),
		userName: JoiString({ field: 'User Name', max: MAX_STRING_LENGTH }),
	});
};

const ipsFilters = (values) => {
	return Joi.object({
		ip: JoiIp(values.ip),
		isp: JoiString({ field: 'ISP', max: MAX_STRING_LENGTH }),
	});
};

const defaultFilters = () => Joi.object({});

const allFilters = {
	[VALIDATION_TYPES.ACCOUNTS]: accountsFilters,
	[VALIDATION_TYPES.DEVICES]: devicesFilters,
	[VALIDATION_TYPES.ACTIONS]: actionsFilters,
	[VALIDATION_TYPES.TRANSACTIONS]: transactionsFilters,
	[VALIDATION_TYPES.IPS]: ipsFilters,
	[VALIDATION_TYPES.DEFAULT]: defaultFilters,
};

const validateFilters =
	(type) =>
	(values = {}) => {
		const filterJoiObject = allFilters[type] || defaultFilters;
		const filterSchema = filterJoiObject(values);

		const validatedUserSchema =
			filterSchema?.validate(values, {
				abortEarly: false,
				allowUnknown: true,
			}) || {};

		if (validatedUserSchema.error) {
			return validatedUserSchema.error.details.reduce(
				(s, e) => ({
					...s,
					[e.path[0]]: e.message,
				}),
				{}
			);
		}

		return {};
	};

export const processValidation = (errors, _) => {
	Object.keys(errors).forEach((key) => {
		notification.invoke(errors[key], { variant: 'error' });
	});
};

export default validateFilters;
