import React, { useContext } from 'react';
import { Tooltip, IconButton, Typography, Link } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ReactComponent as FilterIcon } from 'icons/filter.svg';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import notification from 'helpers/notification';
import addQueryToSearchParams from 'helpers/addQueryToSearchParams';
import { FilterContext } from 'components';
import './styles.css';
import { numberFormatter } from 'helpers/formatters';

const CustomTableCell = (props) => {
	const location = useLocation();
	const context = useContext(FilterContext);
	const navigate = useNavigate();

	const {
		getNewTabLink,
		value,
		filterField,
		processFilterValue = (value) => value,
		textAlign = 'center',
		tooltipPlacement = 'bottom',
	} = props;

	const handleSuccessfulCopy = () => {
		notification.invoke('Value was successfully copied!', {
			variant: 'success',
		});
	};

	const handleChangeFilterField = (e) => {
		context?.changeFilterField({
			key: filterField,
			value: processFilterValue(value),
		});
		if (context?.updateURL) {
			const newQuery = addQueryToSearchParams(location.search, {
				value: processFilterValue(value),
				name: filterField,
			});
			navigate(`${location.pathname}${newQuery}`);
		}
	};

	const tooltipClasses = {
		tooltipPlacementBottom: 'customTooltipPlacementBottom',
	};

	const tooltipTitle = (
		<>
			<CopyToClipboard text={value} onCopy={handleSuccessfulCopy}>
				<IconButton className='customTooltipButton'>
					<FilterNoneIcon fontSize='small' className='customTooltipIcon' />
				</IconButton>
			</CopyToClipboard>
			{getNewTabLink && (
				<Link
					component={IconButton}
					className='tooltipButton'
					target='_blank'
					href={getNewTabLink(value)}
				>
					<ExitToAppIcon fontSize='small' className='customTooltipIcon' />
				</Link>
			)}
			{filterField && context?.changeFilterField && (
				<IconButton className='tooltipButton' onClick={handleChangeFilterField}>
					<FilterIcon className='customTooltipIcon' />
				</IconButton>
			)}
		</>
	);

	return (
		<Tooltip
			classes={tooltipClasses}
			title={tooltipTitle}
			placement={tooltipPlacement}
		>
			<Typography align={textAlign} variant='body2' className='tableCell'>
				{numberFormatter(value)}
			</Typography>
		</Tooltip>
	);
};

export default CustomTableCell;
