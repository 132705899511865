import React from 'react';
import { Field, Form } from 'react-final-form';
import { Button, TextField, Autocomplete } from '@mui/material';
import validateRuleFieldForm from 'helpers/validations/ruleField';
import isSubmitButtonDisabled from 'helpers/isSubmitButtonDisabled';
import './styles.css';

const RuleFieldForm = (props) => {
	const { initialValues, handleSubmit, buttonLabel, status } = props;

	return (
		<Form
			onSubmit={handleSubmit}
			initialValues={initialValues || { type: 'string' }}
			validate={validateRuleFieldForm}
			render={({ handleSubmit, errors, touched }) => (
				<form onSubmit={handleSubmit} className='newRuleField'>
					<Field name='name'>
						{(props) => {
							return (
								<TextField
									error={Boolean(props.meta.touched && props.meta.error)}
									helperText={props.meta.touched && props.meta.error}
									autoComplete='off'
									value={props.input.value}
									name={props.input.name}
									onChange={props.input.onChange}
									className='newRuleFieldInput'
									label='Field'
									InputLabelProps={{ shrink: true }}
								/>
							);
						}}
					</Field>
					<Field name='label'>
						{(props) => {
							return (
								<TextField
									error={Boolean(props.meta.touched && props.meta.error)}
									helperText={props.meta.touched && props.meta.error}
									autoComplete='off'
									value={props.input.value}
									name={props.input.name}
									onChange={props.input.onChange}
									className='newRuleFieldInput'
									label='Description'
									InputLabelProps={{ shrink: true }}
								/>
							);
						}}
					</Field>
					<Field name='example'>
						{(props) => (
							<TextField
								type={'text'}
								error={Boolean(props.meta.touched && props.meta.error)}
								helperText={props.meta.touched && props.meta.error}
								autoComplete='off'
								value={props.input.value}
								name={props.input.name}
								onChange={props.input.onChange}
								className='newRuleFieldInput'
								label='Example'
								InputLabelProps={{ shrink: true }}
							/>
						)}
					</Field>
					<Field name='type'>
						{(props) => (
							<Autocomplete
								disableClearable
								value={props.input.value}
								onChange={(event, value) => props.input.onChange(value)}
								getOptionLabel={(option) => option}
								options={['ip', 'date', 'number', 'string', 'boolean']}
								renderInput={(params) => (
									<TextField
										{...params}
										error={props.meta.touched && props.meta.error}
										helperText={props.meta.touched && props.meta.error}
										autoComplete='off'
										name={props.input.name}
										className='newRuleFieldInput'
										label='Type'
										InputLabelProps={{ shrink: true }}
									/>
								)}
							/>
						)}
					</Field>
					<Button
						disabled={isSubmitButtonDisabled(errors, touched, status)}
						fullWidth
						type='submit'
						className='newRuleFieldInput'
						variant='contained'
						color='primary'
					>
						{buttonLabel}
					</Button>
				</form>
			)}
		/>
	);
};

export default RuleFieldForm;
