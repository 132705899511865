import { createTheme, darken } from '@mui/material/styles';
import darkColors from 'constants/layoutTheme';
import {
	scoreIndicatorStyles,
	scoreIndicatorDarkTheme,
} from 'helpers/scoreIndicator';

export const theme = createTheme({
	components: {
		MuiTextField: {
			defaultProps: {
				variant: 'outlined',
				size: 'small',
			},
		},
		MuiIconButton: {
			styleOverrides: {
				root: {
					color: 'inherit',
				},
			},
		},
		MuiSelect: {
			defaultProps: {
				variant: 'outlined',
				size: 'small',
			},
		},
		MuiFormControl: {
			styleOverrides: {
				root: {
					minWidth: 120,
				},
			},
		},
		MuiDataGrid: {
			styleOverrides: {
				root: {
					marginTop: 10,
					'.MuiDataGrid-row': {
						cursor: 'pointer',
					},
					'.MuiDataGrid-row:nth-of-type(odd)': {
						backgroundColor: 'rgba(0, 0, 0, 0.06)',
					},
					...scoreIndicatorStyles,
				},
			},
		},
	},
	palette: {
		secondary: {
			main: '#E33E7F',
		},
		default: {
			main: '#ddd',
		},
	},
});

export const getDarkTheme = (mode) => {
	let colors = darkColors;
	if (mode === 'pink') {
		colors = colors.pink;
	}
	return {
		palette: {
			mode,
			...(mode !== 'light'
				? {
						// palette values for dark mode
						divider: colors.divider,
						default: { main: colors.transactionsExportButtons },
						background: {
							default: colors.backgroundPrimary,
							paper: colors.backgroundSecondary,
						},
						text: {
							primary: colors.textPrimary,
							secondary: colors.textSecondary,
						},
				  }
				: {}),
		},
		components: {
			...(mode !== 'light'
				? {
						MuiAccordionSummary: {
							styleOverrides: {
								expandIconWrapper: {
									color: 'inherit',
								},
							},
						},
						MuiInputAdornment: {
							styleOverrides: {
								root: {
									color: 'inherit',
								},
							},
						},
						MuiSvgIcon: {
							styleOverrides: {
								root: {
									color: 'inherit !important',
								},
							},
						},
						MuiDialog: {
							styleOverrides: {
								paper: {
									backgroundColor: colors.dialogBackground,
								},
							},
						},
						MuiDataGrid: {
							styleOverrides: {
								root: {
									'.MuiDataGrid-row:nth-of-type(odd)': {
										backgroundColor: colors.oddRowsBackground,
										'&:hover': {
											backgroundColor: darken(colors.oddRowsBackground, 0.05),
										},
									},
									'.MuiDataGrid-row:nth-of-type(even)': {
										'&:hover': {
											backgroundColor: darken(colors.backgroundPrimary, 0.05),
										},
									},
									...scoreIndicatorDarkTheme,
								},
							},
						},
				  }
				: {}),
		},
	};
};
