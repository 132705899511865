import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { connect } from 'react-redux';
import { useCSVReader } from 'react-papaparse';
import {
	changeNewEvidenceFilterField,
	getNewImportedEvidenceInfo,
	setNewImportedEvidencesInfo,
} from 'actions/evidencesActions';
import { LOADING } from 'constants/apiStatuses';
import './styles.css';
import { getReasons } from 'actions/reasonsActions';
import { getBadEvidences, normalizeDate } from 'helpers/checkImportEvidences';
import { resetNewEvidenceFilters } from '../../redux/actions/evidencesActions';
import getQueryFromSearchParams from 'helpers/getQueryFromSearchParams';
import { useLocation } from 'react-router-dom';
import {
	writeNewEvidence,
	getEvidenceImportQueueSize,
} from 'services/api/evidence/getNewImportedEvidenceInfo';

const REFRESH_IN_SEC = 15;

const ImportEvidence2 = (props) => {
	const { CSVReader } = useCSVReader();
	const location = useLocation();

	const [failedReasons, setFailedReasons] = useState(null);
	const [fileName, setFileName] = useState('');
	const [fileRecordCount, setFileRecordCount] = useState(0);
	const [showFileInfo, setShowFileInfo] = useState(false);
	const filtersQuery = new URLSearchParams(location.search);
	const {
		getNewImportedEvidenceInfo,
		newGetImportedEvidenceInfoStatus,
		newImportedEvidenceInfo,
		setNewImportedEvidencesInfo,
		evidenceTypes,
		getReasons,
		evidenceTotalAmount,
		changeNewEvidenceFilterField,
		importEvidenceFilters,
		resetNewEvidenceFilters,
	} = props;

	const [ieQuerySize, setIeQuerySize] = useState(0);
	const [secCnt, setSecCnt] = useState(REFRESH_IN_SEC);

	const fetchIeQuerySize = async () => {
		setIeQuerySize(await getEvidenceImportQueueSize());
		// setShowFileInfo(false);
	};

	useEffect(() => {
		fetchIeQuerySize();

		const intervalId = setInterval(() => {
			setSecCnt((prev) => {
				if (prev <= 0) {
					fetchIeQuerySize();
					setSecCnt(REFRESH_IN_SEC);
				}
				return prev - 1;
			});
		}, 1000);
		return () => clearInterval(intervalId);
	}, []);

	const handleFileLoad = (data, file) => {
		const parsed = data.data
			.map((row) => ({
				'account.name': row[0]?.trim(),
				'evidence.reason': row[1]?.trim(),
				radiate: row[2]?.trim() ?? 'false',
				comment:
					row[3]?.trim() ??
					`Mass-Upload ${new Date()
						.toISOString()
						.substring(0, 4 + 1 + 2 + 1 + 2)}`,
				expires: normalizeDate(row[4]?.trim()),
			}))
			.filter(
				(e) =>
					e['evidence.reason'] &&
					e['account.name'] &&
					e['radiate'] &&
					e['comment']
			);

		setFileName(file.name);
		setFileRecordCount(parsed.length);
		setShowFileInfo(true);

		const badReasons = getBadEvidences(evidenceTypes, parsed);
		if (!badReasons.length) {
			setFailedReasons(null);
			writeNewEvidence({ evidences: parsed });
		} else {
			setFailedReasons(badReasons);
		}
	};

	useEffect(() => {
		getReasons();
		filtersQuery.delete('tab');
		resetNewEvidenceFilters(getQueryFromSearchParams(filtersQuery.entries()));
		return () => {
			setNewImportedEvidencesInfo(null);
		};
	}, []);

	const getTableClass = (state) => {
		return `impEvidenceTable ${
			newGetImportedEvidenceInfoStatus === LOADING ? 'disablePageButton' : ''
		} ${state ? 'removePageButton' : ''}`;
	};

	const isGettingInfo = newGetImportedEvidenceInfoStatus === LOADING;
	return (
		<Box
			display='flex'
			justifyContent='center'
			alignItems='center'
			flexDirection='column'
		>
			<CSVReader
				onUploadAccepted={(results, file) => handleFileLoad(results, file)}
			>
				{({ getRootProps }) => (
					<div {...getRootProps()} className='eviFileInput'>
						<Typography variant='h4'>Your csv file</Typography>
						<div className='eviFileInputBody'>
							First column: account name, <br />
							Second column: reason, <br />
							Third column: true/false radiation, Fourth column: comment text,{' '}
							<br />
							Fifth column: evidence timestamp of expiration. <br />
							Note: Please do NOT use comma in text. <br />
							Note: unknown reasons will be created.
						</div>
					</div>
				)}
			</CSVReader>
			{isGettingInfo && (
				<>
					<CircularProgress style={{ marginTop: 20 }} />
					<p>Getting info...</p>
				</>
			)}
			{failedReasons && (
				<Box>
					<Typography variant='h5'>
						Can't import the data. Please, check the next reasons from the file:
					</Typography>
					{failedReasons?.map((elem) => (
						<Typography
							className='eviFailedReasonItem'
							key={elem['evidence.reason']}
							variant='h5'
						>
							{elem['evidence.reason']}
						</Typography>
					))}
				</Box>
			)}
			<Box>
				{showFileInfo && (
					<>
						Latest uploaded file: {fileName} <br />
						Number of records: {fileRecordCount -
							(failedReasons?.length ?? 0)}{' '}
						<br />
					</>
				)}
				<>
					Queue size: {ieQuerySize}. <br />
					Refresh: {secCnt} seconds.
				</>
			</Box>
		</Box>
	);
};

const mapStateToProps = ({ evidences, reasons }) => ({
	newImportedEvidenceInfo: evidences.data,
	newGetImportedEvidenceInfoStatus: evidences.newGetImportedEvidenceInfoStatus,
	evidenceTypes: reasons.reasons,
	evidenceTotalAmount: evidences.total,
	importEvidenceFilters: evidences.importEvidenceFilters,
});

const mapDispatchToProps = (dispatch) => ({
	getNewImportedEvidenceInfo: (cursor, type, filters) =>
		dispatch(getNewImportedEvidenceInfo(cursor, type, filters)),
	setNewImportedEvidencesInfo: (data) =>
		dispatch(setNewImportedEvidencesInfo(data)),
	getReasons: () => dispatch(getReasons),
	changeNewEvidenceFilterField: (value) =>
		dispatch(changeNewEvidenceFilterField(value)),
	resetNewEvidenceFilters: (body) => dispatch(resetNewEvidenceFilters(body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImportEvidence2);
