import React, { useEffect } from 'react';
import { FormSpy } from 'react-final-form';

const WhenFieldChanges = ({ field, becomes, targetField, to }) => {
	useEffect(() => {
		return () => {};
	}, []);

	return (
		<FormSpy subscription={{ values: true }}>
			{({ values, form }) => {
				if (values && values[field] === becomes && form && targetField) {
					form.change(targetField, to);
				}
				return null;
			}}
		</FormSpy>
	);
};

export default WhenFieldChanges;
