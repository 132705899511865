import React from 'react';
import { Box, Grid } from '@mui/material';
import transformAgeInMs from 'helpers/transformAgeInMs';
import { connect } from 'react-redux';
import momentTimezone from 'moment-timezone';
import '../styles.css';
import CustomAccordion from '../Accordion';
import AccountToCreate from '../AccountToCreate';
import DeviceToCreate from '../DeviceToCreate';
import { dateFormatter } from 'helpers/formatters';
import KeyValueTable, { RowValue } from 'components/KeyValueTable';
import {
	MatchingRulesTable,
	IpEnrichmentBlock,
	CustomTransactionTable,
} from 'containers/Transaction/components';

const TransactionToCreate = ({ transactionInfo, ruleKeys }) => {
	const {
		ipEnrichment,
		connectionIP,
		debug,
		fpAgeInMs,
		ipReputation,
		serverTime,
		transactionsByAccount,
		transactionsByDevice,
		transactionsByIP,
		countriesByDeviceReport,
		countriesByAccountReport,
		accountsByDeviceReport,
		devicesByAccountReport,
		transactionsByDeviceByRS,
		transactionsByAccountByRS,
		transactionsByIPByRS,
		...restSession
	} = transactionInfo.session ?? {};

	const tableReportsData = {
		transactionsByAccount,
		transactionsByDevice,
		transactionsByIP,
		countriesByAccountReport,
		accountsByDeviceReport,
		devicesByAccountReport,
		transactionsByDeviceByRS,
		transactionsByAccountByRS,
		transactionsByIPByRS,
	};

	return (
		<CustomAccordion title='Transaction'>
			countriesByDeviceReport,
			<Grid container spacing={1}>
				<Grid item xs={6}>
					<KeyValueTable>
						<RowValue
							oKey='Account name:'
							oValue={transactionInfo.account?.name}
						/>
						<RowValue oKey='Device id:' oValue={transactionInfo.device?.id} />
						<RowValue
							oKey='Creation date:'
							oValue={dateFormatter(transactionInfo.creationDate)}
						/>
						<RowValue
							oKey='Timezone:'
							oValue={`UTC ${momentTimezone
								.tz(ipEnrichment.timeZone)
								.format('Z')} ${ipEnrichment.timeZone}`}
						/>
						<RowValue oKey='Server time:' oValue={dateFormatter(serverTime)} />
						<RowValue oKey='Connection ip:' oValue={connectionIP} />
						<RowValue oKey='Ip reputation:' oValue={ipReputation} />
						<RowValue oKey='Age:' oValue={transformAgeInMs(fpAgeInMs)} />
					</KeyValueTable>

					<Box className='infoBlock'>
						<CustomTransactionTable data={tableReportsData} />
					</Box>

					<KeyValueTable>
						{Object.entries(restSession).map(([sessionKey, value]) => {
							const ruleKey = ruleKeys.find(
								({ key }) => key === `session.${sessionKey}`
							);
							const oKey = ruleKey ? ruleKey.label : sessionKey;
							return <RowValue key={sessionKey} oKey={oKey} oValue={value} />;
						})}
					</KeyValueTable>

					{!!transactionInfo.rating.matchingRules.length && (
						<Box className='infoItem'>
							Matching rules:{' '}
							<MatchingRulesTable
								matchingRules={transactionInfo.rating.matchingRules}
							/>
						</Box>
					)}

					<KeyValueTable>
						<RowValue
							oKey='Rule-Sets:'
							oValue={transactionInfo.rating.ruleSet}
						/>
						<RowValue oKey='Score:' oValue={transactionInfo.rating.score} />
					</KeyValueTable>
				</Grid>
				<Grid item xs={6}>
					{ipEnrichment && <IpEnrichmentBlock ipEnrichment={ipEnrichment} />}
				</Grid>
				{transactionInfo.account?.name && (
					<AccountToCreate accountInfo={transactionInfo.account} />
				)}
				{transactionInfo.device?.uuid && (
					<DeviceToCreate deviceInfo={transactionInfo.device} />
				)}
			</Grid>
		</CustomAccordion>
	);
};

const mapStateToProps = ({ rules }) => ({
	ruleKeys: rules.ruleKeys || [],
});

export default connect(mapStateToProps, () => ({}))(TransactionToCreate);
