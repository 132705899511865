import { PureTableCell } from 'components';
import React from 'react';

const fpStatsChart = [
	{
		field: 'id',
		editable: false,
		headerName: 'label',
		headerAlign: 'center',
		flex: 1,
		renderCell: (params) => <PureTableCell {...params} />,
	},
	{
		field: 'value',
		editable: false,
		headerName: 'Count',
		headerAlign: 'center',
		flex: 1,
		renderCell: (params) => <PureTableCell {...params} />,
	},
	{
		field: 'percent',
		editable: false,
		headerName: 'Percentage',
		headerAlign: 'center',
		flex: 1,
		renderCell: (params) => <PureTableCell {...params} />,
	},
];

export default fpStatsChart;
