import { Box, Button, IconButton, TextField, Tooltip } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import RefreshIcon from '@mui/icons-material/Refresh';
import { ReactComponent as ClearAllIcon } from 'icons/clearFilter.svg';
import React, { useEffect, useState } from 'react';
import '../styles.css';
import { CustomInput } from 'components';
import Select from 'react-select';
import { useTheme } from '@mui/material/styles';

const TransactionFilters = ({
	filters,
	handleChangeFilters,
	handleRefresh,
	handleResetFilters,
	handleAddQuery = () => {},
	handleExportToJSONButtonClick = () => {},
	handleExportToCSVButtonClick = () => {},
	exclude = {},
	rules,
	isFromAccount,
	menuState,
}) => {
	const keyboardDateTimePickerClass = `transFilterInput pickerInput`;
	const dateTimePickerInputFormat = 'dd-MM-yyyy HH:mm';
	const [ruleList, setRuleList] = useState([]);
	const theme = useTheme();

	const transClearAllIconStyle = {
		fill: theme.palette.primary.main,
	};

	useEffect(() => {
		if (rules) {
			const ruleOptions = rules.map((rule) => ({
				value: rule.name,
				label: rule.name,
				name: 'ruleName',
			}));
			setRuleList([...ruleOptions]);
		}
	}, [rules]);

	const handleDateTimePickerChange = (name) => (value) => {
		const currentDate = Date.parse(value);
		if (!isNaN(currentDate)) {
			handleAddQuery({
				target: {
					name,
					value: new Date(value).toISOString(),
				},
			});
		}
		handleChangeFilters({
			target: {
				name,
				value,
			},
		});
	};

	const dateTimePickerRenderInput = (params) => (
		<TextField {...params} className={keyboardDateTimePickerClass} />
	);

	const ruleWrapperClass = `transRuleWrapper ${
		theme.palette.mode === 'dark' ? 'dark' : 'light'
	}`;

	const ruleWrapperCustomClass = `transRuleWrapperCustom ${
		theme.palette.mode === 'dark' ? 'dark' : 'light'
	}`;

	const dropdownWrapClass =
		theme.palette.mode === 'dark'
			? 'transDropdownWrap dark'
			: 'transDropdownWrap';

	const dropdownWrapInClass =
		theme.palette.mode === 'dark'
			? 'transDropdownWrapIn dark'
			: 'transDropdownWrapIn';

	const dropdownWrapInCustomClass =
		theme.palette.mode === 'dark'
			? 'transDropdownWrapInCustom dark'
			: 'transDropdownWrapInCustom';

	const dropdownWrapColorClass =
		theme.palette.mode === 'dark'
			? 'transDropdownWrapColor dark'
			: 'transDropdownWrapColor';

	const filterRuleName = Array.isArray(filters?.ruleName)
		? filters?.ruleName
		: [filters.ruleName];

	return (
		<Box className='transFilterBar'>
			{!exclude.deviceId && (
				<CustomInput
					value={filters.deviceId}
					name='deviceId'
					label='Device ID'
					onChange={handleChangeFilters}
					onBlur={handleAddQuery}
				/>
			)}
			{!exclude.deviceId && (
				<CustomInput
					value={filters.deviceUUID}
					name='deviceUUID'
					label='Device UUID'
					onChange={handleChangeFilters}
					onBlur={handleAddQuery}
				/>
			)}
			<CustomInput
				value={filters.deviceTimeZone}
				name='deviceTimeZone'
				label='Time Zone'
				onChange={handleChangeFilters}
				onBlur={handleAddQuery}
			/>
			<CustomInput
				value={filters.ruleSet}
				name='ruleSet'
				label='Rule Set'
				onChange={handleChangeFilters}
				onBlur={handleAddQuery}
			/>
			<CustomInput
				value={filters.sessionIsp}
				name='sessionIsp'
				label='ISP'
				onChange={handleChangeFilters}
				onBlur={handleAddQuery}
			/>
			<CustomInput
				value={filters.sessionCountryName}
				name='sessionCountryName'
				label='Country'
				onChange={handleChangeFilters}
				onBlur={handleAddQuery}
			/>
			<CustomInput
				value={filters.sessionIp}
				name='sessionIp'
				label='IP'
				onChange={handleChangeFilters}
				onBlur={handleAddQuery}
			/>
			{!exclude.accountName && (
				<CustomInput
					value={filters.accountName}
					name='accountName'
					label='Account Name'
					onChange={handleChangeFilters}
					onBlur={handleAddQuery}
				/>
			)}
			<CustomInput
				value={filters.score}
				name='score'
				label='Min Score'
				onChange={handleChangeFilters}
				onBlur={handleAddQuery}
			/>
			<CustomInput
				value={filters.maxScore}
				name='maxScore'
				label='Max Score'
				onChange={handleChangeFilters}
				onBlur={handleAddQuery}
			/>
			<Button
				sx={{ mr: 1, mt: 2, mb: 2 }}
				color='secondary'
				variant='outlined'
				size='small'
				onClick={() => {
					handleChangeFilters({ target: { name: 'maxScore', value: '-100' } });
					handleAddQuery({ target: { name: 'maxScore', value: '-100' } });
				}}
			>
				-100
			</Button>{' '}
			<CustomInput
				value={filters.id}
				name='id'
				label='Transaction ID'
				onChange={handleChangeFilters}
				onBlur={handleAddQuery}
			/>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<DateTimePicker
					className={menuState ? 'transCustomDateWrap' : 'transSelectDateWrap'}
					format={dateTimePickerInputFormat}
					margin='normal'
					value={filters.minCreationDate}
					onChange={handleDateTimePickerChange('minCreationDate')}
					label='Min Creation Date'
					textField={dateTimePickerRenderInput}
				/>
				<DateTimePicker
					className={menuState ? 'transCustomDateWrap' : 'transSelectDateWrap'}
					format={dateTimePickerInputFormat}
					margin='normal'
					value={filters.maxCreationDate}
					onChange={handleDateTimePickerChange('maxCreationDate')}
					label='Max Creation Date'
					textField={dateTimePickerRenderInput}
				/>
			</LocalizationProvider>
			<div
				className={
					menuState ? 'transDropdownMainWrap' : 'transDropdownMainWrapCustom'
				}
			>
				<label
					className={isFromAccount ? ruleWrapperCustomClass : ruleWrapperClass}
				>
					Rule Name
				</label>
				<Select
					value={
						filterRuleName?.length > 0 && filterRuleName?.[0]?.trim() !== ''
							? filterRuleName?.map((rule) => ({
									value: rule,
									label: rule,
									name: 'ruleName',
							  }))
							: ''
					}
					onChange={handleChangeFilters}
					options={ruleList}
					isMulti={true}
					className={isFromAccount ? dropdownWrapColorClass : dropdownWrapClass}
					classNamePrefix={
						isFromAccount ? dropdownWrapInCustomClass : dropdownWrapInClass
					}
				/>
			</div>
			<Tooltip title='Refresh page'>
				<IconButton
					className='transFilterInput'
					color='primary'
					onClick={handleRefresh}
				>
					<RefreshIcon
						fontSize='small'
						className='transClearAllIcon'
						style={transClearAllIconStyle}
					/>
				</IconButton>
			</Tooltip>
			<Tooltip title='Clear filters'>
				<IconButton
					className='transFilterInput'
					color='primary'
					onClick={handleResetFilters}
				>
					<ClearAllIcon
						className='transClearAllIcon'
						style={transClearAllIconStyle}
					/>
				</IconButton>
			</Tooltip>
			<div
				className={`transBtnFilterWrap ${
					menuState && 'transBtnFilterWrapCustom'
				}`}
			>
				{!exclude.exportButton && (
					<Box className='transExportButtonContainer'>
						<Button
							variant='contained'
							color='default'
							onClick={handleExportToCSVButtonClick}
							className='transExportButton'
						>
							Export to CSV
						</Button>
						<Button
							color='default'
							variant='contained'
							className='transExportButton'
							onClick={handleExportToJSONButtonClick}
						>
							Export to JSON
						</Button>
					</Box>
				)}
			</div>
		</Box>
	);
};

export default TransactionFilters;
