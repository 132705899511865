import { createStore, applyMiddleware, combineReducers } from 'redux';
import { thunk } from 'redux-thunk';
import accountsReducer from 'reducers/accountsReducer';
import devicesReducer from 'reducers/devicesReducer';
import transactionsReducer from 'reducers/transactionsReducer';
import serverStatsReducer from 'reducers/serverStatsReducer';
import rulesReducer from 'reducers/rulesReducer';
import authReducer from 'reducers/authReducer';
import usersReducer from 'reducers/usersReducer';
import ratingReducer from 'reducers/ratingReducer';
import ipsReducer from 'reducers/ipsReducer';
import actionsReducer from 'reducers/actionsReducer';
import evidencesReducer from 'reducers/evidencesReducer';
import reasonsReducer from 'reducers/reasonsReducer';
import fpStatsReducer from './reducers/fpStatsReducer';

const rootReducer = combineReducers({
	accounts: accountsReducer,
	devices: devicesReducer,
	transactions: transactionsReducer,
	serverStats: serverStatsReducer,
	rules: rulesReducer,
	auth: authReducer,
	users: usersReducer,
	rating: ratingReducer,
	ips: ipsReducer,
	actions: actionsReducer,
	evidences: evidencesReducer,
	reasons: reasonsReducer,
	fpStats: fpStatsReducer,
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
