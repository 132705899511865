import React from 'react';
import { Header, Footer, NavBar } from 'components';
import './styles.css';
import { connect } from 'react-redux';

const Layout = (props) => {
	const { children, menuState } = props;

	const childContentClass = menuState ? 'contentLarge' : 'layoutContent';

	return (
		<div className='layoutWrapper'>
			<Header />
			<div className='layoutBody'>
				<NavBar />
				<div className={childContentClass}>{children}</div>
			</div>
			<Footer menuState={menuState} />
		</div>
	);
};

const mapStateToProps = ({ auth }) => ({
	menuState: auth.menuState,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
