import {
	USERS_DELETE_USER,
	USERS_DELETE_USER_STATUS,
	USERS_EDIT_USER_STATUS,
	USERS_SET_SINGLE_USER,
	USERS_GET_SINGLE_USER_STATUS,
	USERS_CREATE_USER,
	USERS_CREATE_USER_STATUS,
	USERS_SET_USERS,
	USERS_GET_USERS_STATUS,
} from '../constants';
import { getSingleUser as getSingleUserAPI } from 'services/api/users/getSingleUser';
import { editUser as editUserAPI } from 'services/api/users/editUser';
import { getUsers as getUsersAPI } from 'services/api/users/getUsers';
import { addUser as addUserAPI } from 'services/api/users/addUser';
import { deleteUser as deleteUserAPI } from 'services/api/users/deleteUser';
import { LOADING, NONE, ERROR } from 'constants/apiStatuses';

export const setGetSingleUserStatus = (status) => ({
	type: USERS_GET_SINGLE_USER_STATUS,
	payload: status,
});

export const setUser = (user) => ({
	type: USERS_SET_SINGLE_USER,
	payload: user,
});

export const getSingleUser = (id) => (dispatch, getState) => {
	dispatch(setGetSingleUserStatus(LOADING));
	getSingleUserAPI(id)
		.then((user) => {
			dispatch(setGetSingleUserStatus(NONE));
			dispatch(setUser(user));
		})
		.catch(() => dispatch(setGetSingleUserStatus(ERROR)));
};

export const setEditUserStatus = (status) => ({
	type: USERS_EDIT_USER_STATUS,
	payload: status,
});

export const editUser = (user, onLoad) => (dispatch, getState) => {
	dispatch(setEditUserStatus(LOADING));
	editUserAPI(user)
		.then((res) => {
			if (res.error) {
				console.log(res.message);
			} else {
				dispatch(setEditUserStatus(NONE));
				onLoad();
			}
		})
		.catch(() => dispatch(setEditUserStatus(ERROR)));
};

export const setGetUsersStatus = (status) => ({
	type: USERS_GET_USERS_STATUS,
	payload: status,
});

export const setUsers = (users) => ({
	type: USERS_SET_USERS,
	payload: users,
});

export const getUsers = (dispatch, getState) => {
	dispatch(setGetUsersStatus(LOADING));
	getUsersAPI()
		.then((users) => {
			dispatch(setGetUsersStatus(NONE));
			dispatch(setUsers(users));
		})
		.catch(() => dispatch(setGetUsersStatus(ERROR)));
};

export const setCreateUserStatus = (status) => ({
	type: USERS_CREATE_USER_STATUS,
	payload: status,
});

export const setCreateUser = (user) => ({
	type: USERS_CREATE_USER,
	payload: user,
});

export const createUser = (body, onLoad) => (dispatch, getState) => {
	dispatch(setCreateUserStatus(LOADING));
	addUserAPI(body)
		.then((res) => {
			if (res.error) {
				console.log(res.message);
			} else {
				dispatch(setCreateUserStatus(NONE));
				dispatch(setCreateUser(res));
				onLoad();
			}
		})
		.catch(() => dispatch(setCreateUserStatus(ERROR)));
};

export const setDeleteUserStatus = (status) => ({
	type: USERS_DELETE_USER_STATUS,
	payload: status,
});

export const setDeleteUser = (user) => ({
	type: USERS_DELETE_USER,
	payload: user,
});

export const deleteUser = (id, onLoad) => (dispatch, getState) => {
	dispatch(setDeleteUserStatus(LOADING));
	deleteUserAPI(id)
		.then((user) => {
			dispatch(setDeleteUserStatus(NONE));
			dispatch(setDeleteUser(user));
			onLoad();
		})
		.catch(() => dispatch(setDeleteUserStatus(ERROR)));
};
