import Joi from 'joi';
import { MAX_STRING_LENGTH } from '../../constants/validations';

const validateEvidenceForm = (evidenceTypes) => (values) => {
	const evidenceSchema = Joi.object({
		createComment: Joi.string()
			.required()
			.messages({
				'any.required': 'Comment is required',
				'string.empty': 'Comment is required',
				'string.max': `Comment max length is ${MAX_STRING_LENGTH} symbols`,
			})
			.max(MAX_STRING_LENGTH),
		reason: Joi.string()
			.required()
			.messages({
				'any.required': 'Reason is required',
				'string.empty': 'Reason is required',
			})
			.custom((value, helpers) => {
				if (evidenceTypes.find((elem) => elem.id === value)) {
					return value;
				}

				return helpers.message('Incorrect reason');
			}),
		evidenceEndDateInDays: Joi.boolean(),
		endDate: Joi.when('evidenceEndDateInDays', {
			is: false,
			then: Joi.date().greater(Date.now()).allow(null, ''),
			otherwise: Joi.any(),
		}),
		expiresIn: Joi.when('evidenceEndDateInDays', {
			is: true,
			then: Joi.number()
				.greater(0)
				.less(32767)
				.messages({
					'number.base': 'Expiration must be a number',
					'number.greater': 'Expiration must be greater than 0',
					'number.less': 'Expiration must be less than 32767',
				})
				.allow(null, ''),
			otherwise: Joi.any(),
		}),
	});

	const validatedEvidenceSchema = evidenceSchema.validate(
		{
			createComment: values.createComment,
			reason: values.reason,
			endDate: values.endDate,
			expiresIn: values.expiresIn,
			evidenceEndDateInDays: values.evidenceEndDateInDays,
		},
		{
			abortEarly: false,
		}
	);

	if (validatedEvidenceSchema.error) {
		return Object.fromEntries(
			validatedEvidenceSchema.error.details.map((detail) => [
				detail.path[0],
				detail.message,
			])
		);
	}

	return {};
};

export default validateEvidenceForm;
