import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { ReactComponent as ClearAllIcon } from 'icons/clearFilter.svg';
import { DataGrid } from '@mui/x-data-grid';
import getQueryFromSearchParams from 'helpers/getQueryFromSearchParams';
import { Pagination, FilterProvider, CustomInput } from 'components';
import { getIps, changeFilterField, resetFilters } from 'actions/ipsActions';
import ipsColumns from 'constants/columns/ips';
import './styles.css';
import { LOADING } from 'constants/apiStatuses';
import defaultSortModels from 'constants/defaultHeaderSortModels';
import { getRowColorByMark } from 'helpers/getTableRowColor';
import { VALIDATION_TYPES } from '../../helpers/validations/validateFilters';
import LoadingSpinner from '../../components/LoadingSpinner';

const IpsPage = (props) => {
	const navigate = useNavigate();
	const location = useLocation();
	const filtersQuery = new URLSearchParams(location.search);
	const {
		getIps,
		ips,
		ipsTotalAmount,
		getIpsStatus,
		filters,
		changeFilterField,
		resetFilters,
		userInfo,
	} = props;

	const isUserAdmin = userInfo.role === 'admin';

	const handleRowClick = ({ id }) => {
		navigate(`/ips/${id}`);
	};

	const handleGoToNewIp = () => {
		navigate('/new-ip');
	};

	useEffect(() => {
		filtersQuery.delete('tab');
		resetFilters(getQueryFromSearchParams(filtersQuery.entries()));
	}, []);

	const getTableClass = (state) => {
		return `${getIpsStatus === LOADING ? 'disablePageButton' : ''} ${
			state ? 'removePageButton' : ''
		}`;
	};

	return (
		<>
			<Typography variant='h4'>Ip Blocks</Typography>
			<Pagination
				getItems={getIps}
				filters={filters}
				urlToReset='?tab=ipBlocks'
				defaultSortModel={defaultSortModels.ip}
				resetFilters={resetFilters}
				changeFilterField={changeFilterField}
				initialFilters={getQueryFromSearchParams(filtersQuery.entries())}
				validationType={VALIDATION_TYPES.IPS}
			>
				{({
					page,
					handleChangeFilters,
					handleRefresh,
					handleAddQuery,
					handleResetFilters,
					pageSize,
					rowsPerPageOptions,
					isArrowBlocked,
					handleHeaderFilterChange,
					sortModel,
					handlePaginationModelChange,
				}) => (
					<>
						<Box className='ipsFilterBar'>
							<CustomInput
								value={filters.ip}
								name='ip'
								onChange={handleChangeFilters}
								onBlur={handleAddQuery}
								label='Ip'
								autoComplete='off'
							/>
							<CustomInput
								onBlur={handleAddQuery}
								value={filters.isp}
								name='isp'
								onChange={handleChangeFilters}
								label='Isp'
								autoComplete='off'
							/>
							{isUserAdmin && (
								<Button
									color='primary'
									className='ipsFilterInput'
									variant='contained'
									onClick={handleGoToNewIp}
								>
									Add IP to list
								</Button>
							)}
							<Tooltip title='Reset page'>
								<IconButton
									className='ipsFilterInput'
									color='primary'
									onClick={() => handleRefresh(pageSize)}
								>
									<RefreshIcon fontSize='small' className='ipsClearAllIcon' />
								</IconButton>
							</Tooltip>
							<Tooltip title='Clear filters'>
								<IconButton
									className='ipsFilterInput'
									color='primary'
									onClick={handleResetFilters}
								>
									<ClearAllIcon className='ipsClearAllIcon' />
								</IconButton>
							</Tooltip>
						</Box>
						{getIpsStatus === LOADING ? (
							<LoadingSpinner />
						) : (
							ips && (
								<FilterProvider changeFilterField={changeFilterField}>
									<DataGrid
										columns={ipsColumns}
										rows={ips}
										autoHeight
										className={getTableClass(isArrowBlocked)}
										disableColumnMenu={true}
										onRowClick={handleRowClick}
										getCellClassName={getRowColorByMark}
										paginationMode='server'
										rowCount={ipsTotalAmount}
										paginationModel={{ page, pageSize }}
										pageSizeOptions={rowsPerPageOptions}
										onPaginationModelChange={handlePaginationModelChange}
										sortingMode='server'
										onSortModelChange={handleHeaderFilterChange}
										sortModel={sortModel}
									/>
								</FilterProvider>
							)
						)}
					</>
				)}
			</Pagination>
		</>
	);
};

const mapStateToProps = ({ ips, auth }) => ({
	ips: ips.data,
	ipsTotalAmount: ips.total,
	getIpsStatus: ips.getIpsStatus,
	filters: ips.filters,
	userInfo: auth.userInfo,
});

const mapDispatchToProps = (dispatch) => ({
	getIps: (body) => dispatch(getIps(body)),
	changeFilterField: (value) => dispatch(changeFilterField(value)),
	resetFilters: (body) => dispatch(resetFilters(body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IpsPage);
