import { Typography } from '@mui/material';
import '../styles.css';
import React from 'react';
import { numberFormatter } from 'helpers/formatters';

const PureTableCell = ({ value }) => {
	return (
		<Typography align='center' variant='body2' className='tableCell'>
			{numberFormatter(value)}
		</Typography>
	);
};

export default PureTableCell;
