const checkIfRuleTimeIsDefault = (validFrom, validTo) => {
	const fromDate = new Date(validFrom);
	const toDate = new Date(validTo);

	const isFromDateDefault =
		fromDate.getFullYear() === 2000 &&
		fromDate.getMonth() === 0 &&
		fromDate.getDate() === 1;

	const isToDateDefault =
		toDate.getFullYear() === 2099 &&
		toDate.getMonth() === 11 &&
		toDate.getDate() === 31;

	return isFromDateDefault && isToDateDefault;
};

export default checkIfRuleTimeIsDefault;
