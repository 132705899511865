import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { connect } from 'react-redux';
import {
	Route,
	Routes as Switch,
	Navigate,
	BrowserRouter,
} from 'react-router-dom';
import { Layout } from 'components';
import {
	Main,
	Rating,
	RatingTest,
	Rule,
	RuleManager,
	NewRule,
	EditRule,
	Transaction,
	Account,
	Device,
	PageNotFound,
	Login,
	RoleManagement,
	User,
	EditUser,
	NewUser,
	NewAccountEvidence,
	NewDeviceEvidence,
	NewIpEvidence,
	ReportsInfo,
	Ip,
	NewIp,
	ChangeLog,
	Action,
	ImportEvidence,
	ImportEvidence2,
	RuleFieldsPage,
	EvidenceLog,
	NewRuleField,
	FpStats,
} from 'containers';
import { checkToken } from 'actions/authActions';
import { getRuleKeys } from 'actions/rulesActions';
import { getUsers } from 'actions/usersActions';
import { LOADING, NONE } from 'constants/apiStatuses';
import { getReasons } from 'actions/reasonsActions';
import { ColorModeContext } from 'components/ThemeContext';

const Router = (props) => {
	const colorMode = React.useContext(ColorModeContext);

	const {
		userInfo,
		checkToken,
		checkTokenStatus,
		getRuleKeys,
		getUsers,
		getReasons,
	} = props;

	useEffect(() => {
		checkToken(localStorage.getItem('rating_app_token'));
		const theme = localStorage.getItem('rating_app_theme');
		theme && colorMode.toggleColorMode(theme);
	}, []);

	useEffect(() => {
		if (userInfo) {
			getRuleKeys();
			getUsers();
			getReasons();
		}
	}, [userInfo]);

	if (!userInfo && checkTokenStatus === NONE) {
		return (
			<BrowserRouter>
				<Switch>
					<Route path='/' exact element={<Login />} />
					<Route path='*' element={<Navigate to='/' />} />
				</Switch>
			</BrowserRouter>
		);
	}

	if (checkTokenStatus === LOADING) {
		return <div>Loading...</div>;
	}

	return (
		<BrowserRouter>
			<Layout>
				<Box
					style={{
						paddingLeft: 25,
						paddingRight: 25,
					}}
				>
					<Switch>
						<Route path='/' exact element={<Main />} />
						<Route path='/rating' element={<Rating />} />
						<Route path='/rating-test' element={<RatingTest />} />
						<Route path='/rule-manager' element={<RuleManager />} />
						<Route path='/role-manager' element={<RoleManagement />} />
						<Route path='/rule-fields' element={<RuleFieldsPage />} />
						<Route path='/new-user' element={<NewUser />} />
						<Route path='/new-ip' element={<NewIp />} />
						<Route path='/reports/:type' element={<ReportsInfo />} />
						<Route path='/ips/:id' element={<Ip />} />
						<Route path='/devices/:id' element={<Device />} />
						<Route path='/import-evidence' element={<ImportEvidence />} />
						<Route path='/new-import-evidence' element={<ImportEvidence2 />} />
						<Route path='/fp-stats' element={<FpStats />} />
						<Route
							path='/new-device-evidence/:id'
							element={<NewDeviceEvidence />}
						/>
						<Route path='/accounts/:id' element={<Account />} />
						<Route
							path='/new-account-evidence/:id'
							element={<NewAccountEvidence />}
						/>
						<Route path='/new-rule' element={<NewRule />} />
						<Route path='/edit-rule/:id' element={<EditRule />} />
						<Route path='/rules/:id' element={<Rule />} />
						<Route path='/users/:id' element={<User />} />
						<Route path='/edit-user/:id' element={<EditUser />} />
						<Route path='/new-ip-evidence/:id' element={<NewIpEvidence />} />
						<Route path='/transactions/:id' element={<Transaction />} />
						<Route path='/change-log' element={<ChangeLog />} />
						<Route path='/actions/:id' element={<Action />} />
						<Route path='/evidence/:id' element={<EvidenceLog />} />
						<Route path='/new-rule-field' element={<NewRuleField />} />
						<Route path='*' element={<PageNotFound />} />
					</Switch>
				</Box>
			</Layout>
		</BrowserRouter>
	);
};

const mapStateToProps = ({ auth }) => ({
	userInfo: auth.userInfo,
	checkTokenStatus: auth.checkTokenStatus,
});

const mapDispatchToProps = (dispatch) => ({
	checkToken: (token) => dispatch(checkToken(token)),
	getRuleKeys: () => dispatch(getRuleKeys),
	getUsers: () => dispatch(getUsers),
	getReasons: () => dispatch(getReasons),
});

export default connect(mapStateToProps, mapDispatchToProps)(Router);
