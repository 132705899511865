import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { FilterProvider, Pagination, DeleteModal } from 'components';
import { DataGrid } from '@mui/x-data-grid';
import getQueryFromSearchParams from 'helpers/getQueryFromSearchParams';
import evidenceLogColumns from 'constants/columns/evidenceLog';
import './styles.css';
import { LOADING } from 'constants/apiStatuses';
import { getDeviceEvidenceHistory } from 'actions/devicesActions';
import {
	changeEvidenceFilterField,
	exportToCSV,
	exportToJSON,
	resetEvidenceLogFilters,
} from '../../redux/actions/evidencesActions';
import EvidenceLogFilters from './EvidenceLogFilters';
import defaultSortModels from 'constants/defaultHeaderSortModels';
import notification from 'helpers/notification';
import LoadingSpinner from '../../components/LoadingSpinner';

const EvidenceLogPage = (props) => {
	const navigate = useNavigate();
	const location = useLocation();
	const filtersQuery = new URLSearchParams(location.search);
	const [activeModal, setActiveModal] = React.useState(null);
	const [tableSortModel, setTableSortModel] = React.useState(
		defaultSortModels.action
	);
	const modalTypes = {
		exportToCSV: 'exportToCSV',
		exportToJSON: 'exportToJSON',
		resetVectorCount: 'resetVectorCount',
	};
	const {
		getDeviceEvidenceHistory,
		evidenceHistory,
		evidenceFilters,
		resetEvidenceLogFilters,
		changeEvidenceFilterField,
		exportEvidenceLogToCSVStatus,
		exportToCSV,
		exportToJSON,
		exportEvidenceLogToJSONStatus,
	} = props;

	const { total, status, data } = evidenceHistory ?? {};

	const exportLimit = process.env.REACT_APP_TRANSACTIONS_EXPORT_LIMIT;

	const handleRowClick = ({ id }) => {
		navigate(`/evidence/${id}`);
	};

	useEffect(() => {
		filtersQuery.delete('tab');
		resetEvidenceLogFilters(getQueryFromSearchParams(filtersQuery.entries()));
	}, []);

	const isExportToJSONTransactions = exportEvidenceLogToJSONStatus === LOADING;
	const isExportToCSVTransactions = exportEvidenceLogToCSVStatus === LOADING;

	const getTableClass = (state) => {
		return `logTable ${status === LOADING ? 'disablePageButton' : ''} ${
			state ? 'removePageButton' : ''
		}`;
	};

	const handleCloseModal = () => {
		setActiveModal(null);
	};
	const handleOpenExportToJSONModal = () => {
		setActiveModal(modalTypes.exportToJSON);
	};
	const handleOpenExportToCSVModal = () => {
		setActiveModal(modalTypes.exportToCSV);
	};
	const handleExportToCSVClick = () => {
		handleExport(exportToCSV);
	};

	const handleExport = (exportFunction) => {
		if (total > exportLimit) {
			handleCloseModal();
			notification.invoke(`Export limit is ${exportLimit} entities.`, {
				variant: 'error',
			});
		} else {
			exportFunction(evidenceFilters, [], handleCloseModal);
		}
	};

	const handleExportToJSONClick = () => {
		handleExport(exportToJSON);
	};

	const renderModal = () => {
		switch (activeModal) {
			case modalTypes.exportToCSV:
				return (
					<DeleteModal
						title='Export evidence log to CSV'
						onClose={handleCloseModal}
						modalBody={`Are you sure want to export ${total} evidence log?`}
						modalHTML={<p>(Limit to export is {exportLimit})</p>}
						onSubmit={handleExportToCSVClick}
						load={isExportToCSVTransactions}
					/>
				);
			case modalTypes.exportToJSON:
				return (
					<DeleteModal
						title='Export evidence log to JSON'
						onClose={handleCloseModal}
						modalBody={`Are you sure want to export ${total} evidence log?`}
						modalHTML={<p>(Limit to export is {exportLimit})</p>}
						onSubmit={handleExportToJSONClick}
						load={isExportToJSONTransactions}
					/>
				);
			default:
				return null;
		}
	};

	return (
		<>
			<Typography variant='h4'>Evidence Log</Typography>
			<Pagination
				getItems={getDeviceEvidenceHistory}
				changeFilterField={changeEvidenceFilterField}
				filters={evidenceFilters}
				initialFilters={getQueryFromSearchParams(filtersQuery.entries())}
				urlToReset='?tab=evidenceLog'
				exception='exception'
				seconds={210}
				resetFilters={resetEvidenceLogFilters}
				setExternalSortModel={setTableSortModel}
			>
				{({
					page,
					handleChangeFilters,
					handleRefresh,
					handleResetFilters,
					pageSize,
					rowsPerPageOptions,
					isArrowBlocked,
					handleHeaderFilterChange,
					sortModel,
					handlePaginationModelChange,
				}) => {
					return (
						<>
							<Box className='filtersContainer'>
								{data && (
									<EvidenceLogFilters
										filters={evidenceFilters}
										handleChangeFilters={handleChangeFilters}
										handleRefresh={() => handleRefresh(pageSize)}
										handleResetFilters={handleResetFilters}
										handleExportToJSONButtonClick={handleOpenExportToJSONModal}
										handleExportToCSVButtonClick={handleOpenExportToCSVModal}
									/>
								)}
							</Box>
							{status === LOADING ? (
								<LoadingSpinner />
							) : (
								data && (
									<FilterProvider changeFilterField={changeEvidenceFilterField}>
										<DataGrid
											autoHeight
											className={getTableClass(isArrowBlocked)}
											disableColumnMenu={true}
											onRowClick={handleRowClick}
											columns={evidenceLogColumns}
											rows={data}
											rowCount={total}
											paginationModel={{ page, pageSize }}
											pageSizeOptions={rowsPerPageOptions}
											onPaginationModelChange={handlePaginationModelChange}
											paginationMode='server'
											sortingMode='server'
											onSortModelChange={handleHeaderFilterChange}
											sortModel={sortModel}
										/>
									</FilterProvider>
								)
							)}
						</>
					);
				}}
			</Pagination>
			{renderModal()}
		</>
	);
};

const mapStateToProps = ({ evidences, devices, users }) => ({
	evidenceHistory: devices.evidenceHistory,
	evidenceFilters: evidences.evidenceFilters,
	users: users.data,
	exportEvidenceLogToJSONStatus: evidences.exportEvidenceLogToJSONStatus,
	exportEvidenceLogToCSVStatus: evidences.exportEvidenceLogToCSVStatus,
});

const mapDispatchToProps = (dispatch) => ({
	getDeviceEvidenceHistory: (body) => {
		dispatch(getDeviceEvidenceHistory(body));
	},
	changeEvidenceFilterField: (value) =>
		dispatch(changeEvidenceFilterField(value)),
	resetEvidenceLogFilters: (body) => dispatch(resetEvidenceLogFilters(body)),
	exportToJSON: (body, sorting, onLoad) =>
		dispatch(exportToJSON(body, sorting, onLoad)),
	exportToCSV: (body, sorting, onLoad) =>
		dispatch(exportToCSV(body, sorting, onLoad)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EvidenceLogPage);
