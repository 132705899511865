import isArray from './isArray';

const operatorToText = {
	'=': 'in',
	'!=': 'NOT in',
};

const ruleToText = (rule, getEvidenceNameById = () => {}) => {
	const checkEvidenceField = (field, value) => {
		if (
			['account.evidence', 'device.evidence', 'ip.evidence'].includes(field)
		) {
			return isArray(value)
				? value.map(getEvidenceNameById).join(', ')
				: getEvidenceNameById(value);
		}
		return isArray(value) ? value.join(', ') : value;
	};
	return rule
		.map((ruleBlock) => {
			let internalRule =
				`${ruleBlock.length > 1 && rule.length > 1 ? ' ( ' : ''}` +
				ruleBlock
					.map(
						({ field, value, operator }) =>
							`${field} ${
								isArray(value) &&
								value.length > 1 &&
								(operator === '=' || operator === '!=')
									? operatorToText[operator]
									: operator
							} ${checkEvidenceField(field, value)}`
					)
					.join(` OR `) +
				`${ruleBlock.length > 1 && rule.length > 1 ? ' ) ' : ''}`;
			return internalRule;
		})
		.join(` AND `);
};

export default ruleToText;
