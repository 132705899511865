import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
	TextField,
	Button,
	InputLabel,
	Select,
	MenuItem,
	FormControl,
	FormHelperText,
	Box,
	FormControlLabel,
	Checkbox,
} from '@mui/material';
import { Field, Form } from 'react-final-form';
import validateEvidenceForm from 'helpers/validations/evidence';
import isSubmitButtonDisabled from 'helpers/isSubmitButtonDisabled';
import { DeleteModal } from 'components';
import './styles.css';
import NewEvidenceTable from './NewEvidenceTable';
import { connect } from 'react-redux';
import { LOADING, NONE } from 'constants/apiStatuses';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { sortReasons } from 'helpers/reasonsMethods';
import { getReasons } from 'actions/reasonsActions';
import { isEqual } from 'lodash';

import WhenFieldChanges from 'components/RuleForm/components/ConditionalField';

const Condition = ({ when, is, children }) => (
	<Field name={when} subscription={{ value: true }}>
		{({ input: { value } }) => (value === is ? children : null)}
	</Field>
);

const NewEvidence = React.memo(
	(props) => {
		const params = useParams();
		const navigate = useNavigate();
		const [modal, setModal] = useState(false);
		const {
			handleSubmit: onSubmit,
			type,
			status,
			tableProps,
			secondTableProps,
			getCheckboxLabel,
			getRadiateToLink,
			hasSecondTable,
			getRadiateToDeviceLink,
			withFilters = false,
			accountEvidenceStatus = NONE,
			deviceEvidenceStatus = NONE,
			ipEvidenceStatus = NONE,
			evidenceTypes,
			getReasons,
		} = props;

		React.useEffect(() => {
			getReasons();
		}, []);

		const isEvidenceCreate =
			accountEvidenceStatus === LOADING ||
			deviceEvidenceStatus === LOADING ||
			ipEvidenceStatus === LOADING;
		const sortedReasons = sortReasons(evidenceTypes, 'name');

		const getModalLocal = (
			withAccounts,
			accounts,
			withDevices = null,
			devices = null
		) => {
			if (withAccounts && withDevices) {
				return `Are you sure you want to radiate evidence to these ${accounts} accounts and ${devices} devices?`;
			} else if (withAccounts) {
				return `Are you sure you want to radiate evidence to these ${accounts} accounts?`;
			} else if (withDevices) {
				return `Are you sure you want to radiate evidence to these ${devices} devices?`;
			}
			return `accounts: ${accounts}, devices: ${devices}`;
		};

		const fillEvidenceEndDate = (values) => {
			if (values.evidenceEndDateInDays) {
				const result = new Date();
				result.setDate(result.getDate() + Number(values.expiresIn));
				return result.toISOString();
			}
			if (values.endDate) {
				return new Date(values.endDate).toISOString();
			}
			return null;
		};

		const handleSubmit = (values) => {
			if (values.radiate || values.radiateDevice) {
				setModal(true);
			} else {
				onSubmit({
					...values,
					...tableProps.filters,
					consumerType: type,
					consumerId: params.id,
					endDate: fillEvidenceEndDate(values),
				});
			}
		};

		const handleCloseModal = () => {
			setModal(false);
		};

		const handleRowClick = ({ id }) => {
			navigate(getRadiateToLink(id));
		};

		const handleDeviceRowClick = ({ id }) => {
			navigate(getRadiateToDeviceLink(id));
		};

		const initialFormState = {
			reason: sortedReasons[0]?.id,
			radiate: false,
			radiateDevice: false,
			endDate: '',
			expiresIn: '',
			evidenceEndDateInDays: false,
		};

		return (
			<Form
				keepDirtyOnReinitialize={false}
				onSubmit={handleSubmit}
				validate={validateEvidenceForm(evidenceTypes)}
				initialValues={initialFormState}
			>
				{({ handleSubmit, errors, touched, values }) => {
					return (
						<form onSubmit={handleSubmit}>
							<Box className='newEvidence'>
								<Field name='createComment'>
									{(props) => (
										<TextField
											error={Boolean(props.meta.touched && props.meta.error)}
											helperText={props.meta.touched && props.meta.error}
											value={props.input.value}
											onChange={props.input.onChange}
											autoComplete='off'
											className='newEviInput'
											label='Comment'
											InputLabelProps={{ shrink: true }}
										/>
									)}
								</Field>
								<Field name='reason'>
									{(props) => (
										<FormControl
											error={Boolean(props.meta.touched && props.meta.error)}
											className='newEviInput'
										>
											<InputLabel id='evidence'>Evidence Reason</InputLabel>
											<Select
												value={props.input.value}
												labelId='evidence'
												onChange={props.input.onChange}
												label='Evidence Reason'
											>
												{sortedReasons.map((evidence) => (
													<MenuItem value={evidence.id} key={evidence.id}>
														{evidence.name}
													</MenuItem>
												))}
											</Select>
											{props.meta.touched && props.meta.error && (
												<FormHelperText>{props.meta.error}</FormHelperText>
											)}
										</FormControl>
									)}
								</Field>
								<WhenFieldChanges
									field='evidenceEndDateInDays'
									becomes={true}
									set='endDate'
									to={null}
								/>
								<Condition when='evidenceEndDateInDays' is={false}>
									<Field name='endDate'>
										{(props) => {
											return (
												<LocalizationProvider dateAdapter={AdapterDateFns}>
													<Box className='pickerInput'>
														<DateTimePicker
															error={Boolean(
																props.meta.error && props.meta.touched
															)}
															format='dd-MM-yyyy HH:mm'
															margin='normal'
															disablePast
															InputLabelProps={{ shrink: true }}
															helperText={
																props.meta.touched && props.meta.error
															}
															value={props.input.value || null}
															onChange={props.input.onChange}
															label='Evidence end date'
															renderInput={(params) => (
																<TextField {...params} />
															)}
														/>
													</Box>
												</LocalizationProvider>
											);
										}}
									</Field>
								</Condition>
								<Condition when='evidenceEndDateInDays' is={true}>
									<Field name='expiresIn'>
										{(props) => (
											<TextField
												error={Boolean(props.meta.touched && props.meta.error)}
												helperText={props.meta.touched && props.meta.error}
												autoComplete='off'
												value={props.input.value}
												name={props.input.name}
												onChange={props.input.onChange}
												className={`pickerInput input`}
												label='Expiration in days'
												InputLabelProps={{ shrink: true }}
											/>
										)}
									</Field>
								</Condition>
								<Field name='evidenceEndDateInDays' type='checkbox'>
									{(props) => (
										<FormControlLabel
											control={
												<Checkbox
													checked={props.input.checked}
													onChange={props.input.onChange}
													color='primary'
													className='checkBox'
												/>
											}
											className='widthHalf'
											label='Evidence end date in days'
										/>
									)}
								</Field>
							</Box>
							<NewEvidenceTable
								tableProps={tableProps}
								withFilters={withFilters}
								handleRowClick={handleRowClick}
								getCheckboxLabel={getCheckboxLabel}
								checkboxName='radiate'
								checkBoxEntity='accounts'
							/>

							{hasSecondTable && (
								<NewEvidenceTable
									tableProps={secondTableProps}
									withFilters={withFilters}
									handleRowClick={handleDeviceRowClick}
									showDataPicker={false}
									getCheckboxLabel={getCheckboxLabel}
									checkboxName='radiateDevice'
									checkBoxEntity='devices'
								/>
							)}

							<div className='newEvidence'>
								<Button
									disabled={isSubmitButtonDisabled(errors, touched, status)}
									type='submit'
									className='newEviInput'
									variant='contained'
									color='primary'
								>
									Create
								</Button>
							</div>
							{modal && (
								<DeleteModal
									title='Radiate evidence'
									onClose={handleCloseModal}
									modalBody={getModalLocal(
										values.radiate,
										tableProps.total,
										values.radiateDevice,
										secondTableProps?.total
									)}
									onSubmit={() => {
										onSubmit({
											...values,
											...tableProps.filters,
											consumerType: type,
											consumerId: params.id,
											endDate: fillEvidenceEndDate(values),
										});
									}}
									load={isEvidenceCreate}
								/>
							)}
						</form>
					);
				}}
			</Form>
		);
	},
	(prev, next) => isEqual(prev, next)
);
const mapStateToProps = ({ accounts, devices, ips, reasons }) => ({
	accountEvidenceStatus: accounts.createEvidenceStatus,
	deviceEvidenceStatus: devices.createEvidenceStatus,
	ipEvidenceStatus: ips.createEvidenceStatus,
	evidenceTypes: reasons.reasons,
});

const mapDispatchToProps = (dispatch) => ({
	getReasons: () => dispatch(getReasons),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewEvidence);
