import { dateFormatter } from 'helpers/formatters';

const getBadEvidences = (evidenceTypes, data) => {
	let reasons = data
		.filter(
			(entityInfo) =>
				!evidenceTypes.find(
					(reason) =>
						reason.name.trim() === entityInfo['evidence.reason'].trim()
				)
		)
		.filter((v, i, a) => {
			const index = a.findIndex((elem) => {
				return elem['evidence.reason'] === v['evidence.reason'];
			});
			return index === i;
		}); // get unique values
	return reasons;
};

const getExpiresDateFormatted = (date) => {
	if (!date) {
		return '-';
	}
	return dateFormatter(date);
};

const normalizeDate = (date) => {
	if (!date) {
		return null;
	}
	let dateToCheck = new Date(Number(date) ? Number(date) : date);
	if (dateToCheck.toString() !== 'Invalid Date') {
		return dateToCheck.toISOString();
	}
	return null;
};

export { getBadEvidences, getExpiresDateFormatted, normalizeDate };
