import axiosInstance from '../index';

const deleteEvidence = (body) => {
	return axiosInstance
		.delete(
			`/evidence/${body.id}?comment=${body.comment}&entityId=${body.entityId}&deleteRadiated=${body.deleteRadiated}}`
		)
		.then((res) => {
			return res.data;
		});
};

export { deleteEvidence };
