const mergeFilters = (filters, initialFilters) => {
	return filters === null
		? initialFilters
		: {
				...initialFilters,
				...filters,
		  };
};

export default mergeFilters;
