import React from 'react';
import { Form, Field } from 'react-final-form';
import { useNavigate } from 'react-router-dom';
import {
	TextField,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Button,
} from '@mui/material';
import { connect } from 'react-redux';
import { withAdmin } from 'components';
import validateIpForm from 'helpers/validations/ip';
import './styles.css';
import { createIp } from 'actions/ipsActions';
import isSubmitButtonDisabled from 'helpers/isSubmitButtonDisabled';

const NewIp = (props) => {
	const navigate = useNavigate();

	const { createIp, createIpStatus } = props;

	const initialValues = {
		ip: '',
		timeZone: '',
		countryName: '',
		countryISOCode: '',
		isp: '',
		isProxy: true,
		isSatellite: true,
	};

	const handleSubmit = (values) => {
		createIp(values, (id) => {
			navigate(`/ips/${id}`);
		});
	};

	return (
		<Form
			onSubmit={handleSubmit}
			initialValues={initialValues}
			validate={validateIpForm}
		>
			{({ handleSubmit, errors, touched }) => {
				return (
					<form className='newEvidence' onSubmit={handleSubmit}>
						<Field name='ip'>
							{(props) => (
								<TextField
									error={Boolean(props.meta.touched && props.meta.error)}
									helperText={props.meta.touched && props.meta.error}
									value={props.input.value}
									onChange={props.input.onChange}
									autoComplete='off'
									className='newInput'
									label='Ip'
									InputLabelProps={{ shrink: true }}
								/>
							)}
						</Field>
						<Field name='timeZone'>
							{(props) => (
								<TextField
									error={Boolean(props.meta.touched && props.meta.error)}
									helperText={props.meta.touched && props.meta.error}
									value={props.input.value}
									onChange={props.input.onChange}
									autoComplete='off'
									className='newInput'
									label='Timezone'
									InputLabelProps={{ shrink: true }}
								/>
							)}
						</Field>
						<Field name='countryName'>
							{(props) => (
								<TextField
									error={Boolean(props.meta.touched && props.meta.error)}
									helperText={props.meta.touched && props.meta.error}
									value={props.input.value}
									onChange={props.input.onChange}
									autoComplete='off'
									className='newInput'
									label='Country name'
									InputLabelProps={{ shrink: true }}
								/>
							)}
						</Field>
						<Field name='countryISOCode'>
							{(props) => (
								<TextField
									error={Boolean(props.meta.touched && props.meta.error)}
									helperText={props.meta.touched && props.meta.error}
									value={props.input.value}
									onChange={props.input.onChange}
									autoComplete='off'
									className='newInput'
									label='Country ISO code'
									InputLabelProps={{ shrink: true }}
								/>
							)}
						</Field>
						<Field name='accuracyRadius'>
							{(props) => (
								<TextField
									error={Boolean(props.meta.touched && props.meta.error)}
									helperText={props.meta.touched && props.meta.error}
									value={props.input.value}
									onChange={props.input.onChange}
									autoComplete='off'
									className='newInput'
									label='Accuracy radius'
									InputLabelProps={{ shrink: true }}
								/>
							)}
						</Field>
						<Field name='locationLat'>
							{(props) => (
								<TextField
									error={Boolean(props.meta.touched && props.meta.error)}
									helperText={props.meta.touched && props.meta.error}
									value={props.input.value}
									onChange={props.input.onChange}
									autoComplete='off'
									className='newInput'
									label='Latitude'
									InputLabelProps={{ shrink: true }}
								/>
							)}
						</Field>
						<Field name='locationLon'>
							{(props) => (
								<TextField
									error={Boolean(props.meta.touched && props.meta.error)}
									helperText={props.meta.touched && props.meta.error}
									value={props.input.value}
									onChange={props.input.onChange}
									autoComplete='off'
									className='newInput'
									label='Longitude'
									InputLabelProps={{ shrink: true }}
								/>
							)}
						</Field>
						<Field name='isp'>
							{(props) => (
								<TextField
									error={Boolean(props.meta.touched && props.meta.error)}
									helperText={props.meta.touched && props.meta.error}
									value={props.input.value}
									onChange={props.input.onChange}
									autoComplete='off'
									className='newInput'
									label='Internet Services Provider'
									InputLabelProps={{ shrink: true }}
								/>
							)}
						</Field>
						<Field name='isProxy'>
							{(props) => (
								<FormControl className='newInput'>
									<InputLabel id='isProxy'>Proxy</InputLabel>
									<Select
										labelId='isProxy'
										value={props.input.value}
										onChange={props.input.onChange}
									>
										<MenuItem value={true}>Yes</MenuItem>
										<MenuItem value={false}>No</MenuItem>
									</Select>
								</FormControl>
							)}
						</Field>
						<Field name='isSatellite'>
							{(props) => (
								<FormControl className='newInput'>
									<InputLabel id='isSatellite'>Satellite</InputLabel>
									<Select
										labelId='isSatellite'
										value={props.input.value}
										onChange={props.input.onChange}
									>
										<MenuItem value={true}>Yes</MenuItem>
										<MenuItem value={false}>No</MenuItem>
									</Select>
								</FormControl>
							)}
						</Field>
						<Button
							type='submit'
							className='newInput'
							variant='contained'
							color='primary'
							disabled={isSubmitButtonDisabled(errors, touched, createIpStatus)}
						>
							Create
						</Button>
					</form>
				);
			}}
		</Form>
	);
};

const mapStateToProps = ({ ips }) => ({
	createIpStatus: ips.createIpStatus,
});

const mapDispatchToProps = (dispatch) => ({
	createIp: (body, onLoad) => dispatch(createIp(body, onLoad)),
});

export default withAdmin(connect(mapStateToProps, mapDispatchToProps)(NewIp));
