import Joi from 'joi';
import { MAX_STRING_LENGTH } from '../../constants/validations';

const validateIpForm = (values) => {
	const ipSchema = Joi.object({
		ip: Joi.string()
			.required()
			.ip({
				version: ['ipv4', 'ipv6'],
				cidr: 'required',
			})
			.messages({
				'string.ip': 'Invalid ip',
				'string.ipVersion': 'Invalid ip version',
				'string.empty': 'Ip is required',
				'any.required': 'Ip is required',
			}),
		accuracyRadius: Joi.number().less(32767).greater(0).messages({
			'number.base': 'Invalid accuracy radius',
			'number.greater': 'Score must be greater than 0',
			'number.less': 'Score must be less than 32767',
		}),
		locationLat: Joi.number()
			.messages({
				'number.base': 'Invalid latitude',
			})
			.custom((value, helpers) => {
				if (value >= -90 && value <= 90) {
					return value;
				}

				return helpers.error('number.base');
			}),
		locationLon: Joi.number()
			.messages({
				'number.base': 'Invalid longitude',
			})
			.custom((value, helpers) => {
				if (value >= -90 && value <= 90) {
					return value;
				}

				return helpers.error('number.base');
			}),
		isProxy: Joi.bool().messages({
			'bool.base': 'Invalid proxy',
		}),
		isSatellite: Joi.bool().messages({
			'bool.base': 'Invalid satellite',
		}),
		isp: Joi.string()
			.max(MAX_STRING_LENGTH)
			.messages({ 'string.max': `Max length is ${MAX_STRING_LENGTH} symbols` })
			.empty(''),
		countryName: Joi.string()
			.max(MAX_STRING_LENGTH)
			.messages({ 'string.max': `Max length is ${MAX_STRING_LENGTH} symbols` })
			.empty(''),
		countryISOCode: Joi.string()
			.max(MAX_STRING_LENGTH)
			.messages({ 'string.max': `Max length is ${MAX_STRING_LENGTH} symbols` })
			.empty(''),
		timeZone: Joi.string()
			.max(MAX_STRING_LENGTH)
			.messages({ 'string.max': `Max length is ${MAX_STRING_LENGTH} symbols` })
			.empty(''),
	});

	const validatedIpSchema = ipSchema.validate(
		{
			ip: values.ip,
			accuracyRadius: values.accuracyRadius,
			locationLat: values.locationLat,
			locationLon: values.locationLon,
			isProxy: values.isProxy,
			isSatellite: values.isSatellite,
			isp: values.isp,
			countryName: values.countryName,
			countryISOCode: values.countryISOCode,
			timeZone: values.timeZone,
		},
		{
			abortEarly: false,
		}
	);

	if (validatedIpSchema.error) {
		return Object.fromEntries(
			validatedIpSchema.error.details.map((detail) => [
				detail.path[0],
				detail.message,
			])
		);
	}

	return {};
};

export default validateIpForm;
