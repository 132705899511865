import CustomAccordion from '../Accordion';
import { TableRow, TableCell } from '@mui/material';
import React from 'react';
import '../styles.css';
import KeyValueTable, { RowValue } from 'components/KeyValueTable';

const AccountToCreate = ({ accountInfo }) => {
	return (
		<CustomAccordion title='Account'>
			<KeyValueTable>
				{accountInfo.id && (
					<TableRow>
						<TableCell>Account id:</TableCell>
						<TableCell className='link'>{accountInfo.id}</TableCell>
					</TableRow>
				)}
				<RowValue oKey='Account name:' oValue={accountInfo.name} />
				{accountInfo.creationDate && (
					<RowValue oKey='Creation date:' oValue={accountInfo.creationDate} />
				)}
				{!!accountInfo.evidence?.length && (
					<RowValue
						oKey='Account evidence:'
						oValue={JSON.stringify(accountInfo.evidence)}
					/>
				)}
			</KeyValueTable>
		</CustomAccordion>
	);
};

export default AccountToCreate;
