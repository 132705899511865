import { CustomTableCell } from 'components';
import React from 'react';
import { dateFormatter } from 'helpers/formatters';

const accountsColumns = [
	{
		field: 'name',
		editable: false,
		headerName: 'Name',
		headerAlign: 'center',
		flex: 1,
		renderCell: (params) => <CustomTableCell filterField='name' {...params} />,
	},
	{
		field: 'id',
		editable: false,
		headerName: 'Account ID',
		headerAlign: 'center',
		flex: 1,
		renderCell: (params) => (
			<CustomTableCell
				filterField='id'
				getNewTabLink={(id) => `${window.location.origin}/accounts/${id}`}
				{...params}
			/>
		),
	},
	{
		field: 'lastSeen',
		editable: false,
		headerName: 'Last Seen',
		headerAlign: 'center',
		flex: 1,
		renderCell: (params) => {
			return (
				<CustomTableCell {...params} value={dateFormatter(params.value)} />
			);
		},
	},
	{
		field: 'result',
		headerName: 'Result',
		width: 80,
		hideSortIcons: true,
		sortable: false,
		headerAlign: 'center',
		valueGetter: () => ' ',
		renderCell: () => <span className={'scoreIndicator'} />,
	},
];

export default accountsColumns;
