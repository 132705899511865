import React from 'react';
import './styles.css';
import { Box, Typography, useTheme } from '@mui/material';
import { Pie } from 'react-chartjs-2';
import { DataGrid } from '@mui/x-data-grid';
import { getRowColorByMark } from 'helpers/getTableRowColor';
import fpStatsChart from '../../../constants/columns/fpStatsChart';
import generateDatasetConfigForPieChart from 'helpers/generateDatasetConfigForPieChart';

const FpStatsGraph = ({ data }) => {
	const theme = useTheme();

	const chartConfig = generateDatasetConfigForPieChart({
		labels: data?.label,
		data: data?.value,
	});

	const pieLegendColor = { labels: { color: theme.palette.text.secondary } };

	const pieChartOptions = {
		plugins: {
			tooltip: {
				callbacks: {
					label: (tooltipModel) => `${tooltipModel?.formattedValue ?? ''}`,
				},
			},
			legend: {
				display: false,
				...pieLegendColor,
			},
		},
	};

	const dataRows = data?.label.map((label, index) => ({
		id: label,
		value: data?.value[index],
		percent: data?.percent[index],
	}));

	return (
		<Box
			display='flex'
			justifyContent='center'
			alignItems='center'
			flexDirection='column'
			className='pieChartMain'
			gap={2}
		>
			<Typography variant='h5' align='left' width='100%'>
				{data?.title}
			</Typography>
			<Box display='grid' gridTemplateColumns='2fr 1.5fr' width='100%'>
				<Box
					display='flex'
					justifyContent='center'
					alignItems='center'
					className='pieChart'
				>
					<Pie width='100%' data={chartConfig} options={pieChartOptions} />
				</Box>
				<DataGrid
					autoHeight
					disableColumnMenu={true}
					columns={fpStatsChart}
					getRowClassName={getRowColorByMark}
					rows={dataRows}
					hideFooter
				/>
			</Box>
		</Box>
	);
};

export default FpStatsGraph;
