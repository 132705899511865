import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
	Box,
	Select,
	Typography,
	FormControl,
	InputLabel,
	MenuItem,
	Card,
	Grid,
	CardHeader,
	CardContent,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Pie, Bar } from 'react-chartjs-2';
import { getGeneralInfo } from 'actions/serverStatsActions';
import { LOADING } from 'constants/apiStatuses';
import generateDatasetConfigForPieChart from 'helpers/generateDatasetConfigForPieChart';
import './styles.css';
import {
	Chart,
	ArcElement,
	Tooltip,
	BarController,
	CategoryScale,
	LinearScale,
	BarElement,
	Legend,
} from 'chart.js';
import KeyValueTable, { RowValue } from 'components/KeyValueTable';

Chart.register(
	ArcElement,
	Tooltip,
	BarController,
	CategoryScale,
	LinearScale,
	BarElement,
	Legend
);

const ServerStats = (props) => {
	const theme = useTheme();
	const navigate = useNavigate();

	const { getGeneralInfo, getGeneralInfoStatus, generalInfo } = props;

	const [time, setTime] = useState(0);

	const pieTextColor = { color: theme.palette.text.secondary };
	const legendColor = { plugins: { legend: { labels: { ...pieTextColor } } } };

	const generateScoreQuery = (score) => {
		if (score === -100) {
			return `&maxScore=-100`;
		}
		if (score > -100 && score < 0) {
			return `&score=-99&maxScore=-1`;
		}
		if (score === 0) {
			return `&score=0`;
		}
	};

	const handleChangeTime = (e) => {
		setTime(e.target.value);
		getGeneralInfo(e.target.value);
	};

	const handleBarPartClick = (event, elements, chart) => {
		const currentDate = new Date();
		const sixMinutes = new Date(currentDate.getTime() - 360000);
		const fifteenMinutes = new Date(currentDate.getTime() - 900000);
		const oneHour = new Date(currentDate.getTime() - 3600000);
		const filterConfig = {
			0: {
				0: [-100, sixMinutes],
				1: [-1, sixMinutes],
				2: [0, sixMinutes],
			},
			1: {
				0: [-100, fifteenMinutes],
				1: [-1, fifteenMinutes],
				2: [0, fifteenMinutes],
			},
			2: {
				0: [-100, oneHour],
				1: [-1, oneHour],
				2: [0, oneHour],
			},
		};
		const [score] = generalInfo.transactionsCnt.initial
			? filterConfig[elements[0].index][elements[0].datasetIndex]
			: filterConfig[0][elements[0].datasetIndex];

		if (generalInfo.transactionsCnt.initial) {
			const [, minCreationDate] =
				filterConfig[elements[0].index][elements[0].datasetIndex];
			navigate(
				`/?tab=transactions${generateScoreQuery(
					score
				)}&minCreationDate=${minCreationDate.toISOString()}&maxCreationDate=${currentDate.toISOString()}`
			);
		} else {
			const offsetTime = new Date(currentDate.getTime() - time * 60000);
			navigate(
				`/?tab=transactions${generateScoreQuery(
					score
				)}&minCreationDate=${offsetTime.toISOString()}&maxCreationDate=${currentDate.toISOString()}`
			);
		}
	};

	useEffect(() => {
		getGeneralInfo(0);
	}, []);

	const labels = {
		0: '1 hour',
		60: '1 hour',
		360: '6 hours',
		1440: '24 hours',
		2880: '2 days',
	};

	const getGeneralInfoData = (index) => {
		return [
			generalInfo.transactionsCnt.data[0][index],
			generalInfo.transactionsCnt.data[1][index],
			generalInfo.transactionsCnt.data[2][index],
		];
	};

	const getTransactionsCountConfig = () => {
		return generalInfo.transactionsCnt.initial
			? {
					labels: ['Last 6 minutes', 'Last 15 minutes', 'Last 60 minutes'],
					datasets: [
						{
							label: '<=-100',
							data: getGeneralInfoData(0),
							backgroundColor: '#f00',
							stack: true,
						},
						{
							label: '-100 < x < 0',
							data: getGeneralInfoData(1),
							backgroundColor: '#ff0',
							stack: true,
						},
						{
							label: '>=0',
							data: getGeneralInfoData(2),
							backgroundColor: '#0f0',
							stack: true,
						},
					],
			  }
			: {
					labels: [labels[time]],
					type: 'bar',
					datasets: [
						{
							label: '<=-100',
							data: [generalInfo.transactionsCnt[0]],
							backgroundColor: '#f00',
							stack: true,
						},
						{
							label: '-100 < x < 0',
							data: [generalInfo.transactionsCnt[1]],
							backgroundColor: '#ff0',
							stack: true,
						},
						{
							label: '>=0',
							data: [generalInfo.transactionsCnt[2]],
							backgroundColor: '#0f0',
							stack: true,
						},
					],
					options: {
						plugins: {
							title: {
								display: true,
							},
						},
						responsive: true,
					},
			  };
	};

	const transactionsChartOptions = {
		...legendColor,
		scales: {
			xAxes: { ticks: { ...pieTextColor } },
			yAxes: { ticks: { ...pieTextColor } },
		},
		onClick(event, elements, chart) {
			if (elements.length) {
				handleBarPartClick(event, elements, chart);
			}
		},
	};

	const pageTitle = <Typography variant='h5'>Server stats</Typography>;
	const pageActions = (
		<FormControl className='serverSelect'>
			<InputLabel id='time'>Time</InputLabel>
			<Select
				labelId='time'
				label='Time'
				value={time}
				onChange={handleChangeTime}
				variant='standard'
			>
				<MenuItem key='None' value='0'>
					By Range
				</MenuItem>
				{Object.entries(labels)
					.filter(([value]) => value !== '0')
					.map(([value, key]) => (
						<MenuItem key={key} value={value}>
							{key}
						</MenuItem>
					))}
			</Select>
		</FormControl>
	);

	const renderContent = generalInfo && getGeneralInfoStatus !== LOADING;

	return (
		<Card>
			<CardHeader title={pageTitle} action={pageActions} />
			<CardContent>
				{getGeneralInfoStatus === LOADING && <div>Loading...</div>}
			</CardContent>
			<CardContent>
				{renderContent && (
					<>
						<Typography variant='h5'>Transactions</Typography>
						<CardContent className='serverInfoBlock'>
							<Box className='barChart'>
								<Bar
									data={getTransactionsCountConfig()}
									options={transactionsChartOptions}
								/>
							</Box>
							<KeyValueTable classes='totalTransactions'>
								<RowValue
									oKey={`Total transactions in the last ${labels[time]}:`}
									oValue={generalInfo.totalTransactionsCountByRange}
								/>
							</KeyValueTable>
						</CardContent>
					</>
				)}
			</CardContent>
			<CardContent>
				{renderContent && (
					<>
						<Typography variant='h5'>Other info</Typography>
						<KeyValueTable classes='serverCustomTable'>
							<RowValue
								oKey={`Devices in the last ${labels[time]}:`}
								oValue={generalInfo.devicesCnt}
							/>
							<RowValue
								oKey={`Accounts in the last ${labels[time]}:`}
								oValue={generalInfo.accountsCnt}
							/>
							<RowValue
								oKey={`Average score in the last ${labels[time]}:`}
								oValue={generalInfo.scoreAvg}
							/>
							<RowValue
								oKey={`Max score in the last ${labels[time]}:`}
								oValue={generalInfo.scoreMax}
							/>
							<RowValue
								oKey={`Min score in the last ${labels[time]}:`}
								oValue={generalInfo.scoreMin}
							/>
							<RowValue
								oKey={`Score sum in the last ${labels[time]}:`}
								oValue={generalInfo.scoreSum}
							/>
						</KeyValueTable>
					</>
				)}
			</CardContent>
			<CardContent>
				{renderContent && (
					<Grid container>
						<Grid item xs={5}>
							<Typography variant='h5' className='counter'>
								Countries
							</Typography>
							<Box className='pieChart'>
								<Pie
									data={generateDatasetConfigForPieChart({
										labels: Object.keys(generalInfo.countries),
										data: Object.values(generalInfo.countries),
									})}
									options={legendColor}
								/>
							</Box>
						</Grid>
						<Grid item xs={5}>
							<Typography variant='h5' className='counter'>
								Rule sets
							</Typography>
							<Box className='pieChart'>
								<Pie
									data={generateDatasetConfigForPieChart({
										labels: Object.keys(generalInfo.ruleSets),
										data: Object.values(generalInfo.ruleSets),
									})}
									options={legendColor}
								/>
							</Box>
						</Grid>
					</Grid>
				)}
			</CardContent>
			<CardContent>
				Maxmind: XXX
				<br />
				Oldest Device: XXX
				<br />
				Oldest Account: XXX
				<br />
				Oldest Action: XXX
			</CardContent>
		</Card>
	);
};

const mapStateToProps = ({ serverStats }) => ({
	getGeneralInfoStatus: serverStats.getGeneralInfoStatus,
	generalInfo: serverStats.generalInfo,
});

const mapDispatchToProps = (dispatch) => ({
	getGeneralInfo: (seconds) => dispatch(getGeneralInfo(seconds)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ServerStats);
