// todo hardcode fields, need to be refactored
const normalizeHeaderSortField = (field) => {
	switch (field) {
		case 'id':
			return '_id';
		case 'result':
			return null;
		case 'score':
			return 'rating.score';
		case 'ruleSet':
			return 'rating.ruleSet';
		case 'deviceId':
			return 'device.id';
		case 'country':
			return 'session.ipEnrichment.countryName';
		case 'accountName':
			return 'account.name';
		case 'fpAgeInMs':
			return 'session.fpAgeInMs';
		case 'serverTime':
			return 'session.serverTime';
		case 'connectionIP':
			return 'session.connectionIP';
		case 'username':
			return 'user';
		default:
			return field;
	}
};

export default normalizeHeaderSortField;
