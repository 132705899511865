import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import mostUsedRulesColumns from 'constants/columns/mostUsedRules';
import ReportsPage from 'components/ReportPage';
import { getMostUsedRules } from 'actions/serverStatsActions';
import generateDatasetConfigForPieChart from 'helpers/generateDatasetConfigForPieChart';

const MostUsedRuleInfo = (props) => {
	const navigate = useNavigate();
	const { getMostUsedRulesInfoStatus, mostUsedRulesInfo, getMostUsedRules } =
		props;

	const handleRowClick = (params) => {
		navigate(`/?tab=transactions&ruleName=${params.row.name}`);
	};

	const handleChartPartClick = (info) => {
		navigate(`/?tab=transactions&ruleName=${info.name}`);
	};

	return (
		<ReportsPage
			columns={mostUsedRulesColumns}
			getInfo={getMostUsedRules}
			data={mostUsedRulesInfo}
			title='Most used rules over last 96 hours'
			status={getMostUsedRulesInfoStatus}
			DataGridProps={{
				onRowClick: handleRowClick,
			}}
			chartConfig={generateDatasetConfigForPieChart({
				labels: mostUsedRulesInfo?.map(({ name }) => name),
				data: mostUsedRulesInfo?.map(({ count }) => count),
			})}
			onChartPartClick={handleChartPartClick}
		/>
	);
};

const mapStateToProps = ({ serverStats }) => ({
	getMostUsedRulesInfoStatus: serverStats.getMostUsedRulesInfoStatus,
	mostUsedRulesInfo: serverStats.mostUsedRulesInfo,
});

const mapDispatchToProps = (dispatch) => ({
	getMostUsedRules: () => dispatch(getMostUsedRules),
});

export default connect(mapStateToProps, mapDispatchToProps)(MostUsedRuleInfo);
