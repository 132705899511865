import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import {
	Checkbox,
	FormControlLabel,
	TextField,
	Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Field } from 'react-final-form';
import React from 'react';
import './styles.css';
import { Pagination } from '../../index';
import { isEqual } from 'lodash';

const NewEvidenceTable = React.memo(
	({
		tableProps,
		withFilters,
		showDataPicker = true,
		handleRowClick,
		getCheckboxLabel,
		checkboxName,
		checkBoxEntity,
	}) => {
		const getTableClass = (state) => {
			return `newEviTable ${state ? 'removePageButton' : ''}`;
		};

		const tableColumns = tableProps.columns.filter(
			(elem) => elem.field !== 'result'
		);

		return (
			<>
				<Pagination
					getItems={tableProps.getItems}
					{...(withFilters
						? {
								filters: tableProps.filters,
								changeFilterField: tableProps.changeFilterField,
								initialFilters: tableProps.filters,
						  }
						: {})}
					defaultSortModel={tableProps.defaultSortModel}
					exception='exception'
				>
					{({
						page,
						handleChangeFilters,
						pageSize,
						rowsPerPageOptions,
						isArrowBlocked,
						handleHeaderFilterChange,
						sortModel,
						handlePaginationModelChange,
					}) => {
						return (
							<>
								{showDataPicker && withFilters && (
									<LocalizationProvider dateAdapter={AdapterDateFns}>
										<DateTimePicker
											value={tableProps.filters.lastSeen}
											format='dd-MM-yyyy HH:mm'
											margin='normal'
											className='datePicker'
											onChange={(value) =>
												handleChangeFilters({
													target: {
														name: 'lastSeen',
														value,
													},
												})
											}
											label='Last seen from'
											renderInput={(params) => (
												<TextField
													{...params}
													className={`pickerInput input`}
												/>
											)}
										/>
									</LocalizationProvider>
								)}
								<Typography variant='h6' className='newEviTitle'>
									{tableProps.title}
								</Typography>
								{tableProps.data ? (
									<DataGrid
										onRowClick={handleRowClick}
										rowCount={tableProps.total}
										disableColumnMenu={true}
										className={getTableClass(isArrowBlocked)}
										autoHeight
										columns={tableColumns}
										paginationModel={{ page, pageSize }}
										pageSizeOptions={rowsPerPageOptions}
										onPaginationModelChange={handlePaginationModelChange}
										paginationMode='server'
										rows={tableProps.data}
										sortingMode='server'
										onSortModelChange={handleHeaderFilterChange}
										sortModel={sortModel}
									/>
								) : null}
							</>
						);
					}}
				</Pagination>
				{tableProps.total !== 0 ? (
					<Field name={checkboxName} type='checkbox'>
						{(props) => (
							<FormControlLabel
								className='radiateCheckbox'
								control={
									<Checkbox
										checked={props.input.checked}
										onChange={props.input.onChange}
										color='primary'
									/>
								}
								label={getCheckboxLabel(tableProps.total, checkBoxEntity)}
							/>
						)}
					</Field>
				) : null}
			</>
		);
	},
	(prev, next) => isEqual(prev, next)
);
export default NewEvidenceTable;
