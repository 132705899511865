import React from 'react';
import { connect } from 'react-redux';
import {
	Box,
	Table,
	TableBody,
	TableHead,
	TableCell,
	TableRow,
	Button,
	TextField,
	Typography,
} from '@mui/material';
import { StripedTableRow, DeleteModal } from 'components';
import './styles.css';
import {
	deleteReason,
	createReason,
	getReasons,
	editReason,
} from 'actions/reasonsActions';
import { sortReasons } from 'helpers/reasonsMethods';
import notification from 'helpers/notification';
import { MAX_STRING_LENGTH } from '../../constants/validations';

const ReasonPage = (props) => {
	const {
		evidenceTypes,
		deleteReason,
		createReason,
		getReasons,
		editReason,
		userInfo,
	} = props;

	const sortedReasons = sortReasons(evidenceTypes, 'name');

	const [reasonToUpdate, setReasonToUpdate] = React.useState('');
	const [reasonToDelete, setReasonToDelete] = React.useState('');
	const [reasonToCreate, setReasonToCreate] = React.useState(false);

	const [newReasonName, setNewReasonName] = React.useState('');

	React.useEffect(() => {
		getReasons();
	}, []);

	const isReasonNameOK = (value) => {
		const name = value.trim();
		if (!name) {
			notification.invoke(`Reason can't be empty`, {
				variant: 'error',
			});
			return false;
		}
		if (name.length > MAX_STRING_LENGTH) {
			notification.invoke(`Max length is ${MAX_STRING_LENGTH} symbols`, {
				variant: 'error',
			});
			return false;
		}
		const isReasonExist = !!evidenceTypes.find((elem) => elem.name === name);
		if (isReasonExist) {
			notification.invoke(`Reason ${name} already exist`, {
				variant: 'error',
			});
			return false;
		}
		return true;
	};
	const handleReasonToUpdateModalOpen = (reasonId) => {
		setReasonToUpdate(reasonId);
	};
	const handleReasonToUpdateModalClose = () => {
		setReasonToUpdate('');
	};
	const handleReasonToUpdateSubmit = () => {
		if (isReasonNameOK(newReasonName)) {
			editReason(
				{ id: reasonToUpdate, name: newReasonName },
				handleReasonToUpdateModalClose
			);
		}
	};

	const handleReasonToDeleteModalClose = () => {
		setReasonToDelete('');
	};
	const handleReasonToDeleteSubmit = () => {
		deleteReason(reasonToDelete, handleReasonToDeleteModalClose);
	};

	const handleReasonToCreateModalOpen = () => {
		setNewReasonName('');
		setReasonToCreate(true);
	};
	const handleReasonToCreateModalClose = () => {
		setReasonToCreate(false);
	};
	const handleReasonToCreateSubmit = () => {
		if (isReasonNameOK(newReasonName)) {
			createReason({ name: newReasonName }, handleReasonToCreateModalClose);
		}
	};

	const handleNewReasonNameChange = (e) => {
		setNewReasonName(e.target.value);
	};

	const isUserAdmin = userInfo.role === 'admin';
	const updateModalButtons = { accept: 'Save', cancel: 'Cancel' };
	const modalHTML = (
		<TextField
			autoFocus
			value={newReasonName}
			onChange={handleNewReasonNameChange}
			margin='dense'
			id='name'
			label='Reason name'
			type='text'
			fullWidth
			variant='standard'
		/>
	);

	const createModalButtons = { accept: 'Create', cancel: 'Cancel' };
	const deleteModalButtons = { accept: 'Yes', cancel: 'No' };

	return (
		<Box>
			<Typography variant='h4'>Reasons</Typography>
			{isUserAdmin && (
				<Button
					color='primary'
					variant='contained'
					className='addReason'
					onClick={handleReasonToCreateModalOpen}
				>
					Add Reason
				</Button>
			)}

			{sortedReasons && (
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Reason name</TableCell>
							<TableCell>Reason Id</TableCell>
							{isUserAdmin && <TableCell>Edit</TableCell>}
						</TableRow>
					</TableHead>
					<TableBody>
						{sortedReasons.map((reason) => (
							<StripedTableRow key={reason.id}>
								<TableCell>{reason.name}</TableCell>
								<TableCell>{reason.id}</TableCell>
								{isUserAdmin && (
									<TableCell>
										<Button
											variant='contained'
											color='primary'
											onClick={() => {
												setNewReasonName(reason.name);
												handleReasonToUpdateModalOpen(reason.id);
											}}
										>
											Edit
										</Button>
									</TableCell>
								)}
							</StripedTableRow>
						))}
					</TableBody>
				</Table>
			)}
			{reasonToUpdate && (
				<DeleteModal
					title='Update reason name'
					onClose={handleReasonToUpdateModalClose}
					onSubmit={handleReasonToUpdateSubmit}
					modalBody='Enter new reason name'
					load={false}
					buttons={updateModalButtons}
					modalHTML={modalHTML}
				/>
			)}
			{reasonToCreate && (
				<DeleteModal
					title='Create new reason'
					onClose={handleReasonToCreateModalClose}
					onSubmit={handleReasonToCreateSubmit}
					modalBody='Enter new reason name'
					load={false}
					buttons={createModalButtons}
					modalHTML={modalHTML}
				/>
			)}
			{reasonToDelete && (
				<DeleteModal
					title='Delete reason'
					onClose={handleReasonToDeleteModalClose}
					onSubmit={handleReasonToDeleteSubmit}
					modalBody={`Are you sure want to delete reason "${newReasonName}"`}
					load={false}
					buttons={deleteModalButtons}
				/>
			)}
		</Box>
	);
};

const mapStateToProps = ({ reasons, auth }) => ({
	evidenceTypes: reasons.reasons,
	userInfo: auth.userInfo,
});

const mapDispatchToProps = (dispatch) => ({
	deleteReason: (id, onLoad) => dispatch(deleteReason(id, onLoad)),
	createReason: (reason, onLoad) => dispatch(createReason(reason, onLoad)),
	editReason: (reason, onLoad) => dispatch(editReason(reason, onLoad)),
	getReasons: () => dispatch(getReasons),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReasonPage);
