import { PureTableCell } from 'components';
import accountsColumns from './accounts';
import React from 'react';

const accountsColumnsCopy = [...accountsColumns];
const last = accountsColumnsCopy.pop();
const deviceAccountsColumn = [
	...accountsColumnsCopy,
	{
		field: 'count',
		editable: false,
		sortable: false,
		headerName: 'Transactions',
		headerAlign: 'center',
		width: 150,
		renderCell: (params) => <PureTableCell {...params} />,
	},
	last,
];

export default deviceAccountsColumn;
