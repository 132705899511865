import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getIpsInfo } from 'actions/serverStatsActions';
import ReportsPage from 'components/ReportPage';
import ipsWithMostAccounts from 'constants/columns/ipsWithMostAccounts';
import generateDatasetConfigForPieChart from 'helpers/generateDatasetConfigForPieChart';

const IpsInfo = (props) => {
	const { getIpsInfo, ipsInfo, getIpsInfoStatus } = props;
	const navigate = useNavigate();

	const handleRowClick = (params) => {
		navigate(`/?tab=transactions&sessionIp=${params.row.ip}`);
	};

	const handleChartPartClick = (info) => {
		navigate(`/?tab=transactions&sessionIp=${info.ip}`);
	};

	return (
		<ReportsPage
			getInfo={getIpsInfo}
			data={ipsInfo}
			status={getIpsInfoStatus}
			onChartPartClick={handleChartPartClick}
			DataGridProps={{
				onRowClick: handleRowClick,
			}}
			chartConfig={generateDatasetConfigForPieChart({
				labels: ipsInfo?.map(({ ip }) => ip),
				data: ipsInfo?.map(({ accountsCount }) => accountsCount),
			})}
			title='Ips with most accounts over last 96 hours'
			columns={ipsWithMostAccounts}
		/>
	);
};

const mapStateToProps = ({ serverStats }) => ({
	ipsInfo: serverStats.ipsInfo,
	getIpsInfoStatus: serverStats.getIpsInfoStatus,
});

const mapDispatchToProps = (dispatch) => ({
	getIpsInfo: () => dispatch(getIpsInfo),
});

export default connect(mapStateToProps, mapDispatchToProps)(IpsInfo);
