import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { theme as themeCore, getDarkTheme } from '../../theme';
window.theme = themeCore;

const themeConverter = {
	light: 'dark',
	dark: 'light',
	pink: 'light',
};

export const ColorModeContext = React.createContext({
	toggleColorMode: () => {},
});

function ThemeContext({ children }) {
	const [mode, setMode] = React.useState('light');

	const isThemeOK = (theme) => {
		return Object.keys(themeConverter).includes(theme);
	};
	const colorMode = React.useMemo(
		() => ({
			toggleColorMode: (data) => {
				let newTheme = data;
				if (typeof data !== 'string') {
					// filter SyntheticBaseEvent from button
					newTheme = data.ctrlKey ? 'pink' : themeConverter[mode];
				} else if (!isThemeOK(data)) {
					newTheme = themeConverter.light;
				}
				localStorage.setItem('rating_app_theme', newTheme);
				setMode(newTheme);
			},
		}),
		[mode]
	);

	const theme = React.useMemo(
		() => createTheme(themeCore, getDarkTheme(mode)),
		[mode]
	);

	return (
		<ColorModeContext.Provider value={colorMode}>
			<ThemeProvider theme={theme}>{children}</ThemeProvider>
		</ColorModeContext.Provider>
	);
}

export default ThemeContext;
