const destroyRulesIds = (rules) => {
	return rules.map((ruleBlock) =>
		ruleBlock.map(({ id, value, ...rule }) => ({
			...rule,
			[value.length === 1 ? 'value' : 'values']:
				value.length === 1 ? value[0].value : value.map(({ value }) => value),
		}))
	);
};

export default destroyRulesIds;
