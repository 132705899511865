import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
} from '@mui/material';
import React from 'react';
import '../../styles.css';
import { numberFormatter } from 'helpers/formatters';

const MatchingRulesTable = ({ matchingRules }) => {
	const tooltipClasses = { tooltip: 'tooltip' };
	return (
		<TableContainer component={Paper} className='matchingRulesContainer'>
			<Table className='transTable'>
				<TableHead>
					<TableRow className='transTableRow'>
						<TableCell className='tableName'>Name</TableCell>
						<TableCell className='tableScore'>Score</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{matchingRules.map(({ name, score }) => (
						<TableRow key={`${name}_${score}`}>
							<Tooltip title={name} classes={tooltipClasses}>
								<TableCell className='nameCell'>{name}</TableCell>
							</Tooltip>
							<TableCell>{numberFormatter(score)}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default MatchingRulesTable;
