import React from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { createRuleField } from 'actions/rulesActions';
import { RuleFieldForm } from 'components';

const NewRuleField = (props) => {
	const navigate = useNavigate();

	const { createRuleField, createRuleFieldStatus } = props;

	const handleSubmit = (values) => {
		createRuleField(values, () => {
			navigate('/rule-fields');
		});
	};

	return (
		<RuleFieldForm
			handleSubmit={handleSubmit}
			buttonLabel='Create rule field'
			status={createRuleFieldStatus}
		/>
	);
};

const mapStateToProps = ({ rules }) => ({
	createRuleFieldStatus: rules.createRuleFieldStatus,
});

const mapDispatchToProps = (dispatch) => ({
	createRuleField: (body, onLoad) => dispatch(createRuleField(body, onLoad)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewRuleField);
