import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import {
	createEvidence,
	getAccountsIdByAccount,
	getAccountsForEvidence,
	changeAccountsForEvidenceFilterField,
} from 'actions/accountsActions';
import accountsColumns from 'constants/columns/accounts';
import devicesColumns from 'constants/columns/devices';
import { NewEvidence } from 'components';
import { LOADING } from 'constants/apiStatuses';
import { getDevicesAttachedToAccounts } from 'actions/accountsActions';
import defaultSortModels from 'constants/defaultHeaderSortModels';

const NewAccountEvidence = (props) => {
	const {
		createEvidence,
		createEvidenceStatus,
		accountsForEvidence,
		getAttachedAccountsStatus,
		attachedAccounts,
		getAccountsIdByAccount,
		getAccountsForEvidence,
		singleAccountDevices,
		getDevicesAttachedToAccounts,
		attachedDevices,
		changeFilterField,
		filters,
	} = props;

	const navigate = useNavigate();
	const { id } = useParams();

	useEffect(() => {
		getAccountsIdByAccount(id);
	}, []);

	const filteredAccountColumns = accountsColumns.filter(
		(column) => column.field !== 'result'
	);
	const filteredDevicesColumns = devicesColumns.filter(
		(column) => column.field !== 'result'
	);
	const handleSubmit = React.useCallback(
		(values) => {
			createEvidence(
				{
					...values,
					radiateAs: 'devices',
					radiateConsumerType: 'account',
					radiatedByType: 'account',
				},
				() => navigate(`/accounts/${id}`)
			);
		},
		[id]
	);

	const getAccountsItems = React.useCallback(
		(params) =>
			getAccountsForEvidence({
				...params,
				filters: {
					...params.filters,
					accountsId: attachedAccounts,
				},
			}),
		[attachedAccounts]
	);

	const getDevicesItems = React.useCallback(
		(params) =>
			getDevicesAttachedToAccounts({
				...params,
				devicesId: attachedDevices,
			}),
		[attachedDevices]
	);
	const getCheckboxLabel = React.useCallback(
		(total, entity) => `Radiate evidence to these ${total} ${entity}`,
		[]
	);

	const getRadiateToLink = React.useCallback((id) => `/accounts/${id}`, []);

	const getRadiateToDeviceLink = React.useCallback(
		(id) => `/devices/${id}`,
		[]
	);

	if (getAttachedAccountsStatus === LOADING) {
		return <div>Loading...</div>;
	}

	const accountsTableProps = {
		title: 'Accounts,attached to account',
		data: accountsForEvidence.data,
		total: accountsForEvidence.total,
		columns: filteredAccountColumns,
		changeFilterField,
		filters,
		getItems: getAccountsItems,
		defaultSortModel: defaultSortModels.account,
	};

	const devicesTableProps = {
		title: 'Devices,attached to account',
		data: singleAccountDevices.data,
		total: singleAccountDevices.total,
		columns: filteredDevicesColumns,
		changeFilterField,
		filters,
		getItems: getDevicesItems,
		defaultSortModel: defaultSortModels.device,
	};

	return (
		<NewEvidence
			withFilters={true}
			tableProps={accountsTableProps}
			secondTableProps={devicesTableProps}
			hasSecondTable
			getCheckboxLabel={getCheckboxLabel}
			getRadiateToLink={getRadiateToLink}
			getRadiateToDeviceLink={getRadiateToDeviceLink}
			status={createEvidenceStatus}
			handleSubmit={handleSubmit}
			type='account'
		/>
	);
};

const mapStateToProps = ({ accounts }) => ({
	createEvidenceStatus: accounts.createEvidenceStatus,
	accountsForEvidence: accounts.accountsForEvidence,
	attachedAccounts: accounts.attachedAccounts,
	getAttachedAccountsStatus: accounts.getAttachedAccountsStatus,
	singleAccountDevices: accounts.singleAccountDevices,
	attachedDevices: accounts.attachedDevices,
	filters: accounts.accountsFilterForEvidence,
});

const mapDispatchToProps = (dispatch) => ({
	createEvidence: (evidence, onLoad) =>
		dispatch(createEvidence(evidence, onLoad)),
	getAccountsForEvidence: (body) => dispatch(getAccountsForEvidence(body)),
	getDevicesAttachedToAccounts: (body) =>
		dispatch(getDevicesAttachedToAccounts(body)),
	getAccountsIdByAccount: (id) => dispatch(getAccountsIdByAccount(id)),
	changeFilterField: (value) =>
		dispatch(changeAccountsForEvidenceFilterField(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewAccountEvidence);
