import React from 'react';
import { Box, Typography, Paper, TextField, Button } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';
import { authorize, checkToken, gameforgeAuthorize } from 'actions/authActions';
import './styles.css';
import notification from 'helpers/notification';

const Login = (props) => {
	const navigate = useNavigate();
	const location = useLocation();
	const localLogin = document.LOCAL_LOGIN;
	const { authorize, gameforgeAuthorize, checkToken } = props;

	const onSuccessAuthorization = () => {
		const urlParams = new URLSearchParams(window.location.search);
		const lastVisitedPage = urlParams.get('lastVisitedPage');

		if (lastVisitedPage) {
			// Redirect to the last visited page
			window.location.href = lastVisitedPage;
		} else {
			navigate('/?tab=transactions');
		}
	};
	const handleAuthorize = (values) => {
		authorize(values, onSuccessAuthorization);
	};

	const handleGameforgeAuthorization = () => {
		gameforgeAuthorize({}, onSuccessAuthorization);
	};

	React.useEffect(() => {
		const params = new URLSearchParams(location.search);
		const error = params.get('error');
		const token = params.get('token');

		if (error) {
			notification.invoke(error, {
				variant: 'error',
			});
		} else if (token) {
			checkToken(token);
			params.delete('token');
			const url = location.pathname + params.toString();
			navigate(url);
		}
	}, []);

	return (
		<Box
			display='flex'
			alignItems='center'
			justifyContent='center'
			height='100vh'
		>
			<Paper className='loginContainer'>
				<Typography variant='h4'>Login</Typography>
				<Form
					onSubmit={handleAuthorize}
					render={({ handleSubmit }) => (
						<form onSubmit={handleSubmit}>
							{localLogin ? (
								<>
									<Field name='username'>
										{(props) => (
											<TextField
												autoComplete='off'
												label='Username'
												name={props.input.name}
												value={props.input.value}
												onChange={props.input.onChange}
												className='loginInput'
												InputLabelProps={{
													shrink: true,
												}}
												fullWidth
											/>
										)}
									</Field>
									<Field name='password'>
										{(props) => (
											<TextField
												autoComplete='off'
												type='password'
												label='Password'
												className='loginInput'
												name={props.input.name}
												value={props.input.value}
												onChange={props.input.onChange}
												InputLabelProps={{
													shrink: true,
												}}
												fullWidth
											/>
										)}
									</Field>
									<Button
										type='submit'
										fullWidth
										className='loginInput'
										color='primary'
										variant='contained'
									>
										Login
									</Button>
								</>
							) : (
								<div />
							)}
							<Button
								fullWidth
								onClick={handleGameforgeAuthorization}
								className='loginInput'
								variant='contained'
								color='default'
							>
								Gameforge login
							</Button>
						</form>
					)}
				/>
			</Paper>
		</Box>
	);
};

const mapDispatchToProps = (dispatch) => ({
	checkToken: (token) => dispatch(checkToken(token)),
	authorize: (body, onLoad) => dispatch(authorize(body, onLoad)),
	gameforgeAuthorize: (body, onLoad) =>
		dispatch(gameforgeAuthorize(body, onLoad)),
});

export default connect(() => ({}), mapDispatchToProps)(Login);
