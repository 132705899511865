import React from 'react';
import { connect } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { withAdmin } from 'components';
import { Box, Button, TextField, Typography } from '@mui/material';
import {
	getTestRating,
	setRating,
	getTestRatingPost,
} from 'actions/ratingActions';
import { LOADING } from 'constants/apiStatuses';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import './styles.css';
import AccountToCreate from './AccountToCreate';
import DeviceToCreate from './DeviceToCreate';
import TransactionToCreate from './TransactionToCreate';
import EvidenceToCreate from './EvidenceToCreate';
import isSubmitButtonDisabled from 'helpers/isSubmitButtonDisabled';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const Condition = ({ when, is, children }) => (
	<Field name={when} subscription={{ value: true }}>
		{({ input: { value } }) => (value === is ? children : null)}
	</Field>
);

const RatingTest = (props) => {
	const jsonInputRef = React.useRef();

	const {
		data,
		getTestRatingStatus,
		getTestRating,
		setRatingData,
		getTestRatingPost,
	} = props;

	const JSONInputStyle = {
		container: {
			border: '1px solid lightgrey',
		},
		body: {
			fontSize: 16,
		},
	};

	const JSONInputColors = {
		default: 'black',
		string: 'black',
		number: 'black',
		colon: 'black',
		keys: 'black',
		keys_whiteSpace: 'black',
		primitive: 'primitive',
	};

	const onFormSubmit = (values) => {
		if (values.method === 'Get') {
			getTestRating(values.request);
		} else {
			getTestRatingPost({
				...values,
				jsonInput: jsonInputRef.current.state,
			});
		}
	};

	React.useEffect(() => {
		return () => {
			setRatingData(null);
		};
	}, []);

	const { toCreate, toUpdate } = data || {};
	const {
		account: accountToCreate,
		device: deviceToCreate,
		evidences: evidencesToCreate,
	} = toCreate || {};

	const { changedOrNew, delete: toDelete, ...restToUpdate } = toUpdate || {};

	const initialValues = {
		request: '',
		method: 'Get',
		body: jsonInputRef.current?.state?.jsObject || {},
	};

	return (
		<Form
			initialValues={initialValues}
			keepDirtyOnReinitialize={true}
			onSubmit={onFormSubmit}
			render={({ handleSubmit, touched, values, errors }) => {
				return (
					<form onSubmit={handleSubmit}>
						<Typography className='result' variant='h5'>
							Test fp request:
						</Typography>
						<Condition when='method' is='Get'>
							<Field name='request'>
								{(props) => (
									<div>
										<Box display='flex' alignItems='start' className='block'>
											<Typography className='label'>Request:</Typography>
											<TextField
												value={props.input.value}
												multiline
												minRows={10}
												onChange={(e) => props.input.onChange(e.target.value)}
												className='transInput'
												inputRef={(input) => input?.focus?.()}
											/>
										</Box>
										<Box
											sx={{
												display: 'flex',
											}}
											className='helperText'
										>
											<InfoOutlinedIcon className='helpIcon'></InfoOutlinedIcon>
											rule-set=user_login_nofp&account-name=a83c015c-c335-4747-a999-578304ac9e39&ip=79.110.95.4&ip-reputation=CHALLENGE-Datacenter
											IP
										</Box>
									</div>
								)}
							</Field>
						</Condition>
						<Condition when='method' is='Post'>
							<Field name='body'>
								{(props) => (
									<Box display='flex' alignItems='start' className='block'>
										<Typography className='label'>Request:</Typography>
										<Box maxWidth={1200} width='100%'>
											<JSONInput
												ref={jsonInputRef}
												placeholder={props.input.value}
												width='100%'
												confirmGood={false}
												style={JSONInputStyle}
												colors={JSONInputColors}
												theme='light_mitsuketa_tribute'
												locale={locale}
											/>
										</Box>
									</Box>
								)}
							</Field>
						</Condition>

						<Box display='flex' alignItems='center' className='block'>
							<>
								<Typography className='label'>Method:</Typography>
								{['Post', 'Get'].map((method) => (
									<label key={method} className='radioInput'>
										<Field
											name='method'
											component='transInput'
											type='radio'
											value={method}
										/>
										{method}
									</label>
								))}
							</>
						</Box>
						<Box display='flex' alignItems='center' className='block'>
							<Button
								variant='contained'
								color='primary'
								type='submit'
								className='submitButton'
								disabled={isSubmitButtonDisabled(
									errors,
									touched,
									getTestRatingStatus
								)}
							>
								Test Rating
							</Button>
						</Box>
						{getTestRatingStatus === LOADING && <div>Loading...</div>}
						{data && (
							<Box>
								<Typography className='result' variant='h6'>
									Data will be created:
								</Typography>
								<TransactionToCreate transactionInfo={data} />
								{accountToCreate && (
									<AccountToCreate accountInfo={accountToCreate} />
								)}
								{deviceToCreate && (
									<DeviceToCreate deviceInfo={deviceToCreate} />
								)}
								{evidencesToCreate && !!evidencesToCreate.length && (
									<EvidenceToCreate evidenceInfo={evidencesToCreate} />
								)}
							</Box>
						)}
						{toUpdate && (
							<Box>
								<Typography className='result' variant='h6'>
									Data will be updated:
								</Typography>
								{changedOrNew &&
									Object.entries(changedOrNew).map((entry) => (
										<Box className='transInfoItem' key={entry[0]}>
											{`${entry[0]}: `}
											<b>{data.device[entry[0]]}</b>
											{` -> `}
											<b>{entry[1]}</b>
										</Box>
									))}
								{restToUpdate && (
									<Box className='transInfoItem'>
										Rest to update:
										<b>{JSON.stringify(restToUpdate)}</b>
									</Box>
								)}
								<Typography className='result' variant='h6'>
									Data will be deleted:
								</Typography>
								{toDelete &&
									toDelete.map((field, index) => (
										<Box className='transInfoItem' key={field + index}>
											<b>{field}</b>
										</Box>
									))}
							</Box>
						)}
					</form>
				);
			}}
		/>
	);
};

const mapStateToProps = ({ rating }) => ({
	getRatingStatus: rating.getRatingStatus,
	getTestRatingStatus: rating.getTestRatingStatus,
	data: rating.data,
});

const mapDispatchToProps = (dispatch) => ({
	getTestRating: (request) => dispatch(getTestRating(request)),
	setRatingData: (data) => dispatch(setRating(data)),
	getTestRatingPost: (body) => dispatch(getTestRatingPost(body)),
});

export default withAdmin(
	connect(mapStateToProps, mapDispatchToProps)(RatingTest)
);
