const oldSessionFields = [
	{
		key: 'session.transactionsByIPByLastHour',
		label: '(Old) Ip uses within last hour',
		type: 'number',
	},
	{
		key: 'session.transactionsByDeviceByLastHour',
		label: '(Old) Transactions by device within last hour',
		type: 'number',
	},
	{
		key: 'session.transactionsByAccountByLastHour',
		label: '(Old) Transactions by account within last hour',
		type: 'number',
	},
	{
		key: 'session.countriesByDevice',
		label: '(Old) Countries by device over the last 24 hours',
		type: 'number',
	},
	{
		key: 'session.countriesByAccount',
		label: '(Old) Countries by account over the last 24 hours',
		type: 'number',
	},
	{
		key: 'session.accountsByDevice',
		label: '(Old) Accounts by device over the last 24 hours',
		type: 'number',
	},
	{
		key: 'session.devicesByAccount',
		label: '(Old) Devices by account over the last 24 hours',
		type: 'number',
	},
];

export default oldSessionFields;
