import Joi from 'joi';
import { MAX_STRING_LENGTH } from '../../constants/validations';

const validateRuleFieldForm = (values) => {
	const ruleFieldSchema = Joi.object({
		name: Joi.string()
			.required()
			.messages({
				'any.required': 'Name is required',
				'string.empty': 'Name is required',
				'string.max': `Name max length is ${MAX_STRING_LENGTH} symbols`,
			})
			.max(MAX_STRING_LENGTH),
		label: Joi.string().required().messages({
			'any.required': 'Label is required',
			'string.empty': 'Label is required',
		}),
	});

	const validatedRuleFieldSchema = ruleFieldSchema.validate(
		{
			name: values.name,
			label: values.label,
		},
		{
			abortEarly: false,
		}
	);

	if (validatedRuleFieldSchema.error) {
		return Object.fromEntries(
			validatedRuleFieldSchema.error.details.map((detail) => [
				detail.path[0],
				detail.message,
			])
		);
	}

	return {};
};

export default validateRuleFieldForm;
