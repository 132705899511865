import React from 'react';
import { Form, Field } from 'react-final-form';
import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	TextField,
	CircularProgress,
} from '@mui/material';
import isSubmitButtonDisabled from 'helpers/isSubmitButtonDisabled';
import validateRemoveEvidenceForm from 'helpers/validations/removeEvidence';
import { NONE } from 'constants/apiStatuses';

const RemoveEvidenceModal = ({
	onClose,
	evidence,
	request,
	load = false,
	buttons = { OK: 'Remove', CANCEL: 'Cancel' },
	entityId,
}) => {
	const onSubmit = (form) => {
		request(
			{
				id: evidence.id,
				comment: form.comment,
				deleteRadiated: form.deleteRadiated,
				entityId,
			},
			onClose
		);
	};

	const initialFormState = {
		deleteRadiated: false,
	};

	return (
		<Dialog open={true} fullWidth maxWidth='xs' onClose={onClose}>
			<DialogTitle>Evidence comment</DialogTitle>
			<Form
				validate={validateRemoveEvidenceForm}
				onSubmit={onSubmit}
				initialValues={initialFormState}
				render={({ handleSubmit, errors, touched }) => (
					<form onSubmit={handleSubmit}>
						<DialogContent>
							<Field name='comment'>
								{(props) => (
									<TextField
										error={Boolean(props.meta.touched && props.meta.error)}
										helperText={props.meta.touched && props.meta.error}
										value={props.input.value}
										onChange={props.input.onChange}
										autoComplete='off'
										autoFocus
										InputLabelProps={{ shrink: true }}
										margin='dense'
										label='Comment'
										fullWidth
									/>
								)}
							</Field>
							{buttons.OK === 'Remove' && (
								<Field name='deleteRadiated' type='checkbox'>
									{(props) => (
										<FormControlLabel
											control={
												<Checkbox
													checked={props.input.checked}
													onChange={props.input.onChange}
													color='primary'
												/>
											}
											label='Delete radiated'
										/>
									)}
								</Field>
							)}
						</DialogContent>
						<DialogActions>
							{load ? (
								<CircularProgress />
							) : (
								<>
									<Button color='secondary' onClick={onClose}>
										{buttons.CANCEL}
									</Button>
									<Button
										color='primary'
										type='submit'
										disabled={isSubmitButtonDisabled(errors, touched, NONE)}
									>
										{buttons.OK}
									</Button>
								</>
							)}
						</DialogActions>
					</form>
				)}
			/>
		</Dialog>
	);
};

export default RemoveEvidenceModal;
