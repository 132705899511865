import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import '../../styles.css';

const CheckboxGroup = ({ fields, options, error }) => {
	const toggle = (event, option) => {
		const index = fields.value?.indexOf(option);
		if (event.target.checked) {
			fields.push(option);
		} else {
			fields.remove(index);
		}
	};

	const capitalize = (name) => {
		return name[0].toUpperCase() + name.slice(1);
	};

	const checkboxError = `${error && 'checkboxError'}`;
	return (
		<div className='flex'>
			{options.map((option) => (
				<FormControlLabel
					control={
						<Checkbox
							value={option}
							onChange={(event) => toggle(event, option)}
							color='primary'
							checked={fields.value?.includes(option)}
						/>
					}
					className={checkboxError}
					key={option}
					label={capitalize(option)}
				/>
			))}
		</div>
	);
};

export default CheckboxGroup;
