import axiosInstance from '../index';

const addComment = (body) => {
	return axiosInstance
		.put(`/evidence/${body.id}`, {
			comment: body.comment,
		})
		.then((res) => {
			return res.data;
		});
};

export { addComment };
