import React from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { getDevicesCountriesInfo } from 'actions/serverStatsActions';
import devicesWithMostCountries from 'constants/columns/devicesWithMostCountries';
import generateDatasetConfigForPieChart from 'helpers/generateDatasetConfigForPieChart';
import ReportsPage from 'components/ReportPage';

const DevicesCountriesInfo = (props) => {
	const {
		devicesCountriesInfo,
		getDevicesCountriesInfoStatus,
		getDevicesCountriesInfo,
	} = props;

	const navigate = useNavigate();

	const handleClick = ({ id }) => {
		navigate(`/devices/${id}`);
	};

	return (
		<ReportsPage
			getInfo={getDevicesCountriesInfo}
			data={devicesCountriesInfo}
			status={getDevicesCountriesInfoStatus}
			DataGridProps={{
				onRowClick: handleClick,
			}}
			chartConfig={generateDatasetConfigForPieChart({
				labels: devicesCountriesInfo?.map(({ id }) => id),
				data: devicesCountriesInfo?.map(({ countriesCount }) => countriesCount),
			})}
			onChartPartClick={handleClick}
			title='Devices with most countries over last 96 hours'
			columns={devicesWithMostCountries}
		/>
	);
};

const mapStateToProps = ({ serverStats }) => ({
	devicesCountriesInfo: serverStats.devicesCountriesInfo,
	getDevicesCountriesInfoStatus: serverStats.getDevicesCountriesInfoStatus,
});

const mapDispatchToProps = (dispatch) => ({
	getDevicesCountriesInfo: () => dispatch(getDevicesCountriesInfo),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DevicesCountriesInfo);
