import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getStats } from 'actions/fpStatsActions';
import FpStatsGraph from './FpStatsGraph';
import { LOADING } from 'constants/apiStatuses';
import { Box, CardContent } from '@mui/material';

const FpStats = (props) => {
	const { getStatsData, getStats, getStatsStatus } = props;

	useEffect(() => {
		getStats();
	}, []);

	return (
		<>
			<CardContent>
				{getStatsStatus === LOADING && <div>Loading...</div>}
			</CardContent>
			<Box display='flex' flexDirection='column' gap={4}>
				{getStatsData &&
					getStatsData.length > 0 &&
					getStatsData?.map((item) => {
						return <FpStatsGraph data={item} key={item.title} />;
					})}
			</Box>
		</>
	);
};

const mapStateToProps = ({ fpStats }) => ({
	getStatsStatus: fpStats.getStatsStatus,
	getStatsData: fpStats.getStatsData,
});

const mapDispatchToProps = (dispatch) => ({
	getStats: () => dispatch(getStats()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FpStats);
