import React from 'react';
import { CustomTableCell } from 'components';

const valueConverter = (value) => {
	if (typeof value === 'boolean') {
		return value.toString();
	}
	return value ?? '-';
};

const ruleFields = [
	{
		field: 'key',
		editable: false,
		headerName: 'Field',
		headerAlign: 'left',
		flex: 1,
		renderCell: (params) => (
			<CustomTableCell
				{...params}
				textAlign='left'
				tooltipPlacement='bottom-start'
			/>
		),
	},
	{
		field: 'label',
		editable: false,
		headerName: 'Description',
		headerAlign: 'left',
		flex: 1,
		renderCell: (params) => (
			<CustomTableCell
				{...params}
				textAlign='left'
				tooltipPlacement='bottom-start'
			/>
		),
	},
	{
		field: 'example',
		editable: false,
		headerName: 'Example',
		headerAlign: 'left',
		flex: 1,
		renderCell: (params) => (
			<CustomTableCell
				{...params}
				value={valueConverter(params?.value)}
				textAlign='left'
				tooltipPlacement='bottom-start'
			/>
		),
	},
	{
		field: 'type',
		editable: false,
		headerName: 'Type',
		headerAlign: 'left',
		flex: 1,
		renderCell: (params) => (
			<CustomTableCell
				{...params}
				textAlign='left'
				tooltipPlacement='bottom-start'
			/>
		),
	},
];

export default ruleFields;
