import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

const mapStateToProps = ({ auth }) => ({
	userInfo: auth.userInfo,
});

const withAdmin = (Component) =>
	connect(mapStateToProps, () => ({}))((props) => {
		const navigate = useNavigate();

		useEffect(() => {
			if (props.userInfo.role === 'user') {
				navigate(-1);
			}
		}, []);

		return props.userInfo.role === 'admin' ? <Component {...props} /> : null;
	});

export default withAdmin;
