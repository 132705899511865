import axiosInstance from '../index';

const getAccounts = ({
	cursor,
	page,
	pageSize,
	filters,
	deviceId = null,
	sorting,
}) => {
	return axiosInstance
		.post(
			`/accounts?sortId=${cursor.search_after[0]}&remain=${cursor.search_after[1]}&limit=${pageSize}&deviceId=${deviceId}`,
			{ filters, sorting, pit: cursor.pit }
		)
		.then((res) => {
			return {
				...res.data,
				page,
			};
		});
};

export { getAccounts };
