import {
	RATING_GET_RATING_STATUS,
	RATING_GET_TEST_RATING_STATUS,
	RATING_SET_RATING,
} from '../constants';
import { getRating as getRatingAPI } from 'services/api/rules/getRating';
import { getTestRating as getTestRatingAPI } from 'services/api/rules/getTestRating';
import { getTestRatingPost as getTestRatingPostAPI } from 'services/api/rules/getTestRatingPost';
import { LOADING, ERROR, NONE } from 'constants/apiStatuses';
import notification from '../../helpers/notification';

export const setGetRatingStatus = (status) => ({
	type: RATING_GET_RATING_STATUS,
	payload: status,
});

export const setRating = (rating) => ({
	type: RATING_SET_RATING,
	payload: rating,
});

export const setGetTestRatingStatus = (status) => ({
	type: RATING_GET_TEST_RATING_STATUS,
	payload: status,
});

export const getTestRating = (data) => (dispatch) => {
	dispatch(setGetTestRatingStatus(LOADING));
	getTestRatingAPI(data)
		.then((data) => {
			dispatch(setRating(data));
			dispatch(setGetTestRatingStatus(NONE));
		})
		.catch(() => dispatch(setGetTestRatingStatus(ERROR)));
};

export const getTestRatingPost =
	({ jsonInput, ...rest }) =>
	(dispatch) => {
		if (jsonInput.error) {
			notification.invoke('Invalid json', { variant: 'error' });
		} else {
			const data = jsonInput.jsObject || jsonInput.prevPlaceholder;
			dispatch(setGetTestRatingStatus(LOADING));
			getTestRatingPostAPI({
				...rest,
				...data,
			})
				.then((data) => {
					dispatch(setRating(data));
					dispatch(setGetTestRatingStatus(NONE));
				})
				.catch(() => dispatch(setGetTestRatingStatus(ERROR)));
		}
	};

export const getRating =
	({ jsonInput, ...rest }) =>
	(dispatch, getState) => {
		if (jsonInput.error) {
			notification.invoke('Invalid json', { variant: 'error' });
		} else {
			dispatch(setGetRatingStatus(LOADING));
			getRatingAPI({
				...rest,
				fp: jsonInput.jsObject || jsonInput.prevPlaceholder,
			})
				.then((rating) => {
					if (rating.error) {
						console.log(rating);
					} else {
						dispatch(setRating(rating));
						dispatch(setGetRatingStatus(NONE));
					}
				})
				.catch(() => dispatch(setGetRatingStatus(ERROR)));
		}
	};
