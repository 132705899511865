import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import '../styles.css';

const CustomAccordion = ({ title, children }) => {
	return (
		<Accordion elevation={0} className='accordion'>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Typography variant='h5'>{title}</Typography>
			</AccordionSummary>
			<AccordionDetails>{children}</AccordionDetails>
		</Accordion>
	);
};

export default CustomAccordion;
