import {
	REASONS_GET_REASONS_STATUS,
	REASONS_SET_REASONS,
	REASONS_EDIT_REASON_STATUS,
	REASONS_DELETE_REASON_STATUS,
	REASONS_CREATE_REASON_STATUS,
	REASONS_CREATE_REASON,
	REASONS_DELETE_REASON,
	REASONS_EDIT_REASON,
} from '../constants';
import { LOADING, NONE, ERROR } from 'constants/apiStatuses';
import { getReasons as getReasonsAPI } from 'services/api/reasons/getReasons';
import { addReason as addReasonAPI } from 'services/api/reasons/addReason';
import { deleteReason as deleteReasonAPI } from 'services/api/reasons/deleteReason';
import { editReason as editReasonAPI } from 'services/api/reasons/editReason';

export const setGetReasonsStatus = (status) => ({
	type: REASONS_GET_REASONS_STATUS,
	payload: status,
});

export const setCreateReasonStatus = (status) => ({
	type: REASONS_CREATE_REASON_STATUS,
	payload: status,
});

export const setEditReasonStatus = (status) => ({
	type: REASONS_EDIT_REASON_STATUS,
	payload: status,
});

export const setDeleteReasonStatus = (status) => ({
	type: REASONS_DELETE_REASON_STATUS,
	payload: status,
});

export const setReasons = (reasons) => ({
	type: REASONS_SET_REASONS,
	payload: reasons,
});

export const getReasons = (dispatch, getState) => {
	dispatch(setGetReasonsStatus(LOADING));
	getReasonsAPI()
		.then((body) => {
			dispatch(setReasons(body));
			dispatch(setGetReasonsStatus(NONE));
		})
		.catch(() => dispatch(setGetReasonsStatus(ERROR)));
};

export const setCreateReason = (reason) => ({
	type: REASONS_CREATE_REASON,
	payload: reason,
});

export const createReason = (body, onLoad) => (dispatch, getState) => {
	dispatch(setCreateReasonStatus(LOADING));
	addReasonAPI(body)
		.then((res) => {
			dispatch(setCreateReasonStatus(NONE));
			dispatch(setCreateReason(res));
			onLoad();
		})
		.catch(() => dispatch(setCreateReasonStatus(ERROR)));
};

export const setDeleteReason = (reason) => ({
	type: REASONS_DELETE_REASON,
	payload: reason,
});

export const deleteReason = (id, onLoad) => (dispatch, getState) => {
	dispatch(setDeleteReasonStatus(LOADING));
	deleteReasonAPI(id)
		.then((reason) => {
			dispatch(setDeleteReasonStatus(NONE));
			dispatch(setDeleteReason(reason));
			onLoad();
		})
		.catch(() => dispatch(setDeleteReasonStatus(ERROR)));
};

export const setEditReason = (reason) => ({
	type: REASONS_EDIT_REASON,
	payload: reason,
});

export const editReason = (reason, onLoad) => (dispatch, getState) => {
	dispatch(setEditReasonStatus(LOADING));
	editReasonAPI(reason)
		.then((reason) => {
			dispatch(setEditReason(reason));
			dispatch(setEditReasonStatus(NONE));
			onLoad();
		})
		.catch(() => dispatch(setEditReasonStatus(ERROR)));
};
