import { CustomTableCell, PureTableCell } from 'components';
import React from 'react';

const accountsWithMostDevices = [
	{
		field: 'id',
		editable: false,
		headerName: 'Account id',
		headerAlign: 'center',
		flex: 1,
		renderCell: (params) => (
			<CustomTableCell
				getNewTabLink={(id) => `${window.location.origin}/accounts/${id}`}
				{...params}
			/>
		),
	},
	{
		field: 'devicesCount',
		editable: false,
		headerName: 'Devices count',
		headerAlign: 'center',
		flex: 1,
		renderCell: (params) => <PureTableCell {...params} />,
	},
	{
		field: 'result',
		headerName: 'Result',
		width: 80,
		hideSortIcons: true,
		sortable: false,
		headerAlign: 'center',
		valueGetter: () => ' ',
		renderCell: () => <span className='scoreIndicator' />,
	},
];

export default accountsWithMostDevices;
