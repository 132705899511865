import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { Typography, Box, Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import { RemoveEvidenceModal, Pagination } from 'components';
import './styles.css';
import { LOADING } from 'constants/apiStatuses';
import evidenceHistoryColumns from 'constants/columns/evidenceHistory';
import { getRowHeight, getTableHeight } from 'helpers/evidenceManager';

export const EvidenceHistoryContext = React.createContext();

const EvidenceManager = (props) => {
	const navigate = useNavigate();

	const [isEvidenceModalOpen, setIsEvidenceModalOpen] = useState(false);
	const [evidence, setEvidence] = useState(null);

	const [isAddCommentModalOpen, setCommentModalOpen] = useState(false);
	const [tableHeight, setTableHeight] = useState(0);

	const {
		users,
		evidenceHistory,
		type,
		getEvidences,
		deleteRequest,
		addCommentRequest,
		createEvidenceUrl,
		entityId = null,
		deleteAccountEvidenceStatus,
		deleteDeviceEvidenceStatus,
		deleteIpsEvidenceStatus,
		evidenceTypes,
		getAccount,
	} = props;

	const { total, status, data } = evidenceHistory ?? {};

	React.useEffect(() => {
		if (data) {
			setTableHeight(getTableHeight(data));
		}
	}, [data]);

	const getRadiatedFromUrl = (id, type) => `/${type}s/${id}`;

	const handleEvidenceModalClose = () => {
		setIsEvidenceModalOpen(false);
	};

	const handleGoToCreateEvidence = () => {
		navigate(createEvidenceUrl);
	};

	const handleGoToRadiateFrom = (id, type) => {
		navigate(getRadiatedFromUrl(id, type));
	};

	const handleCloseNewCommentModal = () => {
		setCommentModalOpen(false);
	};
	const handleSetEvidence = (evidence) => {
		setEvidence(evidence);
	};

	const isEvidenceDeleted =
		deleteAccountEvidenceStatus === LOADING ||
		deleteDeviceEvidenceStatus === LOADING ||
		deleteIpsEvidenceStatus === LOADING;

	const getTableClass = (actionStatus, state) => {
		return `${actionStatus === LOADING ? 'disablePageButton' : ''} ${
			state ? 'removePageButton' : ''
		}`;
	};

	const handleDeleteEvidence = (evidence) => () => {
		setIsEvidenceModalOpen(true);
		setEvidence(evidence);
	};
	const handleOpenNewComment = (evidence) => (e) => {
		e.stopPropagation();
		setCommentModalOpen(true);
		handleSetEvidence(evidence);
	};

	const paginationFilters = React.useRef({
		consumerId: entityId,
		type,
	});

	const contextValues = {
		handleGoToRadiateFrom,
		handleDeleteEvidence,
		handleOpenNewComment,
		evidenceTypes,
		users,
	};

	(data || []).forEach((item) => {
		const matchedAccount = getAccount?.find(
			(account) => account?.id === item?.radiatedBy?.id
		);

		// If a match is found, update radiatedBy.name
		if (matchedAccount) {
			item.radiatedBy.name = matchedAccount?.name;
		}
	});

	return (
		<EvidenceHistoryContext.Provider value={contextValues}>
			<Box>
				<Typography variant='h6' className='evidenceTitle'>
					Evidence history
				</Typography>
				<Button
					variant='contained'
					color='primary'
					className='addEvidence'
					onClick={handleGoToCreateEvidence}
				>
					Add evidence
				</Button>

				<Pagination
					getItems={getEvidences}
					filters={paginationFilters.current}
					initialFilters={paginationFilters.current}
					accountId={entityId}
					isEvidence={true}
				>
					{({
						page,
						pageSize,
						rowsPerPageOptions,
						isArrowBlocked,
						handleHeaderFilterChange,
						sortModel,
						handlePaginationModelChange,
					}) => (
						<Box style={{ height: '100%' }}>
							{data && (
								<DataGrid
									autoHeight
									getRowHeight={getRowHeight}
									columns={evidenceHistoryColumns}
									rows={data}
									paginationMode='server'
									disableColumnMenu={true}
									rowCount={total}
									paginationModel={{ page, pageSize }}
									pageSizeOptions={rowsPerPageOptions}
									onPaginationModelChange={handlePaginationModelChange}
									sortingMode='server'
									onSortModelChange={handleHeaderFilterChange}
									sortModel={sortModel}
									disableSelectionOnClick={true}
									className={getTableClass(status, isArrowBlocked)}
								/>
							)}
						</Box>
					)}
				</Pagination>

				{isEvidenceModalOpen && (
					<RemoveEvidenceModal
						request={deleteRequest}
						onClose={handleEvidenceModalClose}
						evidence={evidence}
						entityId={entityId}
						load={isEvidenceDeleted}
					/>
				)}
				{isAddCommentModalOpen && (
					<RemoveEvidenceModal
						request={addCommentRequest}
						onClose={handleCloseNewCommentModal}
						evidence={evidence}
						buttons={{ OK: 'Add', CANCEL: 'Cancel' }}
					/>
				)}
			</Box>
		</EvidenceHistoryContext.Provider>
	);
};

const mapStateToProps = ({ users, accounts, devices, ips, reasons }) => ({
	users: users.data,
	deleteAccountEvidenceStatus: accounts.deleteEvidenceStatus,
	deleteDeviceEvidenceStatus: devices.deleteEvidenceStatus,
	deleteIpsEvidenceStatus: ips.deleteEvidenceStatus,
	evidenceTypes: reasons.reasons,
});

export default connect(mapStateToProps, () => ({}))(EvidenceManager);
