import Joi from 'joi';
import { MAX_STRING_LENGTH } from '../../constants/validations';

const validateUserForm = (values) => {
	const userSchema = Joi.object({
		email: Joi.string()
			.required()
			.email({ tlds: { allow: false } })
			.messages({
				'any.required': 'Email is required',
			}),
		username: Joi.string()
			.required()
			.max(MAX_STRING_LENGTH)
			.messages({
				'any.required': 'Username is required',
				'string.max': `Max length is ${MAX_STRING_LENGTH} symbols`,
			}),
		password: Joi.string()
			.max(MAX_STRING_LENGTH)
			.messages({
				'any.required': 'Password is required',
				'string.max': `Max length is ${MAX_STRING_LENGTH} symbols`,
			}),
		role: Joi.string().valid('admin', 'user').messages({
			'any.required': 'Role must be admin or user',
		}),
	});

	const validatedUserSchema = userSchema.validate(
		{
			email: values.email,
			username: values.username,
			password: values.password,
			role: values.role,
		},
		{
			abortEarly: false,
		}
	);

	if (validatedUserSchema.error) {
		return Object.fromEntries(
			validatedUserSchema.error.details.map((detail) => [
				detail.path[0],
				detail.message,
			])
		);
	}

	return {};
};

export default validateUserForm;
