import moment from 'moment';

const excludeToFormat = [
	'Width:',
	'Height:',
	'Canvas FP:',
	'dF',
	'dW',
	'dC',
	'd',
];

export const dateFormatter = (date) => {
	if (!date) {
		return 'n/a';
	}
	return moment(date).local().format('YYYY-MM-DD HH:mm:ss');
};

export const numberFormatter = (value, key = '') => {
	if (Number(value) && !excludeToFormat.includes(key)) {
		return Number(value).toLocaleString('de-DE');
	}
	return value;
};
