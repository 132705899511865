import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { Pagination } from 'components';
import { DataGrid } from '@mui/x-data-grid';
import {
	getActions,
	changeFilterField,
	resetFilters,
} from 'actions/actionsActions';
import getQueryFromSearchParams from 'helpers/getQueryFromSearchParams';
import actionsColumns from 'constants/columns/actions';
import RefreshIcon from '@mui/icons-material/Refresh';
import { ReactComponent as ClearAllIcon } from 'icons/clearFilter.svg';
import { FilterProvider, CustomInput } from 'components';
import './styles.css';
import { LOADING } from 'constants/apiStatuses';
import defaultSortModels from 'constants/defaultHeaderSortModels';
import { VALIDATION_TYPES } from '../../helpers/validations/validateFilters';
import LoadingSpinner from '../../components/LoadingSpinner';

const ActionsPage = (props) => {
	const navigate = useNavigate();
	const location = useLocation();
	const filtersQuery = new URLSearchParams(location.search);
	const {
		getActions,
		actions,
		actionsTotalAmount,
		getActionsStatus,
		filters,
		changeFilterField,
		resetFilters,
	} = props;

	const handleRowClick = ({ id }) => {
		navigate(`/actions/${id}`);
	};

	useEffect(() => {
		filtersQuery.delete('tab');
		resetFilters(getQueryFromSearchParams(filtersQuery.entries()));
	}, []);

	const getTableClass = (state) => {
		return `actionTable ${
			getActionsStatus === LOADING ? 'disablePageButton' : ''
		} ${state ? 'removePageButton' : ''}`;
	};

	const initialFilters = { actionType: '', userName: '' };

	return (
		<>
			<Typography variant='h4'>Actions</Typography>
			<Pagination
				getItems={getActions}
				filters={filters}
				changeFilterField={changeFilterField}
				defaultSortModel={defaultSortModels.action}
				urlToReset='?tab=actions'
				initialFilters={initialFilters}
				resetFilters={resetFilters}
				validationType={VALIDATION_TYPES.ACTIONS}
			>
				{({
					page,
					handleChangeFilters,
					handleRefresh,
					handleAddQuery,
					handleResetFilters,
					pageSize,
					rowsPerPageOptions,
					isArrowBlocked,
					handleHeaderFilterChange,
					sortModel,
					handlePaginationModelChange,
				}) => {
					return (
						<>
							<Box className='actionFilterBar'>
								<CustomInput
									value={filters.actionType}
									name='actionType'
									onBlur={handleAddQuery}
									onChange={handleChangeFilters}
									label='Action type'
								/>
								<CustomInput
									value={filters.userName}
									name='userName'
									onBlur={handleAddQuery}
									onChange={handleChangeFilters}
									label='User name'
								/>
								<IconButton
									className='actionFilterInput'
									color='primary'
									onClick={() => handleRefresh(pageSize)}
								>
									<RefreshIcon
										fontSize='small'
										className='actionClearAllIcon'
									/>
								</IconButton>
								<Tooltip title='Clear filters'>
									<IconButton
										className='actionFilterInput'
										color='primary'
										onClick={handleResetFilters}
									>
										<ClearAllIcon className='actionClearAllIcon' />
									</IconButton>
								</Tooltip>
							</Box>
							{getActionsStatus === LOADING ? (
								<LoadingSpinner />
							) : (
								actions && (
									<FilterProvider changeFilterField={changeFilterField}>
										<DataGrid
											autoHeight
											className={getTableClass(isArrowBlocked)}
											disableColumnMenu={true}
											onRowClick={handleRowClick}
											columns={actionsColumns}
											rows={actions}
											rowCount={actionsTotalAmount}
											paginationModel={{ page, pageSize }}
											pageSizeOptions={rowsPerPageOptions}
											onPaginationModelChange={handlePaginationModelChange}
											paginationMode='server'
											sortingMode='server'
											onSortModelChange={handleHeaderFilterChange}
											sortModel={sortModel}
										/>
									</FilterProvider>
								)
							)}
						</>
					);
				}}
			</Pagination>
		</>
	);
};

const mapStateToProps = ({ actions }) => ({
	actions: actions.data,
	actionsTotalAmount: actions.total,
	filters: actions.filters,
	getActionsStatus: actions.getActionsStatus,
});

const mapDispatchToProps = (dispatch) => ({
	getActions: (cursor, type, filters) =>
		dispatch(getActions(cursor, type, filters)),
	changeFilterField: (value) => dispatch(changeFilterField(value)),
	resetFilters: (body) => dispatch(resetFilters(body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActionsPage);
