import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Box, Button, Typography } from '@mui/material';
import './styles.css';
import { getSingleRule } from 'actions/rulesActions';
import { LOADING } from 'constants/apiStatuses';

const Rule = (props) => {
	const navigate = useNavigate();
	const params = useParams();

	const { rule, getRuleStatus, getSingleRule } = props;

	useEffect(() => {
		getSingleRule(params.id);
	}, []);

	const handleGoBack = () => {
		navigate(-1);
	};

	if (getRuleStatus === LOADING) {
		return <div>Loading...</div>;
	}

	return (
		<Box>
			<Box className='ruleContainer'>
				<Typography variant='h5'>Rule</Typography>
				<Button color='primary' variant='contained' onClick={handleGoBack}>
					Go back
				</Button>
			</Box>
			<Box>Rule name: {rule.name}</Box>
			<Box>Rule sets: {rule.ruleSet}</Box>
			<Box>Rule text: {rule.rule}</Box>
		</Box>
	);
};

const mapStateToProps = ({ rules }) => ({
	rule: rules.singleRule,
	getRuleStatus: rules.getSingleRuleStatus,
});

const mapDispatchToProps = (dispatch) => ({
	getSingleRule: (id) => dispatch(getSingleRule(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Rule);
