const transformAgeInMs = (ageInMs) => {
	if (!ageInMs) {
		return '--';
	}
	let isNegative = ageInMs < 0;
	let sign = '';
	if (isNegative) {
		sign = '-';
		ageInMs = -ageInMs;
	}
	return (
		sign +
		[
			(v) => (v / 1000 / 60 / 60 / 24) | 0,
			(v) => ((v / 1000 / 60 / 60) | 0) % 24,
			(v) => ((v / 1000 / 60) | 0) % 60,
			(v) => ((v / 1000) | 0) % 60,
			(v) => v % 1000,
		]
			.map((f) => f(ageInMs))
			.map((v, idx) =>
				idx ? ('000' + v).substr(-2 - (idx === 4 ? 1 : 0)) : '' + v
			)
			.map((v, idx) => v + ':::. '.split('')[idx])
			.join('')
	);
};

export default transformAgeInMs;
