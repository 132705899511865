import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import {
	getTransactions,
	changeFilterField,
	resetFilters,
	exportToJSON,
	exportToCSV,
} from 'actions/transactionsActions';
import getQueryFromSearchParams from 'helpers/getQueryFromSearchParams';
import transactionsColumns from 'constants/columns/transactions';
import { Pagination, FilterProvider, DeleteModal } from 'components';
import './styles.css';
import { getRowColorByScore } from 'helpers/getTableRowColor';
import TransactionFilters from './TransactionFilters';
import { LOADING } from 'constants/apiStatuses';
import notification from 'helpers/notification';
import defaultSortModels from 'constants/defaultHeaderSortModels';
import { Typography } from '@mui/material';
import { VALIDATION_TYPES } from '../../helpers/validations/validateFilters';
import LoadingSpinner from '../../components/LoadingSpinner';
import { getRules } from '../../redux/actions/rulesActions';

const TransactionsPage = (props) => {
	const navigate = useNavigate();
	const location = useLocation();
	const filtersQuery = new URLSearchParams(location.search);
	const exportLimit = process.env.REACT_APP_TRANSACTIONS_EXPORT_LIMIT;

	const modalTypes = {
		exportToCSV: 'exportToCSV',
		exportToJSON: 'exportToJSON',
	};

	const [activeModal, setActiveModal] = React.useState(null);
	const [tableSortModel, setTableSortModel] = React.useState(
		defaultSortModels.transaction
	);

	const {
		changeFilterField,
		transactions,
		transactionsTotalAmount,
		exportTransactionsToJSONStatus,
		exportTransactionsToCSVStatus,
		getTransactionsStatus,
		exportToJSON,
		exportToCSV,
		filters,
		getTransactions,
		resetFilters,
		rules,
		getRules,
		menuState,
	} = props;

	const handleRowClick = ({ id }) => {
		navigate(`/transactions/${id}`);
	};
	useEffect(() => {
		filtersQuery.delete('tab');
		resetFilters(getQueryFromSearchParams(filtersQuery.entries()));
	}, []);

	const getTableClass = (state) => {
		return `${getTransactionsStatus === LOADING ? 'disablePageButton' : ''} ${
			state ? 'removePageButton' : ''
		}`;
	};

	const handleExport = (exportFunction) => {
		if (transactionsTotalAmount > exportLimit) {
			handleCloseModal();
			notification.invoke(`Export limit is ${exportLimit} entities.`, {
				variant: 'error',
			});
		} else {
			exportFunction(filters, tableSortModel, handleCloseModal);
		}
	};

	const handleExportToJSONClick = () => {
		handleExport(exportToJSON);
	};

	const handleExportToCSVClick = () => {
		handleExport(exportToCSV);
	};

	const handleCloseModal = () => {
		setActiveModal(null);
	};

	const handleOpenExportJSONModal = () => {
		setActiveModal(modalTypes.exportToJSON);
	};

	const handleOpenExportCSVModal = () => {
		setActiveModal(modalTypes.exportToCSV);
	};

	useEffect(() => {
		getRules(filters);
	}, []);

	const isJSONTransactionExporting = exportTransactionsToJSONStatus === LOADING;
	const isCSVTransactionExporting = exportTransactionsToCSVStatus === LOADING;

	const renderModal = () => {
		switch (activeModal) {
			case modalTypes.exportToCSV:
				return (
					<DeleteModal
						title='Export transactions to CSV'
						onClose={handleCloseModal}
						modalBody={`Are you sure want to export ${transactionsTotalAmount} transactions?`}
						modalHTML={<p>(Limit to export is {exportLimit})</p>}
						onSubmit={handleExportToCSVClick}
						load={isCSVTransactionExporting}
					/>
				);
			case modalTypes.exportToJSON:
				return (
					<DeleteModal
						title='Export transactions to JSON'
						onClose={handleCloseModal}
						modalBody={`Are you sure want to export ${transactionsTotalAmount} transactions?`}
						modalHTML={<p>(Limit to export is {exportLimit})</p>}
						onSubmit={handleExportToJSONClick}
						load={isJSONTransactionExporting}
					/>
				);
			default:
				return null;
		}
	};

	return (
		<>
			<Typography variant='h4'>Transactions</Typography>
			<Pagination
				getItems={getTransactions}
				filters={filters}
				urlToReset='?tab=transactions'
				defaultSortModel={defaultSortModels.transaction}
				resetFilters={resetFilters}
				initialFilters={getQueryFromSearchParams(filtersQuery.entries())}
				changeFilterField={changeFilterField}
				setExternalSortModel={setTableSortModel}
				validationType={VALIDATION_TYPES.TRANSACTIONS}
			>
				{({
					handleChangeFilters,
					page,
					handleAddQuery,
					handleRefresh,
					handleResetFilters,
					pageSize,
					rowsPerPageOptions,
					isArrowBlocked,
					handleHeaderFilterChange,
					sortModel,
					handlePaginationModelChange,
				}) => (
					<>
						<TransactionFilters
							filters={filters}
							handleChangeFilters={handleChangeFilters}
							handleRefresh={() => handleRefresh(pageSize)}
							handleResetFilters={handleResetFilters}
							handleExportToJSONButtonClick={handleOpenExportJSONModal}
							handleExportToCSVButtonClick={handleOpenExportCSVModal}
							handleAddQuery={handleAddQuery}
							exclude={{ exportButton: !transactionsTotalAmount }}
							rules={rules}
							menuState={menuState}
						/>
						{getTransactionsStatus === LOADING ? (
							<LoadingSpinner />
						) : (
							transactions && (
								<FilterProvider changeFilterField={changeFilterField}>
									<DataGrid
										autoHeight
										className={getTableClass(isArrowBlocked)}
										onRowClick={handleRowClick}
										disableColumnMenu={true}
										columns={transactionsColumns}
										rows={transactions}
										paginationMode='server'
										rowCount={transactionsTotalAmount}
										getRowClassName={getRowColorByScore}
										paginationModel={{ page, pageSize }}
										pageSizeOptions={rowsPerPageOptions}
										onPaginationModelChange={handlePaginationModelChange}
										sortingMode='server'
										onSortModelChange={handleHeaderFilterChange}
										sortModel={sortModel}
									/>
								</FilterProvider>
							)
						)}
					</>
				)}
			</Pagination>
			{renderModal()}
		</>
	);
};

const mapStateToProps = ({ transactions, rules, auth }) => ({
	transactions: transactions.data,
	transactionsTotalAmount: transactions.total,
	filters: transactions.filters,
	getTransactionsStatus: transactions.getTransactionsStatus,
	exportTransactionsToJSONStatus: transactions.exportTransactionsToJSONStatus,
	exportTransactionsToCSVStatus: transactions.exportTransactionsToCSVStatus,
	rules: rules.data.rules ? rules.data.rules : null,
	menuState: auth.menuState,
});

const mapDispatchToProps = (dispatch) => ({
	getTransactions: (cursor, type, filters) =>
		dispatch(getTransactions(cursor, type, filters)),
	changeFilterField: (value) => dispatch(changeFilterField(value)),
	resetFilters: (body) => dispatch(resetFilters(body)),
	exportToJSON: (body, sorting, onLoad) =>
		dispatch(exportToJSON(body, sorting, onLoad)),
	exportToCSV: (body, sorting, onLoad) =>
		dispatch(exportToCSV(body, sorting, onLoad)),
	getRules: (filters) => dispatch(getRules(filters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsPage);
