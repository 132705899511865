import axiosInstance from '../index';

const getNewImportedEvidenceInfo = async ({
	cursor,
	page,
	pageSize,
	filters,
	sorting,
}) => {
	console.log('cursor?.search_after===', cursor?.search_after);
	let res = await axiosInstance.get(
		`/evidence/new-import-info?sortId=${cursor?.search_after[0]}&remain=${cursor?.search_after[1]}&limit=${pageSize}`,
		{ filters, sorting, pit: cursor?.pit }
	);
	return {
		...res.data,
		page,
	};
};

const writeNewEvidence = async (data) => {
	let res = await axiosInstance.post(`evidence/new-evidence-import`, data);
	return { ...res.data };
};

const getEvidenceImportQueueSize = async () => {
	let res = await axiosInstance.get(`evidence/import-queue-size`);
	return res.data;
};

export {
	getNewImportedEvidenceInfo,
	writeNewEvidence,
	getEvidenceImportQueueSize,
};
