import React from 'react';
import { PureTableCell } from 'components';
import EvidenceHistory from 'components/EvidenceManager/EvidenceHistory';
import EvidenceReasonCell from 'components/CustomTableCell/EvidenceReasonCell';

const evidenceHistoryColumns = [
	{
		field: 'reason',
		headerName: 'Reason',
		editable: false,
		sortable: false,
		headerAlign: 'center',
		width: 250,
		renderCell: (params) => <EvidenceReasonCell {...params} />,
	},
	{
		field: 'status',
		editable: false,
		sortable: false,
		headerName: 'Status',
		headerAlign: 'center',
		width: 200,
		renderCell: (params) => <PureTableCell {...params} />,
	},
	{
		field: 'history',
		editable: false,
		sortable: false,
		headerName: 'History',
		headerAlign: 'center',
		flex: 1,
		renderCell: (params) => <EvidenceHistory {...params} />,
	},
];

export default evidenceHistoryColumns;
