import React from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { NewEvidence } from 'components';
import { getIpsForEvidence } from 'actions/ipsActions';
import { createEvidence } from 'actions/ipsActions';
import ipsColumns from 'constants/columns/ips';
import defaultSortModels from 'constants/defaultHeaderSortModels';

const NewIpEvidence = (props) => {
	const { id } = useParams();
	const navigate = useNavigate();
	const {
		ipBlocksForEvidence,
		getIpsForEvidence,
		createEvidenceStatus,
		createEvidence,
	} = props;

	const handleSubmit = React.useCallback((values) => {
		createEvidence(
			{
				...values,
				radiateConsumerType: 'ip',
				radiatedByType: 'ip',
				radiateAs: 'ip',
			},
			() => navigate(-1)
		);
	}, []);

	const getIpsItems = React.useCallback(
		(params) => getIpsForEvidence({ ...params, id }),
		[]
	);

	const getCheckboxLabel = React.useCallback(
		(total) => `Radiate evidence to these ${total} ip blocks`,
		[]
	);

	const getModelLabel = React.useCallback(
		(total) =>
			`Are you sure you want to radiate evidence to these ${total} ip blocks?`,
		[]
	);

	const getRadiateToLink = React.useCallback((id) => `/ip/${id}`, []);

	const ipsTableProps = {
		title: 'Ip blocks with the same internet service provider',
		data: ipBlocksForEvidence.data,
		total: ipBlocksForEvidence.total,
		columns: ipsColumns,
		getItems: getIpsItems,
		filters: {},
		changeFilterField: () => {},
		defaultSortModel: defaultSortModels.ip,
	};

	return (
		<NewEvidence
			tableProps={ipsTableProps}
			getCheckboxLabel={getCheckboxLabel}
			getModalLabel={getModelLabel}
			getRadiateToLink={getRadiateToLink}
			handleSubmit={handleSubmit}
			status={createEvidenceStatus}
			type='ip'
		/>
	);
};

const mapStateToProps = ({ ips }) => ({
	ipBlocksForEvidence: ips.ipBlocksForEvidence,
	createEvidenceStatus: ips.createEvidenceStatus,
});

const mapDispatchToProps = (dispatch) => ({
	getIpsForEvidence: (body) => dispatch(getIpsForEvidence(body)),
	createEvidence: (body, onLoad) => dispatch(createEvidence(body, onLoad)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewIpEvidence);
