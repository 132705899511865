import Joi from 'joi';
import { MAX_STRING_LENGTH } from '../../constants/validations';

const validateRemoveEvidenceForm = (values) => {
	const removeEvidenceSchema = Joi.object({
		comment: Joi.string()
			.required()
			.max(MAX_STRING_LENGTH)
			.messages({
				'string.empty': 'Comment is required',
				'any.required': 'Comment is required',
				'string.max': `Max length is ${MAX_STRING_LENGTH} symbols`,
			}),
	});

	const validatedRemoveEvidenceSchema = removeEvidenceSchema.validate(
		{
			comment: values.comment,
		},
		{ abortEarly: false }
	);

	if (validatedRemoveEvidenceSchema.error) {
		return Object.fromEntries(
			validatedRemoveEvidenceSchema.error.details.map((detail) => [
				detail.path[0],
				detail.message,
			])
		);
	}

	return {};
};

export default validateRemoveEvidenceForm;
