const sortObjectArray = (rules, field = 'name') => {
	if (!Array.isArray(rules)) {
		return [];
	}

	return [...rules].sort((a, b) => {
		if (a[field] > b[field]) {
			return 1;
		} else if (a[field] < b[field]) {
			return -1;
		}
		return 0;
	});
};

export default sortObjectArray;
