import {
	EVIDENCES_SET_IMPORTED_EVIDENCES_INFO,
	EVIDENCES_GET_IMPORTED_EVIDENCES_INFO_STATUS,
	EVIDENCES_IMPORT_EVIDENCES_STATUS,
	EVIDENCES_IMPORT_EVIDENCES,
	EVIDENCE_LOG_RESET_FILTERS,
	EVIDENCE_CHANGE_FILTER_FIELD,
	EVIDENCE_RESET_FILTERS,
	EVIDENCE_LOG_EXPORT_TO_CSV_STATUS,
	EVIDENCE_LOG_EXPORT_TO_JSON_STATUS,
	EVIDENCE_GET_SINGLE_EVIDENCE_STATUS,
	EVIDENCE_SET_EVIDENCE_ACTION,
	EVIDENCES_GET_NEW_IMPORTED_EVIDENCES_INFO_STATUS,
	EVIDENCES_SET_NEW_IMPORTED_EVIDENCES_INFO,
	IMPORTED_NEW_EVIDENCE_CHANGE_FILTER_FIELD,
	IMPORTED_EVIDENCE_RESET_FILTERS,
} from '../constants';
import { getImportedEvidenceInfo as getImportedEvidenceInfoAPI } from 'services/api/evidence/getImportedEvidenceInfo';
import { getNewImportedEvidenceInfo as getNewImportedEvidenceInfoAPI } from 'services/api/evidence/getNewImportedEvidenceInfo';
import {
	writeNewEvidence as writeNewEvidenceAPI,
	getEvidenceImportQueueSize,
} from 'services/api/evidence/getNewImportedEvidenceInfo';
import { importEvidence as importEvidenceAPI } from 'services/api/evidence/importEvidence';
import { LOADING, NONE, ERROR } from 'constants/apiStatuses';
import { exportEvidenceLogToCSV as exportEvidenceLogToCSVAPI } from 'services/api/evidence/exportEvidenceLogToCSV';
import { exportEvidenceLogToJSON as exportEvidenceLogToJSONAPI } from 'services/api/evidence/exportEvidenceLogToJSON';
import { getSingleEvidence as getSingleEvidenceAPI } from 'services/api/evidence/getSingleEvidence';
import blobDownloader from 'helpers/blobDownloader';

const setGetImportedEvidencesInfoStatus = (status) => ({
	type: EVIDENCES_GET_IMPORTED_EVIDENCES_INFO_STATUS,
	payload: status,
});

export const setImportedEvidencesInfo = (data) => ({
	type: EVIDENCES_SET_IMPORTED_EVIDENCES_INFO,
	payload: data,
});

export const getImportedEvidenceInfo = (data) => (dispatch, getState) => {
	dispatch(setGetImportedEvidencesInfoStatus(LOADING));
	getImportedEvidenceInfoAPI({ evidences: data })
		.then((importedEvidencesInfo) => {
			dispatch(setImportedEvidencesInfo(importedEvidencesInfo));
			dispatch(setGetImportedEvidencesInfoStatus(NONE));
		})
		.catch(() => dispatch(setGetImportedEvidencesInfoStatus(ERROR)));
};

export const setNewImportedEvidencesInfo = (evidences) => ({
	type: EVIDENCES_SET_NEW_IMPORTED_EVIDENCES_INFO,
	payload: evidences,
});

const setGetNewImportedEvidencesInfoStatus = (status) => ({
	type: EVIDENCES_GET_NEW_IMPORTED_EVIDENCES_INFO_STATUS,
	payload: status,
});

export const getNewImportedEvidenceInfo =
	({
		cursor,
		page,
		pageSize,
		changeCursors,
		filters,
		refresh,
		isSetData,
		sorting,
	}) =>
	(dispatch, getState) => {
		dispatch(setGetNewImportedEvidencesInfoStatus(LOADING));
		getNewImportedEvidenceInfoAPI({
			cursor,
			page,
			pageSize,
			filters,
			sorting,
		})
			.then((data) => {
				const currentState = getState();
				const newData = {
					...currentState.evidences.data,
					...data,
				};
				dispatch(setNewImportedEvidencesInfo(newData));
				changeCursors({ ...data, refresh });
				dispatch(setGetNewImportedEvidencesInfoStatus(NONE));
			})
			.catch(() => dispatch(setGetNewImportedEvidencesInfoStatus(ERROR)));
	};

export const changeNewEvidenceFilterField = (value) => ({
	type: IMPORTED_NEW_EVIDENCE_CHANGE_FILTER_FIELD,
	payload: value,
});

export const resetNewEvidenceFilters = (filters) => ({
	type: IMPORTED_EVIDENCE_RESET_FILTERS,
	payload: filters,
});

const setImportEvidencesStatus = (status) => ({
	type: EVIDENCES_IMPORT_EVIDENCES_STATUS,
	payload: status,
});

const setImportEvidences = () => ({
	type: EVIDENCES_IMPORT_EVIDENCES,
});

export const importEvidence = (data) => (dispatch, getState) => {
	dispatch(setImportEvidencesStatus(LOADING));
	importEvidenceAPI({ evidenceInfo: data })
		.then((data) => {
			dispatch(setImportEvidences());
			dispatch(setImportEvidencesStatus(NONE));
		})
		.catch(() => dispatch(setImportEvidencesStatus(ERROR)));
};

export const resetEvidenceLogFilters = (filters) => ({
	type: EVIDENCE_LOG_RESET_FILTERS,
	payload: filters,
});

export const changeEvidenceFilterField = (value) => ({
	type: EVIDENCE_CHANGE_FILTER_FIELD,
	payload: value,
});

export const resetEvidenceFilters = (filters) => ({
	type: EVIDENCE_RESET_FILTERS,
	payload: filters,
});

export const setExportToCSVStatus = (status) => ({
	type: EVIDENCE_LOG_EXPORT_TO_CSV_STATUS,
	payload: status,
});

export const setExportToJSONStatus = (status) => ({
	type: EVIDENCE_LOG_EXPORT_TO_JSON_STATUS,
	payload: status,
});

export const exportToCSV = (filters, sorting, onLoad) => (dispatch) => {
	const body = {
		filters,
		sorting,
	};
	dispatch(setExportToCSVStatus(LOADING));
	exportEvidenceLogToCSVAPI(body)
		.then((res) => {
			blobDownloader({ data: res.data, options: { type: 'text/csv' } });

			dispatch(setExportToCSVStatus(NONE));
			onLoad();
		})
		.catch((_) => {
			dispatch(setExportToCSVStatus(ERROR));
		});
};

export const exportToJSON = (filters, sorting, onLoad) => (dispatch) => {
	const body = {
		filters,
		sorting,
	};
	dispatch(setExportToJSONStatus(LOADING));
	exportEvidenceLogToJSONAPI(body)
		.then((res) => {
			blobDownloader({ data: res.data, options: { type: 'Application/json' } });

			dispatch(setExportToJSONStatus(NONE));
			onLoad();
		})
		.catch((_) => {
			dispatch(setExportToJSONStatus(ERROR));
		});
};

const setGetSingleEvidenceStatus = (status) => ({
	type: EVIDENCE_GET_SINGLE_EVIDENCE_STATUS,
	payload: status,
});

const setSingleEvidence = (evidence) => ({
	type: EVIDENCE_SET_EVIDENCE_ACTION,
	payload: evidence,
});

export const getSingleEvidence = (id) => (dispatch, getState) => {
	dispatch(setGetSingleEvidenceStatus(LOADING));
	getSingleEvidenceAPI(id)
		.then((action) => {
			dispatch(setSingleEvidence(action));
			dispatch(setGetSingleEvidenceStatus(NONE));
		})
		.catch(() => dispatch(setGetSingleEvidenceStatus(ERROR)));
};
