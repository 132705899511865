import generateRandomColor from './generateRandomColor';

const generateDatasetConfigForPieChart = ({
	labels,
	data,
	disableLabels = false,
}) => {
	return {
		labels: !disableLabels ? labels : [],
		datasets: [
			{
				label: labels,
				data,
				backgroundColor: new Array(data?.length || 0)
					.fill(0)
					.map(generateRandomColor),
				rotation: 1,
				hoverOffset: 3,
			},
		],
	};
};

export default generateDatasetConfigForPieChart;
