import { FPSTATS_GET_STATS, FPSTATS_GET_STATS_STATUS } from '../constants';
import { NONE } from 'constants/apiStatuses';

const initialState = {
	getStatsStatus: NONE,
	getStatsData: null,
};

const fpStatsReducer = (state = initialState, action) => {
	switch (action.type) {
		case FPSTATS_GET_STATS_STATUS: {
			return {
				...state,
				getStatsStatus: action.payload,
			};
		}

		case FPSTATS_GET_STATS: {
			return {
				...state,
				getStatsData: action.payload,
			};
		}

		default: {
			return state;
		}
	}
};

export default fpStatsReducer;
