import {
	TRANSACTIONS_GET_SINGLE_TRANSACTION_STATUS,
	TRANSACTIONS_SET_SINGLE_TRANSACTION,
	TRANSACTIONS_SET_TRANSACTIONS,
	TRANSACTIONS_GET_TRANSACTIONS_STATUS,
	TRANSACTIONS_CHANGE_FILTER_FIELD,
	TRANSACTIONS_RESET_FILTERS,
	TRANSACTIONS_EXPORT_TO_JSON_STATUS,
	TRANSACTIONS_EXPORT_TO_CSV_STATUS,
} from '../constants';
import { LOADING, NONE } from 'constants/apiStatuses';
import mergeFilters from 'helpers/mergeFilters';

const initialState = {
	data: null,
	getTransactionsStatus: NONE,
	getSingleTransactionStatus: LOADING,
	exportTransactionsToJSONStatus: NONE,
	exportTransactionsToCSVStatus: NONE,
	singleTransaction: null,
	total: null,
	filters: {
		accountName: '',
		deviceId: '',
		deviceUUID: '',
		deviceTimeZone: '',
		sessionIsp: '',
		sessionCountryName: '',
		ruleSet: '',
		ruleName: [''],
		score: '',
		maxScore: '',
		sessionIp: '',
		id: '',
		minCreationDate: new Date(2019, 11, 30),
		maxCreationDate: new Date(2030, 11, 31),
	},
};

const transactionsReducer = (state = initialState, action) => {
	switch (action.type) {
		case TRANSACTIONS_SET_SINGLE_TRANSACTION: {
			return {
				...state,
				singleTransaction: action.payload,
			};
		}
		case TRANSACTIONS_GET_SINGLE_TRANSACTION_STATUS: {
			return {
				...state,
				getSingleTransactionStatus: action.payload,
			};
		}

		case TRANSACTIONS_SET_TRANSACTIONS: {
			return {
				...state,
				data: action.payload.transactions.map(({ session, ...transaction }) => {
					return {
						...transaction,
						connectionIP: session.connectionIP,
						serverTime: session.serverTime,
						ip: session.ipEnrichment.ip,
						debug: JSON.stringify(session.debug),
						fpAgeInMs: session.fpAgeInMs,
						score: transaction.rating.score,
						ruleSet: transaction.rating.ruleSet,
						deviceId: transaction.device.id,
						accountName: transaction.account.name,
					};
				}),
				total: action.payload.total,
			};
		}

		case TRANSACTIONS_GET_TRANSACTIONS_STATUS: {
			return {
				...state,
				getTransactionsStatus: action.payload,
			};
		}

		case TRANSACTIONS_CHANGE_FILTER_FIELD: {
			let updatedFilters = {};

			if (Array.isArray(action.payload)) {
				action.payload.forEach((item) => {
					if (updatedFilters[item.key]) {
						updatedFilters[item.key].push(item.value);
					} else {
						updatedFilters[item.key] = [item.value];
					}
				});
			} else if (typeof action.payload === 'object') {
				updatedFilters = {
					...state.filters,
					[action.payload.key]: action.payload.value,
				};
			}

			return {
				...state,
				filters: {
					...state.filters,
					...updatedFilters,
				},
			};
		}

		case TRANSACTIONS_RESET_FILTERS: {
			const { ruleName, ...otherFilters } = action.payload || {};
			const updatedRuleName = ruleName
				? Array.isArray(ruleName)
					? ruleName
					: [ruleName]
				: null;
			return {
				...state,
				filters: mergeFilters(
					updatedRuleName
						? { ...otherFilters, ruleName: updatedRuleName }
						: action.payload,
					initialState.filters
				),
			};
		}

		case TRANSACTIONS_EXPORT_TO_JSON_STATUS: {
			return {
				...state,
				exportTransactionsToJSONStatus: action.payload,
			};
		}

		case TRANSACTIONS_EXPORT_TO_CSV_STATUS: {
			return {
				...state,
				exportTransactionsToCSVStatus: action.payload,
			};
		}

		default: {
			return state;
		}
	}
};

export default transactionsReducer;
