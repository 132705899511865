import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { UserForm, withAdmin } from 'components';
import { createUser } from 'actions/usersActions';

const NewUser = (props) => {
	const navigate = useNavigate();

	const { createUser, createUserStatus } = props;

	const handleSubmit = (values) => {
		createUser(values, () => {
			navigate('/role-manager');
		});
	};

	return (
		<UserForm
			handleSubmit={handleSubmit}
			buttonLabel='Create User'
			status={createUserStatus}
		/>
	);
};

const mapStateToProps = ({ users }) => ({
	createUserStatus: users.createUserStatus,
});

const mapDispatchToProps = (dispatch) => ({
	createUser: (body, onLoad) => dispatch(createUser(body, onLoad)),
});
export default withAdmin(connect(mapStateToProps, mapDispatchToProps)(NewUser));
