export const sortRuleSets = (ruleSets) => {
	if (!Array.isArray(ruleSets)) {
		return [];
	}
	return [...ruleSets]?.sort((a, b) => {
		if (a > b) return 1;
		if (a < b) return -1;
		return 0;
	});
};
