const addQueryToSearchParams = (search, query) => {
	const searchParams = new URLSearchParams(search);
	if (searchParams.get(query.name)) {
		searchParams.delete(query.name);
	}
	const newQuery = query.value !== '' ? `&${query.name}=${query.value}` : '';

	return `?${searchParams.toString()}${newQuery}`;
};

export default addQueryToSearchParams;
