export const keys = [
	'1H',
	/*'2H', '4H', '8H',*/
	'12H',
	/*'16H', */
	'24H',
	'7D',
];

export const fieldToLabel = {
	transactionsByAccount: 'Account',
	transactionsByDevice: 'Device',
	transactionsByIP: 'IP',
	countriesByDeviceReport: 'Countries by Device',
	countriesByAccountReport: 'Countries by Account',
	accountsByDeviceReport: 'Accounts by Device',
	devicesByAccountReport: 'Devices by Account',
	transactionsByDeviceByRS: 'Device by RS',
	transactionsByAccountByRS: 'Account by RS',
	transactionsByIPByRS: 'IP by RS',
	countriesByDeviceByRSReport: 'Countries by Device By RS',
	countriesByAccountByRSReport: 'Countries by Account By RS',
};
