import Joi from 'joi';
import destroyRuleIds from '../destroyRuleIds';
import { MAX_STRING_LENGTH } from '../../constants/validations';

const validateRuleForm = (values) => {
	const ruleSchema = Joi.object({
		name: Joi.string()
			.required()
			.messages({
				'any.required': 'Name is required',
				'string.empty': 'Name is required',
				'string.max': `Max length is ${MAX_STRING_LENGTH} symbols`,
			})
			.max(MAX_STRING_LENGTH),
		score: Joi.number().required().greater(-32768).less(32767).messages({
			'any.required': 'Score is required',
			'number.base': 'Score must be number',
			'number.greater': 'Score must be greater than -32768',
			'number.less': 'Score must be less than 32767',
		}),
		placeEvidence: Joi.boolean(),
		evidence: Joi.string()
			.when('placeEvidence', {
				is: true,
				then: Joi.string().required(),
				otherwise: Joi.string().allow(''),
			})
			.messages({
				'any.required': 'Evidence is required',
			}),
		...(!values.defaultTimes
			? {
					validFrom: Joi.date().less(values.validTo).messages({
						'date.less': 'Valid from date must be less than valid to',
					}),
					validTo: Joi.date().greater(values.validFrom).messages({
						'date.greater': 'Valid to date must be present or future time',
					}),
			  }
			: {}),
		...(values.placeEvidence
			? {
					evidenceToEntity: Joi.array().min(1).required().messages({
						'array.min': 'Select at least 1 entity for evidence',
					}),
					...(values.evidenceExpiresIn
						? {
								evidenceExpiresIn: Joi.number()
									.greater(0)
									.less(32767)
									.required()
									.messages({
										'number.base': 'Expiration must be a number',
										'number.greater': 'Expiration must be greater than 0',
										'number.less': 'Expiration must be less than 32767',
									}),
						  }
						: {
								evidenceExpiresIn: Joi.string().allow('', null),
						  }),
			  }
			: {}),
	});

	const ruleSetSchema = Joi.object({
		ruleSet: Joi.array()
			.items(
				Joi.string()
					.required()
					.messages({
						'any.required': 'Rule set is required',
						'string.empty': 'Rule set is required',
						'array.includesRequiredUnknowns': 'Rule sets are required',
						'string.max': `Max length is ${MAX_STRING_LENGTH} symbols`,
					})
					.max(MAX_STRING_LENGTH)
			)
			.messages({
				'array.unique': 'Rule set contains duplicated value',
			})
			.unique((a, b) => a === b && a !== '' && b !== ''),
	});

	const exRuleSetSchema = Joi.object({
		exRuleSet: Joi.array()
			.items(
				Joi.string()
					.max(MAX_STRING_LENGTH)
					.empty('')
					.messages({
						'string.max': `Max length is ${MAX_STRING_LENGTH} symbols`,
					})
			)
			.sparse(true)
			.messages({
				'array.unique': 'Rule set to exclude contains duplicated value',
			})
			.unique((a, b) => a === b && a !== '' && b !== ''),
	});

	const ruleValuesSchema = Joi.object({
		rules: Joi.array().items(
			Joi.array().items(
				Joi.object()
					.keys({
						field: Joi.string().required().messages({
							'any.required': 'Field is required',
						}),
						operator: Joi.string().required().messages({
							'any.required': 'Operator is required',
						}),
						values: Joi.array().items(
							Joi.alternatives().try(
								Joi.string().required().messages({
									'string.empty': 'Values are required',
									'any.required': 'Values are required',
								}),
								Joi.number()
							)
						),
						value: Joi.alternatives().try(
							Joi.string()
								.max(MAX_STRING_LENGTH)
								.messages({
									'string.empty': 'Value is required',
									'any.required': 'Value are required',
									'string.max': `Max value length is ${MAX_STRING_LENGTH}`,
								}),
							Joi.number()
						),
						useField: Joi.boolean(),
					})
					.messages({
						'array.includesRequiredUnknowns': 'Values are required',
					})
			)
		),
	});

	const validateExRuleSetSchema = exRuleSetSchema.validate(
		{
			exRuleSet: values.exRuleSet,
		},
		{ abortEarly: false }
	);

	const validatedRuleSetSchema = ruleSetSchema.validate(
		{
			ruleSet: values.ruleSet,
		},
		{ abortEarly: false }
	);

	const validatedRuleSchema = ruleSchema.validate(
		{
			name: values.name,
			score: values.score,
			placeEvidence: values.placeEvidence,
			evidence: values.evidence,
			...(!values.defaultTimes
				? {
						validFrom: values.validFrom,
						validTo: values.validTo,
				  }
				: {}),
			...(values.placeEvidence
				? {
						evidenceToEntity: values.evidenceToEntity,
						evidenceExpiresIn: values.evidenceExpiresIn,
				  }
				: {}),
		},
		{
			abortEarly: false,
		}
	);

	const validatedRuleValuesSchema = ruleValuesSchema.validate(
		{
			rules: destroyRuleIds(values.rules),
		},
		{ abortEarly: false }
	);

	let ruleValuesErrors = {};
	if (validatedRuleValuesSchema.error) {
		ruleValuesErrors = validatedRuleValuesSchema.error.details.reduce(
			(acc, detail) => {
				acc.rules[detail.path[1]] = detail.message;
				return acc;
			},
			{ rules: [] }
		);
	}

	let ruleErrors = {};
	if (validatedRuleSchema.error) {
		ruleErrors = Object.fromEntries(
			validatedRuleSchema.error.details.map((detail) => [
				detail.path[0],
				detail.message,
			])
		);
	}

	let exRuleSetErrors = {};
	if (validateExRuleSetSchema.error) {
		exRuleSetErrors = validateExRuleSetSchema.error.details.reduce(
			(acc, detail) => {
				acc.exRuleSet[detail.path[1]] = detail.message;
				return acc;
			},
			{
				exRuleSet: [],
			}
		);
	}

	let ruleSetErrors = {};
	if (validatedRuleSetSchema.error) {
		ruleSetErrors = validatedRuleSetSchema.error.details.reduce(
			(acc, detail) => {
				acc.ruleSet[detail.path[1]] = detail.message;
				return acc;
			},
			{
				ruleSet: [],
			}
		);
	}

	return {
		...ruleValuesErrors,
		...exRuleSetErrors,
		...ruleErrors,
		...ruleSetErrors,
	};
};

export default validateRuleForm;
