import React from 'react';
import { Field, Form } from 'react-final-form';
import { Button, TextField, Autocomplete } from '@mui/material';
import validateUserForm from 'helpers/validations/user';
import isSubmitButtonDisabled from 'helpers/isSubmitButtonDisabled';
import './styles.css';

const UserForm = (props) => {
	const { initialValues, handleSubmit, buttonLabel, status } = props;

	return (
		<Form
			onSubmit={handleSubmit}
			initialValues={initialValues || { role: 'admin' }}
			validate={validateUserForm}
			render={({ handleSubmit, errors, touched }) => (
				<form onSubmit={handleSubmit} className='newUser'>
					<Field name='email'>
						{(props) => {
							return (
								<TextField
									error={Boolean(props.meta.touched && props.meta.error)}
									helperText={props.meta.touched && props.meta.error}
									autoComplete='off'
									value={props.input.value}
									name={props.input.name}
									onChange={props.input.onChange}
									className='input'
									label='Email'
									InputLabelProps={{ shrink: true }}
								/>
							);
						}}
					</Field>
					<Field name='username'>
						{(props) => {
							return (
								<TextField
									error={Boolean(props.meta.touched && props.meta.error)}
									helperText={props.meta.touched && props.meta.error}
									autoComplete='off'
									value={props.input.value}
									name={props.input.name}
									onChange={props.input.onChange}
									className='input'
									label='Username'
									InputLabelProps={{ shrink: true }}
								/>
							);
						}}
					</Field>
					<Field name='password'>
						{(props) => (
							<TextField
								type={'password'}
								error={Boolean(props.meta.touched && props.meta.error)}
								helperText={props.meta.touched && props.meta.error}
								autoComplete='off'
								value={props.input.value}
								name={props.input.name}
								onChange={props.input.onChange}
								className='input'
								label='Password'
								InputLabelProps={{ shrink: true }}
							/>
						)}
					</Field>
					<Field name='role'>
						{(props) => (
							<Autocomplete
								disableClearable
								value={props.input.value}
								onChange={(event, value) => props.input.onChange(value)}
								getOptionLabel={(option) => option}
								options={['admin', 'user']}
								renderInput={(params) => (
									<TextField
										{...params}
										error={props.meta.touched && props.meta.error}
										helperText={props.meta.touched && props.meta.error}
										autoComplete='off'
										name={props.input.name}
										className='input'
										label='Role'
										InputLabelProps={{ shrink: true }}
									/>
								)}
							/>
						)}
					</Field>
					<Button
						fullWidth
						disabled={isSubmitButtonDisabled(errors, touched, status)}
						type='submit'
						className='input'
						variant='contained'
						color='primary'
					>
						{buttonLabel}
					</Button>
				</form>
			)}
		/>
	);
};

export default UserForm;
