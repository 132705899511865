const changeLogs = [
	{
		date: '17 Sept, 2021',
		title: 'Conflict-Resolution in DB',
		description:
			'When the same device/account is used with high frequency, sometimes you saw a 409 Conflict. Resolved now.',
	},
	{
		date: '16 Sept, 2021',
		title: 'AuditLog',
		description: 'Added audit log to system',
	},
	{
		date: '16 Sept, 2021',
		title: 'Evidence-View',
		description: 'Evidence can be used to show items with evidence',
	},
	{
		date: '15 Sept, 2021',
		title: 'Bugfixes',
		description:
			'Multiple minor issues fixed: ageInMs overflow, no fp-white-page-of-doom in FE fixed, no fp-rule enabled',
	},
	{
		date: '14 Sept, 2021',
		title: 'Bugfixes',
		description: 'Role Management & Report by Rule fixed',
	},
	{
		date: '13 September, 2021',
		title: 'Roles in Backend',
		description: 'Backend calls work with rules',
	},
	{
		date: '13 September, 2021',
		title: 'UI Improvements',
		description: 'Minor UI improvements',
	},
	{
		date: '10 September, 2021',
		title: 'IPBlocks',
		description: 'IPBlocks can have evidence',
	},
];

export default changeLogs;
