import {
	DEVICES_SET_SINGLE_DEVICE,
	DEVICES_GET_SINGLE_DEVICE_STATUS,
	DEVICES_CREATE_EVIDENCE_STATUS,
	DEVICES_DELETE_EVIDENCE_STATUS,
	DEVICES_DELETE_EVIDENCE,
	DEVICES_SET_DEVICES,
	DEVICES_GET_DEVICES_STATUS,
	DEVICES_CHANGE_FILTER_FIELD,
	DEVICES_SET_TRANSACTIONS,
	DEVICES_SET_ACCOUNTS,
	DEVICES_GET_ACCOUNTS_FOR_EVIDENCE_STATUS,
	DEVICES_SET_ACCOUNTS_FOR_EVIDENCE,
	DEVICES_RESET_FILTERS,
	DEVICES_CHANGE_ACCOUNTS_FOR_EVIDENCE_FILTER_FIELD,
	DEVICES_CHANGE_TRANSACTIONS_FILTER_FIELD,
	DEVICES_RESET_TRANSACTIONS_FILTERS,
	DEVICES_GET_TRANSACTIONS_STATUS,
	DEVICES_GET_ACCOUNTS_STATUS,
	DEVICES_EXPORT_TRANSACTIONS_TO_JSON_STATUS,
	DEVICES_EXPORT_TRANSACTIONS_TO_CSV_STATUS,
	DEVICES_ADD_EVIDENCE_COMMENT_STATUS,
	DEVICES_ADD_EVIDENCE_COMMENT,
	DEVICES_GET_RESET_VECTOR_STATUS,
	DEVICES_SET_EVIDENCE_HISTORY,
	DEVICES_GET_EVIDENCE_HISTORY_STATUS,
} from '../constants';
import { LOADING, NONE } from 'constants/apiStatuses';
import mergeFilters from '../../helpers/mergeFilters';

const initialState = {
	getDevicesStatus: LOADING,
	getSingleDeviceStatus: LOADING,
	evidenceDeviceAccounts: null,
	getEvidenceDeviceAccountsStatus: LOADING,
	exportTransactionsToJSONStatus: NONE,
	exportTransactionsToCSVStatus: NONE,
	singleDevice: null,
	singleDeviceAccountsForEvidence: {
		status: NONE,
		total: null,
		data: null,
	},
	singleDeviceTransactions: {
		status: NONE,
		total: null,
		data: null,
	},
	singleDeviceAccounts: {
		status: NONE,
		total: null,
		data: null,
	},
	evidenceHistory: {
		status: NONE,
		total: null,
		data: null,
	},
	devicesFilterForEvidence: {
		lastSeen: new Date(new Date() - 3 * 30 * 24 * 60 * 60 * 1000),
	},
	createEvidenceStatus: NONE,
	deleteEvidenceStatus: NONE,
	addCommentStatus: NONE,
	resetVectorStatus: NONE,
	data: null,
	total: null,
	filters: {
		id: '',
		uuid: '',
		gpu: '',
		app: '',
		osType: '',
		osVersion: '',
	},
	transactionFilters: {
		accountName: '',
		deviceId: '',
		deviceUUID: '',
		deviceTimeZone: '',
		sessionIsp: '',
		sessionCountryName: '',
		ruleSet: '',
		ruleName: [''],
		score: '',
		maxScore: '',
		sessionIp: '',
		id: '',
		minCreationDate: new Date(2019, 11, 30),
		maxCreationDate: new Date(2030, 11, 31),
	},
};

const devicesReducer = (state = initialState, action) => {
	switch (action.type) {
		case DEVICES_GET_DEVICES_STATUS: {
			return {
				...state,
				getDevicesStatus: action.payload,
			};
		}

		case DEVICES_SET_DEVICES: {
			return {
				...state,
				data: action.payload.devices,
				total: action.payload.total,
			};
		}

		case DEVICES_SET_SINGLE_DEVICE: {
			return {
				...state,
				singleDevice: action.payload,
			};
		}

		case DEVICES_GET_SINGLE_DEVICE_STATUS: {
			return {
				...state,
				getSingleDeviceStatus: action.payload,
			};
		}

		case DEVICES_GET_ACCOUNTS_FOR_EVIDENCE_STATUS: {
			return {
				...state,
				singleDeviceAccountsForEvidence: {
					...state.singleDeviceAccountsForEvidence,
					status: action.payload,
				},
			};
		}

		case DEVICES_SET_ACCOUNTS_FOR_EVIDENCE: {
			return {
				...state,
				singleDeviceAccountsForEvidence: {
					...state.singleDeviceAccountsForEvidence,
					total: action.payload.total,
					data: action.payload.accounts,
				},
			};
		}

		case DEVICES_CREATE_EVIDENCE_STATUS: {
			return {
				...state,
				createEvidenceStatus: action.payload,
			};
		}

		case DEVICES_DELETE_EVIDENCE: {
			const evidenceIndex = state.evidenceHistory.data.findIndex(
				(evidence) => evidence.id === action.payload.id
			);
			return {
				...state,
				evidenceHistory: {
					...state.evidenceHistory,
					data: [
						...state.evidenceHistory.data.slice(0, evidenceIndex),
						action.payload,
						...state.evidenceHistory.data.slice(evidenceIndex + 1),
					],
				},
			};
		}

		case DEVICES_DELETE_EVIDENCE_STATUS: {
			return {
				...state,
				deleteEvidenceStatus: action.payload,
			};
		}

		case DEVICES_CHANGE_FILTER_FIELD: {
			return {
				...state,
				filters: {
					...state.filters,
					[action.payload.key]: action.payload.value,
				},
			};
		}

		case DEVICES_SET_ACCOUNTS: {
			return {
				...state,
				singleDeviceAccounts: {
					...state.singleDeviceAccounts,
					data: action.payload.accounts,
					total: action.payload.total,
				},
			};
		}

		case DEVICES_SET_TRANSACTIONS: {
			return {
				...state,
				singleDeviceTransactions: {
					...state.singleDeviceTransactions,
					data: action.payload.transactions.map(
						({ session, ...transaction }) => {
							return {
								...transaction,
								connectionIP: session.connectionIP,
								serverTime: session.serverTime,
								ip: session.ipEnrichment.ip,
								debug: JSON.stringify(session.debug),
								fpAgeInMs: session.fpAgeInMs,
								score: transaction.rating.score,
								ruleSet: transaction.rating.ruleSet,
								deviceId: transaction.device.id,
								accountName: transaction.account.name,
							};
						}
					),
					total: action.payload.total,
				},
			};
		}

		case DEVICES_RESET_FILTERS: {
			return {
				...state,
				filters: mergeFilters(action.payload, initialState.filters),
			};
		}

		case DEVICES_CHANGE_ACCOUNTS_FOR_EVIDENCE_FILTER_FIELD: {
			return {
				...state,
				devicesFilterForEvidence: {
					...state.devicesFilterForEvidence,
					[action.payload.key]: action.payload.value,
				},
			};
		}

		case DEVICES_CHANGE_TRANSACTIONS_FILTER_FIELD: {
			let updatedFilters = {};

			if (Array.isArray(action.payload)) {
				action.payload.forEach((item) => {
					if (updatedFilters[item.key]) {
						updatedFilters[item.key].push(item.value);
					} else {
						updatedFilters[item.key] = [item.value];
					}
				});
			} else if (typeof action.payload === 'object') {
				updatedFilters = {
					...state.transactionFilters,
					[action.payload.key]: action.payload.value,
				};
			}

			return {
				...state,
				transactionFilters: {
					...state.transactionFilters,
					...updatedFilters,
				},
			};
		}

		case DEVICES_RESET_TRANSACTIONS_FILTERS: {
			return {
				...state,
				transactionFilters: mergeFilters(
					action.payload,
					initialState.transactionFilters
				),
			};
		}

		case DEVICES_GET_TRANSACTIONS_STATUS: {
			return {
				...state,
				singleDeviceTransactions: {
					...state.singleDeviceTransactions,
					status: action.payload,
				},
			};
		}

		case DEVICES_GET_ACCOUNTS_STATUS: {
			return {
				...state,
				singleDeviceAccounts: {
					...state.singleDeviceAccounts,
					status: action.payload,
				},
			};
		}

		case DEVICES_EXPORT_TRANSACTIONS_TO_JSON_STATUS: {
			return {
				...state,
				exportTransactionsToJSONStatus: action.payload,
			};
		}

		case DEVICES_EXPORT_TRANSACTIONS_TO_CSV_STATUS: {
			return {
				...state,
				exportTransactionsToCSVStatus: action.payload,
			};
		}

		case DEVICES_ADD_EVIDENCE_COMMENT_STATUS: {
			return {
				...state,
				addCommentStatus: action.payload,
			};
		}

		case DEVICES_ADD_EVIDENCE_COMMENT: {
			const evidenceIndex = state.evidenceHistory.data.findIndex(
				(evidence) => evidence.id === action.payload.id
			);

			return {
				...state,
				evidenceHistory: {
					...state.evidenceHistory,
					data: [
						...state.evidenceHistory.data.slice(0, evidenceIndex),
						action.payload,
						...state.evidenceHistory.data.slice(evidenceIndex + 1),
					],
				},
			};
		}

		case DEVICES_GET_RESET_VECTOR_STATUS: {
			return {
				...state,
				resetVectorStatus: action.payload,
			};
		}

		case DEVICES_SET_EVIDENCE_HISTORY: {
			return {
				...state,
				evidenceHistory: {
					...state.evidenceHistory,
					data: action.payload.evidences,
					total: action.payload.total,
				},
			};
		}

		case DEVICES_GET_EVIDENCE_HISTORY_STATUS: {
			return {
				...state,
				evidenceHistory: {
					...state.evidenceHistory,
					status: action.payload,
				},
			};
		}

		default: {
			return state;
		}
	}
};

export default devicesReducer;
