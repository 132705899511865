function sortReasons(reasons, key) {
	let result = [];
	if (Array.isArray(reasons)) {
		result = reasons.sort((a, b) => parseInt(a[key]) - parseInt(b[key]));
	}
	return result;
}

function getReasonNameById(evidenceTypes, id) {
	const reason = evidenceTypes.find((elem) => elem.id === id)?.name;
	return reason ?? id;
}

function getReasonIdByName(evidenceTypes, name) {
	return evidenceTypes.find((elem) => elem.name === name)?.id;
}

export { sortReasons, getReasonNameById, getReasonIdByName };
