import axiosInstance from '../index';

const authorize = (body) => {
	return axiosInstance.post('/auth/login', body).then((res) => res.data);
};

const gameforgeAuthorize = () => {
	const { REACT_APP_GAME_FORGE_AUTH_SCOPE } = process.env;
	const {
		REACT_APP_GAME_FORGE_AUTH_URL,
		REACT_APP_GAME_FORGE_AUTH_CLIENT_ID,
		REACT_APP_GAME_FORGE_AUTH_REDIRECT_URI,
	} = document;

	window.open(
		`${REACT_APP_GAME_FORGE_AUTH_URL}/authorize?client_id=${REACT_APP_GAME_FORGE_AUTH_CLIENT_ID}&redirect_uri=${REACT_APP_GAME_FORGE_AUTH_REDIRECT_URI}&scope=${REACT_APP_GAME_FORGE_AUTH_SCOPE}&response_type=code&auto_login=true`,
		'_self'
	);
};

export { authorize, gameforgeAuthorize };
