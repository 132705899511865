const getQueryFromSearchParams = (searchParamsIterator, queryObject = {}) => {
	const { done, value } = searchParamsIterator.next();
	if (done) {
		return queryObject;
	} else {
		queryObject[value[0]] = value[1];
		return getQueryFromSearchParams(searchParamsIterator, queryObject);
	}
};

export default getQueryFromSearchParams;
