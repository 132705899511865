import {
	RATING_SET_RATING,
	RATING_GET_RATING_STATUS,
	RATING_GET_TEST_RATING_STATUS,
} from '../constants';
import { NONE } from 'constants/apiStatuses';

const initialState = {
	getRatingStatus: NONE,
	getTestRatingStatus: NONE,
	data: null,
};

const ratingReducer = (state = initialState, action) => {
	switch (action.type) {
		case RATING_GET_RATING_STATUS: {
			return {
				...state,
				getRatingStatus: action.payload,
			};
		}

		case RATING_GET_TEST_RATING_STATUS: {
			return {
				...state,
				getTestRatingStatus: action.payload,
			};
		}

		case RATING_SET_RATING: {
			return {
				...state,
				data: action.payload,
			};
		}

		default: {
			return state;
		}
	}
};

export default ratingReducer;
