import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
	getSingleIp,
	setSingleIp,
	setGetSingleIpStatus,
	deleteEvidence,
	addEvidenceComment,
	getIpsEvidenceHistory,
} from 'actions/ipsActions';
import { Typography, Grid, Card, CardHeader, CardContent } from '@mui/material';
import { useParams } from 'react-router-dom';
import { LOADING } from 'constants/apiStatuses';
import { EvidenceManager, GoogleMap } from 'components';
import KeyValueTable, { RowValue } from 'components/KeyValueTable';

const Ip = (props) => {
	const params = useParams();

	const {
		getSingleIp,
		setSingleIp,
		setGetSingleIpStatus,
		ip,
		getSingleIpStatus,
		deleteEvidence,
		addEvidenceComment,
		getIpsEvidenceHistory,
		evidenceHistory,
	} = props;

	useEffect(() => {
		getSingleIp(params.id);
		return () => {
			setSingleIp(null);
			setGetSingleIpStatus(LOADING);
		};
	}, []);

	if (getSingleIpStatus === LOADING) {
		return <div>Loading...</div>;
	}

	const pageTitle = <Typography variant='h3'>Ip</Typography>;

	return (
		<Card>
			<CardHeader title={pageTitle} />
			<CardContent>
				<Grid container spacing={1}>
					<Grid item xs={6}>
						<KeyValueTable>
							<RowValue oKey='Ip:' oValue={ip.ip} />
							<RowValue oKey='Proxy:' oValue={ip.isProxy} />
							<RowValue oKey='Satellite:' oValue={ip.isSatellite} />
							<RowValue oKey='Latitude:' oValue={ip.location?.lat} />
							<RowValue oKey='Longitude:' oValue={ip.location?.lon} />
							<RowValue oKey='Accuracy radius:' oValue={ip.accuracyRadius} />
							<RowValue oKey='Country ISO code:' oValue={ip.countryISOCode} />
							<RowValue oKey='Country name:' oValue={ip.countryName} />
							<RowValue oKey='Name:' oValue={ip.name} />
							<RowValue oKey='Timezone:' oValue={ip.timeZone} />
							<RowValue oKey='Isp:' oValue={ip.isp} />
						</KeyValueTable>
					</Grid>
					<Grid item xs={6}>
						<CardContent>
							<GoogleMap location={ip.location} />
						</CardContent>
					</Grid>
				</Grid>
			</CardContent>
			<CardContent>
				<EvidenceManager
					type='ip'
					getEvidences={getIpsEvidenceHistory}
					evidenceHistory={evidenceHistory}
					deleteRequest={deleteEvidence}
					addCommentRequest={addEvidenceComment}
					createEvidenceUrl={`/new-ip-evidence/${ip.id}`}
					entityId={ip.id}
				/>
			</CardContent>
		</Card>
	);
};

const mapStateToProps = ({ ips }) => ({
	ip: ips.singleIp,
	getSingleIpStatus: ips.getSingleIpStatus,
	evidenceHistory: ips.evidenceHistory,
});

const mapDispatchToProps = (dispatch) => ({
	getSingleIp: (id) => dispatch(getSingleIp(id)),
	deleteEvidence: (body, onLoad) => dispatch(deleteEvidence(body, onLoad)),
	addEvidenceComment: (body, onLoad) =>
		dispatch(addEvidenceComment(body, onLoad)),
	setSingleIp: (data) => dispatch(setSingleIp(data)),
	setGetSingleIpStatus: (status) => dispatch(setGetSingleIpStatus(status)),
	getIpsEvidenceHistory: (body) => dispatch(getIpsEvidenceHistory(body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Ip);
