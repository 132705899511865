import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { NotificationService, ThemeContext } from 'components';
import Router from './router';
import store from './redux/store';
import '@fontsource/roboto';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider } from '@mui/material/styles';

const root = createRoot(document.getElementById('root'));

root.render(
	<StyledEngineProvider injectFirst>
		<Provider store={store}>
			<ThemeContext>
				<SnackbarProvider
					hideIconVariant
					autoHideDuration={3000}
					maxSnack={3}
					anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				>
					<NotificationService />
					<CssBaseline />
					<Router />
				</SnackbarProvider>
			</ThemeContext>
		</Provider>
	</StyledEngineProvider>
);
