import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
	Box,
	Typography,
	Table,
	TableBody,
	TableHead,
	TableCell,
	TableRow,
	Button,
} from '@mui/material';

import { getUsers, deleteUser } from 'actions/usersActions';
import { StripedTableRow, DeleteModal } from 'components';
import sortObjectArray from 'helpers/sortObjectArray';
import { dateFormatter } from 'helpers/formatters';
import LastAction from '../User/LastAction';
import './styles.css';

const RoleManagement = (props) => {
	const { users, getUsers, deleteUser, userInfo } = props;

	const navigate = useNavigate();

	const [userIdToDelete, setUserIdToDelete] = React.useState('');

	const handleDeleteUser = () => {
		deleteUser(userIdToDelete, () => setUserIdToDelete(''));
	};

	const handleCloseModal = () => {
		setUserIdToDelete('');
	};

	useEffect(() => {
		getUsers();
	}, []);

	let usersToRender = sortObjectArray(users, 'username');

	if (userInfo.role === 'user') {
		usersToRender = usersToRender.filter((item) => item.id === userInfo.id);
	}

	const isButtonDisabled = (userId) => {
		return userId === userInfo.id;
	};

	const deleteUserClick = (userId) => (e) => {
		e.stopPropagation();
		setUserIdToDelete(userId);
	};

	return (
		<Box>
			<Typography variant='h5'>User Management</Typography>
			{userInfo.role === 'admin' ? (
				<Button
					color='primary'
					variant='contained'
					className='addUser'
					onClick={() => navigate('/new-user')}
				>
					Add user
				</Button>
			) : null}
			{users && (
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Email</TableCell>
							<TableCell>Username</TableCell>
							<TableCell>Role</TableCell>
							<TableCell>Last action</TableCell>
							<TableCell>Last login</TableCell>
							<TableCell>Delete</TableCell>
							<TableCell>Id</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{usersToRender.map((user) => (
							<StripedTableRow
								className='tableRow'
								key={user.id}
								onClick={() => navigate(`/users/${user.id}`)}
							>
								<TableCell>{user.email}</TableCell>
								<TableCell>{user.username}</TableCell>
								<TableCell>{user.role}</TableCell>
								<TableCell>
									<LastAction
										lastAction={user.lastAction}
										userName={user.username}
									/>
								</TableCell>
								<TableCell>
									{dateFormatter(user.lastLogin?.creationDate)}
								</TableCell>
								<TableCell>
									<Button
										variant='contained'
										color='secondary'
										disabled={isButtonDisabled(user.id)}
										onClick={deleteUserClick(user.id)}
									>
										Delete
									</Button>
								</TableCell>
								<TableCell>{user.id}</TableCell>
							</StripedTableRow>
						))}
					</TableBody>
				</Table>
			)}
			{userIdToDelete && (
				<DeleteModal
					title='Deleting user'
					onClose={handleCloseModal}
					onSubmit={handleDeleteUser}
					modalBody='Are you sure want to delete this user?'
				/>
			)}
		</Box>
	);
};

const mapStateToProps = ({ users, auth }) => ({
	users: users.data,
	userInfo: auth.userInfo,
});

const mapDispatchToProps = (dispatch) => ({
	getUsers: () => dispatch(getUsers),
	deleteUser: (id, onLoad) => dispatch(deleteUser(id, onLoad)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RoleManagement);
