import CustomAccordion from '../Accordion';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Grid,
	Typography,
} from '@mui/material';
import React from 'react';
import '../styles.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import momentTimezone from 'moment-timezone';
import { dateFormatter } from 'helpers/formatters';
import KeyValueTable, { RowValue } from 'components/KeyValueTable';

const DeviceToCreate = ({ deviceInfo }) => {
	const {
		osVersion,
		accounts,
		evidenceHistory,
		gpu,
		osType,
		con,
		mem,
		width,
		height,
		depth,
		app,
		product,
		lang,
		lStore,
		sStore,
		vendor,
		cookies,
		video,
		audio,
		media,
		plugins,
		audioFP,
		webglFP,
		canvasFP,
		fonts,
		analyser,
		audioC,
		permissions,
		tz,
		creation,
		creationDate,
		id,
		evidence,
		...restDevice
	} = deviceInfo ?? {};

	return (
		<CustomAccordion title='Device'>
			<Grid container spacing={1}>
				<Grid item xs={6}>
					<Typography variant='h5'>Device info</Typography>
					<KeyValueTable>
						<RowValue oKey='GPU:' oValue={gpu} />
						<RowValue oKey='Platform:' oValue={osType} />
						<RowValue oKey='Platform version:' oValue={osVersion} />
						<RowValue oKey='Memory:' oValue={mem} />
						<RowValue oKey='Concurrency hardware:' oValue={con} />
						<RowValue oKey='Width:' oValue={width} />
						<RowValue oKey='Height:' oValue={height} />
						<RowValue oKey='Depth:' oValue={depth} />
					</KeyValueTable>
				</Grid>
				<Grid item xs={6}>
					<Typography variant='h5'>Browser info</Typography>
					<KeyValueTable>
						<RowValue oKey='Browser name:' oValue={app} />
						<RowValue oKey='Product:' oValue={product} />
						<RowValue oKey='Languages:' oValue={lang} />
						<RowValue oKey='Local storage:' oValue={lStore} />
						<RowValue oKey='Session storage:' oValue={sStore} />
						<RowValue oKey='Vendor:' oValue={vendor} />
						<RowValue oKey='Cookies:' oValue={cookies} />
					</KeyValueTable>
				</Grid>
				<Grid item xs={6}>
					<Accordion elevation={0} className='accordion'>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<Typography variant='h5'>Other Info</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<KeyValueTable>
								<RowValue oKey='Analyser:' oValue={analyser} />
								<RowValue oKey='Audio:' oValue={audio} />
								<RowValue oKey='Audio Context:' oValue={audioC} />
								<RowValue oKey='Fonts:' oValue={fonts} />
								<RowValue oKey='Media:' oValue={media} />
								<RowValue oKey='Permissions:' oValue={permissions} />
								<RowValue oKey='Video:' oValue={video} />
								<RowValue oKey='Plugins:' oValue={plugins} />
								<RowValue
									oKey='Timezone:'
									oValue={`UTC ${momentTimezone.tz(tz).format('Z')} ${tz}`}
								/>

								{Object.entries(restDevice).map(([key, value]) => {
									return <RowValue oKey={`${key}:`} oValue={value} key={key} />;
								})}
								<RowValue oKey='creation:' oValue={dateFormatter(creation)} />
								<RowValue
									oKey='creationDate:'
									oValue={dateFormatter(creationDate)}
								/>
							</KeyValueTable>
						</AccordionDetails>
					</Accordion>
				</Grid>
				<Grid item xs={6}>
					<Accordion elevation={0} className='accordion'>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<Typography variant='h5'>Fingerprints</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<KeyValueTable>
								<RowValue oKey='Audio FP:' oValue={audioFP} />
								<RowValue oKey='Canvas FP:' oValue={canvasFP} />
								<RowValue oKey='Webgl FP:' oValue={webglFP} />
							</KeyValueTable>
						</AccordionDetails>
					</Accordion>
				</Grid>
			</Grid>
		</CustomAccordion>
	);
};

export default DeviceToCreate;
