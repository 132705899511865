import React, { Fragment } from 'react';
import {
	Box,
	Checkbox,
	FormControl,
	FormControlLabel,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Autocomplete,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import generateId from 'helpers/generateId';
import '../../styles.css';
import './styles.css';

const RuleFieldManager = (props) => {
	const {
		blockIndex,
		total,
		handleAddRuleValue,
		handleDeleteRuleValue,
		evidenceTypes,
		fields,
		ruleKeys,
		touched,
		errors,
	} = props;

	const isRuleBlockHasError = touched && errors[blockIndex];

	const isNumber = (key) =>
		ruleKeys.find((ruleKey) => ruleKey.key === key)?.type === 'number';

	const isEvidenceField = (field) => {
		return (
			field === 'account.evidence' ||
			field === 'device.evidence' ||
			field === 'session.ipEnrichment.evidence'
		);
	};

	const handleUpdateRuleField = (event, value, index, rule) => {
		const newValue = fields.map((item, itemIndex) => {
			if (itemIndex !== index) {
				return item;
			}
			const getInitialValue = () => {
				if (rule.useField) {
					return [{ value: '', id: generateId() }];
				}
				if (isEvidenceField(value.key)) {
					return [{ value: evidenceTypes[0].id, id: generateId() }];
				}
				return [{ value: 'Europe/Kiev', id: generateId() }];
			};
			return {
				...item,
				value: getInitialValue(),
				field: value.key,
				operator: isNumber(value) ? '>=' : '=',
			};
		});
		total.update(blockIndex, newValue);
	};

	const handleUpdateRuleOperator = (event, value, index, rule) => {
		const newValue = fields.map((item, itemIndex) => {
			if (itemIndex !== index) {
				return item;
			}
			return {
				...item,
				operator: value,
			};
		});
		total.update(blockIndex, newValue);
	};

	const handleUpdateRuleMark = (event, value, index, ruleOptions) => {
		const newValue = fields.map((item, itemIndex) => {
			if (itemIndex !== index) {
				return item;
			}
			return {
				...item,
				useField: value,
				value: [
					{
						value: '',
						id: generateId(),
					},
				],
			};
		});
		total.update(blockIndex, newValue);
	};

	const ruleOptions = ruleKeys
		.map(({ key, label }) => ({ key, label }))
		.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));

	const handleShowIcon = (condition, type, onClick) => {
		if (condition) {
			return (
				<IconButton disableTouchRipple onClick={onClick}>
					{type === 'add' ? <AddIcon /> : <CloseIcon />}
				</IconButton>
			);
		}

		return null;
	};

	const renderTextField = (restProps) => {
		return (
			<TextField
				fullWidth
				InputLabelProps={{ shrink: true }}
				autoComplete='off'
				className={!restProps.value && isRuleBlockHasError ? 'ruleInput' : ''}
				{...restProps}
			/>
		);
	};

	const renderRuleValues = (rule, index, ruleOperator, currentRuleKeyField) => {
		return rule.value.map((value, idx, values) => {
			const handleAddRuleValue = () => {
				const newValue = fields.map((item, itemIndex) => {
					if (itemIndex !== index) {
						return item;
					}
					return {
						...item,
						value: [
							...item.value,
							{
								value: '',
								id: generateId(),
							},
						],
					};
				});
				total.update(blockIndex, newValue);
			};

			const handleDeleteRuleValue = () => {
				const newValue = fields.map((item, itemIndex) => {
					if (itemIndex !== index) {
						return item;
					}
					return {
						...item,
						value: [...item.value.slice(0, idx), ...item.value.slice(idx + 1)],
					};
				});
				total.update(blockIndex, newValue);
			};

			const handleUpdateRuleValue = (e) => {
				const newValue = fields.map((item, itemIndex) => {
					if (itemIndex !== index) {
						return item;
					}
					return {
						...item,
						value: [
							...item.value.slice(0, idx),
							{ ...value, value: e.target.value },
							...item.value.slice(idx + 1),
						],
					};
				});
				total.update(blockIndex, newValue);
			};

			const ruleSelectClass =
				!value.value && isRuleBlockHasError ? 'ruleInputSelect' : '';

			const getFieldsByType = (fields) => {
				return fields
					.filter(
						(elem) => !elem.exclude && elem.type === currentRuleKeyField.type
					)
					.filter((elem) => elem.key !== currentRuleKeyField.key);
			};

			const getRuleInputField = () => {
				if (rule.useField) {
					return (
						<Autocomplete
							disableClearable
							value={ruleOptions.find((elem) => elem.key === value.value)}
							onChange={(e, value, index) =>
								handleUpdateRuleValue({ target: { value: value.key } })
							}
							isOptionEqualToValue={(option, value) => option.label}
							className='widthFull'
							getOptionLabel={(option) => option.label}
							options={getFieldsByType(ruleKeys)}
							renderInput={(params) => {
								return renderTextField({
									...params,
									value: value.value,
									label: 'Value',
								});
							}}
						/>
					);
				}
				if (isEvidenceField(rule.field)) {
					return (
						<FormControl fullWidth>
							<InputLabel
								id='evidence'
								classes={{
									root: ruleSelectClass,
								}}
							>
								Evidence
							</InputLabel>
							<Select
								value={value.value}
								labelId='evidence'
								onChange={handleUpdateRuleValue}
								className={ruleSelectClass}
							>
								{evidenceTypes.map((evidence) => (
									<MenuItem value={evidence.id} key={evidence.id}>
										{evidence.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					);
				}
				return renderTextField({
					value: value.value,
					onChange: handleUpdateRuleValue,
					label: 'Value',
				});
			};

			return (
				<Box
					className='input'
					display='flex'
					alignItems='center'
					key={value.id}
				>
					{getRuleInputField()}
					{handleShowIcon(
						idx === values.length - 1 &&
							['=', '!='].includes(ruleOperator) &&
							!rule.useField,
						'add',
						handleAddRuleValue
					)}
					{handleShowIcon(values.length !== 1, 'delete', handleDeleteRuleValue)}
				</Box>
			);
		});
	};

	return fields.map(({ id, ...rule }, index) => {
		const currentRuleKeyField = ruleKeys.find(
			(elem) => elem.key === rule.field
		);

		return (
			<Fragment key={id}>
				<Box className={`rule`}>
					<Box className='rulePanel'>
						{handleShowIcon(
							index === fields.length - 1,
							'add',
							handleAddRuleValue
						)}
						{handleShowIcon(fields.length !== 1, 'delete', () =>
							handleDeleteRuleValue(index)
						)}
					</Box>
					<Autocomplete
						disableClearable
						value={ruleKeys.find(({ key }) => key === rule.field)}
						onChange={(e, value) =>
							handleUpdateRuleField(e, value, index, { ...rule, id })
						}
						isOptionEqualToValue={(option, value) => option.label}
						getOptionLabel={(option) => option.label}
						options={ruleOptions}
						renderInput={(params) =>
							renderTextField({
								...params,
								value: rule.operator,
								label: 'Field name',
							})
						}
					/>
					<Autocomplete
						disableClearable
						value={rule.operator}
						onChange={(e, value) =>
							handleUpdateRuleOperator(e, value, index, { ...rule, id })
						}
						getOptionLabel={(option) => option}
						options={
							isNumber(rule.field)
								? ['>', '<', '>=', '<=', '=', '!=']
								: ['=', '!=']
						}
						renderInput={(params) =>
							renderTextField({
								...params,
								className: 'input',
								label: 'Operator',
							})
						}
					/>
					{!currentRuleKeyField?.exclude && (
						<FormControlLabel
							className='compareCheckbox'
							control={
								<Checkbox
									checked={rule.useField}
									onChange={(e, value) =>
										handleUpdateRuleMark(e, value, index, ruleOptions)
									}
									color='primary'
								/>
							}
							label='Use fields'
						/>
					)}
					{renderRuleValues(
						{ ...rule, id },
						index,
						rule.operator,
						currentRuleKeyField
					)}
				</Box>
			</Fragment>
		);
	});
};

export default RuleFieldManager;
