import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { getSingleUser, editUser } from 'actions/usersActions';
import { UserForm } from 'components';

const EditUser = (props) => {
	const params = useParams();
	const navigate = useNavigate();

	const { getSingleUser, user, editUser, editUserStatus } = props;

	useEffect(() => {
		getSingleUser(params.id);
	}, []);

	const handleSubmit = (form) => {
		editUser(form, () => navigate('/role-manager'));
	};

	if (!user) {
		return <div>Loading...</div>;
	}

	return (
		<UserForm
			status={editUserStatus}
			handleSubmit={handleSubmit}
			initialValues={user}
			buttonLabel='Edit User'
		/>
	);
};

const mapStateToProps = ({ users }) => ({
	user: users.singleUser,
	editUserStatus: users.editUserStatus,
});

const mapDispatchToProps = (dispatch) => ({
	getSingleUser: (id) => dispatch(getSingleUser(id)),
	editUser: (body, onLoad) => dispatch(editUser(body, onLoad)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);
