import './styles.css';
import React from 'react';
import { TextField } from '@mui/material';

const trimValue = (value) => {
	return typeof value === 'string' ? value.trim() : value;
};

const CustomInput = React.memo(
	({
		name,
		label,
		value,
		onChange,
		onBlur = () => {},
		normalizer = trimValue,
		...rest
	}) => {
		const [inputValue, setValue] = React.useState(() => value);
		React.useEffect(() => {
			setValue(value);
		}, [value]);

		const handleInputChange = (e) => {
			setValue(e.target.value);
			onChange({
				target: { name: e.target.name, value: normalizer(e.target.value) },
			});
		};

		return (
			<TextField
				value={inputValue}
				name={name}
				onChange={handleInputChange}
				onBlur={onBlur}
				label={label}
				InputLabelProps={{ shrink: true }}
				className='customInput'
				{...rest}
			/>
		);
	},
	(prev, next) => prev.value === next.value
);

export default CustomInput;
