import { Table, TableBody, TableCell, Tooltip, TableRow } from '@mui/material';
import React from 'react';
import './styles.css';
import { numberFormatter } from 'helpers/formatters';

const valueConverter = (value, key = '') => {
	if (value === null || value === undefined) {
		return 'n/a';
	}
	switch (typeof value) {
		case 'boolean':
			return value ? '✓' : '✗';
		case 'number':
			return numberFormatter(value, key);
		case 'string':
			return value;
		case 'object': {
			if (Array.isArray(value)) {
				if (!value.length) {
					return 'n/a';
				}
				return value.join(', ');
			}
			return Object.entries(value)
				.map(
					([itemKey, itemValue]) => `${itemKey}: ${valueConverter(itemValue)}`
				)
				.join(', ');
		}
		default: {
			return JSON.stringify(value);
		}
	}
};

export const TableCellInfo = ({ children }) => {
	const tooltipClasses = {
		tooltipPlacementBottom: 'keyTooltipPlacementBottom',
	};

	return (
		<TableCell>
			<Tooltip classes={tooltipClasses} title={children} placement='left'>
				<span>{children}</span>
			</Tooltip>
		</TableCell>
	);
};

export const RowValue = ({ oKey, oValue }) => {
	if (oValue && typeof oValue === 'object' && !Array.isArray(oValue)) {
		return (
			<>
				<TableRow>
					<TableCell>{oKey}:</TableCell>
				</TableRow>
				{Object.entries(oValue).map(([itemKey, itemValue]) => {
					const value = valueConverter(itemValue, oKey);
					return (
						<TableRow key={itemKey} className='rowOffset'>
							<TableCell>{itemKey}</TableCell>
							<TableCell>{value}</TableCell>
						</TableRow>
					);
				})}
			</>
		);
	}

	return (
		<TableRow>
			<TableCell>{oKey}</TableCell>
			<TableCell>{valueConverter(oValue, oKey)}</TableCell>
		</TableRow>
	);
};

const KeyValueTable = ({ classes = '', children, ...props }) => {
	const tableClass = `customTable ${classes}`;
	return (
		<Table className={tableClass}>
			<TableBody>{children}</TableBody>
		</Table>
	);
};

export default KeyValueTable;
