import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { getSingleRule, editRule, createRule } from 'actions/rulesActions';
import { LOADING } from 'constants/apiStatuses';
import { RuleForm, withAdmin } from 'components';
import destroyRulesIds from 'helpers/destroyRuleIds';
import clearArray from 'helpers/clearEmptyStrings';

const EditRule = (props) => {
	const navigate = useNavigate();
	const params = useParams();
	const location = useLocation();
	const action = new URLSearchParams(location.search).get('action');
	const isCopyRulePage = action === 'copyRule';

	const { getRule, rule, getRuleStatus, editRule, editRuleStatus, createRule } =
		props;

	const handleSubmit = ({ rules, exRuleSet, defaultTimes, ...rest }) => {
		if (isCopyRulePage) {
			createRule(
				{
					...rest,
					validTo: defaultTimes ? new Date(2099, 11, 31) : rest.validTo,
					validFrom: defaultTimes ? new Date(2000, 0, 1) : rest.validFrom,
					exRuleSet: clearArray(exRuleSet),
					rules: destroyRulesIds(rules),
				},
				() => navigate('/rule-manager')
			);
		} else {
			editRule(
				{
					...rest,
					validTo: defaultTimes ? new Date(2099, 11, 31) : rest.validTo,
					validFrom: defaultTimes ? new Date(2000, 0, 1) : rest.validFrom,
					exRuleSet: clearArray(exRuleSet),
					rules: destroyRulesIds(rules),
				},
				() => navigate('/rule-manager')
			);
		}
	};

	useEffect(() => {
		getRule(params.id);
	}, []);

	if (getRuleStatus === LOADING || !rule) {
		return <div>Loading...</div>;
	}

	return (
		<RuleForm
			status={editRuleStatus}
			handleSubmit={handleSubmit}
			buttonLabel={isCopyRulePage ? 'Copy Rule' : 'Save'}
			initialValues={rule}
		/>
	);
};

const mapStateToProps = ({ rules }) => ({
	getRuleStatus: rules.getRuleStatus,
	rule: rules.singleRule,
	editRuleStatus: rules.editRuleStatus,
	createRuleStatus: rules.createRuleStatus,
});

const mapDispatchToProps = (dispatch) => ({
	getRule: (id) => dispatch(getSingleRule(id)),
	editRule: (body, onLoad) => dispatch(editRule(body, onLoad)),
	createRule: (body, onLoad) => dispatch(createRule(body, onLoad)),
});

export default withAdmin(
	connect(mapStateToProps, mapDispatchToProps)(EditRule)
);
