import axiosInstance from '../index';
import { parseISO } from 'date-fns';

const createRule = async (body) => {
	body.validFrom = body.validFrom.toISOString();
	body.validTo = body.validTo.toISOString();
	let res = await axiosInstance.post('/rules', body);
	res = res.data;
	res.validFrom = parseISO(res.validFrom);
	res.validTo = parseISO(res.validTo);
	return res;
};

export { createRule };
