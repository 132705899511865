import {
	AUTH_AUTHORIZE,
	AUTH_AUTHORIZE_STATUS,
	AUTH_CHECK_TOKEN_STATUS,
	AUTH_SET_AUTHORIZATION_TYPE,
	AUTH_SET_FE_VERSION,
	APP_SET_OPEN_MENU,
} from '../constants';
import { NONE, LOADING } from 'constants/apiStatuses';
import { DEFAULT } from 'constants/authorizations';

const initialState = {
	userInfo: null,
	feVersion: null,
	authorizeStatus: NONE,
	checkTokenStatus: LOADING,
	authorizationType: DEFAULT,
	menuState: false,
};

const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case AUTH_AUTHORIZE_STATUS: {
			return {
				...state,
				authorizeStatus: action.payload,
			};
		}

		case AUTH_AUTHORIZE: {
			return {
				...state,
				userInfo: action.payload,
			};
		}

		case AUTH_CHECK_TOKEN_STATUS: {
			return {
				...state,
				checkTokenStatus: action.payload,
			};
		}

		case AUTH_SET_AUTHORIZATION_TYPE: {
			return {
				...state,
				authorizationType: action.payload,
			};
		}

		case AUTH_SET_FE_VERSION: {
			return {
				...state,
				feVersion: action.payload,
			};
		}

		case APP_SET_OPEN_MENU: {
			return {
				...state,
				menuState: action.payload,
			};
		}

		default: {
			return state;
		}
	}
};

export default authReducer;
