import { CustomTableCell } from 'components';
import React from 'react';
import { dateFormatter } from 'helpers/formatters';

const evidenceColumns = [
	{
		field: 'evidenceId',
		editable: false,
		sortable: false,
		hide: true,
		renderCell: (params) => <CustomTableCell {...params} />,
	},
	{
		field: 'dateCreation',
		editable: false,
		sortable: false,
		headerName: 'Date creation',
		headerAlign: 'center',
		flex: 1,
		renderCell: (params) => {
			return (
				<CustomTableCell {...params} value={dateFormatter(params.value)} />
			);
		},
	},
];

export default evidenceColumns;
