export const AUTH_AUTHORIZE_STATUS = '@auth/AUTHORIZE_STATUS';
export const AUTH_AUTHORIZE = '@auth/AUTHORIZE';
export const AUTH_CHECK_TOKEN_STATUS = '@auth/CHECK_TOKEN_STATUS';
export const AUTH_SET_AUTHORIZATION_TYPE = '@auth/SET_AUTHORIZATION_TYPE';
export const AUTH_SET_FE_VERSION = '@auth/SET_FE_VERSION';

export const ACCOUNTS_GET_SINGLE_ACCOUNT_STATUS =
	'@accounts/GET_SINGLE_ACCOUNT_STATUS';
export const ACCOUNTS_SET_SINGLE_ACCOUNT = '@accounts/SET_SINGLE_ACCOUNT';
export const ACCOUNTS_CREATE_EVIDENCE_STATUS =
	'@accounts/CREATE_EVIDENCE_STATUS';
export const ACCOUNTS_CREATE_EVIDENCE = '@accounts/CREATE_EVIDENCE';
export const ACCOUNTS_DELETE_EVIDENCE_STATUS =
	'@accounts/DELETE_EVIDENCE_STATUS';
export const ACCOUNTS_DELETE_EVIDENCE = '@accounts/DELETE_EVIDENCE';
export const ACCOUNTS_ADD_EVIDENCE_COMMENT = '@accounts/ADD_EVIDENCE_COMMENT';
export const ACCOUNTS_ADD_EVIDENCE_COMMENT_STATUS =
	'@accounts/ADD_EVIDENCE_COMMENT_STATUS';
export const ACCOUNTS_GET_ACCOUNTS_STATUS = '@accounts/GET_ACCOUNTS_STATUS';
export const ACCOUNTS_SET_ACCOUNTS = '@accounts/SET_ACCOUNTS';
export const ACCOUNTS_CHANGE_FILTER_FIELD = '@accounts/CHANGE_FILTER_FIELD';
export const ACCOUNTS_SET_DEVICES = '@accounts/SET_DEVICES';
export const ACCOUNTS_SET_TRANSACTIONS = '@accounts/SET_TRANSACTIONS';
export const ACCOUNTS_GET_TRANSACTIONS_STATUS =
	'@accounts/GET_TRANSACTIONS_STATUS';
export const ACCOUNTS_GET_ACCOUNTS_FOR_EVIDENCE_STATUS =
	'@accounts/GET_ACCOUNTS_FOR_EVIDENCE_STATUS';
export const ACCOUNTS_SET_ACCOUNTS_FOR_EVIDENCE =
	'@accounts/SET_ACCOUNTS_FOR_EVIDENCE';
export const ACCOUNTS_GET_ATTACHED_ACCOUNTS_STATUS =
	'@accounts/GET_ATTACHED_ACCOUNTS_STATUS';
export const ACCOUNTS_GET_ATTACHED_DEVICES_STATUS =
	'@accounts/GET_ATTACHED_DEVICES_STATUS';
export const ACCOUNTS_SET_ATTACHED_ACCOUNTS = '@accounts/SET_ATTACHED_ACCOUNTS';
export const ACCOUNTS_SET_ATTACHED_DEVICES = '@accounts/SET_ATTACHED_DEVICES';
export const ACCOUNTS_CHANGE_ACCOUNTS_FOR_EVIDENCE_FILTER_FIELD =
	'@accounts/CHANGE_ACCOUNTS_FOR_EVIDENCE_FILTER_FIELD';
export const ACCOUNTS_RESET_FILTERS = '@accounts/RESET_FILTERS';
export const ACCOUNTS_GET_ACCOUNTS_BY_NAME = '@accounts/GET_ACCOUNTS_BY_NAME';
export const ACCOUNTS_SET_ACCOUNTS_BY_NAME = '@accounts/SET_ACCOUNTS_BY_NAME';
export const ACCOUNTS_GET_ACCOUNTS_BY_NAME_STATUS =
	'@accounts/GET_ACCOUNTS_BY_NAME_STATUS';
export const ACCOUNTS_DELETE_ACCOUNT = '@accounts/DELETE_ACCOUNT';
export const ACCOUNTS_DELETE_ACCOUNT_STATUS = '@accounts/DELETE_ACCOUNT_STATUS';
export const ACCOUNTS_CHANGE_TRANSACTIONS_FILTER_FIELD = `@accounts/CHANGE_TRANSACTIONS_FILTER_FIELD`;
export const ACCOUNTS_RESET_TRANSACTIONS_FILTERS = `@accounts/RESET_TRANSACTIONS_FILTERS`;
export const ACCOUNTS_EXPORT_TRANSACTIONS_TO_JSON_STATUS =
	'@accounts/EXPORT_TRANSACTIONS_TO_JSON_STATUS';
export const ACCOUNTS_EXPORT_TRANSACTIONS_TO_CSV_STATUS =
	'@accounts/EXPORT_TRANSACTIONS_TO_CSV_STATUS';
export const ACCOUNTS_SET_EVIDENCE_HISTORY =
	'@accounts/GET_SET_EVIDENCE_HISTORY';
export const ACCOUNTS_GET_EVIDENCE_HISTORY_STATUS =
	'@accounts/GET_EVIDENCE_HISTORY_STATUS';

export const DEVICES_GET_DEVICES_STATUS = '@devices/GET_DEVICES_STATUS';
export const DEVICES_SET_DEVICES = '@devices/SET_DEVICES';
export const DEVICES_GET_SINGLE_DEVICE_STATUS =
	'@devices/GET_SINGLE_DEVICE_STATUS';
export const DEVICES_SET_SINGLE_DEVICE = '@devices/SET_SINGlE_DEVICE';
export const DEVICES_CREATE_EVIDENCE_STATUS = '@devices/CREATE_EVIDENCE_STATUS';
export const DEVICES_CREATE_EVIDENCE = '@devices/CREATE_EVIDENCE';
export const DEVICES_DELETE_EVIDENCE = '@devices/DELETE_EVIDENCE';
export const DEVICES_DELETE_EVIDENCE_STATUS = '@devices/DELETE_EVIDENCE_STATUS';
export const DEVICES_ADD_EVIDENCE_COMMENT = '@devices/ADD_EVIDENCE_COMMENT';
export const DEVICES_ADD_EVIDENCE_COMMENT_STATUS =
	'@devices/ADD_EVIDENCE_COMMENT_STATUS';
export const DEVICES_CHANGE_FILTER_FIELD = '@devices/CHANGE_FILTER_FIELD';
export const DEVICES_SET_TRANSACTIONS = '@devices/SET_TRANSACTIONS';
export const DEVICES_GET_TRANSACTIONS_STATUS =
	'@devices/GET_TRANSACTIONS_STATUS';
export const DEVICES_SET_ACCOUNTS = '@device/SET_ACCOUNTS';
export const DEVICES_GET_ACCOUNTS_STATUS = '@devices/GET_ACCOUNTS_STATUS';
export const DEVICES_GET_ACCOUNTS_FOR_EVIDENCE_STATUS =
	'@devices/GET_ACCOUNTS_FOR_EVIDENCE_STATUS';
export const DEVICES_SET_ACCOUNTS_FOR_EVIDENCE =
	'@devices/SET_ACCOUNTS_FOR_EVIDENCE';
export const DEVICES_CHANGE_ACCOUNTS_FOR_EVIDENCE_FILTER_FIELD =
	'@devices/CHANGE_ACCOUNTS_FOR_EVIDENCE_FILTER_FIELD';
export const DEVICES_RESET_FILTERS = '@devices/RESET_FILTERS';
export const EVIDENCE_LOG_RESET_FILTERS = '@devices/EVIDENCE_LOG_RESET_FILTERS';
export const DEVICES_CHANGE_TRANSACTIONS_FILTER_FIELD = `@devices/CHANGE_TRANSACTIONS_FILTER_FIELD`;
export const DEVICES_RESET_TRANSACTIONS_FILTERS = `@devices/RESET_TRANSACTIONS_FILTERS`;
export const DEVICES_EXPORT_TRANSACTIONS_TO_JSON_STATUS =
	'@devices/EXPORT_TRANSACTIONS_TO_JSON_STATUS';
export const DEVICES_EXPORT_TRANSACTIONS_TO_CSV_STATUS =
	'@devices/EXPORT_TRANSACTIONS_TO_CSV_STATUS';
export const DEVICES_GET_RESET_VECTOR_STATUS =
	'@devices/GET_RESET_VECTOR_STATUS';
export const DEVICES_SET_EVIDENCE_HISTORY = '@devices/GET_SET_EVIDENCE_HISTORY';
export const DEVICES_GET_EVIDENCE_HISTORY_STATUS =
	'@devices/GET_EVIDENCE_HISTORY_STATUS';

export const EVIDENCE_CHANGE_FILTER_FIELD =
	'@devices/EVIDENCE_CHANGE_FILTER_FIELD';

export const EVIDENCE_RESET_FILTERS = '@devices/EVIDENCE_RESET_FILTERS';

export const TRANSACTIONS_GET_SINGLE_TRANSACTION_STATUS =
	'@transactions/GET_SINGLE_TRANSACTION_STATUS';
export const TRANSACTIONS_SET_SINGLE_TRANSACTION =
	'@transactions/SET_SINGLE_TRANSACTION';
export const TRANSACTIONS_GET_TRANSACTIONS_STATUS =
	'@transactions/GET_TRANSACTIONS_STATUS';
export const TRANSACTIONS_SET_TRANSACTIONS = '@transactions/SET_TRANSACTIONS';
export const TRANSACTIONS_CHANGE_FILTER_FIELD =
	'@transactions/CHANGE_FILTER_FIELD';
export const TRANSACTIONS_RESET_FILTERS = '@transactions/RESET_FILTERS';
export const TRANSACTIONS_EXPORT_TO_JSON_STATUS =
	'@transactions/EXPORT_TO_JSON_STATUS';
export const TRANSACTIONS_EXPORT_TO_CSV_STATUS =
	'@transactions/EXPORT_TO_CSV_STATUS';

export const EVIDENCE_LOG_EXPORT_TO_CSV_STATUS =
	'@transactions/EVIDENCE_LOG_EXPORT_TO_CSV_STATUS';
export const EVIDENCE_LOG_EXPORT_TO_JSON_STATUS =
	'@transactions/EVIDENCE_LOG_EXPORT_TO_JSON_STATUS';

export const EVIDENCE_GET_SINGLE_EVIDENCE_STATUS =
	'@actions/EVIDENCE_GET_SINGLE_EVIDENCE_STATUS';
export const EVIDENCE_SET_EVIDENCE_ACTION =
	'@actions/EVIDENCE_SET_EVIDENCE_ACTION';

export const IPS_GET_IPS_STATUS = '@ips/GET_IPS_STATUS';
export const IPS_SET_IPS = '@ips/SET_IPS';
export const IPS_CHANGE_FILTER_FIELD = '@ips/CHANGE_FILTER_FIELD';
export const IPS_GET_SINGLE_IP_STATUS = '@ips/GET_SINGLE_IP_STATUS';
export const IPS_SET_SINGLE_IP = '@ips/SET_SINGLE_IP';
export const IPS_GET_IPS_FOR_EVIDENCE_STATUS =
	'@ips/GET_IPS_FOR_EVIDENCE_STATUS';
export const IPS_SET_IPS_FOR_EVIDENCE = '@ips/SET_IPS_FOR_EVIDENCE';
export const IPS_CREATE_EVIDENCE_STATUS = '@ips/CREATE_EVIDENCE_STATUS';
export const IPS_DELETE_EVIDENCE_STATUS = '@ips/DELETE_EVIDENCE_STATUS';
export const IPS_DELETE_EVIDENCE = '@ips/DELETE_EVIDENCE';
export const IPS_ADD_EVIDENCE_COMMENT = '@ips/ADD_EVIDENCE_COMMENT';
export const IPS_ADD_EVIDENCE_COMMENT_STATUS =
	'@ips/ADD_EVIDENCE_COMMENT_STATUS';
export const IPS_CREATE_IP_STATUS = '@ips/CREATE_IP_STATUS';
export const IPS_RESET_FILTERS = '@ips/RESET_FILTERS';
export const IPS_SET_EVIDENCE_HISTORY = '@ips/GET_SET_EVIDENCE_HISTORY';
export const IPS_GET_EVIDENCE_HISTORY_STATUS =
	'@ips/GET_EVIDENCE_HISTORY_STATUS';

export const RULES_DELETE_RULE_STATUS = '@rules/DELETE_RULE_STATUS';
export const RULES_DELETE_RULE = '@rules/DELETE_RULE';
export const RULES_GET_RULES_STATUS = '@rules/GET_RULES_STATUS';
export const RULES_SET_RULES = '@rules/SET_RULES';
export const RULES_GET_SINGLE_RULE_STATUS = '@rules/GET_SINGLE_RULE_STATUS';
export const RULES_SET_SINGLE_RULE = '@rules/SET_SINGLE_RULE';
export const RULES_CREATE_RULE_STATUS = '@rules/CREATE_RULE_STATUS';
export const RULES_EDIT_RULE_STATUS = '@rules/RULE_EDIT_RULE_STATUS';
export const RULES_GET_KEYS_STATUS = '@rules/GET_KEYS_STATUS';
export const RULES_SET_KEYS = '@rules/SET_KEYS';
export const RULES_CHANGE_FILTER_FIELD = '@rules/CHANGE_FILTER_FIELD';
export const RULES_GET_RULESETS_STATUS = '@rules/GET_RULESETS_STATUS';
export const RULES_SET_RULESETS = '@rules/SET_RULESETS';

export const SERVERSTATS_GET_INFO_STATUS = '@serverstats/GET_INFO_STATUS';
export const SERVERSTATS_SET_INFO = '@serverstats/SET_INFO';
export const SERVERSTATS_GET_IPS_INFO_STATUS =
	'@serverstats/GET_IPS_INFO_STATUS';
export const SERVERSTATS_SET_IPS_INFO = '@serverstats/SET_IPS_INFO';
export const SERVERSTATS_GET_DEVICES_INFO_STATUS =
	'@serverstats/GET_DEVICES_INFO_STATUS';
export const SERVERSTATS_SET_DEVICES_INFO = '@serverstats/SET_DEVICES_INFO';
export const SERVERSTATS_GET_ACCOUNTS_INFO_STATUS =
	'@serverstats/GET_ACCOUNTS_INFO_STATUS';
export const SERVERSTATS_SET_ACCOUNTS_INFO = '@serverstats/SET_ACCOUNTS_INFO';
export const SERVER_STATS_GET_ACCOUNTS_COUNTRIES_INFO_STATUS =
	'@serverstats/GET_ACCOUNTS_COUNTRIES_INFO_STATUS';
export const SERVER_STATS_SET_ACCOUNTS_COUNTRIES_INFO =
	'@serverstats/SET_ACCOUNTS_COUNTRIES_INFO';
export const SERVER_STATS_GET_DEVICES_COUNTRIES_INFO_STATUS =
	'@serverstats/GET_DEVICES_COUNTRIES_INFO_STATUS';
export const SERVER_STATS_SET_DEVICES_COUNTRIES_INFO =
	'@serverstats/SET_DEVICES_COUNTRIES_INFO';
export const SERVER_STATS_GET_MOST_USED_RULES_STATUS =
	'@serverstats/GET_MOST_USED_RULES_STATUS';
export const SERVER_STATS_SET_MOST_USED_RULES =
	'@serverstats/SET_MOST_USED_RULES';

export const SERVER_STATS_SET_SYSTEM_KPI_DATA =
	'@serverstats/SET_SYSTEM_KPI_DATA';
export const SERVER_STATS_GET_SYSTEM_KPI_DATA_STATUS =
	'@serverstats/GET_SYSTEM_KPI_DATA_STATUS';

export const SERVER_STATS_GET_EVIDENCE_ENTITIES_STATUS =
	'@serverstats/GET_EVIDENCE_ENTITIES_STATUS';
export const SERVER_STATS_SET_EVIDENCE_ENTITIES =
	'@serverstats/SET_EVIDENCE_ENTITIES';
export const SERVER_STATS_CHANGE_EVIDENCE_ENTITIES_FILTER_FIELD =
	'@serverstats/CHANGE_EVIDENCE_ENTITIES_FILTER_FIELD';
export const SERVER_STATS_RESET_EVIDENCE_ENTITIES_FILTERS =
	'@serverstats/RESET_EVIDENCE_ENTITIES_FILTERS';

export const USERS_GET_SINGLE_USER_STATUS = '@users/GET_SINGLE_USER_STATUS';
export const USERS_SET_SINGLE_USER = '@users/SET_SINGLE_USER';
export const USERS_EDIT_USER_STATUS = '@users/EDIT_USER_STATUS';
export const USERS_GET_USERS_STATUS = '@users/GET_USERS_STATUS';
export const USERS_SET_USERS = '@users/GET_USERS';
export const USERS_CREATE_USER_STATUS = '@users/CREATE_USER_STATUS';
export const USERS_CREATE_USER = '@users/CREATE_USER';
export const USERS_DELETE_USER = '@users/DELETE_USER';
export const USERS_DELETE_USER_STATUS = '@users/DELETE_USER_STATUS';

export const ACTIONS_GET_ACTIONS_STATUS = '@actions/GET_ACTIONS_STATUS';
export const ACTIONS_SET_ACTIONS = '@actions/SET_ACTIONS';
export const ACTIONS_GET_SINGLE_ACTION_STATUS =
	'@actions/GET_SINGLE_ACTION_STATUS';
export const ACTIONS_SET_SINGLE_ACTION = '@actions/SET_SINGLE_ACTION';
export const ACTIONS_CHANGE_FILTER_FIELD = '@actions/CHANGE_FILTER_FIELD';
export const ACTIONS_RESET_FILTERS = '@actions/RESET_FILTERS';

export const EVIDENCES_GET_IMPORTED_EVIDENCES_INFO_STATUS =
	'@evidences/@GET_IMPORTED_EVIDENCES_INFO';
export const EVIDENCES_SET_IMPORTED_EVIDENCES_INFO =
	'@evidences/SET_IMPORTED_EVIDENCES_INFO';
export const EVIDENCES_IMPORT_EVIDENCES_STATUS =
	'@evidences/IMPORT_EVIDENCES_STATUS';
export const EVIDENCES_IMPORT_EVIDENCES = '@evidences/IMPORT_EVIDENCES';

export const EVIDENCES_GET_NEW_IMPORTED_EVIDENCES_INFO_STATUS =
	'@evidences/GET_NEW_IMPORTED_EVIDENCES_INFO';
export const EVIDENCES_SET_NEW_IMPORTED_EVIDENCES_INFO =
	'@evidences/SET_NEW_IMPORTED_EVIDENCES_INFO';
export const IMPORTED_NEW_EVIDENCE_CHANGE_FILTER_FIELD =
	'@devices/NEW_EVIDENCE_CHANGE_FILTER_FIELD';
export const IMPORTED_EVIDENCE_RESET_FILTERS =
	'@devices/NEW_EVIDENCE_RESET_FILTERS';

export const FPSTATS_GET_STATS_STATUS = '@fpStats/GET_STATS_STATUS';
export const FPSTATS_GET_STATS = '@fpStats/GET_STATS';

export const RATING_GET_RATING_STATUS = '@rating/GET_RATING_STATUS';
export const RATING_GET_TEST_RATING_STATUS = '@rating/GET_TEST_RATING_STATUS';
export const RATING_SET_RATING = '@rating/SET_RATING';

export const REASONS_SET_REASONS = '@reasons/SET_REASONS';
export const REASONS_GET_REASONS_STATUS = '@reasons/GET_REASONS_STATUS';
export const REASONS_CREATE_REASON = '@reasons/CREATE_REASON';
export const REASONS_CREATE_REASON_STATUS = '@reasons/CREATE_REASON_STATUS';
export const REASONS_EDIT_REASON = '@reasons/EDIT_REASON';
export const REASONS_EDIT_REASON_STATUS = '@reasons/EDIT_REASON_STATUS';
export const REASONS_DELETE_REASON = '@reasons/DELETE_REASON';
export const REASONS_DELETE_REASON_STATUS = '@reasons/DELETE_REASON_STATUS';
export const CREATE_RULE_FIELD_STATUS = '@rules/CREATE_RULE_FIELD_STATUS';
export const DELETE_RULE_FIELD_STATUS = '@rules/DELETE_RULE_FIELD_STATUS';
export const DELETE_RULE_FIELD = '@rules/DELETE_RULE_FIELD';

export const APP_SET_OPEN_MENU = '@app/SET_OPEN_MENU';
