import React from 'react';
import { TableRow } from '@mui/material';
import './styles.css';
import { useTheme } from '@mui/material/styles';
import colors from 'constants/layoutTheme';

const StripedTableRow = ({ children, className, ...rest }) => {
	const theme = useTheme();
	const color =
		theme.palette.mode === 'dark'
			? colors.oddRowsBackground
			: theme.palette.action.hover;

	return (
		<TableRow
			className={'stripedTableRow'}
			style={{ '--oddRowsBackground': color }}
			{...rest}
		>
			{children}
		</TableRow>
	);
};

export default StripedTableRow;
