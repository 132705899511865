import { CustomTableCell, PureTableCell } from 'components';
import React from 'react';

const mostUsedRulesColumns = [
	{
		field: 'name',
		editable: false,
		headerName: 'Name',
		headerAlign: 'center',
		flex: 1,
		renderCell: (params) => <CustomTableCell {...params} />,
	},
	{
		field: 'count',
		editable: false,
		headerName: 'Amount',
		headerAlign: 'center',
		flex: 1,
		renderCell: (params) => <PureTableCell {...params} />,
	},
];

export default mostUsedRulesColumns;
