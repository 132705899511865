import { dateFormatter } from 'helpers/formatters';
import { CustomTableCell } from 'components';
import React from 'react';

const evidenceLog = [
	{
		field: 'actionTime',
		editable: false,
		headerName: 'Date',
		headerAlign: 'center',
		flex: 1,
		renderCell: (params) => {
			return (
				<CustomTableCell {...params} value={dateFormatter(params.value)} />
			);
		},
	},
	{
		field: 'userName',
		editable: false,
		headerName: 'User name',
		headerAlign: 'center',
		flex: 1,
		renderCell: (params) => (
			<CustomTableCell filterField='userName' {...params} />
		),
	},
	{
		field: 'consumerId',
		editable: false,
		headerName: 'Device ID / Account Name',
		headerAlign: 'center',
		flex: 1,
		renderCell: (params) => (
			<CustomTableCell filterField='consumerId' {...params} />
		),
	},
	{
		field: 'status',
		editable: false,
		headerName: 'Evidence Code',
		headerAlign: 'center',
		flex: 1,
		renderCell: (params) => (
			<CustomTableCell filterField='status' {...params} />
		),
	},
	{
		field: 'consumerType',
		editable: false,
		headerName: 'Evidence Type',
		headerAlign: 'center',
		flex: 1,
		renderCell: (params) => (
			<CustomTableCell
				getNewTabLink={(type) =>
					`${window.location.origin}/${type}s/${params.row.consumerId}`
				}
				{...params}
			/>
		),
	},

	{
		field: 'action',
		editable: false,
		headerName: 'Action',
		headerAlign: 'center',
		flex: 1,
		renderCell: (params) => <CustomTableCell {...params} />,
	},
];

export default evidenceLog;
