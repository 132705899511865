import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import {
	getEvidenceEntities,
	changeEvidenceEntitiesFilterField,
	resetEvidenceEntitiesFilters,
} from 'actions/serverStatsActions';
import {
	Box,
	FormControl,
	MenuItem,
	Select,
	InputLabel,
	IconButton,
	Tooltip,
	Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import addQueryToSearchParams from 'helpers/addQueryToSearchParams';
import getQueryFromSearchParams from 'helpers/getQueryFromSearchParams';
import devicesColumns from 'constants/columns/devices';
import accountsColumns from 'constants/columns/accounts';
import ipsColumns from 'constants/columns/ips';
import './styles.css';
import { ReactComponent as ClearAllIcon } from 'icons/clearFilter.svg';
import evidenceColumns from 'constants/columns/evidenceColumns';
import { LOADING } from 'constants/apiStatuses';
import { rowsPerPageOptions, aftTimeout } from 'constants/tablesVariables';
import { CustomTableCell } from 'components';
import { getReasonNameById } from 'helpers/reasonsMethods';
import { getReasons } from 'actions/reasonsActions';
import LoadingSpinner from '../../components/LoadingSpinner';
const columns = {
	device: devicesColumns,
	account: accountsColumns,
	ip: ipsColumns,
};

const EvidenceFilter = (props) => {
	const navigate = useNavigate();
	const location = useLocation();
	const [page, setPage] = useState(0);
	const timer = React.useRef(null);
	const [pitExpired, setPitExpired] = React.useState(false);
	const [pageSize, setPageSize] = useState(rowsPerPageOptions[0]);
	const filtersQuery = new URLSearchParams(location.search);

	const {
		getEvidenceEntities,
		evidenceEntities,
		filters,
		changeFilterField,
		resetFilters,
		evidenceTypes,
		getReasons,
	} = props;

	const handleChangePage = (pageData) => {
		getEvidenceEntities({
			after: evidenceEntities.after[pageData - 1]
				? evidenceEntities.after[pageData - 1]
				: null,
			newEntity: false,
			page: pageData,
			pageSize,
			filters,
		});
		resetTimer();
		setPage(pageData);
	};

	const handleChangeFilters = (e) => {
		setPage(0);
		resetTimer();
		changeFilterField({ key: e.target.name, value: e.target.value });
		navigate(
			`${location.pathname}${addQueryToSearchParams(location.search, {
				value: e.target.value,
				name: e.target.name,
			})}`
		);
		getEvidenceEntities({
			after: null,
			newEntity: true,
			page: 0,
			pageSize,
			filters: {
				...filters,
				[e.target.name]: e.target.value,
			},
		});
	};

	const getNewTimer = () => {
		return setTimeout(() => setPitExpired(true), aftTimeout * 1000);
	};
	const resetTimer = () => {
		clearTimeout(timer.current);
		timer.current = getNewTimer();
	};

	const handleRowClick = ({ row }) => {
		navigate(`${filters.entity}s/${row.id}`);
	};

	useEffect(() => {
		getReasons();
		const initialFiltersFromQuery = getQueryFromSearchParams(
			filtersQuery.entries()
		);
		const initialFilters = {
			...initialFiltersFromQuery,
			entity: initialFiltersFromQuery.entity
				? initialFiltersFromQuery.entity
				: 'ip',
		};
		resetFilters(initialFilters);

		getEvidenceEntities({
			after: null,
			newEntity: false,
			page: 0,
			pageSize,
			filters: initialFilters,
		});
		timer.current = getNewTimer();
		return () => {
			clearTimeout(timer.current);
		};
	}, []);

	const newColumns = columns[filters.entity]
		? [
				...columns[filters.entity]
					?.filter((column) => column.field !== 'result')
					.map((elem) => ({ ...elem, sortable: false })),
				{
					field: 'reason',
					editable: false,
					sortable: false,
					headerName: 'Reason',
					headerAlign: 'center',
					flex: 1,
					renderCell: (params) => (
						<CustomTableCell
							{...params}
							value={getReasonNameById(evidenceTypes, params.value)}
						/>
					),
				},
				...evidenceColumns,
		  ]
		: [];

	const handleResetFilters = () => {
		const initialFilters = {
			entity: 'ip',
		};
		resetFilters(initialFilters);
		resetTimer();
		getEvidenceEntities({
			after: null,
			newEntity: false,
			page: 0,
			pageSize,
			filters: initialFilters,
		});
		navigate('?tab=evidences');
	};

	const handlePageSizeChange = (newPageSize) => {
		setPageSize(newPageSize);
		setPage(0);
		resetTimer();
		getEvidenceEntities({
			after: null,
			newEntity: true,
			page: 0,
			pageSize: newPageSize,
			filters,
		});
	};

	const getTableClass = () => {
		return `evidenceTable ${
			evidenceEntities.status === LOADING ? 'disablePageButton' : ''
		} ${pitExpired ? 'removePageButton' : ''}`;
	};
	const getRowId = (data) => data.evidenceId;

	const handleChangeFilterField = (name, e) => {
		handleChangeFilters({
			target: {
				name,
				value: e.target.value,
			},
		});
	};

	const handleConsumerChange = (e) => {
		handleChangeFilterField('entity', e);
	};

	const handleEvidenceTypeChange = (e) => {
		handleChangeFilterField('evidenceType', e);
	};

	const handlePaginationModelChange = (paginationModel) => {
		handleChangePage(paginationModel.page);
		handlePageSizeChange(paginationModel.pageSize);

		setPage(paginationModel.page);
		setPageSize(paginationModel.pageSize);
	};

	const availableEvidenceTypes = evidenceTypes.map((value) => {
		return (
			<MenuItem value={value.id} key={value.id}>
				{value.name}
			</MenuItem>
		);
	});

	return (
		<Box>
			<Typography variant='h4'>Evidences</Typography>
			<Box className='evidenceFilterBar'>
				<FormControl className='evidenceFilterInput'>
					<InputLabel id='consumer-type'>Filter..</InputLabel>
					<Select
						labelId='consumer-type'
						onChange={handleConsumerChange}
						className='evidenceFilterWidth'
						value={filters.entity}
						label='Filter..'
					>
						<MenuItem value='device'>Devices</MenuItem>
						<MenuItem value='account'>Accounts</MenuItem>
						<MenuItem value='ip'>Ip blocks</MenuItem>
					</Select>
				</FormControl>
				<FormControl className='evidenceFilterInput'>
					<InputLabel id='evidence-type'>Evidence type..</InputLabel>
					<Select
						labelId='evidence-type'
						value={filters.evidenceType}
						onChange={handleEvidenceTypeChange}
						className='evidenceFilterWidth'
						label='Evidence type..'
						size='small'
					>
						{availableEvidenceTypes}
					</Select>
				</FormControl>
				<Tooltip title='Clear filters'>
					<IconButton
						className='evidenceFilterInput'
						color='primary'
						onClick={handleResetFilters}
					>
						<ClearAllIcon className='evidenceClearAllIcon' />
					</IconButton>
				</Tooltip>
			</Box>
			{evidenceEntities.status === LOADING ? (
				<LoadingSpinner />
			) : evidenceEntities.data ? (
				<DataGrid
					className={getTableClass()}
					onRowClick={handleRowClick}
					getRowId={getRowId}
					disableColumnMenu={true}
					rowCount={evidenceEntities.total}
					columns={newColumns}
					rows={evidenceEntities.data}
					autoHeight
					paginationMode='server'
					pageSizeOptions={rowsPerPageOptions}
					paginationModel={{ page, pageSize }}
					onPaginationModelChange={handlePaginationModelChange}
				/>
			) : null}
		</Box>
	);
};

const mapStateToProps = ({ serverStats, reasons }) => ({
	evidenceEntities: serverStats.evidenceEntities,
	filters: serverStats.evidenceEntitiesFilters,
	evidenceTypes: reasons.reasons,
});

const mapDispatchToProps = (dispatch) => ({
	getEvidenceEntities: (body) => dispatch(getEvidenceEntities(body)),
	changeFilterField: (value) =>
		dispatch(changeEvidenceEntitiesFilterField(value)),
	resetFilters: (filters) => dispatch(resetEvidenceEntitiesFilters(filters)),
	getReasons: () => dispatch(getReasons),
});

export default connect(mapStateToProps, mapDispatchToProps)(EvidenceFilter);
