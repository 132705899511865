import { getSingleAccount as getSingleAccountAPI } from 'services/api/accounts/getSingleAccount';
import { createEvidence as createEvidenceAPI } from 'services/api/evidence/createEvidence';
import { deleteEvidence as deleteEvidenceAPI } from 'services/api/evidence/deleteEvidence';
import { addComment as addCommentAPI } from 'services/api/evidence/addComment';
import { copyEvidence as copyEvidenceAPI } from 'services/api/evidence/copyEvidence';
import { getEvidenceHistory as getEvidenceHistoryAPI } from 'services/api/evidence/getEvidenceHistory';
import { getAccounts as getAccountsAPI } from 'services/api/accounts/getAccounts';
import { getDevices as getDevicesAPI } from 'services/api/devices/getDevices';
import { getTransactions as getTransactionsAPI } from 'services/api/transactions/getTransactions';
import { exportTransactions as exportTransactionsAPI } from 'services/api/transactions/exportTransactions';
import { exportTransactionsToCSV as exportTransactionsToCSVAPI } from 'services/api/transactions/exportTransactionsToCSV';
import { getAccountsByAccount as getAccountsByAccountAPI } from 'services/api/accounts/getAccountsByAccount';
import { getAccountsByName as getAccountByNameAPI } from 'services/api/accounts/getAccountsByName';
import { getAccountAccounts as getAccountAccountsAPI } from 'services/api/accounts/getAccountAccounts';
import { deleteAccount as deleteAccountAPI } from 'services/api/accounts/deleteAccount';
import blobDownloader from 'helpers/blobDownloader';

import { ERROR, LOADING, NONE } from 'constants/apiStatuses';
import {
	ACCOUNTS_GET_SINGLE_ACCOUNT_STATUS,
	ACCOUNTS_SET_SINGLE_ACCOUNT,
	ACCOUNTS_CREATE_EVIDENCE,
	ACCOUNTS_CREATE_EVIDENCE_STATUS,
	ACCOUNTS_DELETE_EVIDENCE,
	ACCOUNTS_DELETE_EVIDENCE_STATUS,
	ACCOUNTS_SET_ACCOUNTS,
	ACCOUNTS_GET_ACCOUNTS_STATUS,
	ACCOUNTS_CHANGE_FILTER_FIELD,
	ACCOUNTS_SET_DEVICES,
	ACCOUNTS_SET_TRANSACTIONS,
	ACCOUNTS_GET_TRANSACTIONS_STATUS,
	ACCOUNTS_SET_ACCOUNTS_FOR_EVIDENCE,
	ACCOUNTS_GET_ACCOUNTS_FOR_EVIDENCE_STATUS,
	ACCOUNTS_GET_ATTACHED_ACCOUNTS_STATUS,
	ACCOUNTS_SET_ATTACHED_ACCOUNTS,
	ACCOUNTS_SET_ATTACHED_DEVICES,
	ACCOUNTS_CHANGE_ACCOUNTS_FOR_EVIDENCE_FILTER_FIELD,
	ACCOUNTS_RESET_FILTERS,
	ACCOUNTS_SET_ACCOUNTS_BY_NAME,
	ACCOUNTS_GET_ACCOUNTS_BY_NAME_STATUS,
	ACCOUNTS_DELETE_ACCOUNT_STATUS,
	ACCOUNTS_CHANGE_TRANSACTIONS_FILTER_FIELD,
	ACCOUNTS_RESET_TRANSACTIONS_FILTERS,
	ACCOUNTS_GET_ATTACHED_DEVICES_STATUS,
	ACCOUNTS_EXPORT_TRANSACTIONS_TO_JSON_STATUS,
	ACCOUNTS_ADD_EVIDENCE_COMMENT_STATUS,
	ACCOUNTS_ADD_EVIDENCE_COMMENT,
	ACCOUNTS_EXPORT_TRANSACTIONS_TO_CSV_STATUS,
	ACCOUNTS_SET_EVIDENCE_HISTORY,
	ACCOUNTS_GET_EVIDENCE_HISTORY_STATUS,
} from '../constants';

export const setGetAccountsStatus = (status) => ({
	type: ACCOUNTS_GET_ACCOUNTS_STATUS,
	payload: status,
});

export const setAccounts = (accounts) => ({
	type: ACCOUNTS_SET_ACCOUNTS,
	payload: accounts,
});

export const getAccounts =
	({
		cursor,
		page,
		pageSize,
		changeCursors,
		filters,
		refresh,
		isSetData,
		sorting,
	}) =>
	(dispatch, getState) => {
		dispatch(setGetAccountsStatus(LOADING));
		getAccountsAPI({ cursor, page, pageSize, filters, sorting })
			.then((data) => {
				if (isSetData) {
					dispatch(setAccounts(data));
				}
				changeCursors({ ...data, refresh });
				dispatch(setGetAccountsStatus(NONE));
			})
			.catch(() => dispatch(setGetAccountsStatus(ERROR)));
	};

export const getDevicesAttachedToAccounts =
	({
		cursor,
		page,
		pageSize,
		changeCursors,
		devicesId,
		accountId,
		isSetData,
		sorting,
	}) =>
	(dispatch, getState) => {
		dispatch(setGetAttachedDevicesStatus(LOADING));
		getDevicesAPI({
			cursor,
			page,
			pageSize,
			filters: { devicesId },
			accountId,
			sorting,
		})
			.then((data) => {
				if (isSetData) {
					dispatch(setDevices(data));
				}
				changeCursors({ ...data });
				dispatch(setGetAttachedDevicesStatus(NONE));
			})
			.catch(() => dispatch(setGetAttachedDevicesStatus(ERROR)));
	};

export const setGetSingleAccountStatus = (status) => ({
	type: ACCOUNTS_GET_SINGLE_ACCOUNT_STATUS,
	payload: status,
});

export const setSingleAccount = (account) => ({
	type: ACCOUNTS_SET_SINGLE_ACCOUNT,
	payload: account,
});

export const getSingleAccount = (id) => (dispatch, getState) => {
	dispatch(setGetSingleAccountStatus(LOADING));
	getSingleAccountAPI(id)
		.then((account) => {
			dispatch(setSingleAccount(account));
			dispatch(setGetSingleAccountStatus(NONE));
		})
		.catch(() => dispatch(setGetSingleAccountStatus(ERROR)));
};

export const setCreateEvidenceStatus = (status) => ({
	type: ACCOUNTS_CREATE_EVIDENCE_STATUS,
	payload: status,
});

export const setCreateEvidence = (evidence) => ({
	type: ACCOUNTS_CREATE_EVIDENCE,
	payload: evidence,
});

export const createEvidence = (body, onLoad) => (dispatch, getState) => {
	dispatch(setCreateEvidenceStatus(LOADING));
	createEvidenceAPI(body)
		.then((evidence) => {
			dispatch(setCreateEvidenceStatus(NONE));
			dispatch(setCreateEvidence(evidence));
			onLoad();
		})
		.catch(() => dispatch(setCreateEvidenceStatus(ERROR)));
};

export const copyEvidence = (name, id, onLoad, onSuccess) => (dispatch) => {
	dispatch(setCreateEvidenceStatus(LOADING));
	copyEvidenceAPI(name, id)
		.then((res) => {
			onLoad();
			onSuccess();
			dispatch(setCreateEvidenceStatus(NONE));
		})
		.catch(() => dispatch(setCreateEvidenceStatus(ERROR)));
};

export const setDeleteEvidenceStatus = (status) => ({
	type: ACCOUNTS_DELETE_EVIDENCE_STATUS,
	payload: status,
});

export const setDeleteEvidence = (evidence) => ({
	type: ACCOUNTS_DELETE_EVIDENCE,
	payload: evidence,
});

export const deleteEvidence = (body, onClose) => (dispatch, getState) => {
	dispatch(setDeleteEvidenceStatus(LOADING));
	deleteEvidenceAPI(body)
		.then((history) => {
			dispatch(setDeleteEvidenceStatus(NONE));
			dispatch(setDeleteEvidence(history));
			onClose();
		})
		.catch(() => dispatch(setDeleteEvidenceStatus(ERROR)));
};

export const setAddEvidenceCommentStatus = (status) => ({
	type: ACCOUNTS_ADD_EVIDENCE_COMMENT_STATUS,
	payload: status,
});

export const setAddEvidenceComment = (evidence) => ({
	type: ACCOUNTS_ADD_EVIDENCE_COMMENT,
	payload: evidence,
});

export const addEvidenceComment = (body, onLoad) => (dispatch) => {
	dispatch(setAddEvidenceCommentStatus(LOADING));
	onLoad();
	addCommentAPI(body)
		.then((evidence) => {
			dispatch(setAddEvidenceComment(evidence));
			dispatch(setAddEvidenceCommentStatus(NONE));
		})
		.catch((err) => {
			dispatch(setAddEvidenceCommentStatus(ERROR));
		});
};

export const changeFilterField = (value) => ({
	type: ACCOUNTS_CHANGE_FILTER_FIELD,
	payload: value,
});

export const resetFilters = (filters) => ({
	type: ACCOUNTS_RESET_FILTERS,
	payload: filters,
});

export const setDevices = (devices) => ({
	type: ACCOUNTS_SET_DEVICES,
	payload: devices,
});

export const setTransactions = (transactions) => ({
	type: ACCOUNTS_SET_TRANSACTIONS,
	payload: transactions,
});

export const setGetTransactionsStatus = (status) => ({
	type: ACCOUNTS_GET_TRANSACTIONS_STATUS,
	payload: status,
});

export const getAccountTransactions =
	({
		cursor,
		page,
		pageSize,
		accountId,
		changeCursors,
		filters,
		refresh,
		isSetData,
		sorting,
	}) =>
	(dispatch, getState) => {
		dispatch(setGetTransactionsStatus(LOADING));
		getTransactionsAPI({
			cursor,
			page,
			pageSize,
			filters: { ...filters, accountId },
			sorting,
		})
			.then((transactions) => {
				if (isSetData) {
					dispatch(setTransactions(transactions));
				}
				changeCursors({ ...transactions, refresh });
				dispatch(setGetTransactionsStatus(NONE));
			})
			.catch(() => dispatch(setGetTransactionsStatus(ERROR)));
	};

export const setGetAccountsForEvidenceStatus = (status) => ({
	type: ACCOUNTS_GET_ACCOUNTS_FOR_EVIDENCE_STATUS,
	payload: status,
});

export const setAccountsForEvidence = (devices) => ({
	type: ACCOUNTS_SET_ACCOUNTS_FOR_EVIDENCE,
	payload: devices,
});

export const getAccountsForEvidence =
	({ cursor, page, pageSize, changeCursors, filters, isSetData, sorting }) =>
	(dispatch, getState) => {
		dispatch(setGetAccountsForEvidenceStatus(LOADING));
		getAccountAccountsAPI({ cursor, page, pageSize, filters, sorting })
			.then((data) => {
				if (isSetData) {
					dispatch(setAccountsForEvidence(data));
				}
				changeCursors({ ...data });
				dispatch(setGetAccountsForEvidenceStatus(NONE));
			})
			.catch(() => dispatch(setGetAccountsForEvidenceStatus(ERROR)));
	};

export const setGetAttachedAccountsStatus = (status) => ({
	type: ACCOUNTS_GET_ATTACHED_ACCOUNTS_STATUS,
	payload: status,
});

export const setGetAttachedDevicesStatus = (status) => ({
	type: ACCOUNTS_GET_ATTACHED_DEVICES_STATUS,
	payload: status,
});

export const setAttachedAccounts = (attachedAccounts) => ({
	type: ACCOUNTS_SET_ATTACHED_ACCOUNTS,
	payload: attachedAccounts,
});

export const setAttachedDevices = (attachedDevices) => ({
	type: ACCOUNTS_SET_ATTACHED_DEVICES,
	payload: attachedDevices,
});

export const getAccountsIdByAccount = (id) => (dispatch, getState) => {
	dispatch(setGetAttachedAccountsStatus(LOADING));
	getAccountsByAccountAPI(id)
		.then((attachedAccounts) => {
			dispatch(setAttachedAccounts(attachedAccounts.accounts));
			dispatch(setAttachedDevices(attachedAccounts.devices));
			dispatch(setGetAttachedAccountsStatus(NONE));
		})
		.catch(() => dispatch(setGetAttachedAccountsStatus(ERROR)));
};

export const changeAccountsForEvidenceFilterField = (value) => ({
	type: ACCOUNTS_CHANGE_ACCOUNTS_FOR_EVIDENCE_FILTER_FIELD,
	payload: value,
});

export const setAccountsByName = (accounts) => ({
	type: ACCOUNTS_SET_ACCOUNTS_BY_NAME,
	payload: accounts,
});

export const setAccountsByNameStatus = (status) => ({
	type: ACCOUNTS_GET_ACCOUNTS_BY_NAME_STATUS,
	payload: status,
});

export const getAccountsByName = (name) => (dispatch, getState) => {
	dispatch(setAccountsByNameStatus(LOADING));
	getAccountByNameAPI(name)
		.then((accounts) => {
			dispatch(setAccountsByName(accounts.data));
			dispatch(setAccountsByNameStatus(NONE));
		})
		.catch(() => dispatch(setAccountsByNameStatus(ERROR)));
};

export const setDeleteAccountStatus = (status) => ({
	type: ACCOUNTS_DELETE_ACCOUNT_STATUS,
	payload: status,
});

export const deleteAccount = (id, onLoad, refreshPage) => (dispatch) => {
	dispatch(setDeleteAccountStatus(LOADING));
	deleteAccountAPI(id)
		.then((res) => {
			refreshPage(res.id);
			onLoad();
			dispatch(setDeleteAccountStatus(NONE));
		})
		.catch(() => dispatch(setDeleteAccountStatus(ERROR)));
};

export const changeTransactionFilterField = (value) => ({
	type: ACCOUNTS_CHANGE_TRANSACTIONS_FILTER_FIELD,
	payload: value,
});

export const resetTransactionFilters = (filters) => ({
	type: ACCOUNTS_RESET_TRANSACTIONS_FILTERS,
	payload: filters,
});

export const setExportTransactionsToJSONStatus = (status) => ({
	type: ACCOUNTS_EXPORT_TRANSACTIONS_TO_JSON_STATUS,
	payload: status,
});

export const exportTransactionsToJSON =
	(filters, sorting, accountId, onLoad) => (dispatch) => {
		const body = {
			filters: { ...filters, accountId },
			sorting,
		};
		dispatch(setExportTransactionsToJSONStatus(LOADING));
		exportTransactionsAPI(body)
			.then((res) => {
				blobDownloader({
					data: res.data,
					options: { type: 'Application/json' },
				});

				dispatch(setExportTransactionsToJSONStatus(NONE));
				onLoad();
			})
			.catch((error) => {
				dispatch(setExportTransactionsToJSONStatus(ERROR));
			});
	};

export const setExportTransactionsToCSVStatus = (status) => ({
	type: ACCOUNTS_EXPORT_TRANSACTIONS_TO_CSV_STATUS,
	payload: status,
});

export const exportTransactionsToCSV =
	(filters, sorting, accountId, onLoad) => (dispatch) => {
		const body = {
			filters: { ...filters, accountId },
			sorting,
		};
		dispatch(setExportTransactionsToCSVStatus(LOADING));
		exportTransactionsToCSVAPI(body)
			.then((res) => {
				blobDownloader({ data: res.data, options: { type: 'text/csv' } });

				dispatch(setExportTransactionsToCSVStatus(NONE));
				onLoad();
			})
			.catch((error) => {
				dispatch(setExportTransactionsToCSVStatus(ERROR));
			});
	};

export const setGetEvidenceHistoryStatus = (status) => ({
	type: ACCOUNTS_GET_EVIDENCE_HISTORY_STATUS,
	payload: status,
});

export const setEvidenceHistory = (evidences) => ({
	type: ACCOUNTS_SET_EVIDENCE_HISTORY,
	payload: evidences,
});

export const getAccountsEvidenceHistory =
	({
		cursor,
		page,
		pageSize,
		changeCursors,
		filters,
		refresh,
		isSetData,
		sorting,
	}) =>
	(dispatch) => {
		dispatch(setGetEvidenceHistoryStatus(LOADING));
		getEvidenceHistoryAPI({
			cursor,
			page,
			pageSize,
			filters,
			sorting,
		})
			.then((evidences) => {
				if (isSetData) {
					dispatch(setEvidenceHistory(evidences));
				}
				changeCursors({ ...evidences, refresh });
				dispatch(setGetEvidenceHistoryStatus(NONE));
			})
			.catch(() => dispatch(setGetEvidenceHistoryStatus(ERROR)));
	};
