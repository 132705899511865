const scoreIndicator = {
	width: 20,
	border: '1px solid grey',
	borderRadius: 10,
	height: 20,
	margin: 'auto',
};

export const scoreIndicatorStyles = {
	'& .tableCellYellow': {
		'& .scoreIndicator': {
			...scoreIndicator,
			backgroundColor: 'yellow',
		},
	},
	'& .tableCellRed': {
		'& .scoreIndicator': {
			...scoreIndicator,
			backgroundColor: 'red',
		},
	},
	'& .tableCellGreen': {
		'& .scoreIndicator': {
			...scoreIndicator,
			backgroundColor: 'green',
		},
	},
};

export function setScoreIndicatorStyle(styles) {
	return ['& .tableCellGreen', '& .tableCellYellow', '& .tableCellRed'].reduce(
		(total, current) => ({
			...total,
			[current]: {
				...scoreIndicatorStyles[current],
				'& .scoreIndicator': {
					...scoreIndicatorStyles[current]['& .scoreIndicator'],
					...styles,
				},
			},
		}),
		{}
	);
}
export const scoreIndicatorDarkTheme = {
	'& .tableCellYellow': {
		'& .scoreIndicator': {
			border: '1px solid white',
		},
	},
	'& .tableCellRed': {
		'& .scoreIndicator': {
			border: '1px solid white',
		},
	},
	'& .tableCellGreen': {
		'& .scoreIndicator': {
			border: '1px solid white',
		},
	},
};
