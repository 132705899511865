import { PureTableCell, CustomTableCell } from 'components';
import devicesColumns from './devices';
import { dateFormatter } from 'helpers/formatters';
import React from 'react';

const devicesColumnsCopy = [...devicesColumns];
const last = devicesColumnsCopy.pop();
const accountDevicesColumns = [
	...devicesColumnsCopy,
	{
		field: 'lastIp',
		editable: false,
		sortable: false,
		headerName: 'Last ip used',
		headerAlign: 'center',
		flex: 1,
		renderCell: (params) => <CustomTableCell {...params} />,
	},
	{
		field: 'count',
		editable: false,
		sortable: false,
		headerName: 'Transactions',
		headerAlign: 'center',
		width: 150,
		renderCell: (params) => <PureTableCell {...params} />,
	},
	{
		field: 'lastSeen',
		editable: false,
		sortable: false,
		headerAlign: 'center',
		width: 240,
		renderCell: (params) => (
			<CustomTableCell {...params} value={dateFormatter(params.value)} />
		),
	},
	last,
];

export default accountDevicesColumns;
