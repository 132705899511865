import {
	RULES_SET_SINGLE_RULE,
	RULES_DELETE_RULE,
	RULES_SET_RULES,
	RULES_DELETE_RULE_STATUS,
	RULES_GET_SINGLE_RULE_STATUS,
	RULES_GET_RULES_STATUS,
	RULES_CREATE_RULE_STATUS,
	RULES_EDIT_RULE_STATUS,
	RULES_SET_KEYS,
	RULES_GET_KEYS_STATUS,
	RULES_CHANGE_FILTER_FIELD,
	RULES_GET_RULESETS_STATUS,
	RULES_SET_RULESETS,
	CREATE_RULE_FIELD_STATUS,
	DELETE_RULE_FIELD_STATUS,
	DELETE_RULE_FIELD,
} from '../constants';
import { LOADING, NONE } from 'constants/apiStatuses';

const initialState = {
	data: {
		ruleSets: null,
		rules: null,
	},
	getRulesStatus: LOADING,
	singleRule: null,
	getSingleRuleStatus: LOADING,
	editRuleStatus: NONE,
	deleteRuleStatus: NONE,
	createRuleStatus: NONE,
	createRuleFieldStatus: NONE,
	deleteRuleFieldStatus: NONE,
	ruleKeys: null,
	getRuleKeysStatus: NONE,
	ruleSets: null,
	getRuleSetsStatus: NONE,
	filters: {
		ruleSet: '',
	},
};

const rulesReducer = (state = initialState, action) => {
	switch (action.type) {
		case RULES_SET_SINGLE_RULE: {
			return {
				...state,
				singleRule: action.payload,
			};
		}

		case RULES_GET_SINGLE_RULE_STATUS: {
			return {
				...state,
				getSingleRuleStatus: action.payload,
			};
		}

		case RULES_EDIT_RULE_STATUS: {
			return {
				...state,
				editRuleStatus: action.payload,
			};
		}

		case RULES_CREATE_RULE_STATUS: {
			return {
				...state,
				createRuleStatus: action.payload,
			};
		}

		case RULES_GET_RULES_STATUS: {
			return {
				...state,
				getRulesStatus: action.payload,
			};
		}

		case RULES_SET_RULES: {
			return {
				...state,
				data: action.payload,
			};
		}

		case RULES_DELETE_RULE_STATUS: {
			return {
				...state,
				deleteRuleStatus: action.payload,
			};
		}

		case RULES_DELETE_RULE: {
			const neededIdx = state.data.rules.findIndex(
				(rule) => rule.id === action.payload.id
			);

			const newRules = [
				...state.data.rules.slice(0, neededIdx),
				...state.data.rules.slice(neededIdx + 1),
			];

			return {
				...state,
				data: {
					ruleSets: action.payload.ruleSets,
					rules: newRules,
				},
			};
		}

		case RULES_GET_KEYS_STATUS: {
			return {
				...state,
				getRuleKeysStatus: action.payload,
			};
		}

		case RULES_SET_KEYS: {
			return {
				...state,
				ruleKeys: action.payload,
			};
		}

		case RULES_CHANGE_FILTER_FIELD: {
			return {
				...state,
				filters: {
					...state.filters,
					[action.payload.key]: action.payload.value,
				},
			};
		}

		case RULES_GET_RULESETS_STATUS: {
			return {
				...state,
				getRuleSetsStatus: action.payload,
			};
		}

		case RULES_SET_RULESETS: {
			return {
				...state,
				ruleSets: action.payload,
			};
		}

		case CREATE_RULE_FIELD_STATUS: {
			return {
				...state,
				createRuleFieldStatus: action.payload,
			};
		}

		case DELETE_RULE_FIELD_STATUS: {
			return {
				...state,
				deleteRuleFieldStatus: action.payload,
			};
		}

		case DELETE_RULE_FIELD: {
			const ruleFieldIdx = state.ruleKeys.findIndex(
				(field) => field.id === action.payload.id
			);
			return {
				...state,
				ruleKeys: [
					...state.ruleKeys.slice(0, ruleFieldIdx),
					...state.ruleKeys.slice(ruleFieldIdx + 1),
				],
			};
		}

		default: {
			return state;
		}
	}
};

export default rulesReducer;
