import axiosInstance from '../index';

const getRating = async (body) => {
	return axiosInstance
		.get(
			`/rating-internal?fp=${btoa(JSON.stringify(body.fp))}&rule-set=${
				body.ruleSet
			}&account-name=${body.accountName}&debug=${btoa(
				JSON.stringify(body.debug)
			)}&ip=${body.ip}&ip-reputation=${body.ipReputation}`
		)
		.then((res) => res.data);
};

export { getRating };
