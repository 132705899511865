import React from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
	CircularProgress,
	TextField,
} from '@mui/material';

const DeleteModal = ({
	onClose,
	onSubmit,
	modalBody,
	modalHTML,
	title,
	load = false,
	buttons = { accept: 'Yes', cancel: 'No' },
	input = null,
}) => {
	const [inputValue, setInputValue] = React.useState('');
	const handleInputModalChange = (e) => {
		setInputValue(e.target.value);
	};

	const handleSubmit = () => {
		onSubmit(inputValue);
	};

	return (
		<Dialog open={true} fullWidth maxWidth='xs' onClose={onClose}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>
				<DialogContentText>{modalBody}</DialogContentText>
				{modalHTML}
				{input && (
					<TextField
						autoFocus
						fullWidth
						value={inputValue}
						onChange={handleInputModalChange}
						{...input}
					/>
				)}
				<DialogActions>
					{load ? (
						<CircularProgress />
					) : (
						<>
							<Button color='secondary' onClick={onClose}>
								{buttons.cancel}
							</Button>
							<Button color='primary' onClick={handleSubmit}>
								{buttons.accept}
							</Button>
						</>
					)}
				</DialogActions>
			</DialogContent>
		</Dialog>
	);
};

export default DeleteModal;
