import React from 'react';
import { connect } from 'react-redux';
import { Typography, Box, Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import { getRuleKeys, deleteRuleField } from 'actions/rulesActions';
import ruleFields from 'constants/columns/ruleFields';
import { rowsPerPageOptions } from 'constants/tablesVariables';
import './styles.css';
import { useNavigate } from 'react-router-dom';
import { DeleteModal } from 'components';
import LoadingSpinner from '../../components/LoadingSpinner';
import { LOADING } from 'constants/apiStatuses';

const RuleFieldsPage = (props) => {
	const {
		ruleKeys,
		getRuleKeys,
		deleteRuleField,
		getRuleKeysStatus,
		userInfo,
	} = props;

	const navigate = useNavigate();

	const [paginationModel, setPaginationModel] = React.useState({
		page: 0,
		pageSize: rowsPerPageOptions[0],
	});
	const [fieldIdToDelete, setFieldIdToDelete] = React.useState('');

	const handleDeleteRuleField = () => {
		deleteRuleField(fieldIdToDelete, () => setFieldIdToDelete(''));
	};

	const handleCloseModal = () => {
		setFieldIdToDelete('');
	};

	const deleteRuleFieldClick = (ruleFieldId) => (e) => {
		e.stopPropagation();
		setFieldIdToDelete(ruleFieldId);
	};

	const extendedColumns = [
		...ruleFields,
		{
			field: 'actions',
			headerName: 'Actions',
			sortable: false,
			renderCell: (params) => (
				<Button
					className={userInfo.role !== 'admin' ? 'disableButton' : 'btnTrash'}
					onClick={deleteRuleFieldClick(params.row.id)}
					disabled={userInfo.role !== 'admin'}
				>
					Delete
				</Button>
			),
		},
	];

	const handlePaginationModelChange = (newPaginationModel) => {
		setPaginationModel(newPaginationModel);
	};

	React.useEffect(() => {
		getRuleKeys();
	}, []);

	const handleGetRowId = (data) => data.key;

	return (
		<Box className='container'>
			<Typography variant='h4'>Rule fields</Typography>
			<Button
				color='primary'
				disabled={userInfo.role !== 'admin'}
				variant='contained'
				className={userInfo.role !== 'admin' ? 'disableButton' : 'addRuleField'}
				onClick={() => navigate('/new-rule-field')}
			>
				Add Rule Field
			</Button>
			{getRuleKeysStatus === LOADING ? (
				<LoadingSpinner />
			) : (
				ruleKeys && (
					<DataGrid
						autoHeight
						disableColumnMenu={true}
						disableSelectionOnClick={true}
						columns={extendedColumns}
						rows={ruleKeys}
						getRowId={handleGetRowId}
						className='fieldTable'
						paginationModel={paginationModel}
						pageSizeOptions={rowsPerPageOptions}
						onPaginationModelChange={handlePaginationModelChange}
					/>
				)
			)}
			{fieldIdToDelete && (
				<DeleteModal
					title='Deleting rule field'
					onClose={handleCloseModal}
					onSubmit={handleDeleteRuleField}
					modalBody='Are you sure want to delete this rule field?'
				/>
			)}
		</Box>
	);
};

const mapStateToProps = ({ rules, auth }) => ({
	ruleKeys: rules.ruleKeys || [],
	getRuleKeysStatus: rules.getRuleKeysStatus,
	userInfo: auth.userInfo,
});

const mapDispatchToProps = (dispatch) => ({
	getRuleKeys: () => dispatch(getRuleKeys),
	deleteRuleField: (id, onLoad) => dispatch(deleteRuleField(id, onLoad)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RuleFieldsPage);
