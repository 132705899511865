import { getActions as getActionsAPI } from 'services/api/actions/getActions';
import { getSingleAction as getSingleActionAPI } from 'services/api/actions/getSingleAction';
import {
	ACTIONS_GET_ACTIONS_STATUS,
	ACTIONS_SET_ACTIONS,
	ACTIONS_GET_SINGLE_ACTION_STATUS,
	ACTIONS_SET_SINGLE_ACTION,
	ACTIONS_CHANGE_FILTER_FIELD,
	ACTIONS_RESET_FILTERS,
} from '../constants';
import { LOADING, ERROR, NONE } from 'constants/apiStatuses';

const setGetActionsStatus = (status) => ({
	type: ACTIONS_GET_ACTIONS_STATUS,
	payload: status,
});

const setActions = (actions) => ({
	type: ACTIONS_SET_ACTIONS,
	payload: actions,
});

export const getActions =
	({ cursor, page, pageSize, changeCursors, filters, refresh, sorting }) =>
	(dispatch, getState) => {
		dispatch(setGetActionsStatus(LOADING));
		getActionsAPI({ cursor, page, pageSize, filters, sorting })
			.then((data) => {
				dispatch(setActions(data));
				changeCursors({ ...data, refresh });
				dispatch(setGetActionsStatus(NONE));
			})
			.catch(() => dispatch(setGetActionsStatus(ERROR)));
	};

const setGetSingleActionStatus = (status) => ({
	type: ACTIONS_GET_SINGLE_ACTION_STATUS,
	payload: status,
});

const setSingleAction = (action) => ({
	type: ACTIONS_SET_SINGLE_ACTION,
	payload: action,
});

export const getSingleAction = (id) => (dispatch, getState) => {
	dispatch(setGetSingleActionStatus(LOADING));
	getSingleActionAPI(id)
		.then((action) => {
			dispatch(setSingleAction(action));
			dispatch(setGetSingleActionStatus(NONE));
		})
		.catch(() => dispatch(setGetSingleActionStatus(ERROR)));
};

export const changeFilterField = (value) => ({
	type: ACTIONS_CHANGE_FILTER_FIELD,
	payload: value,
});

export const resetFilters = (filters) => ({
	type: ACTIONS_RESET_FILTERS,
	payload: filters,
});
