export const getRowColorByMark = (params) => {
	return params.row.isMarked ? 'tableCellYellow' : 'tableCellGreen';
};

export const getRowColorByScore = (param) => {
	const score = param.row.score;
	if (score < 0 && score > -100) {
		return 'tableCellYellow';
	}
	if (score <= -100) {
		return 'tableCellRed';
	}
	return 'tableCellGreen';
};
